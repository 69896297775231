export default {
  company: {
    name: '',
    address: {
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      lat: null,
      lng: null,
      timeZone: null,
      title: null,
      completeAddress: '',
      addressName: '',
    },
    ada: false,
    spab: false,
    email: '',
    emailSenderName: '',
    phone: '',
    opsPhone: '',
    fax: null,
    dotNumber: null,
    companyType: {
      companyTypeId: null,
      companyTypeName: null,
    },
    websiteUrl: '',
    redirectPageUrl: '',
    active: true,
    preferred: false,
    dotVerified: false,
  },
  checkoutTypes: [
    {
      checkoutTypeId: 1,
      checkoutTypeName: 'E-Checkout',
      key: 'e-checkout',
      description: '',
    },
    {
      checkoutTypeId: 2,
      checkoutTypeName: 'PDF',
      key: 'pdf',
      description: '',
    },
  ],
  companyTypes: [
    {
      companyTypeId: 1,
      companyTypeName: 'Free',
      key: 'free',
      description: '',
    },
    {
      companyTypeId: 2,
      companyTypeName: 'Paid',
      key: 'paid',
      description: '',
    },
    {
      companyTypeId: 3,
      companyTypeName: 'Broker',
      key: 'broker',
      description: '',
    },
    {
      companyTypeId: 4,
      companyTypeName: 'Admin',
      key: 'admin',
      description: '',
    },
    {
      companyTypeId: 5,
      companyTypeName: 'Lead',
      key: 'lead',
      description: '',
    },
  ],

  paymentMethods: [
    {
      paymentMethodTypeId: 1,
      paymentMethodTypeName: 'Credit Card',
      key: 'credit_card',
    },
    {
      paymentMethodTypeId: 2,
      paymentMethodTypeName: 'ACH',
      key: 'ach',
    },
  ],
  carrierOperationTypes: [
    {
      carrierOperationTypeId: 1,
      carrierOperationName: 'Interstate',
    },
    {
      carrierOperationTypeId: 2,
      carrierOperationName: 'Intrastate Only',
    },
  ],
  insuranceOnFileTypes: [
    {
      insuranceOnFileTypeId: 1,
      insuranceOnFileName: 'Yes',
    },
    {
      insuranceOnFileTypeId: 0,
      insuranceOnFileName: 'No',
    },
  ],
  insuranceActiveTypes: [
    {
      insuranceActiveTypeId: 1,
      insuranceActiveName: 'Active',
    },
    {
      insuranceActiveTypeId: 2,
      insuranceActiveName: 'Inactive',
    },
    {
      insuranceActiveTypeId: 3,
      insuranceActiveName: 'N/A',
    },
  ],
}
