<template>
  <div class="send-amenities-update">
    <div class="send-amenities-update--content">
      <v-layout row class="send-amenities-update--email-row">
        <v-flex xs12>
          <CRTextArea
            v-model="comments"
            class="send-amenities-update--input"
            placeholder="Write update details for the provider here."
            background-color="inputBackgroundGray"
            validate-on-blur
          />
        </v-flex>
      </v-layout>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-itinerary-update-send-button"
      class="cr-sidebar-dialog--action-btn"
      @click="send"
    >
      <span v-if="!loading">Send</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>
<script>
import { isRequired, isNotEmpty } from '@/utils/validators'

export default {
  props: {
    managedId: {
      type: String,
      required: true,
    },
    previousAmenities: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      comments: '',
      loading: false,
    }
  },
  methods: {
    async send() {
      this.loading = true

      const payload = {
        managedId: this.managedId,
        previousAmenities: this.previousAmenities,
        comments: this.comments || null, // Don't send an empty string if nothing is provided
      }

      try {
        await this.$store.dispatch('trips/sendAmenitiesUpdateEmail', payload)
        this.close()
      } catch (e) {
        console.error(e)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Failed to send email',
        })
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.send-amenities-update {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--email-row {
    margin: 10px 0;
  }

  &--link-btn {
    color: $primary;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
