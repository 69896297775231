<template>
  <v-container grid-list-md>
    <span>
      <h6>{{ detailTitle }}</h6>
      <span v-html="detailDescription" />
    </span>
  </v-container>
</template>

<script>
const CREATED = 1
const ACCEPTED = 2
const REJECTED = 3
const MODIFIED = 4
const DELETED = 5
const DRIVER_ASSIGNMENT_CREATED = 6
const DRIVER_ASSIGNMENT_DELETED = 7
const VEHICLE_ASSIGNMENT_CREATED = 8
const VEHICLE_ASSIGNMENT_DELETED = 9
const OPERATOR_VIEWED_IN_CUPOPS = 10
const OPERATOR_VIEWED_IN_COACHRAIL = 11
const NEEDS_MANUAL_REFERRAL = 12
const ITINERARY_EDITED = 13
const NOTE_MODIFIED = 14
const CLIENT_PAYMENT_COLLECTED = 15
const CLIENT_REFUND_ADDED = 16
const CLIENT_CHARGE_ADDED = 17
const CLIENT_CHARGE_REDUCED = 18
const CLIENT_INVOICE_SENT = 19
const CLIENT_DUE_DATE_CHANGED = 20
const PROVIDER_PAID_PROVIDER = 21
const PROVIDER_REFUND_ADDED = 22
const PROVIDER_CHARGE_ADDED = 23
const PROVIDER_CHARGE_REDUCED = 24
const RESERVATION_CANCELLATION_REQUESTED = 25
const RESERVATION_CANCELLED = 26
const PROVIDER_INVOICE_SENT = 27
const CONFIRMATION_RESENT = 28
const DRIVER_INFO_SENT = 29
const RECEIPT_RESENT = 30
const CUSTOMER_CHANGED_RESERVATION = 31
const CUSTOMER_CHANGED_QUOTE = 32
const AGENT_CHANGED_RESERVATION = 33
const AGENT_CHANGED_QUOTE = 34
const OPERATOR_UNASSIGNED = 36
const OPERATOR_UNASSIGNED_EMAIL_SENT = 37
const RESERVATION_CANCELLATION_VOIDED = 38
const RESERVATION_REINSTATEMENT_REQUESTED = 41
const RESERVATION_REINSTATEMENT = 42
const RESERVATION_REINSTATEMENT_VOIDED = 43
const OPERATOR_CLICKED_REQUEST_CANCELLATION = 44
const OFFERED_AVAILABILITY_OVERRIDE = 62
const OFFERED_EDITED_REFERRAL = 66
const ACCEPTED_EDITED_REFERRAL = 67
const REJECTED_EDITED_REFERRAL = 68
const TRIP_TRACKED = 63
const TRIP_PARTIALLY_TRACKED = 64
const TRIP_NOT_TRACKED = 65
const RESERVATION_TIER_UPDATED = 72
const GPS_DATA_RECORDED = 73
const PAYMENT_TERMS_DETERMINED = 74
const BALANCE_PAYMENT_METHOD_UPDATED = 75
const PAYMENT_TERMS_EDITED = 76
const CHANGE_REQUEST_SUBMITTED = 78
const CHANGE_REQUEST_AUTO_APPLIED = 80

import { isoToString } from '@/utils/time'
import { mapGetters } from 'vuex'

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
    isGenericTitle() {
      return [
        CREATED,
        OFFERED_AVAILABILITY_OVERRIDE,
        ACCEPTED,
        ACCEPTED_EDITED_REFERRAL,
        REJECTED,
        REJECTED_EDITED_REFERRAL,
        DELETED,
        DRIVER_ASSIGNMENT_CREATED,
        DRIVER_ASSIGNMENT_DELETED,
        VEHICLE_ASSIGNMENT_CREATED,
        VEHICLE_ASSIGNMENT_DELETED,
      ].includes(this.row?.actionTypeId)
    },
    isGenericStatus() {
      return (
        [
          CREATED,
          DELETED,
          DRIVER_ASSIGNMENT_DELETED,
          VEHICLE_ASSIGNMENT_DELETED,
        ].includes(this.row?.actionTypeId) && !this.row.actionType === 'Offered'
      )
    },
    useRowTitle() {
      return (
        [
          ITINERARY_EDITED,
          CLIENT_INVOICE_SENT,
          CLIENT_DUE_DATE_CHANGED,
          PROVIDER_PAID_PROVIDER,
          PROVIDER_INVOICE_SENT,
          CUSTOMER_CHANGED_RESERVATION,
          CUSTOMER_CHANGED_QUOTE,
          AGENT_CHANGED_RESERVATION,
          AGENT_CHANGED_QUOTE,
          RESERVATION_CANCELLATION_REQUESTED,
          RESERVATION_CANCELLED,
          RESERVATION_CANCELLATION_VOIDED,
          RESERVATION_REINSTATEMENT_REQUESTED,
          RESERVATION_REINSTATEMENT,
          RESERVATION_REINSTATEMENT_VOIDED,
          BALANCE_PAYMENT_METHOD_UPDATED,
        ].includes(this.row?.actionTypeId) || this.useRowDescription
      )
    },
    useRowDescription() {
      return !!this.row?.description
    },
    detailTitle() {
      if (this.isGenericTitle) {
        return 'Reservation Status'
      } else if (this.useRowTitle) {
        return this.row.actionType
      }
      switch (this.row?.actionTypeId) {
        case MODIFIED:
          return 'Reservation Status Modified'
        case NOTE_MODIFIED:
          return this.row.description
        default:
          return ''
      }
    },
    detailDescription() {
      if (this.isGenericStatus) {
        return `Status is ${this.row.actionType.bold()}`
      } else if (this.useRowDescription) {
        return this.row.description
      }
      switch (this.row?.actionTypeId) {
        case CREATED: {
          if (this.row?.newValue) {
            const company = this.row.newValue.split('|')[1]
            return `Offered to ${company}`
          }
          return `Status is ${this.row.actionType.bold()}`
        }
        case OFFERED_AVAILABILITY_OVERRIDE: {
          if (this.row?.newValue) {
            const company = this.row.newValue.split('|')[1]
            return `Offered to ${company}`
          }
          return `Status is ${this.row.actionType.bold()}`
        }
        case ACCEPTED_EDITED_REFERRAL:
        case ACCEPTED:
          return `Status is <b>Accepted</b>. <br/> Signed by: ${
            this.row.newValue.bold() || ''
          }`
        case REJECTED_EDITED_REFERRAL:
        case REJECTED:
          if (this.isReferralRejectionReasonV2Enabled) {
            return `Status is <b>Rejected</b>. <br/> <b>Reason for Rejection:</b> ${
              this.row.newValue || ''
            } <br/> <b>Rejection Notes:</b> ${this.row?.description || ''}`
          }
          return `Status is ${this.row.actionType.bold()}. <br/> <b>Reason for Rejection:</b> ${
            this.row.referralRejectionReasonType || ''
          } <br/> <b>Rejection Notes:</b> ${this.row?.newValue || ''}`
        case MODIFIED:
          return `Modified from ${this.computedValue(
            this.row.previousValue
          ).bold()} to ${this.computedValue(this.row.newValue).bold()}.`
        case DRIVER_ASSIGNMENT_CREATED:
          return `Trip assigned to driver ${this.computedValue(
            this.row.newValue
          ).bold()}.`
        case VEHICLE_ASSIGNMENT_CREATED:
          return `Trip assigned to vehicle ${
            this.row.newValue
          .bold()}.`
        case OPERATOR_VIEWED_IN_CUPOPS:
          return 'Viewed Booking Detail in CharterUP for Operators.'
        case OPERATOR_VIEWED_IN_COACHRAIL:
          return 'Viewed Booking Detail in CoachRail.'
        case OPERATOR_CLICKED_REQUEST_CANCELLATION:
          return 'Operator Clicked Request Cancellation.'
        case NEEDS_MANUAL_REFERRAL:
          return `MARGE enabled set to ${
            this.row.newValue === 'true' ? '<b>no</b>' : '<b>yes</b>'
          }.`
        case ITINERARY_EDITED:
          return `
        ${this.splitUpdateString(this.row.description)[0]}
        ${this.splitUpdateString(this.row.description)[1]}`
        case CLIENT_DUE_DATE_CHANGED:
          return `From ${this.formatDatetime(
            this.row.previousValue
          )} to ${this.formatDatetime(this.row.newValue)}`
        case PROVIDER_PAID_PROVIDER:
          return `${this.row.description?.split('|')?.[0] || ''} <br/>
          ${this.row.description?.split('|')?.[1] || ''}
          `
        case CUSTOMER_CHANGED_RESERVATION:
        case CUSTOMER_CHANGED_QUOTE:
        case AGENT_CHANGED_RESERVATION:
        case AGENT_CHANGED_QUOTE:
        case BALANCE_PAYMENT_METHOD_UPDATED:
          return `${this.row.previousValue} to ${this.row.newValue}`
        case OPERATOR_UNASSIGNED:
          return `Referral unassigned from ${this.row.newValue.split('|')[1]}.`
        case OPERATOR_UNASSIGNED_EMAIL_SENT:
          return 'Operator unassigned email sent.'
        case RESERVATION_CANCELLATION_REQUESTED:
        case RESERVATION_CANCELLED:
        case RESERVATION_CANCELLATION_VOIDED:
          return `${
            this.row.newValue
              ? this.row.newValue + '<br/>' + this.row.description
              : this.row.description
          }`
        case TRIP_TRACKED:
        case TRIP_PARTIALLY_TRACKED:
        case TRIP_NOT_TRACKED: {
          let result = '<b>' + this.row.actionType + '</b><br/>'
          const description = this.row.description?.split(';')
          result += description?.[0] || ''
          const vehicleDescriptions = description?.[1]?.split('|')
          if (vehicleDescriptions) {
            result += '<ul>'
            for (let i = 0; i < vehicleDescriptions.length; i++) {
              result += '<li>' + vehicleDescriptions[i] + '</li>'
            }
            result += '</ul>'
          }
          return result
        }
        case OFFERED_EDITED_REFERRAL: {
          const referralDetails = this.row.description.split(';')
          let result = '<b>Original Referral:</b><br/>'
          result += referralDetails[0] + '<br/>'
          result += '<b>New Referral:</b><br/>'
          result += referralDetails[1]
          return result
        }
        case GPS_DATA_RECORDED: {
          const description = this.row.description.split('*')
          let result = description?.[0] + '</br>' || ''
          result += `<b>${this.row.newValue}</b>`
          if (description.length > 1) {
            result += '<ul>'
            for (let i = 1; i < description.length; i++) {
              result += '<li>' + description[i] + '</li>'
            }
            result += '</ul>'
          }
          return result
        }
        case PAYMENT_TERMS_EDITED: {
          const description = JSON.parse(this.row.description)
          let result = '<b>Payment Terms Edited</b><br/>'
          result += `Payment Terms updated from ${this.row.previousValue} to ${this.row.newValue}<br/>`

          if (description.overrideReason.key === 'incorrect_tracked_status') {
            result += 'Tracked-On-Time Status updated:'
            for (const journey of description.reservationTrackingSummary[0].journeyTrackingSummary) {
              const showJourney = journey.trackedArrivalDisputed || journey.onTimeDisputed || journey.trackedOverallDisputed

              if (showJourney) {
                const disputeStrings = []
                if (journey.trackedArrivalDisputed) {
                  disputeStrings.push("Tracked Arrival")
                }
                if (journey.onTimeDisputed) {
                  disputeStrings.push("On Time")
                }
                if (journey.trackedOverallDisputed) {
                  disputeStrings.push("GPS Data")
                }

                result += `<br/><b>${journey.vehicleId}</b> | ${disputeStrings.join(', ')} `
              }
            }
          } else {
            result += `${description.overrideReason.description}`
          }
          if (description.overrideReason.key === 'open_complaint') {
            const caseNumbers = description.overrideNotes.split('|')
            result += '<br/><br/>'
            for (const caseNumber of caseNumbers) {
              result += `${caseNumber}<br/>`
            }
          } else {
            result += `<br/><br/><b>Notes:</b> ${description.overrideNotes || ''}`
          }
          return result
        }
        default:
          return ''
      }
    },
  },
  methods: {
    computedValue(val) {
      try {
        const parsed = JSON.parse(val)
        const out = Array.isArray(parsed) ? parsed : []
        return out.join(', ')
      } catch (e) {
        return val
      }
    },
    splitUpdateString(str) {
      if (str.indexOf(':') > 0) {
        const after = str.slice(str.indexOf(':') + 1)
        const before = str.slice(0, str.indexOf(':') + 1)
        return [before, after]
      }
      return [str, '']
    },
    formatDatetime(val) {
      return isoToString(
        val,
        this.row?.reservation?.firstStopAddressTimeZone ||
          this.currentUser?.timeZone ||
          this.currentUser?.company?.address?.timeZone
      )
    },
  },
}
</script>
