<template>
  <v-layout row class="margin-t-3">
    <v-flex xs12>
      <v-layout row wrap>
        <v-form ref="team-classifications-form" class="w-full">
          <v-flex class="margin-r-2" xs12>
            <CRSelect
              label="Product"
              :items="productClassifications"
              :value="productClassificationId"
              :disabled="disabled"
              item-value="classificationId"
              item-text="label"
              use-svg-icons
              :loading="loading"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Product is Required',
                  error: 'Product is Required',
                }),
              ]"
              @input="handleInput('product-input', $event)"
            />
          </v-flex>
          <v-flex class="margin-r-2" xs12>
            <CRSelect
              label="Sourcing Team"
              :items="sourcingTeamClassifications"
              :value="sourcingTeamClassificationId"
              :disabled="disabled"
              use-svg-icons
              item-value="classificationId"
              item-text="label"
              :loading="loading"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Sourcing Team is Required',
                  error: 'Sourcing Team is Required',
                }),
              ]"
              @input="handleInput('sourcing-team-input', $event)"
            />
          </v-flex>
          <v-flex class="margin-r-2" xs12>
            <CRSelect
              label="Support Team"
              :items="supportTeamClassifications"
              :value="supportTeamClassificationId"
              :disabled="disabled"
              item-value="classificationId"
              item-text="label"
              :loading="loading"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Support Team is Required',
                  error: 'Support Team is Required',
                }),
              ]"
              use-svg-icons
              @input="handleInput('support-team-input', $event)"
            />
          </v-flex>
          <v-flex v-if="showTierSelection || true" class="margin-r-2" xs12>
            <CRSelect
              id="reservation-tier"
              v-model="tierId"
              use-svg-icons
              label="Service Tier"
              :items="tiers"
              item-text="label"
              item-value="tierId"
              :loading="loading"
              @input="handleInput('reservation-tier-input', $event)"
            />
          </v-flex>
        </v-form>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import classifications from '@/services/classifications'
import { SplitFeatureFlag } from '@/utils/enum'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { findPermissionByName } from '@/utils/permissions'
import { mapGetters, mapActions } from 'vuex'

const PRODUCT_CLASSIFICATION_TYPE_KEY = 'product'
const SOURCING_TEAM_CLASSIFICATION_TYPE_KEY = 'sourcing_team'
const SUPPORT_TEAM_CLASSIFICATION_TYPE_KEY = 'support_team'

export default {
  model: {
    prop: 'classifications',
    event: 'input',
  },
  props: {
    mode: {
      type: String,
      default: 'add',
    },
    existingProductClassificationId: {
      type: Number,
      default: null,
    },
    existingSourcingTeamClassificationId: {
      type: Number,
      default: null,
    },
    existingSupportTeamClassificationId: {
      type: Number,
      default: null,
    },
    existingTierId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isReservationTeamSelection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parentClassifications: [],
      productClassifications: [],
      sourcingTeamClassifications: [],
      supportTeamClassifications: [],
      productClassificationId: null,
      sourcingTeamClassificationId: null,
      supportTeamClassificationId: null,
      tierId: null,
      productClassificationError: false,
      sourcingTeamClassificationError: false,
      supportTeamClassificationError: false,
      loading: false,
      isTiersEnabled: false,
    }
  },
  computed: {
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    currentUserProfile() {
      return this.$store.getters['auth/currentUserProfile'] || {}
    },
    tiers() {
      return this.getTiers()
    },
    canEditTiers() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'canEditTiers'
      )
    },
    showTierSelection() {
      return (
        this.isTiersEnabled &&
        this.canEditTiers &&
        this.isReservationTeamSelection
      )
    },
  },
  watch: {
    existingProductClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.productClassificationId = val
        }
      },
    },
    existingSourcingTeamClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.sourcingTeamClassificationId = val
        }
      },
    },
    existingSupportTeamClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.supportTeamClassificationId = val
        }
      },
    },
    existingTierId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.tierId = val
        }
      },
    },
  },
  async mounted() {
    this.getClassifications()
    this.isTiersEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.ServiceTier
    )
    if (this.isTiersEnabled) {
      await this.fetchAllTiers()
    }
  },
  methods: {
    ...mapGetters({
      getTiers: 'tiers/getTiers',
    }),
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
      fetchAllTiers: 'tiers/fetchAllTiers',
    }),
    isRequired,
    isNotEmpty,
    async getClassifications() {
      this.loading = true
      const productClassificationsData = await classifications.getClassificationsByType(
        { classificationTypeKey: PRODUCT_CLASSIFICATION_TYPE_KEY }
      )
      this.productClassifications = productClassificationsData.data?.subClassifications.sort(
        this.sortClassifications
      )
      const sourcingTeamClassificationsData = await classifications.getClassificationsByType(
        { classificationTypeKey: SOURCING_TEAM_CLASSIFICATION_TYPE_KEY }
      )
      this.sourcingTeamClassifications = sourcingTeamClassificationsData.data?.subClassifications.sort(
        this.sortClassifications
      )
      const supportTeamClassificationsData = await classifications.getClassificationsByType(
        { classificationTypeKey: SUPPORT_TEAM_CLASSIFICATION_TYPE_KEY }
      )
      this.supportTeamClassifications = supportTeamClassificationsData.data?.subClassifications.sort(
        this.sortClassifications
      )

      const defaultProductClassificationId = this.productClassifications?.find(
        (classification) => classification.key === 'marketplace_product'
      )?.classificationId
      const defaultSourcingClassificationId = this.sourcingTeamClassifications?.find(
        (classification) => classification.key === 'marketplace_sourcing'
      )?.classificationId
      const defaultSupportClassificationId = this.supportTeamClassifications?.find(
        (classification) => classification.key === 'l_cs_support'
      )?.classificationId

      if (
        this.isModeAdd &&
        !this.existingProductClassificationId &&
        defaultProductClassificationId
      ) {
        this.handleInput('product-input', defaultProductClassificationId)
      }
      if (
        this.isModeAdd &&
        !this.existingSourcingTeamClassificationId &&
        defaultSourcingClassificationId
      ) {
        this.handleInput('sourcing-team-input', defaultSourcingClassificationId)
      }
      if (
        this.isModeAdd &&
        !this.existingSupportTeamClassificationId &&
        defaultSupportClassificationId
      ) {
        this.handleInput('support-team-input', defaultSupportClassificationId)
      }
      this.loading = false
    },
    sortClassifications(a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    },
    validate() {
      return this.$refs['team-classifications-form'].validate()
    },
    handleInput(typeKey, classificationId) {
      this.$emit(typeKey, classificationId)
    },
  },
}
</script>
