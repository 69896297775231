<template>
  <div class="summary-section max-w-684 pa-3">
    <v-layout class="justify-space-between align-center mb-3">
      <span class="font-28">Notification Preferences</span>
      <span
        id="open-customer-account-sidebar"
        class="text-primary margin-r-4 cursor-pointer"
        @click="openEditPanel"
      >
        Edit
      </span>
    </v-layout>
    <div v-for="notificationSection in notificationSettingsSections">
      <h2 class="font-20 font-weight-bold">{{ notificationSection.header }}</h2>
      <h3 class="font-16">{{ notificationSection.subtext }}</h3>
      <v-container grid-list-md class="padding-y-4 margin-l-2" column>
        <v-layout column>
          <v-flex v-if="!isLoading" row v-for="notificationSetting in notificationSection.settingsForNotificationType">
            <div class="font-weight-bold">{{ notificationSetting.header }}</div>
            <div class="font-12">{{ notificationSetting.subtext }}</div>
            <AccountNotificationSettingDisplay
              :recipient="recipientTypes[BOOKING_CONTACT]"
              :status="getNotificationStatus(notificationSetting.type, BOOKING_CONTACT)"
            />
            <AccountNotificationSettingDisplay
              v-if="!notificationSetting.excludeForTripContact"
              :recipient="recipientTypes[TRIP_CONTACT]"
              :status="getNotificationStatus(notificationSetting.type, TRIP_CONTACT)"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>

import AccountNotificationSettingDisplay from '@/components/AccountNotificationSettingDisplay.vue'
import { BOOKING_CONTACT, TRIP_CONTACT } from '@/services/accountNotifications'
import { getNotificationSettingsKey } from '@/utils/accountNotification'

export default {
  components: { AccountNotificationSettingDisplay },
  props: {
    accountId: {
      type: Number,
      required: true
    },
    recipientTypes: {
      type: Object,
      required: true
    },
    notificationStatuses: {
      type: Object,
      required: true
    },
    notificationSettingTypes: {
      type: Object,
      required: true
    },
    notificationSettingsSections: {
      type: Array,
      required: true
    },
    notificationSettings: {
      type: Object,
      required: true
    },
    openEditPanel: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    isLoading: true,
    BOOKING_CONTACT,
    TRIP_CONTACT
  }),
  methods: {
    getNotificationStatus(notificationType, recipientType) {
      // Get the status key for the notification setting and recipient types, then get the display value of the status
      return this.notificationStatuses[this.notificationSettings[getNotificationSettingsKey(notificationType, recipientType)]]
    }
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch('accountNotifications/getAccountNotificationTypes'),
      this.$store.dispatch('accountNotifications/getAccountNotificationSettings', this.accountId)
    ])
    this.isLoading = false
  },
}
</script>

<style scoped lang="scss">

.summary-section {
  border: 2px solid $gray-light;
  border-radius: 10px;
}

</style>
