
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { PAYMENT_TERM_OPTIONS } from '@/models/paymentTerms'
import {
  isNotEmpty,
  isRequired,
} from '@/utils/validators'
import { DateTime } from 'luxon'
import { snakeToTitleCase } from '@/utils/string'
import { getInvoiceSentDateSidebarDetails, submitInvoiceSentDateSidebarDetails } from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'

const DEFAULT_PAYMENT_TERM_DAYS = 30

@Component
export default class PurchaseOrderSidebar extends Vue {
  @Prop({ required: true}) readonly reservationExternalId!: string

  paymentTerms: number | null = null
  invoiceSentDate: string | null = null
  dueDate: string | null = null
  invoiceNumber: string | null = null

  paymentTermOptions: Array<{ label: string; value: number }> = PAYMENT_TERM_OPTIONS
  loading: boolean = false

  notEmptyValidator(message: string) {
    return [isRequired(true, isNotEmpty, { req: message, error: message })]
  }

  async mounted() {
    const response = await getInvoiceSentDateSidebarDetails(this.reservationExternalId)
    const sidebarDetails = response.data

    // If sent date is not set, default to today
    this.invoiceSentDate = sidebarDetails.sentDate || DateTime.local().toISODate();

    // Map payment terms key on the reservation to the correct value in days
    const paymentTermsLabel = sidebarDetails.paymentTerms ? snakeToTitleCase(sidebarDetails.paymentTerms) : null
    this.paymentTerms = PAYMENT_TERM_OPTIONS.find(term => term.label === paymentTermsLabel)?.value || DEFAULT_PAYMENT_TERM_DAYS

    // If due date is not set, calculate it based on the payment terms
    this.dueDate = sidebarDetails.dueDate
    if (!sidebarDetails.dueDate) {
      this.recomputeDueDate()
    }

    // Invoice number is not required, so it does not have a default
    this.invoiceNumber = sidebarDetails.invoiceNumber
  }

  @Watch('paymentTerms')
  onPaymentTermsChanged(newTerms: number | null) {
    this.recomputeDueDate()
  }

  @Watch('invoiceSentDate')
  onInvoiceSentDateChanged(newDate: string | null) {
    this.recomputeDueDate()
  }

  recomputeDueDate() {
    if (this.invoiceSentDate && this.paymentTerms) {
      const sentDate = DateTime.fromISO(this.invoiceSentDate)
      this.dueDate = sentDate.plus({ days: this.paymentTerms }).toISODate()
    }
  }

  async save() {
    this.loading = true

    try {
      const formattedInvoiceSentDate = DateTime.fromISO(this.invoiceSentDate).toISO()
      const formattedDueDate = DateTime.fromISO(this.dueDate).toISO()

      await submitInvoiceSentDateSidebarDetails(
        this.reservationExternalId,
        formattedInvoiceSentDate,
        formattedDueDate,
        this.invoiceNumber,
      )

      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-query-request')
      this.$store.dispatch('app/closeDialog')

    } catch (error) {

      console.error("Failed to update invoice sent date:", error)
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Failed to update invoice sent date.', type: 'error' },
        { root: true }
      )
    }

    this.loading = false
  }

}
