
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import LiveTripEventCard from '@/components/LiveTripEventCard.vue'
import TripMonitoringItineraryItem from '@/components/TripMonitoringItineraryItem.vue'
import { DateTime } from 'luxon'

@Component({
  components: {
    LiveTripEventCard,
    TripMonitoringItineraryItem,
  },
})
export default class LiveTripEvents extends Vue {
  @Prop({ required: true }) journeys!: any
  @Prop({ required: true }) vehicles!: any
  @Prop({ required: true }) selectedVehicleId!: number
  @Prop({ required: true }) events!: any

  timer = null
  loading: boolean = false
  activeTab: number = 0

  get referralManagedId(): string {
    return this.journeys[0]?.managedId
  }

  clearAllAlerts() {
    this.$emit('dismiss-all-alerts')
  }

  get lastStopTimezone(): string {
    if (this.currentJourneyStops?.length > 0) {
      return this.currentJourneyStops[this.currentJourneyStops.length - 1]?.address.timeZone
    }
    return 'America/New_York'
  }

  get selectedJourney(): any {
    return this.journeys.find((journey) => journey.vehicleId === this.selectedVehicleId)
  }

  get currentJourneyStops(): any[] {
    return this.selectedJourney?.stops
  }

  get stopsToShow(): any[] {
    if (this.currentJourneyStops?.length > 0 && this.selectedJourney?.hasCirculation) {
      const stops = [...this.currentJourneyStops]
      // adding another iteration of stops for display purposes on circulating routes
      for (const stop of this.currentJourneyStops) {
        stops.push({
          stopId: stop.stopId,
          address: stop.address
        })
      }
      return stops
    }
    return this.currentJourneyStops
  }

  datetimeToSimpleTime(datetime: string, timezone: string): string {
    return DateTime.fromISO(datetime, { zone: timezone }).toLocaleString(DateTime.TIME_SIMPLE)
  }

}
