
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { SplitFeatureFlag } from '@/utils/enum'


@Component
export default class TripMonitoringContactLogsTab extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly contactLogs: Array<any>

  headers = [
        { text: 'Log submitted at', value: 'createdAt' },
        { text: 'Log', value: 'text' },
        { text: 'Contact Method', value: 'contactMethodString' },
        {},
        { text: 'Rep', value: 'representativeName' },
        { text: 'Contact Time', value: 'contactTime' },
        { text: 'Reminder Time', value: 'reminderTime' },
      ]

  pagination = {
    descending: true,
    page: 1,
    rowsPerPage: 10,
    sortBy: 'createdAt',
  }
  areAICallsEnabled = false

  async mounted() {
    this.areAICallsEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled', SplitFeatureFlag.TripMonitoringAutomatedCalls
    )
  }

  hasCompletedCall(item): boolean {
    return !!item.automatedCallExternalId
  }

  openCallTranscript(item): void {
    window.open(
      `/ai-calls/${item.automatedCallExternalId}`,
      '_blank'
    )
  }

  formatTime(datetime) {
    if (!datetime) {
      return '-'
    }
    return DateTime.fromISO(datetime)
    .setZone('America/New_York')
    .toFormat('t ZZZZ')
  }

  formatDatetime(timestamp) {
    if (!timestamp) {
      return '-'
    }
    return DateTime.fromISO(timestamp)
      .setZone('America/New_York')
      .toLocaleString({
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
      })
  }
}
