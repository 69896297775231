<template>
  <div>
    <v-layout class="sheet customer-detail-wrapper">
      <div class="main-style pa-4" style="max-width: 100%">
        <v-layout row align-center justify-space-between class="mb-4">
          <v-flex xs6>
            <h1 class="mt-2">
              {{ customerName }}
              <span
                :class="`invite-status invite-status--${toKebab(
                  customerInvitationStatus
                )}`"
              >
                {{ customerInvitationStatus }}
              </span>
            </h1>
          </v-flex>
          <v-flex xs6 class="justify-end">
            <v-btn class="back-style" outline color="primary" @click="goBack">
              Back To All Customers
            </v-btn>
          </v-flex>
        </v-layout>
        <v-divider />
        <v-layout row justify-start>
          <v-flex xs5 class="pr-5">
            <v-form ref="form" v-model="valid">
              <v-layout wrap>
                <v-flex xs12>First Name</v-flex>
                <v-flex xs12 class="mt-1">
                  <CRInput
                    id="customer-detail-first-name-input"
                    v-model="customer.firstName"
                    :disabled="disabled"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'First Name Is Required',
                        error: 'First Name Is Required',
                      }),
                    ]"
                  />
                </v-flex>
                <v-flex xs12>Last Name</v-flex>
                <v-flex xs12 class="mt-1">
                  <CRInput
                    id="customer-detail-last-name-input"
                    v-model="customer.lastName"
                    :disabled="disabled"
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'Last Name Is Required',
                        error: 'Last Name Is Required',
                      }),
                    ]"
                  />
                </v-flex>
                <v-flex xs12 class="mt-1">
                  <CRInput
                    id="customer-detail-email-input"
                    v-model="customer.email"
                    floatinglabel="Email"
                    :disabled="isAuth0CharterUpEnabled"
                    :tooltip="(isAuth0CharterUpEnabled) ? `For customer email address updates, please create a new customer. Contact support for assistance with re-associating quotes and reservations if needed.` : ''"
                  />
                </v-flex>
                <v-flex xs12>Phone</v-flex>
                <v-flex xs12 class="mt-1">
                  <v-text-field
                    id="customer-detail-phone-input"
                    v-model="customer.phone"
                    v-mask="['(###) ###-####', '+## ## #### ####']"
                    :disabled="disabled"
                    flat
                    solo
                    :rules="[
                      isRequired(true, isNotEmpty, {
                        req: 'Phone Number Is Required',
                        error: 'Phone Number Is Required',
                      }),
                    ]"
                  />
                </v-flex>
                <v-flex xs12>Title</v-flex>
                <v-flex xs12 class="mt-1">
                  <v-text-field
                    id="customer-detail-title-input"
                    v-model="customer.title"
                    :disabled="disabled"
                    flat
                    solo
                  />
                </v-flex>
                <v-flex xs12 class="mt-1">
                  <div>
                    <CustomerAccountSelector
                      :customer-account-id="customer.customerAccountId"
                      :disabled="disabled"
                      label="Customer Account"
                      placeholder="Type to search"
                      clearable
                      link-detail
                      :rules="[
                        isRequired(true, isNotEmpty, {
                          req: 'Customer Account Is Required',
                          error: 'Customer Account Is Required',
                        }),
                      ]"
                      @customer-account-selected="selectCustomerAccountId"
                      @customer-account-cleared="clearCustomerAccountId"
                    />
                  </div>
                </v-flex>
                <v-flex xs12>Roles</v-flex>
                <v-flex xs12 class="mt-1">
                  <v-select
                    id="customer-detail-roles-input"
                    v-model="customer.userRoleNames"
                    :disabled="disabled"
                    :items="[
                      { text: 'Admin', value: 'customer_account_admin' },
                    ]"
                    multiple
                    chips
                    solo
                    flat
                  />
                </v-flex>
                <v-layout justify-end>
                  <v-flex shrink>
                    <v-btn
                      id="customer-detail-submit-button"
                      :disabled="loading || disabled || !valid"
                      :loading="loading"
                      class="button-text-color mr-0"
                      color="primary"
                      type="submit"
                      @click="submit"
                    >
                      Save Changes
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-form>
          </v-flex>
          <v-flex shrink>
            <v-divider vertical />
          </v-flex>
          <v-flex xs7 class="ml-5 mt-3">
            <CustomerDetailGraphs :customer-id="id" />
          </v-flex>
        </v-layout>
        <v-divider class="mt-4" />
        <v-layout ref="customerDetailTables" row class="parent-scroll">
          <v-flex xs12 class="scrollable-table">
            <CustomerDetailTables
              :customer-id="id"
              @scroll-to-table="scrollToBottom"
            />
          </v-flex>
        </v-layout>
      </div>
    </v-layout>
  </div>
</template>

<script>
import CustomerDetailGraphs from '@/components/CustomerDetailGraphs.vue'
import CustomerDetailTables from '@/components/CustomerDetailTables.vue'
import customers from '@/services/customers'
import { isNotEmpty, isRequired } from '@/utils/validators'
import { mask } from 'vue-the-mask'
import { authComputed } from '@/state/helpers'
import CustomerAccountSelector from '@/components/CustomerAccountSelector.vue'
import { currencyFilter, toKebab } from '@/utils/string'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

export default {
  name: 'CustomerDetail',
  components: {
    CustomerDetailGraphs,
    CustomerDetailTables,
    CustomerAccountSelector,
  },
  directives: { mask },
  props: {
    id: { type: String, default: () => null },
    mode: { type: String, default: () => 'view' },
  },
  metaInfo() {
    return {
      title: 'Customer Details',
    }
  },
  watch: {
    '$store.state.split.isReady': {
      async handler(isReady) {
        if (!isReady) {
          return
        }
        this.isAuth0CharterUpEnabled = await this.$store.dispatch('split/isFeatureEnabled', SplitFeatureFlag.Auth0CharterUp)
      },
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      disabled: true,
      valid: true,
      isMainContactEditEnabled: false,
      customer: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        title: '',
        customerAccountId: null,
      },
      isAuth0CharterUpEnabled: false
    }
  },
  computed: {
    ...authComputed,
    customerName() {
      return this.customer?.firstName + ' ' + this.customer?.lastName
    },
    rawPhoneNumber() {
      return this.customer.phone.replace(/\D+/g, '')
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
    customerInvitationStatus() {
      return this.customer.isSignedUp ? 'Signed Up' : 'New'
    },
  },
  async mounted() {
    this.isMainContactEditEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.MainContactEdit
    )
    const customerResponse = await customers.getCustomer(this.id)
    const customerData = customerResponse.data
    this.customer = Object.assign({}, this.customer, customerData.customer)
    this.customer.customerAccountId =
      customerData.customer?.customerAccount?.customerAccountId
    this.disabled = !this.hasPermission('canUpdateCustomer')
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    isRequired,
    isNotEmpty,
    toKebab,
    goBack() {
      this.$router.push({
        name: 'customers',
      })
    },
    selectCustomerAccountId(customerAccountId) {
      this.customer.customerAccountId = customerAccountId
    },
    clearCustomerAccountId() {
      this.customer.customerAccountId = null
    },
    async submit() {
      this.loading = true

      this.valid = this.$refs.form.validate()

      // check for blanks and validate email
      if (this.valid === false) {
        this.loading = false
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message:
            'Some fields are missing that may be required or are invalid.',
        })
        return
      }

      const user = this.currentUser
      this.customer.companyId = user?.companyId
      const rawPhoneNumber = this.rawPhoneNumber

      const payload = JSON.parse(JSON.stringify(this.customer))
      delete payload.phone
      payload.phone = rawPhoneNumber

      this.customer = payload

      try {
        await customers.modifyCustomer({
          id: this.id,
          payload,
        })
      } catch (e) {
        console.error(e)
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: `${e}, Please try again`,
        })
        this.loading = false
        return
      }

      setTimeout(
        function () {
          this.loading = false
        }.bind(this),
        1000
      )
    },
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight)
    },
  },
}
</script>

<style lang="scss" scoped>
.main-style {
  background-color: $white;
  color: $black;
  margin-top: -18px;
}

.customer-detail-wrapper {
  margin: 0 25px 25px;
}

.scrollable-table {
  overflow-x: auto;
}

.back-style {
  background-color: $white !important;
}

.invite-status {
  padding: 3px 6px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  border-radius: 2px;
  width: max-content;
  vertical-align: middle;

  &--signed-up {
    background: $green;
    color: white;
  }

  &--new {
    background: $primary;
    color: white;
  }
}

::v-deep .v-tooltip.v-tooltip--top {
  z-index: 20;
}

@media only screen and (max-width: 1425px) {
  .scrollable-table {
    max-width: 900px;
  }
}

@media only screen and (max-width: 1300px) {
  .scrollable-table {
    max-width: 800px;
  }
}

@media only screen and (max-width: 1200px) {
  .scrollable-table {
    max-width: 700px;
  }
}
</style>
