import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Type } from '@/models/dto'
import { PaymentTypeKey } from '@/utils/enum'

const httpService: HttpService = new HttpService()

/**
 * Gets the onboarding status types table view.
 * @param params - the parameters for the table view.
 * @returns the TableViewResult of onboarding status types.
 */
export const getOnboardingStatusTypes = (params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Type>>> => {
  const { pageSize = 10, page = 1, sorts = null, filters = null } = params
  let query = `page=${page}&pageSize=${pageSize}`
  if (sorts) {
    query += `&${sorts}`
  }
  if (filters) {
    query += `&${filters}`
  }
  query = encodeURI(query)

  const host = baseUrl()
  const url = `https://${host}/onboardingStatusTypes?${query}`

  return httpService.get(url)
}

export const ALLOWED_PAYMENT_METHOD_KEYS: Set<string> = new Set([
  PaymentTypeKey.FullPayment,
  PaymentTypeKey.DownPayment,
  PaymentTypeKey.BillAfterServices,
  PaymentTypeKey.PurchaseOrder
])
