var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.reservation != null && !_vm.reservation.active)?_c('v-layout',[_c('v-layout',{staticClass:"background-error-pale border-radius-5 border-red border-1 border-solid text-red padding-a-3 margin-a-4 margin-l-0",attrs:{"row":"","align-center":""}},[_c('CRIcon',{attrs:{"material":"","height":36,"width":36}},[_vm._v("error_color")]),_c('p',{staticClass:"margin-a-0"},[_vm._v("This reservation has been deleted.")])],1)],1):_vm._e(),(!_vm.loading)?_c('Sheet',{staticClass:"reservation-detail",attrs:{"id":"reservation-detail"}},[_c('v-layout',{staticClass:"margin-b-2",attrs:{"row":"","wrap":"","align-center":"","justify-space-between":""}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-flex',{attrs:{"shrink":""}},[_c('h1',{staticClass:"d-inline-block margin-y-0 text-center"},[_vm._v(" Reservation ID: "+_vm._s(_vm.reservationId)+" "+_vm._s(_vm.tripName)+" ")])]),(!!_vm.reservation && !!_vm.reservation.cancellationStatusKey)?_c('v-flex',{staticClass:"margin-l-1",staticStyle:{"display":"flex","align-items":"center"}},[_c('v-chip',{class:{
              'background-yellow-new text-gray-dark':
                _vm.reservation.cancellationStatusKey === 'started',
              error: _vm.reservation.cancellationStatusKey === 'cancelled',
            },staticStyle:{"pointer-events":"none","font-size":"12px"},attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.cancellationStatusString)+" ")]),_c('p',{staticStyle:{"margin":"0","line-height":"24px","padding-left":"4px"}},[_vm._v(" "+_vm._s(_vm.reservation.cancellationClassificationLabel)+" ")])],1):_c('v-flex',{staticClass:"margin-l-1"},[(_vm.reservation)?_c('v-chip',{class:{
              'background-success-new text-white':
                _vm.reservation.reservationStatus === 'started',
              'background-blue-new text-white':
                _vm.reservation.reservationStatus === 'upcoming',
              'background-yellow-new text-gray-dark':
                _vm.reservation.reservationStatus === 'hold',
              'background-gray-dark text-white':
                _vm.reservation.reservationStatus === 'finished',
              'background-error-new text-white':
                _vm.reservation.reservationStatus === 'cancelled',
            },staticStyle:{"pointer-events":"none","font-size":"12px"},attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.reservationStatusTypeString)+" ")]):_vm._e()],1),(_vm.showTierMarker)?_c('TierBadge',{staticClass:"margin-l-1",attrs:{"tier":_vm.reservation.tier}}):_vm._e()],1),_c('v-flex',{attrs:{"shrink":""}},[_c('ReservationActionsMenu',{attrs:{"reservation":_vm.reservation,"current-user-profile":_vm.currentUserProfile,"user-id":_vm.currentUser.userId,"reasons":_vm.reasonTypeLevels}})],1)],1),(_vm.reservation)?_c('v-layout',{attrs:{"row":""}},[_c('div',{staticClass:"grow"},[_c('div',{staticClass:"d-inline-block margin-b-2"},[_vm._v(" Created: "+_vm._s(_vm.isoToString(_vm.reservation.createdOn))+" | Created By: "+_vm._s(_vm.reservation.quoteCreatedBy)+" ")])])]):_vm._e(),(_vm.refreshingDetail)?_c('div',{staticStyle:{"display":"flex","align-items":"center","height":"292px"}},[_c('CRProgressCircular',{attrs:{"color":"blue","strokeWidth":3,"size":120}})],1):_c('ReservationOverview',_vm._b({attrs:{"id":"reservation-detail-overview","customer-account":_vm.customerAccount,"reservation-id":_vm.$route.params.id,"payment-method-types":_vm.paymentMethodTypes,"payment-methods":_vm.balancePaymentMethods,"payment-type-id":_vm.paymentTypeId,"invoice-sent-date":_vm.invoiceSentDate}},'ReservationOverview',_vm.reservation,false)),_c('ReservationComponentSelector',{attrs:{"id":"reservation-detail-component-selector","reservation":_vm.reservationAndReasons,"customer-account":_vm.customerAccount,"payment-method-types":_vm.paymentMethodTypes,"balance-payment-methods":_vm.balancePaymentMethods},on:{"refresh":_vm.handleRefresh}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }