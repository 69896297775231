import { ApiResult } from './ApiResult'
import { DateTime, Zone } from 'luxon'

export enum TripMonitoringStatusLevel {
  Success = 'SUCCESS',
  Warning = 'WARNING',
  Failure = 'FAILURE',
  Inactive = 'INACTIVE',
}

export interface TripMonitoringStatus {
  label: string
  statusLevel: TripMonitoringStatusLevel
}

export interface TripMonitoringReferral {
  reservationId: number
  managedId: string
  pickupTime: DateTime
  companyId: number
  companyName: string
  driverStatus: TripMonitoringStatus
  vehicleStatus: TripMonitoringStatus
  trackingStatus: TripMonitoringStatus
  contactStatus: string
  lastContact: DateTime
  logs: string
  assignedTo: string
  assignedDriverCount: number
  externalId: string
}

export interface TripMonitoringTableResult extends ApiResult {
  referrals: TripMonitoringReferral[]
}

export interface WindowWatcherUser {
  userId: number
  firstName: string | null
  lastName: string | null
}

export interface WindowWatcherUserListResult extends ApiResult {
  users: WindowWatcherUser[]
}

export interface ReferralAssignment {
  userId: number
  referralIds: number[]
}

export interface CompanyContactInfo {
  contactPreferenceText: string | null
  email: string
  phone: string
  opsPhone: string | null
  partnerPhone: string | null
  smsPhone: string | null
}

export interface DriverContactInfo {
  firstName: string
  lastName: string
  email: string
  phone: string | null
}
export interface ReferralDriverContactInfo {
  contactPreferenceText: string | null
  driverContactInfo: DriverContactInfo[]
}

export enum VehicleStatusLabel {
  UNASSIGNED = 'Unassigned',
  NON_COMPLIANT = 'Non-Compliant',
  COMPLIANT = 'Compliant',
}

export enum JourneyAlertType {
  DRIVER = 'DRIVER',
  VEHICLE = 'VEHICLE',
  TRACKING = 'TRACKING',
  ON_TIME = 'ON_TIME',
}

export enum JourneyAlertStatus {
  ACTIVE = 'ACTIVE',
  DISMISSED = 'DISMISSED',
  SNOOZED = 'SNOOZED',
}

export interface JourneyAlertDTO {
  id: string
  type: JourneyAlertType
  status: JourneyAlertStatus
  triggeredAt: string
  snoozedUntil: string | null
}

export interface JourneyAlertResponse {
  journeyAlert: JourneyAlertDTO
}

export interface LiveTripMonitoringOverviewReferral {
  reservationId: number
  externalId: string
  managedId: string
  pickupTime: string
  companyId: number
  companyName: string
  companyTimeZone: string
  contractName: string
  contractId: number
  marketId: number
  market: string
}

export interface LiveTripMonitoringOverview {
  referrals: LiveTripMonitoringOverviewReferral[]
}
