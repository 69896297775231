
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DateTime } from 'luxon'
@Component({})
export default class LiveTripEventCard extends Vue {
  @Prop({ required: true }) event!: any
  @Prop({ required: true }) stops!: any[]
  @Prop({ required: false, type: Boolean }) isActive!: boolean
  get etaTime() {
    if (!this.event.eta) {
      return ''
    }

    return DateTime.fromISO(this.event.eta)
      .setZone(this.event.timeZone)
      .toLocaleString(DateTime.TIME_SIMPLE)
  }

  get heading() {
    if (this.event.type === 'ON_TIME_ETA') {
      return `On-Time ETA, ${this.etaTime}`
    }
    return ''
  }

  get body() {
    if (this.event.type === 'ON_TIME_ETA') {
      return `ETA to address is on-time at ${this.etaTime}`
    }
  }

  get occurredOnTimestamp() {
    return DateTime.fromISO(this.event.occurredOn).toFormat('MM/dd, h:mm a')
  }

  handleAlertClick() {
    this.$emit('alert-click', this.event)
  }
}
