import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/state/store'
import { baseUrl } from '@/utils/env'
import { SplitKeyType } from '@/utils/enum'

Vue.use(Router)

const coachrailRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/blocked',
      component: () => import('@/views/Blocked.vue'),
      name: 'blocked',
    },
    {
      path: '',
      component: () => import('@/views/Main.vue'),
      children: [
        {
          path: '/',
          component: () =>
            import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
          name: 'home',
        },
        {
          path: '/change-password',
          component: () => import('@/views/ChangePasswordForm.vue'),
          name: 'change-password',
        },
        {
          path: '/change-timezone',
          component: () => import('@/views/ChangeUserTimeZone.vue'),
          name: 'change-timezone',
        },
        {
          path: '/bids',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'bids',
          }),
          children: [
            {
              path: '',
              name: 'bids',
              component: () => import('@/views/BidsList.vue'),
            },
          ],
        },
        {
          path: '/marketplace-bids',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'marketplace-bids',
          }),
          children: [
            {
              path: '',
              name: 'marketplace-bids',
              component: () => import('@/views/MarketplaceBidsList.vue'),
            },
          ],
        },
        {
          path: '/leadfeed',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'leadfeed',
          }),
          children: [
            {
              path: '',
              component: () => import('@/views/LeadFeed.vue'),
              name: 'leadfeed',
            },
          ],
        },
        {
          path: '/trip-monitoring',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'trip-monitoring',
          }),
          meta: {
            requiredPermissions: ['canViewWindowWatching']
          },
          children: [
            {
              path: '',
              component: () => import('@/views/TripMonitoring.vue'),
              name: 'trip-monitoring',
            },
          ],
        },
        {
          path: '/live-trip-monitoring',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'live-trip-monitoring',
          }),
          meta: {
            requiredPermissions: ['canViewWindowWatching']
          },
          children: [
            {
              path: '',
              component: () => import('@/views/LiveTripOps.vue'),
              name: 'live-trip-monitoring',
            },
            {
              path: ':referralId',
              component: () => import('@/views/LiveTripDetail.vue'),
              name: 'live-trip-detail',
            },
          ],
        },
        {
          path: '/ai-calls',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'ai-calls',
          }),
          children: [
            {
              path: ':externalId',
              name: 'ai-calls.view',
              props: (router) => ({
                externalId: router.params.externalId
              }),
              component: () => import('@/views/AICallDetails.vue')
            }
          ]
        },
        {
          path: '/company-checkout-page',
          name: 'company-checkout-page',
          props: () => ({
            id: 'company-checkout-page',
          }),
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: ':id',
              name: 'company-checkout-page.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CompanyCheckoutPage.vue'),
            },
          ],
        },
        {
          path: '/admin',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'admin',
          }),
          children: [
            {
              path: '',
              name: 'admin',
              component: () => import('@/views/AdminForm.vue'),
              meta: {
                requiredPermissions: ['canViewAdminPanel'],
              }
            },
            {
              path: 'operators',
              name: 'charterup-operators',
              component: () => import('@/views/OperatorLeadsList.vue'),
            },
            {
              path: 'on-demand-bus-routes',
              name: 'charterup-on-demand-bus-routes',
              component: () => import('@/views/OnDemandBusRouteLeadsList.vue'),
            },
            {
              path: 'credits',
              name: 'credits',
              component: () => import('@/views/CreditsList.vue'),
            },
            {
              path: 'payments',
              name: 'payments',
              component: () => import('@/views/PaymentsList.vue'),
            },
            {
              path: 'jobs',
              name: 'jobs',
              component: () => import('@/views/JobsPermissions.vue'),
            },
            {
              path: 'pricing-map',
              name: 'pricing-map',
              component: () => import('@/views/PricingMap.vue'),
            },
            {
              path: 'metrics-map',
              name: 'metrics-map',
              component: () => import('@/views/MetricsMap.vue'),
              meta: {
                requiredPermissions: ['canViewMetricsMap'],
              }
            },
            {
              path: 'knownAddresses',
              component: () => import('@/views/RouterOutlet.vue'),
              props: () => ({
                id: 'knownAddresses',
              }),
              meta: {
                requiredPermissions: ['canCreateKnownAddress'],
              },
              children: [
                {
                  path: '',
                  name: 'known-addresses',
                  component: () => import('@/views/KnownAddressList.vue'),
                },
                {
                  path: 'view/:id',
                  name: 'known-address.view',
                  component: () => {
                    return import('@/views/KnownAddressDetail.vue')
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'known-address.edit',
                  component: () => {
                    return import('@/views/KnownAddressDetail.vue')
                  },
                },
                {
                  path: 'add',
                  name: 'known-address.add',
                  component: () => {
                    return import('@/views/KnownAddressDetail.vue')
                  },
                },
              ],
            },
            {
              path: 'referrals',
              name: 'admin-referrals',
              component: () => import('@/views/AdminReferralsList.vue'),
            },
            {
              path: 'receipts',
              name: 'receipts',
              component: () => import('@/views/ReceiptsList.vue'),
            },
            {
              path: 'calls',
              name: 'calls',
              component: () => import('@/views/CallsList.vue'),
            },
            {
              path: 'market-rate-audits',
              name: 'market-rate-audits',
              component: () => import('@/components/MarketRatesAuditList.vue'),
            },
            {
              path: 'web-forms',
              name: 'web-forms',
              component: () => import('@/views/WebFormsList.vue'),
            },
            {
              path: 'market-rate-reviews',
              name: 'market-rate-reviews',
              component: () => import('@/views/MarketRateReviewList.vue'),
            },
          ],
        },
        {
          path: '/company-rates',
          name: 'company-rates',
          component: () => import('@/views/RatesForm.vue'),
        },
        {
          path: '/quotes',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'quotes',
          }),
          children: [
            {
              path: '',
              name: 'quotes',
              component: () => import('@/views/QuotesList.vue'),
            },
            {
              path: 'add',
              name: 'quotes.add',
              props: (router) => ({
                mode: 'add',
                userId: router.query.userId,
              }),
              component: () => {
                return import('@/views/QuoteForm.vue')
              },
            },
            {
              path: ':mode/:id?/newLayout/:subRoute?',
              name: 'quotes.new',
              props: (router) => ({
                clientQuoteView: ['bids', 'confirmation'].includes(
                  router.params.subRoute
                )
                  ? router.params.subRoute
                  : null,
              }),
              component: () => {
                return import('@/views/QuoteFormNew.vue')
              },
              beforeEnter(to, from, next) {
                if (
                  store.getters['featureToggles/isShuttleCreateQuoteEnabled'] &&
                  store.getters['auth/hasPermission']('canCreateShuttleQuotes')
                ) {
                  next()
                } else {
                  next({ name: `quotes.${to.params.mode}` })
                }
              },
            },
            {
              path: 'view/:id/:subRoute?',
              name: 'quotes.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
                clientQuoteView: ['bids', 'confirmation'].includes(
                  router.params.subRoute
                )
                  ? router.params.subRoute
                  : null,
              }),
              component: () => {
                return import('@/views/QuoteForm.vue')
              },
            },
            {
              path: 'edit/:id',
              name: 'quotes.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => {
                return import('@/views/QuoteForm.vue')
              },
            },
            {
              path: 'email',
              component: () => {
                return import('@/views/RouterOutlet.vue')
              },
              props: {
                id: 'email',
              },
              children: [
                {
                  path: '',
                  name: 'quotes.email-customization',
                  component: () => {
                    return import('@/views/QuoteCustomizationForm.vue')
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'leads',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'leads',
          }),
          children: [
            {
              path: '',
              name: 'leads',
              component: () => import('@/views/LeadsList.vue'),
            },
            {
              path: 'add',
              name: 'leads.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => {
                return import('@/views/LeadForm.vue')
              },
            },
            {
              path: 'view/:id',
              name: 'leads.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => {
                return import('@/views/LeadForm.vue')
              },
            },
            {
              path: 'edit/:id',
              name: 'leads.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => {
                return import('@/views/LeadForm.vue')
              },
            },
          ],
        },
        {
          path: '/events',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'events',
          }),
          children: [
            {
              path: '',
              name: 'events',
              component: () => import('@/views/EventsList.vue'),
            },
          ],
        },
        {
          path: '/reservations',
          component: () =>
            import(
              /* webpackChunkName: "reservations" */ '@/views/RouterOutlet.vue'
            ),
          props: () => ({
            id: 'reservations',
          }),
          children: [
            {
              path: '',
              name: 'reservations',
              component: () => import('@/views/ReservationsTVList.vue'),
            },
            {
              path: 'map/:id',
              name: 'reservation-tracking-map',
              props: (router) => ({
                id: router.params.id,
              }),
              component: () => import('@/views/ReservationsTVList.vue'),
            },
            {
              path: 'edit/:id',
              name: 'reservation.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/TripsForm.vue'),
            },
            {
              path: ':id/:map?',
              name: 'reservation-detail',
              props: (router) => ({
                ignoreCache: router.params.ignoreCache || false,
              }),
              component: () => {
                return import('@/views/ReservationDetail.vue')
              },
            },
            {
              path: 'view/:id',
              name: 'reservation.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/TripsForm.vue'),
            },
          ],
        },
        {
          path: '/referrals',
          name: 'referrals',
          component: () => {
            return import('@/views/ReferralsList.vue')
          },
          props: (router) => ({
            queryInfo: router.query,
          }),
        },
        {
          path: '/my-sales-dashboard',
          name: 'my-sales-dashboard',
          component: () => import('@/views/MySalesDashboard.vue'),
        },
        {
          path: '/company-referral-terms',
          props: () => ({
            id: 'company-referral-terms',
          }),
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: '',
              name: 'company-referral-terms',
              props: () => ({
                mode: 'company-referral-terms',
              }),
              component: () => import('@/views/terms.vue'),
            },
          ],
        },
        {
          path: '/company-terms',
          props: () => ({
            id: 'company-terms',
          }),
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: '',
              name: 'company-terms',
              props: () => ({
                mode: 'company-terms',
              }),
              component: () => import('@/views/CompanyTermsList.vue'),
            },
          ],
        },
        {
          path: '/companyTerms',
          props: () => ({
            id: 'companyTerms',
          }),
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: 'view/:id',
              name: 'company-terms.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CompanyTermsForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'company-terms.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/CompanyTermsForm.vue'),
            },
            {
              path: 'add',
              name: 'company-terms.add',
              props: (router) => ({
                mode: 'add',
              }),
              component: () => import('@/views/CompanyTermsForm.vue'),
            },
          ],
        },
        {
          path: '/my-company',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'my-company',
          }),
          children: [
            {
              path: '',
              name: 'my-company',
              props: () => ({
                mode: 'my-company',
              }),
              component: () => import('@/views/CompaniesForm.vue'),
            },
          ],
        },
        {
          path: '/drivers',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'drivers',
          }),
          children: [
            {
              path: 'add',
              name: 'drivers.add',
              props: () => ({
                mode: 'driverAdd',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
            {
              path: 'addFromReferral',
              name: 'drivers.referralAdd',
              props: () => ({
                mode: 'referralAdd',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'drivers.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'drivers.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
          ],
        },
        {
          path: '/affiliates',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'affiliates',
          }),
          children: [
            {
              path: '',
              name: 'affiliates-list',
              props: () => ({
                mode: 'list',
              }),
              component: () => import('@/views/Affiliates.vue'),
            },
            {
              path: 'map',
              name: 'affiliates-map',
              props: () => ({
                mode: 'map',
              }),
              component: () => import('@/views/Affiliates.vue'),
            },
            {
              path: 'availability',
              name: 'affiliates-availability',
              props: (router) => ({
                company: router.params.company,
              }),
              component: () => import('@/views/Availability.vue'),
            },
            {
              path: 'vehicle-reviews',
              name: 'affiliates-vehicleReviews',
              component: () => import('@/views/VehicleReviewsList.vue'),
            },
            {
              path: ':id',
              name: 'affiliates-detail',
              component: () => import('@/views/AffiliateDetail.vue'),
            },
          ],
        },
        {
          path: '/industries',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'industries',
          }),
          children: [
            {
              path: '',
              name: 'industries',
              component: () => import('@/views/IndustriesList.vue'),
            },
            {
              path: 'add',
              name: 'industries.add',
              props: (router) => ({
                mode: 'add',
              }),
              component: () => import('@/views/IndustriesForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'industries.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/IndustriesForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'industries.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/IndustriesForm.vue'),
            },
          ],
        },
        {
          path: '/customer-accounts',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'customer-accounts',
          }),
          children: [
            {
              path: '',
              name: 'customer-accounts',
              component: () => import('@/views/CustomerAccountsList.vue'),
            },
            {
              path: 'view/:id',
              name: 'customer-accounts.view',
              props: (router) => ({
                id: Number(router.params.id),
                mode: 'view',
              }),
              component: () => import('@/views/CustomerAccountDetail.vue'),
            },
          ],
        },
        {
          path: '/customers',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'customers',
          }),
          children: [
            {
              path: '',
              name: 'customers',
              component: () => import('@/views/CustomersList.vue'),
            },
            {
              path: 'add',
              name: 'customers.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/CustomerForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'customers.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CustomerForm.vue'),
            },
            {
              path: ':id/details',
              name: 'customers.details',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CustomerDetail.vue'),
            },
            {
              path: 'edit/:id',
              name: 'customers.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/CustomerForm.vue'),
            },
          ],
        },
        {
          path: '/companies',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'companies',
          }),
          children: [
            {
              path: '',
              name: 'companies',
              component: () => import('@/views/CompaniesList.vue'),
            },
            {
              path: 'add',
              name: 'companies.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/CompaniesForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'companies.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CompaniesForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'companies.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/CompaniesForm.vue'),
            },
          ],
        },
        {
          path: '/users',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'users',
          }),
          children: [
            {
              path: '',
              name: 'users',
              component: () => import('@/views/UsersList.vue'),
            },
            {
              path: 'add',
              name: 'users.add',
              props: (router) => ({
                mode: 'add',
                companyId: router.query.companyId,
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'users.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'users.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/UsersForm.vue'),
            },
          ],
        },
        {
          path: '/markups',
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: '',
              name: 'markups',
              component: () => import('@/views/MarkupList.vue'),
            },
          ],
          props: () => ({
            id: 'markups',
          }),
        },
        {
          path: '/minimums',
          component: () => import('@/views/RouterOutlet.vue'),
          children: [
            {
              path: '',
              name: 'minimums',
              component: () => import('@/views/MinimumList.vue'),
            },
          ],
          props: () => ({
            id: 'minimums',
          }),
        },
        {
          path: '/tickets',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'tickets',
          }),
          children: [
            {
              path: '',
              name: 'tickets',
              props: (router) => ({
                ticketIdFilter: router.params.ticketIdFilter,
              }),
              component: () => import('@/views/TicketsList.vue'),
            },
            {
              path: 'add',
              name: 'tickets.add',
              props: (router) => ({
                managedId: router.params.managedId,
                reservationId: router.params.reservationId,
                mode: 'add',
              }),
              component: () => import('@/views/TicketsForm.vue'),
            },
            {
              path: ':id',
              name: 'tickets.view',
              props: (router) => ({
                ticketIdFilter: router.params.id,
              }),
              component: () => import('@/views/TicketsList.vue'),
            },
          ],
        },
        {
          path: '/classifications',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'classifications',
          }),
          children: [
            {
              path: '',
              name: 'classifications',
              component: () => import('@/views/ClassificationsList.vue'),
            },
            {
              path: 'add',
              name: 'classifications.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/ClassificationsForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'classifications.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/ClassificationsForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'classifications.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/ClassificationsForm.vue'),
            },
          ],
        },
        {
          path: 'lead-sources',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'lead-sources',
          }),
          children: [
            {
              path: '',
              name: 'lead-sources',
              component: () => import('@/views/LeadSourcesList.vue'),
            },
            {
              path: 'add',
              name: 'lead-sources.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/LeadSourceForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'lead-sources.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/LeadSourceForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'lead-sources.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/LeadSourceForm.vue'),
            },
          ],
        },
        {
          path: '/market-rates',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'market-rates',
          }),
          children: [
            {
              path: '',
              name: 'market-rates',
              component: () => import('@/views/MarketRatesList.vue'),
            },
            {
              path: 'add',
              name: 'market-rates.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/MarketRatesForm.vue'),
            },
            {
              path: 'view/:id',
              name: 'market-rates.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/MarketRatesForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'market-rates.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/MarketRatesForm.vue'),
            },
          ],
        },
        {
          path: '/checkout-pages',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'checkout-pages',
          }),
          children: [
            {
              path: '',
              name: 'checkout-pages',
              component: () => import('@/views/CheckoutPagesList.vue'),
            },
            {
              path: 'view/:id',
              name: 'checkout-pages.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/CheckoutForm.vue'),
            },
            {
              path: 'add',
              name: 'checkout-pages.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => import('@/views/CheckoutForm.vue'),
            },
            {
              path: 'edit/:id',
              name: 'checkout-pages.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/CheckoutForm.vue'),
            },
          ],
        },
        {
          path: '/contracts',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'contracts',
          }),
          children: [
            {
              path: 'add',
              name: 'contracts.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => {
                return import('@/views/ContractQuoteForm.vue')
              },
            },
            {
              path: 'view/:id',
              name: 'contracts.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => import('@/views/ContractDetail.vue'),
            },
            {
              path: 'edit/:id',
              name: 'contracts.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => import('@/views/ContractQuoteForm.vue'),
            },
          ],
        },
        {
          path: '/charterup',
          component: () => import('@/views/CharterUp.vue'),
          children: [
            {
              path: 'quotes/:quoteId',
              name: 'charterup-quote-detail',
              component: () => import('@/views/charterup/QuoteDetail.vue'),
            },
            {
              path: 'quotes/:quoteId/provider/:providerId',
              name: 'charterup-provider-detail',
              component: () => import('@/views/charterup/Provider.vue'),
            },
            {
              path: 'reservations/:id',
              name: 'charterup-reservation-detail',
              component: () =>
                import('@/views/charterup/ReservationDetail.vue'),
            },
            {
              path: 'quotes/:quoteId/provider/:providerId/checkout',
              name: 'charterup-checkout',
              component: () => import('@/views/charterup/Checkout.vue'),
            },
          ],
        },
        {
          path: '/accounting',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'accounting',
          }),
          children: [
            {
              path: 'client-payments',
              name: 'client-payments',
              component: () => import('@/views/ClientPaymentsList.vue'),
            },
            {
              path: 'provider-payments',
              name: 'provider-payments',
              component: () => import('@/views/ProviderPaymentsList.vue'),
            },
          ],
        },
        {
          path: '/reviews',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'reviews',
          }),
          meta: {
            requiredPermissions: ['canViewReviews'],
          },
          children: [
            {
              path: 'ratings',
              name: 'ratings',
              component: () => import('@/views/Ratings.vue'),
            },
            {
              path: 'nps',
              name: 'nps',
              component: () => import('@/views/NPSResponseList.vue'),
            },
          ],
        },
        {
          path: 'chats',
          name: 'chats',
          meta: {
            requiredPermissions: ['canViewChats'],
          },
          component: () => import('@/views/ChatsList.vue'),
        },
        {
          path: 'style-guide',
          name: 'style-guide',
          component: () => import('@/views/Icons.vue'),
        },
        {
          path: '/brands',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'brands',
          }),
          children: [
            {
              path: '',
              name: 'brands',
              component: () => import('@/views/BrandsList.vue'),
            },
            {
              path: 'add',
              name: 'brands.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => {
                return import('@/views/BrandDetail.vue')
              },
            },
            {
              path: 'view/:id',
              name: 'brands.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => {
                return import('@/views/BrandDetail.vue')
              },
            },
            {
              path: 'edit/:id',
              name: 'brands.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => {
                return import('@/views/BrandDetail.vue')
              },
            },
          ],
        },
        {
          path: '/phone-numbers',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'phoneNumbers',
          }),
          children: [
            {
              path: '',
              name: 'phone-numbers',
              component: () => import('@/views/PhoneNumbersList.vue'),
            },
            {
              path: 'add',
              name: 'phone-numbers.add',
              props: () => ({
                mode: 'add',
              }),
              component: () => {
                return import('@/views/PhoneNumberDetail.vue')
              },
            },
            {
              path: 'view/:id',
              name: 'phone-numbers.view',
              props: (router) => ({
                id: router.params.id,
                mode: 'view',
              }),
              component: () => {
                return import('@/views/PhoneNumberDetail.vue')
              },
            },
            {
              path: 'edit/:id',
              name: 'phone-numbers.edit',
              props: (router) => ({
                id: router.params.id,
                mode: 'edit',
              }),
              component: () => {
                return import('@/views/PhoneNumberDetail.vue')
              },
            },
          ],
        },
        {
          path: '/feature-toggles',
          name: 'feature-toggles',
          component: () => import('@/views/RouterOutlet.vue'),
          props: () => ({
            id: 'feature-toggles',
          }),
          meta: {
            requiredPermissions: ['canViewFeatureToggles'],
          },
          children: [
            {
              path: '',
              name: 'feature-toggles',
              component: () => import('@/views/FeatureToggleList.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/referral-terms/:companyId',
      name: 'referral-terms-legacy',
      props: (router) => ({
        mode: 'referral-terms',
      }),
      component: () => import('@/views/terms-legacy.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/terms/:companyId',
      name: 'terms',
      component: () => import('@/views/terms-legacy.vue'),
      meta: {
        publicRoute: true,
      },
    },
    //used only for local twilio development
    // {
    //   path: '/temp-call-center',
    //   name: 'temp-call-center',
    //   component: () => import('@/views/CallCenterTesting.vue'),
    //   meta: {
    //     publicRoute: true,
    //   },
    // },
    {
      path: '/review-trip/:hash',
      name: 'review-trip',
      component: () => import('@/views/ReviewTrip.vue'),
      props: (router) => ({ hash: router.params.hash }),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/Forgot.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: () => import('@/views/SetPass.vue'),
      props: (router) => ({
        token: router.params.token,
        welcome: router.query.welcome,
      }),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/logout',
      name: 'logout',

      async beforeEnter(routeTo, routeFrom, next) {
        await store.dispatch('auth/logOut')
      },
    },
    {
      path: '/cancel-reservation/:hash',
      name: 'guest.cancel.reservation',
      component: () => import('@/views/GuestReservationCancellation.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/customer-feedback/:hash/:complaintTicketId',
      name: 'guest.customer.feedback',
      component: () => import('@/views/GuestComplaintDetails.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/checkout/:quoteHash',
      name: 'checkout',
      component: () => import('@/views/Checkout.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/checkout/contract/:hash',
      name: 'contracts.checkout',
      props: (router) => ({
        hash: router.params.hash,
      }),
      component: () => import('@/views/ContractCheckout.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/checkout/:quoteHash/:miscVariable',
      alias: '/checkout',
      component: () => import('@/views/Checkout.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/invoice/:reservationHash',
      name: 'invoice',
      component: () => import('@/views/Invoice.vue'),
      meta: {
        publicRoute: true,
      },
    },
    {
      path: '/auth-callback',
      name: 'auth-callback',
      component: () => import('@/views/Auth0Callback.vue'),
      meta: {
        publicRoute: true,
      },
    },
  ],
})

coachrailRouter.beforeEach(async (routeTo, routeFrom, next) => {
  baseUrl()

  store.dispatch('app/closeDialog')
  store.dispatch('systemParameters/getSystemParameters')
  store.dispatch('featureToggles/getFeatureToggles')

  function checkPermissions(route) {
    const requiredPermissions = route.meta?.requiredPermissions
    if (requiredPermissions?.length) {
      return requiredPermissions.every(permission =>
        store.getters['auth/hasPermission'](permission)
      )
    }
    return true
  }

  async function redirectToLogin() {
    await store.dispatch('split/updateKey', {
      key: SplitKeyType.AnonymousUser,
    })
    next({
      name: 'login',
      query: {
        redirectFrom: routeTo.fullPath,
      },
    })
  }

  const hasRequiredPermissions = routeTo.matched.every(route =>
    checkPermissions(route)
  )

  if (!hasRequiredPermissions) {
    next({ name: 'home' })
    return
  }

  const authRequired = !routeTo.matched.some((route) => route.meta.publicRoute)

  if (!authRequired) {
    next()
    return
  }

  if (store.getters['auth/loggedIn']) {
    store.dispatch('auth/validate').then(async (validUser) => {
      if (validUser) {
        const currentUser = store.getters['auth/currentUser'] || {}
        if (!currentUser.company) {
          await redirectToLogin()
          return
        }

        const currentUserProfile = store.getters['auth/currentUserProfile']

        const isBlocked =
          [1, 2].includes(currentUser.company.companyType.companyTypeId) &&
          !currentUserProfile.roles.some(
            (r) => r.roleName === 'can_transform_all_companies'
          )

        if (routeTo.name !== 'blocked' && isBlocked) {
          next({ name: 'blocked' })
          return
        }

        if (routeTo.name === 'blocked' && !isBlocked) {
          next({ name: 'home' })
          return
        }

        if (currentUserProfile && currentUserProfile.roles.length) {
          const isDriver = currentUserProfile.roles.some(
            (role) => role.roleName === 'is_driver'
          )
          const isAdmin = currentUserProfile.roles.some((role) =>
            [
              'is_free_admin',
              'is_paid_admin',
              'is_broker_admin',
              'is_admin_admin',
            ].includes(role.roleName)
          )
          const isUser = currentUserProfile.roles.some((role) =>
            [
              'is_free_user',
              'is_paid_user',
              'is_broker_user',
              'is_report_admin',
            ].includes(role.roleName)
          )
          if (!isAdmin && !isUser && isDriver) {
            next({
              name: 'download-app',
            })
          }
        }

        await store.dispatch('split/updateKey', {
          key: currentUser.userId.toString(),
        })

        next()
        return
      } else {
        // If a user is already authenticated with Auth0, use popup reauthentication
        // instead of redirection to preserve their existing session
        if (Vue.prototype.$auth0.isAuthenticated) {
          store.dispatch('app/showAlert', {
            message: `Your credentials have expired. Please reauthenticate yourself.`,
            type: 'error',
            hasAction: true,
            action: async () => {
              await Vue.prototype.$auth0.loginWithPopup()
            },
          })
          return
        }
      }

      await redirectToLogin()
    })
    return
  }

  await redirectToLogin()
})

export default coachrailRouter
