import { baseUrl } from '@/utils/env'

import axios, { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'

export const getTakeRate = async (
  companyId: number,
  tripStartTime?: string,
  tripStartTimezone?: string
): Promise<AxiosResponse<number>> => {
  const params: Record<string, string> = {}
  if (tripStartTime) {
    params.tripStartTime = DateTime.fromISO(tripStartTime).toFormat('yyyy-MM-dd HH:mm:ss.SSS')
  }

  if (tripStartTimezone) {
    params.tripStartTimezone = tripStartTimezone
  }

  const queryString = new URLSearchParams(params).toString()
  const url = `https://${baseUrl()}/take-rate/${companyId}${queryString ? `?${queryString}` : ''}`

  try {
    return await axios.get(url)
  } catch (error) {
    console.error('Error fetching take rate:', error)
    return null
  }
}
