
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { ChatDialogMessage } from '@/models/dto/CallRequest'
import { DateTime } from 'luxon'

@Component
export default class IOSStyleChatDialog extends Vue {
  @Prop({ type: Array, required: true })
  readonly transcript: ChatDialogMessage[]
  @Prop({ type: Object, required: true }) readonly senderUsers: Set<String>

  formatDateTime(dateTime: DateTime): string {
    return dateTime.toFormat('MM/dd/yy h:mma ZZZZ')
  }
}
