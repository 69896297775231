
import { Component, Vue, Watch } from 'vue-property-decorator'

import { convertQuote } from '@/services/quotes'
import { nonMarketplaceCheckoutDetailByHash } from '@/services/checkoutDetail'
import { PaymentGateway } from '@/services/paymentGatewayService'
import exchangeRate from '@/services/exchangeRate'
import companies from '@/services/companies'

import TheAlerts from '@/components/TheAlerts.vue'
import CheckoutProviderForm from '@/components/CheckoutProviderForm.vue'
import CheckoutProviderConfirmation from '@/components/CheckoutProviderConfirmation.vue'
import CheckoutProviderPageNotFound from '@/components/CheckoutProviderPageNotFound.vue'
import CheckoutBrokerForm from '@/components/CheckoutBrokerForm.vue'
import { environmentPrefix } from '@/utils/env'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'

@Component({
  components: {
    EnvironmentIndicator,
    TheAlerts,
    CheckoutProviderForm,
    CheckoutProviderConfirmation,
    CheckoutProviderPageNotFound,
    CheckoutBrokerForm,
  },
})
export default class Checkout extends Vue {
  metaInfo() {
    return {
      title: `Checkout${
        this.quote && this.quote.company.name
          ? ' - ' + this.quote.company.name
          : ''
      }`,
      titleTemplate: '%s',
    }
  }

  quote: any = null
  terms: string = ''
  isSubmitting: boolean = false
  quoteNotFound: boolean = false
  hasSubmittedSuccessfully: boolean = false
  paymentGateway: any = undefined
  exchangeRate: number = null
  currencyType: string = 'USD'
  redirectsForBrokerTypes: any = {
    shofur: 'https://shofur.com/reservation/thank-you',
    national:
      'https://www.charterup.com/reservation-confirmation?name=National%20Charter%20Bus',
    gogo: 'https://gogocharters.com/thank-you',
  }

  get checkoutPageIsForBroker() {
    return !!this.quote?.checkoutPage && this.quote?.company?.broker
  }

  get checkoutPageIsForCharterUp() {
    return (
      (!this.quote?.checkoutPage || this.quote?.checkoutPageId === 4) &&
      this.quote?.company?.broker
    )
  }

  get checkoutBrokerRedirectType() {
    // if provider
    if (!this.checkoutPageIsForBroker) {
      return null
    }
    if (this.checkoutPageIsForCharterUp) {
      this.redirectToCharterUP(this.$route.params.quoteHash)
    }

    // if shofur, gogo, national
    return this.quote?.checkoutPage?.key
  }

  get checkoutPageBasedOnCompanyType() {
    // DEBUG FLAGS
    if (this.$route.query.showConfirmation) {
      return CheckoutProviderConfirmation
    }
    if (this.hasSubmittedSuccessfully) {
      return CheckoutProviderConfirmation
    }
    if (this.quoteNotFound) {
      return CheckoutProviderPageNotFound
    }
    if (this.checkoutPageIsForCharterUp) {
      this.redirectToCharterUP(this.$route.params.quoteHash)
      return
    }
    if (this.checkoutPageIsForBroker) {
      return CheckoutBrokerForm
    }
    if (this.quote) {
      return CheckoutProviderForm
    }
    return null
  }

  async created(): Promise<void> {
    try {
      const quoteResponse = await nonMarketplaceCheckoutDetailByHash(
        this.$route.params.quoteHash
      )
      if (quoteResponse.status === 404) {
        this.quoteNotFound = true
        return
      } else if (quoteResponse?.data?.redirect?.length > 0) {
        window.open(quoteResponse.data.redirect, '_self')
        return
      }

      this.quote = quoteResponse?.data

      if (!this.quote.isActive) {
        this.quoteNotFound = true
        return
      }
      const companyId = this.quote?.company.companyId
      const companyTermsResponse = await companies.getDefaultCompanyTerms(
        companyId
      )
      this.terms = companyTermsResponse.data?.companyTerm?.body
      this.currencyType = this.quote.currencyType
      if (this.currencyType !== 'USD') {
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate = exchangeRateData?.data?.rates?.[this.currencyType]
      }

      await this.$store.dispatch('split/updateKey', {
        key: this.quote?.customer?.customerId,
        forceRefresh: true,
      })

      await this.initPaymentGateway()

      // add processing fee of 0 if there is none
      this.quote.trips.forEach((trip) => {
        if (
          !trip.charges.some((charge) => {
            return charge.chargeType.key === 'processing_fees'
          })
        ) {
          trip.charges.push({
            amount: 0.0,
            chargeType: {
              description: 'Miscellaneous Fees',
              key: 'processing_fees',
              label: 'Processing Fees',
            },
          })
        }
      })
    } catch (error) {
      //@ts-ignore
      const host = window.location.host
      if (host.includes('shofur')) {
        //@ts-ignore
        window.location = 'https://www.shofur.com/checkout-page-not-found'
      } else if (host.includes('gogocharters')) {
        //@ts-ignore
        window.location = 'https://www.gogocharters.com/checkout-page-not-found'
      } else if (host.includes('nationalbuscharter')) {
        //@ts-ignore
        window.location =
          'https://www.nationalbuscharter.com/checkout-page-not-found'
      } else {
        this.quoteNotFound = true
      }
    }
  }

  async initPaymentGateway(): Promise<void> {
    const companyId = this.quote?.company.companyId
    const companyCheckoutPageId = this.quote?.checkoutPage?.checkoutPageId

    const paymentGateway = new PaymentGateway()
    const paymentGatewayResponse = await paymentGateway.init(
      { id: companyId },
      companyCheckoutPageId,
      'finix'
    )

    this.paymentGateway = paymentGatewayResponse?.paymentGateway
  }

  async submit(paymentMethodData: any): Promise<void> {
    let tokenizedPaymentInfo
    this.isSubmitting = true
    try {
      tokenizedPaymentInfo = {
        tokens: [paymentMethodData.finixToken],
        paymentGateway: this.paymentGateway,
      }
      delete paymentMethodData.finixToken
    } catch (error: any) {
      error.forEach((specificError) => {
        if (specificError.includes('credit card')) {
          this.$store.dispatch('app/showAlert', {
            message: specificError,
            type: 'error',
          })
        } else if (
          specificError.includes('expir') ||
          specificError.includes('Expir')
        ) {
          this.$store.dispatch('app/showAlert', {
            message: specificError,
            type: 'error',
          })
        }
      })
      this.isSubmitting = false
      throw new Error(error?.message || JSON.stringify(error))
    }

    try {
      await convertQuote(this.quote, tokenizedPaymentInfo, paymentMethodData)
      if (this.checkoutPageIsForBroker) {
        if (this.checkoutPageIsForCharterUp) {
          this.redirectToCharterUP(this.$route.params.quoteHash)
        }
        //@ts-ignore
        window.location = this.redirectsForBrokerTypes[
          this.checkoutBrokerRedirectType
        ]
        return
      }
      this.hasSubmittedSuccessfully = true
    } catch (error: any) {
      if (
        error?.response?.data?.message?.includes('expir') ||
        error?.response?.data?.message?.includes('Expir')
      ) {
        this.$store.dispatch('app/showAlert', {
          message: [error?.response.data.message],
          type: 'error',
        })
      } else {
        this.$store.dispatch('app/showAlert', {
          message:
            error?.response?.data?.message ||
            'Could not process payment. Please check your payment information and try again.',
          type: 'error',
        })
      }
      this.isSubmitting = false
      throw new Error(error?.message || JSON.stringify(error))
    }
  }

  redirectToCharterUP(hash) {
    const url = `https://${environmentPrefix()}.charterup.com/guest-checkout/${hash}`
    //@ts-ignore
    window.location = url
  }
}
