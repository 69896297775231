<template>
  <v-card flat>
    <v-progress-linear v-if="!loaded" :indeterminate="true" height="2" />
    <v-layout v-else class="padding-y-4">
      <v-flex class="d-flex align-center" row>
        <v-tabs v-model="selectedTab" class="margin-t-0 margin-b-2 no-grow v-tabs__container">
          <template v-for="component in tripMonitoringComponents">
            <v-tab
              :id="`trip-monitoring-tab-${component.text.toLowerCase()}`"
              :key="component.text"
              class="v-tabs__item"
            >
              <v-flex class="d-flex align-center">
                <p class="margin-a-0">
                  {{ component.text }}
                </p>
                <CRIcon v-if="showWarning(component)" color="yellow" class="padding-l-2 no-grow" view-box="0 0 24 24">
                  alert_filled
                </CRIcon>
              </v-flex>
            </v-tab>
          </template>
        </v-tabs>
        <p v-if="loaded && showHighlightLink" class="margin-a-0 cursor-pointer text-primary no-grow padding-l-4" @click="highlightLinkAction">
          {{ highlightLinkDisplay }}
        </p>
      </v-flex>
    </v-layout>
    <keep-alive>
      <component
        v-show="loaded"
        :is="tripMonitoringComponents[selectedTab].value"
        :id="`${currentReferral.reservationId}-trip-monitoring-component-selector`"
        v-bind="currentReferral"
        :trip-monitoring-detail="tripMonitoringDetail"
        :referral="referral"
        :driver-list="driverList"
        :vehicle-list="vehicleList"
        :trip-monitoring-vehicles="tripMonitoringVehicles"
        :trip-amenities="tripAmenities"
        :contact-logs="contactLogs"
        :contact-preference="contactPreference"
        :vehicle-assignments="vehicleAssignments"
        :tracking-devices="trackingDevices"
        :model="model"
        :company="company"
        @refresh="$emit('refresh')"
      />
    </keep-alive>
  </v-card>
</template>

<script>
import TripMonitoringReservationDetails from '@/components/TripMonitoringReservationDetails.vue'
import TripMonitoringDriverTab from '@/components/TripMonitoringDriverTab.vue'
import TripMonitoringContactLogsTab from '@/components/TripMonitoringContactLogsTab.vue'
import TripMonitoringTrackingTab from '@/components/TripMonitoringTrackingTab.vue'
import TripMonitoringProvider from './TripMonitoringProvider.vue'
import TripMonitoringVehicleDetail from './TripMonitoringVehicleDetail.vue'
import {
  getTripMonitoringDetail,
  reservationById,
} from '@/services/reservations'
import drivers from '@/services/drivers'
import { EventBus } from '@/utils/event-bus'
import {
  buildModel
} from '@/utils/tripAssignments'
import { isCompanyTierLessThanReservationTierByLabels, getTierLabelByQuality } from '@/utils/company'
import { TripMonitoringTabsHeader, guaranteedAmenityTypeIds, VehicleAmenityTypeIds } from '@/utils/enum'

export default {
  props: {
    currentReferral: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      selectedTab: 0,
      tripMonitoringComponents: [
        {
          text: TripMonitoringTabsHeader.Reservation,
          value: TripMonitoringReservationDetails,
        },
        {
          text: TripMonitoringTabsHeader.Driver,
          value: TripMonitoringDriverTab,
        },
        {
          text: TripMonitoringTabsHeader.Vehicle,
          value: TripMonitoringVehicleDetail,
        },
        {
          text: TripMonitoringTabsHeader.Provider,
          value: TripMonitoringProvider,
        },
        {
          text: TripMonitoringTabsHeader.Tracking,
          value: TripMonitoringTrackingTab,
        },
        {
          text: 'Contact Logs',
          value: TripMonitoringContactLogsTab,
        },
      ],
      tripMonitoringDetail: {},
      referral: {},
      driverList: [],
      vehicleList: [],
      vehicleAssignments: [],
      contactLogs: [],
      contactPreference: '',
      trackingDevices:[],
      loaded: false,
      model: {},
      company: {},
      tripMonitoringVehicles: [],
      tripAmenities: [],
    }
  },
  computed: {
    showHighlightLink() {
      return [TripMonitoringTabsHeader.Reservation, TripMonitoringTabsHeader.Driver, TripMonitoringTabsHeader.Vehicle, TripMonitoringTabsHeader.Provider]
      .includes(this.tripMonitoringComponents[this.selectedTab].text)
    },
    highlightLinkDisplay() {
      switch (this.tripMonitoringComponents[this.selectedTab].text) {
        case TripMonitoringTabsHeader.Reservation:
          return 'Go to reservation'
        case TripMonitoringTabsHeader.Driver:
          return 'Add new driver'
        case TripMonitoringTabsHeader.Vehicle:
        case TripMonitoringTabsHeader.Provider:
          return 'Go to operator'
      }
      return ''
    },
    highlightLinkAction() {
      switch (this.tripMonitoringComponents[this.selectedTab].text) {
        case TripMonitoringTabsHeader.Reservation:
          return this.openReservationDetail
        case TripMonitoringTabsHeader.Driver:
          return this.openNewDriverSidebar
        case TripMonitoringTabsHeader.Vehicle:
        case TripMonitoringTabsHeader.Provider:
        return this.openCompanyDetail
      }
      return ''
    },
    doesAssignedVehiclesCoverPassengerCount() {
      return this.tripMonitoringVehicles.reduce((total, vehicle) => total + vehicle.passengerCapacity, 0) >= this.tripMonitoringDetail.referralPassengerCount
    },
    doAssignedVehiclesHaveAllGuaranteedAmenities() {
      const guaranteedAmenities = this.tripAmenities
        .filter(amenity => Object.values(VehicleAmenityTypeIds).includes(amenity.id))
        .filter(amenity => Object.values(guaranteedAmenityTypeIds).includes(amenity.id))

      for (const vehicle of this.tripMonitoringVehicles) {
        if (!guaranteedAmenities.every(ga => vehicle.amenities.find(va => va.id === ga.id))) {
          return false
        }
      }
      return true
    }
  },
  beforeDestroy() {
    EventBus.$off('global-table-view-refresh')
    EventBus.$off('successful-add-driver')
  },
  methods: {
    async populateTripMonitoringDetail() {
      const tripMonitoringDetailRes = await getTripMonitoringDetail(
        this.currentReferral.reservationId
      )
      this.tripMonitoringDetail =
        tripMonitoringDetailRes.data.baseTripMonitoringDetail
      this.vehicleAssignments =
        tripMonitoringDetailRes.data.vehicleAssignments
      this.tripMonitoringVehicles = tripMonitoringDetailRes.data.vehicleAssignments
        .filter((va) => va.vehicle !== null)
        .map((va) => {
          return {
            name: va.vehicle.vehicleName,
            assignedAt: va.createdOn,
            licensePlate: va.vehicle.licensePlate,
            vehicleTypeLabel: va.vehicle.vehicleTypeLabel,
            vehicleYear: va.vehicle.vehicleYear,
            passengerCapacity: va.vehicle.passengerCapacity,
            amenities: va.vehicle.amenities
              .filter((amenity) => amenity.supported)
          }
        })
      this.tripAmenities = tripMonitoringDetailRes.data.tripAmenities
      this.company = tripMonitoringDetailRes.data.providerDetail
      this.contactLogs =
        tripMonitoringDetailRes.data.contactLogs
      this.contactPreference =
        tripMonitoringDetailRes.data.contactPreference
      this.trackingDevices =
        tripMonitoringDetailRes.data.trackingDevices
    },
    async getReferral() {
      const res = await reservationById(this.tripMonitoringDetail.parentReservationManagedId)
      this.referral = res.data.referredTo.find(
        (item) => item.reservationId === this.currentReferral.reservationId
      )
    },
    async getDriverList() {
      const driverData = await drivers.getAllDriversForCompany({
          page: 1,
          pageSize: -1,
          companyId: this.tripMonitoringDetail.companyId,
        })
        this.driverList = driverData?.data?.resultList
    },
    async getVehicleList() {
      const vehicleData = await this.$store.dispatch('vehicles/getAllVehiclesForCompany', {
          page: 1,
          pageSize: -1,
          companyId: this.tripMonitoringDetail.companyId,
        })
        this.vehicleList = vehicleData?.data?.resultList
    },
    async buildAssignmentModel() {
      const { requiredVehicles, requiredDrivers, tripVehicleGroups } = this.referral

      if (!requiredVehicles) return

      let model = buildModel(
        this.vehicleAssignments,
        requiredVehicles,
        requiredDrivers,
        tripVehicleGroups
      )
      model.driverAssignments = model.flatMap(group => group.vehicleAssignments.flatMap(va => va.driverAssignments))
      this.model = model
    },
    async load() {
      // await this.populateTripMonitoringDetail()
      // const [res, driverData, vehicleData] = await Promise.all([
      //   this.getReferral(),
      //   this.getDriverList(),
      //   this.getVehicleList(),

      // ])

      // this.buildAssignmentModel()
      this.loaded = true
    },
    openReservationDetail() {
      const routeData = this.$router.resolve({
        name: 'reservation-detail',
        params: {
          id: this.tripMonitoringDetail.parentReservationManagedId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    openNewDriverSidebar() {
      const component = () => import('../views/DriverSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        component,
        data: {
          title: 'Add Driver',
          mode: 'referralAdd',
          companyId: this.tripMonitoringDetail?.companyId,
          minimumRequirements: true,
        },
      })
    },
    openCompanyDetail() {
      const routeData = this.$router.resolve({
        name: 'affiliates-detail',
        params: {
          id: this.tripMonitoringDetail.companyId,
        },
      })
      window.open(routeData.href, '_blank')
    },
    showWarning(component) {
      if (component.text === TripMonitoringTabsHeader.Vehicle) {
        return this.tripMonitoringVehicles.length > 0
          && (!this.doesAssignedVehiclesCoverPassengerCount || !this.doAssignedVehiclesHaveAllGuaranteedAmenities)
      }
      if (component.text === TripMonitoringTabsHeader.Provider) {
        return isCompanyTierLessThanReservationTierByLabels(getTierLabelByQuality(this.company?.quality), this.tripMonitoringDetail?.tierLabel)
      }
    }
  },
  async mounted() {
    await this.load()
  },
  created() {
    EventBus.$on('global-table-view-refresh', async () => {
      await this.load()
    })
    EventBus.$on('successful-add-driver', async () => {
      await this.getDriverList()
    })
  }
}
</script>

<style lang="scss" scoped>
.v-tabs {
  .v-tabs__wrapper {
    .v-tabs__container {
      flex-direction: row;
      justify-content: flex-start !important;
      margin-top: 50px;
      .v-tabs__div {
        &.v-tabs__item {
          font-size: 14px !important;
          font-weight: 600 !important;
          background-color: $gray-new;
          height: 44px;
          opacity: 1;
          border-radius: 6px 0 0 0;
          &:has(.v-tabs__item--active) {
            background-color: $gray-light !important;
            border-radius: 6px 6px 0 0 !important;
          }
        }
        &.v-tabs__item ~ .v-tabs__item {
          border-radius: 0 0 0 0;
          width: fit-content;
        }
      }
    }
  }
}

::v-deep .v-tabs {
  background-color: $gray-new;
  border-radius: 0 6px 0 0;
  margin-top: 50px;
  height: 44px;
  &__item {
    &--active {
      color: $blue-new !important;
    }
  }
  &__slider-wrapper {
    .v-tabs__slider.accent {
      background-color: $blue-new !important;
      border-color: $blue-new !important;
      margin-bottom: 4px;
    }
  }
}
</style>
