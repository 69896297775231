<template>
  <v-form ref="lead-follow-up-detail">
    <v-layout row>
      <v-layout col>
        <CRSelect
          :id="`quote-form-lead-temperature-type`"
          v-model="leadTemperatureTypeId"
          style="width: 200px"
          :items="leadTemperatureTypes"
          item-value="leadTemperatureTypeId"
          item-text="label"
          label="Lead Temperature"
          use-svg-icons
          placeholder="Select Lead Temp"
          :disabled="isModeView"
          :error-messages="leadTemperatureValidationError"
          @change="setQuoteLeadTemperature"
        />
      </v-layout>
      <v-layout col>
        <CRInput
          :id="`quote-form-follow-up-date`"
          v-model="leadFollowUpDate"
          type="date"
          label="Next Follow Up Date"
          :disabled="isModeView"
          :error-messages="followUpDateValidationError"
          @input="setQuoteLeadFollowUpDate"
        />
      </v-layout>
    </v-layout>
    <v-layout row>
      <CRRichText
        v-show="!isModeView"
        id="quote-form-notes-text-area"
        :note="newQuoteNoteText"
        placeholder="Add a comment"
        :rows="4"
        auto-grow
        hide-details
        style="width: 100%"
        @htmlchange="updateQuoteNoteHtml"
        @textchange="updateQuoteNoteText"
      />
    </v-layout>
    <v-layout column>
      <div
        v-for="(note, index) in sortedQuoteNotes"
        :key="index"
        style="padding-top: 20px"
      >
        <div style="padding-bottom: 10px">
          <b>Added by</b>
          {{ `${note.createdByName} ${isoToString(note.createdOn)}` }}
        </div>
        <span v-html="note.html || note.note" />
      </div>
    </v-layout>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import { isoToString } from '@/utils/time'
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    quote: { type: Object, default: () => ({}) },
    mode: { type: String, default: undefined },
  },
  data() {
    return {
      leadTemperatureTypes: [],
      leadTemperatureTypeId: null,
      leadFollowUpDate: null,
      leadTemperatureValidationError: null,
      followUpDateValidationError: null,
      newQuoteNoteText: null,
    }
  },
  computed: {
    sortedQuoteNotes() {
      if (!this.quote?.quoteNotes) {
        return []
      }
      return deepClone(this.quote.quoteNotes).sort((a, b) =>
        a.createdOn < b.createdOn ? 0 : -1
      )
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
  },
  watch: {
    'quote.leadTemperatureTypeId'() {
      if (this.quote?.leadTemperatureTypeId) {
        this.leadTemperatureTypeId = this.quote.leadTemperatureTypeId
      }
    },
    'quote.leadFollowUpDate'() {
      if (this.quote?.leadFollowUpDate) {
        this.leadFollowUpDate = this.quote.leadFollowUpDate
      }
    },
  },
  async mounted() {
    await this.getLeadTemperatureTypes()

    this.leadTemperatureTypeId = this.quote?.leadTemperatureTypeId
    this.leadFollowUpDate = this.quote?.leadFollowUpDate

    if (!this.quote?.leadFollowUpDate) {
      this.leadFollowUpDate = DateTime.local().plus({ days: 2 }).toISODate()
      this.setQuoteLeadFollowUpDate(this.leadFollowUpDate)
    }
  },
  methods: {
    isoToString,
    deepClone,
    async getLeadTemperatureTypes() {
      const leadTemperatureTypeResponse = await this.$store.dispatch(
        'types/getLeadTemperatureTypes',
        {}
      )
      this.leadTemperatureTypes = leadTemperatureTypeResponse.data.resultList
    },
    setQuoteLeadTemperature(event) {
      this.$emit('set-lead-temperature', event)
      this.leadTemperatureValidationError = null
    },
    setQuoteLeadFollowUpDate(event) {
      this.$emit('set-lead-follow-up-date', event)
      if (this.validate()) {
        this.followUpDateValidationError = null
      }
    },
    updateQuoteNoteText(value) {
      this.$emit('set-quote-note-text', value)
    },
    updateQuoteNoteHtml(value) {
      this.$emit('set-quote-note-html', value)
    },
    validate() {
      if (
        !this.leadFollowUpDate ||
        (this.isModeAdd && this.leadFollowUpDate < DateTime.local().toISODate())
      ) {
        this.followUpDateValidationError = 'Select Future Date'
        return false
      }
      return true
    },
  },
}
</script>
