import { render, staticRenderFns } from "./ReservationOverview.vue?vue&type=template&id=dbaea730&scoped=true"
import script from "./ReservationOverview.vue?vue&type=script&lang=ts"
export * from "./ReservationOverview.vue?vue&type=script&lang=ts"
import style0 from "./ReservationOverview.vue?vue&type=style&index=0&id=dbaea730&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbaea730",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout,VSpacer})
