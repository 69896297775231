
import Sheet from '@/layouts/Sheet.vue'
import IOSStyleChatDialog from '@/components/IOSStyleChatDialog.vue'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { OperatorAssignmentCallDetail, TranscriptMessage, ChatDialogMessage } from '@/models/dto/CallRequest'
import { getOperatorAssignmentCallDetail } from '@/services/callRequest'
import { IANAZone, DateTime } from 'luxon'

@Component({
  components: {
    Sheet,
    IOSStyleChatDialog,
  },
})
export default class AICallDetails extends Vue {
  @Prop({ type: String, required: true}) readonly externalId: string

  callDetails: OperatorAssignmentCallDetail | null = null
  loading: boolean = true
  senderUsers: Set<string> = new Set(['assistant', 'agent-action'])
  displayTimeZone: IANAZone = new IANAZone('America/New_York')

  get referralManagedIds(): string {
    if (this.callDetails) {
      return Array.from(this.callDetails?.managedIds).join(', ')
    }

    return ''
  }

  get callCreatedAt(): DateTime | null {
    if (this.callDetails) {
      return DateTime.fromISO(this.callDetails?.createdAt, { zone: this.displayTimeZone })
    }

    return null
  }

  get callTranscript(): ChatDialogMessage[] {
    if (this.callDetails) {
      return this.callDetails?.transcript.map((messageDTO) => ({
        user: messageDTO.user,
        text: messageDTO.text,
        createdAt: DateTime.fromISO(messageDTO.createdAt, { zone: this.displayTimeZone })
      }))
    }

    return []
  }

  get fileFormats(): string[] {
    if (this.recordingUrlHasFileEnding) {
      return []
    }

    return ['mp3']
  }

  get recordingUrlHasFileEnding(): boolean {
    const hasFileEnding = /.*\.\w+$/
    if (this.callDetails) {
      return hasFileEnding.test(this.callDetails?.recordingUrl)
    }

    return false
  }

  formatDateTime(dateTime: DateTime): string {
    return dateTime.toFormat('MM/dd/yy h:mma ZZZZ')
  }

  async created(): Promise<void> {
    const response = await getOperatorAssignmentCallDetail(this.externalId)
    this.callDetails = response.data
    this.loading = false
  }
}

