
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import { liveTripMapOptions } from '@/utils/mapStyles'
import { Coordinates, Route } from '@/models/GoogleMaps'
import { MarkerClusterer, GridAlgorithm } from '@googlemaps/markerclusterer'
import { generateBusIcon } from '@/utils/baseMap'

@Component({})
export default class BaseMapComponent extends Vue {
  @Prop({ required: false, default: { lat: 10, lng: 10 } }) center!: Coordinates
  @Prop({ required: false, default: 7 }) zoom!: number
  @Prop({ required: false, default: () => [] }) busMarkers!: {
    position: { lat: number; lng: number }
    hasAlert?: boolean
    heading?: number
  }[]
  @Prop({ required: false, default: () => [] }) routes!: Route[]
  @Prop({ required: false, default: () => [] }) highlightedRoutes!: string[]
  @Prop({ required: false, default: 300 }) height!: number | string
  @Prop({ required: false, default: 500 }) width!: number | string

  @Watch('routes', { immediate: true })
  async onRoutesChanged(newRoutes, oldRoutes) {
    if (!newRoutes?.length || newRoutes?.length === oldRoutes?.length) {
      return
    }
    await this.loadRoutes()
  }

  @Watch('highlightedRoutes', { immediate: true })
  async onHighlighedRoutesChange(newRoutes, oldRoutes) {
    if (newRoutes?.length === oldRoutes?.length) {
      return
    }
    await this.loadRoutes()
  }

  @Watch('busMarkers')
  async onBusMarkersChanged() {
    await this.$nextTick()
    this.initializeClusterer()
  }

  map = undefined
  mapOptions = liveTripMapOptions
  directionsDisplay = {}
  directionsService = null
  markersClusterer = null

  get style() {
    return {
      height:
        typeof this.height === 'number' ? `${this.height}px` : this.height,
      width: typeof this.width === 'number' ? `${this.width}px` : this.width,
    }
  }

  async loadRoutes(): Promise<void> {
    for (const route of this.routes) {
      // @ts-ignore
      this.directionsService = new window.google.maps.DirectionsService()

      const strokeColor = this.highlightedRoutes.includes(route.id)
        ? '#FF6161'
        : '#222930'

      const polylineOptions = {
        strokeColor,
        strokeWeight: 6,
        zIndex: 5,
      }

      // @ts-ignore
      if (this.directionsDisplay[route.id]) {
        this.directionsDisplay[route.id].setMap(null)
      }

      // @ts-ignore
      this.directionsDisplay[
        route.id
      ] = new window.google.maps.DirectionsRenderer({
        polylineOptions,
        suppressMarkers: true,
        preserveViewport: true,
      })
      this.directionsDisplay[route.id].setMap(this.map)
      const { stops } = route

      const request = {
        origin: stops[0].position,
        destination: stops[stops.length - 1].position,
        // @ts-ignore
        travelMode: window.google.maps.TravelMode.DRIVING,
      }

      this.directionsService.route(request, (result, status) => {
        if (status === 'OK') {
          this.directionsDisplay[route.id].setDirections(result)
        } else {
          console.error('Directions request failed:', status)
        }
      })
    }
  }

  createGoogleMapMarkers(): any[] {
    // @ts-ignore
    if (!window.google) return []

    return this.busMarkers.map((markerData) => {
      const icon = generateBusIcon(markerData.hasAlert, markerData.heading)
      // @ts-ignore
      return new window.google.maps.Marker({
        position: markerData.position,
        icon,
      })
    })
  }

  initializeClusterer(): void {
    // @ts-ignore
    if (!this.map || !window.google) return

    // Clear existing clusterer if it exists
    if (this.markersClusterer) {
      this.markersClusterer.clearMarkers()
    }

    const markers = this.createGoogleMapMarkers()

    this.markersClusterer = new MarkerClusterer({
      map: this.map,
      markers: markers,
      // @ts-ignore
      algorithm: new GridAlgorithm({
        maxZoom: 16,
        gridSize: 60,
      }),
      renderer: {
        render: ({ count, position }) => {
          return new window.google.maps.Marker({
            position,
            label: {
              text: String(count),
              color: '#3b9cf1',
            },
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              scale: 20,
              fillColor: '#FFFFFF',
              fillOpacity: 1.0,
              strokeWeight: 2,
              strokeColor: '#3b9cf1',
            },
          })
        },
      },
    })
  }

  // Emits an event with a bounds type of ParsedBounds
  handleBoundsChanged(event): void {
    if (!event) {
      return
    }
    const bounds = JSON.parse(JSON.stringify(event))
    this.$emit('bounds-changed', bounds)
  }

  async loadMap(): Promise<void> {
    if (this.$refs.map == null) {
      return
    }

    const map = await (this.$refs.map as any).$mapPromise
    this.map = map

    await this.loadRoutes()
    this.initializeClusterer()
  }

  mounted() {
    this.loadMap()
  }
}
