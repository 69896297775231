<template>
  <v-layout justify-space-between>
    <v-flex xs6>
      <div>
        <div
          style="display: flex; flex-wrap: wrap; width: 80%; word-wrap: break"
        >
          <strong class="operator-name">
            {{ op(operator, 'name') }}
          </strong>
        </div>

        <div style="display: flex; align-items: center">
          <v-chip
            :color="$cr.theme.grayLight"
            label
            disabled
            style="right: 5px"
          >
            {{ operator?.partnerTypeLabel }}
          </v-chip>
          <v-chip
            :color="$cr.theme.grayLight"
            label
            disabled
            style="right: 5px"
          >
            {{ operator?.onboardingStatusTypeLabel }}
          </v-chip>
          <v-chip
            v-if="operator?.internationalOperation"
            :color="$cr.theme.grayLight"
            label
            disabled
            style="right: 5px"
          >
            Int'l
          </v-chip>
        </div>
        <div style="display: flex; align-items: center">
          <v-chip class="pa-0" color="primary" disabled style="right: 5px">
            {{ operator?.referralCount }}
          </v-chip>
          <template v-if="operator?.preferred">
            <img src="@/assets/images/CharterUP.svg" />
          </template>
          <template v-else>
            <img
              v-if="operator?.partnerTypeId === 1"
              class="ml-2"
              src="@/assets/images/shofur.svg"
            />
            <img
              v-if="operator?.partnerTypeId === 2"
              class="ml-2"
              src="@/assets/images/gogo.svg"
            />
          </template>
          <template v-if="operator?.spab">
            <CRIcon
              height="42"
              width="42"
              style="margin: 0 0 -10px 8px"
              color="primary"
            >
              spab
            </CRIcon>
          </template>
        </div>
        <div class="operator-name">
          {{ phoneFormatFilter(op(operator, 'phone')) }}
        </div>
        <div style="word-wrap: break; width: 90%" class="operator-name">
          {{ operator?.email }}
        </div>
      </div>
    </v-flex>
    <v-flex xs6>
      <layout
        class="padding-t-5 padding-l-5 justify-start"
        style="display: flex"
        column
      >
        <v-flex class="no-grow">
          <v-layout row>
            {{ operator?.addressName }}
          </v-layout>
          <v-layout>
            <v-tooltip v-if="garageNames.length" right>
              <template #activator="{ on }">
                <div :style="`color: ${$cr.theme.primary}`" v-on="on">
                  Show More
                </div>
              </template>
              <div v-for="(garage, gidx) in garageNames" :key="gidx">
                {{ garage }}
              </div>
            </v-tooltip>
          </v-layout>
        </v-flex>
      </layout>
    </v-flex>
  </v-layout>
</template>

<script>
import op from 'simple-object-path'
import { phoneFormatFilter } from '@/utils/phone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    operator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      op,
      loading: false,
      phoneFormatFilter,
    }
  },
  computed: {
    ...authComputed,
    garageNames() {
      return Array.from(
        new Set(
          this.operator?.garageNames?.filter(
            (g) => g !== this.operator.addressName
          )
        )
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.location-container {
  display: flex;
  align-items: center;
  height: 120px;
  padding-bottom: 5px;
}

.operator-name {
  color: $blue;
  word-wrap: break-word;
}
</style>
