<template>
  <div>
    <div style="display: flex; justify-items: start; align-items: center">
      <v-switch
        :input-value="areTripExperienceNotificationsEnabled"
        :loading="loading"
        color="primary"
        hide-details
        style="
          max-width: 46px;
          margin-top: 0;
          padding-top: 0;
          margin-right: 8px;
        "
        @change="toggleMaster($event)"
      />
      <span class="font-weight-bold">
        All Notifications
        {{ areTripExperienceNotificationsEnabled ? 'On' : 'Off' }}
      </span>
    </div>
    <div
      v-for="(notification, notificationIndex) in settings"
      :key="`${notification.customerNotificationTypeId}-${notificationIndex}`"
    >
      <div>
        {{ notification.label }}
        <div
          v-for="(userSetting, userSettingIndex) in notification.userSettings"
          :key="`${notification.customerNotificationTypeId}-${notificationIndex}-${userSettingIndex}`"
          style="display: flex; justify-items: start; align-items: center"
          class="margin-l-4"
        >
          <v-switch
            :input-value="getSwitchInputValue(userSetting)"
            :loading="loading"
            color="primary"
            hide-details
            :disabled="isSwitchDisabled(userSetting)"
            style="
              max-width: 46px;
              margin-top: 0;
              padding-top: 0;
              margin-right: 8px;
            "
            @change="toggleSetting($event, userSetting)"
          />
          <span>
            {{ createSwitchLabel(userSetting) }}
          </span>
          <div
            v-if="userSetting.isDisabledByCustomer"
            class="d-flex align-center"
          >
            <CRIcon
              color="error"
              view-box="0 0 24 24"
              class="margin-r-1 margin-l-2 w-16 h-16"
            >
              info
            </CRIcon>
            Disabled by Customer
          </div>
          <div
            v-else-if="userSetting.isDisabledByCustomerAccount"
            class="d-flex align-center"
          >
            <CRIcon
              color="error"
              view-box="0 0 24 24"
              class="margin-r-1 margin-l-2 w-16 h-16"
            >
              info
            </CRIcon>
            Disabled by Account {{ customerAccount.name }}
          </div>
          <div v-if="userSetting.sentOn" class="d-flex align-center">
            <CRIcon
              color="success"
              view-box="0 0 24 24"
              class="margin-r-1 margin-l-2 w-16 h-16"
            >
              check_circle
            </CRIcon>
            Sent on {{ userSetting.sentOn }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getTripExperienceNotificationSettingsByReservationId,
  updateTripExperienceNotificationSettingsById,
  updateMasterTripExperienceNotificationSettingsByReservationId,
} from '@/services/tripExperienceNotification'
import { CustomerAccount } from '@/classes/CustomerAccount'

const SORTED_SETTINGS_VALUES = [
  { id: 7,  label: 'Automatically send prep for your trip 7 days prior to departure' },
  { id: 1,  label: 'Automatically send driver information 3 hours before pickup' },
  { id: 2,  label: 'Automatically send vehicle is on the way 10 minutes before pickup' },
  { id: 5,  label: 'Automatically send vehicle is arriving when ETA is 5 minutes away from pickup' },
  { id: 4,  label: 'Automatically send how was your trip 60 minutes after dropoff' },
]

export default {
  props: {
    reservationId: { type: Number, default: null },
    customerId: { type: Number, default: null },
    tripContactId: { type: Number, default: null },
    customerAccount: { type: CustomerAccount, default: null },
  },
  data() {
    return {
      settings: [],
      loading: false,
      areTripExperienceNotificationsEnabled: false,
    }
  },
  async mounted() {
    this.loadNotificationSettings()
  },
  methods: {
    async loadNotificationSettings() {
      const tripExperienceNotificationSettings = await getTripExperienceNotificationSettingsByReservationId(
        this.reservationId
      )
      this.areTripExperienceNotificationsEnabled =
        tripExperienceNotificationSettings.data.areTripExperienceNotificationsEnabled
      this.settings = this.processSettings(
        tripExperienceNotificationSettings.data
          .tripExperienceNotificationSettingList
      )
    },
    processSettings(tripExperienceNotificationSettings) {
      return SORTED_SETTINGS_VALUES
        .map((setting) => this.buildSection(tripExperienceNotificationSettings, setting.id, setting.label))
        .filter((section) => section.userSettings.length > 0)
    },
    buildSection(
      tripExperienceNotificationSettings,
      customerNotificationTypeId,
      label
    ) {
      return {
        label,
        customerNotificationTypeId,
        userSettings: this.orderUsers(
          tripExperienceNotificationSettings.filter(
            (setting) =>
              setting.customerNotificationTypeId ===
              customerNotificationTypeId
          )
        ),
      }
    },
    orderUsers(userSettings) {
      return userSettings.sort((a, b) => {
        // First, check if a or b match the customerId or tripContactId
        if (a.user.userId === this.customerId) {
          return -1 // a comes first
        } else if (b.user.userId === this.customerId) {
          return 1 // b comes first
        } else if (a.user.userId === this.tripContactId) {
          return -1 // a comes first
        } else if (b.user.userId === this.tripContactId) {
          return 1 // b comes first
        }
        // If neither a nor b match, sort by last name
        const lastNameA = a.user.lastName.toUpperCase()
        const lastNameB = b.user.lastName.toUpperCase()
        if (lastNameA < lastNameB) {
          return -1
        } else if (lastNameA > lastNameB) {
          return 1
        }
        return 0
      })
    },
    isSwitchDisabled(userSetting) {
      return (
        userSetting.sentOn ||
        userSetting.isDisabledByCustomer ||
        userSetting.isDisabledByCustomerAccount ||
        !this.areTripExperienceNotificationsEnabled
      )
    },
    getSwitchInputValue(userSetting) {
      return (
        userSetting.isEnabled &&
        !userSetting.isDisabledByCustomer &&
        !userSetting.isDisabledByCustomerAccount &&
        this.areTripExperienceNotificationsEnabled
      )
    },
    async toggleSetting(isEnabled, tripExperienceNotificationSetting) {
      this.loading = true
      const tripExperienceNotificationSettingId =
        tripExperienceNotificationSetting.tripExperienceNotificationSettingId
      const payload = {
        ...tripExperienceNotificationSetting,
        isEnabled,
      }
      await updateTripExperienceNotificationSettingsById(
        tripExperienceNotificationSettingId,
        payload
      )
      await this.loadNotificationSettings()
      this.loading = false
    },
    createSwitchLabel(tripExperienceNotificationSetting) {
      const userId = tripExperienceNotificationSetting.userId
      let name = `${tripExperienceNotificationSetting.user.firstName} ${tripExperienceNotificationSetting.user.lastName}`
      name = name !== ' ' ? name : `User ID ${userId}`
      let append = ''
      if (userId === this.tripContactId && userId === this.customerId) {
        append = ' (Customer & Trip Contact)'
      } else if (userId === this.customerId) {
        append = ' (Customer)'
      } else if (userId === this.tripContactId) {
        append = ' (Trip Contact)'
      }
      return `${name}${append}`
    },
    async toggleMaster(value) {
      this.loading = true
      try {
        await updateMasterTripExperienceNotificationSettingsByReservationId(
          this.reservationId,
          value
        )
        await this.loadNotificationSettings()
      } catch (error) {
        console.log(error)
      }
      this.loading = false
    },
  },
}
</script>
