
import { Component, Vue } from 'vue-property-decorator'
import CustomerAccountContracts from '@/components/CustomerAccountContracts.vue'
import CustomerAccountSummaryQuotes from '@/components/CustomerAccountSummaryQuotes.vue'
import CustomerAccountSummaryReservations from '@/components/CustomerAccountSummaryReservations.vue'
import { BillingFrequencyType } from '@/models/dto'
import { CustomerAccountPurpose, SplitFeatureFlag } from '@/utils/enum'
import { phoneFormatFilter } from '@/utils/phone'
import { currencyFilter } from '@/utils/string'

@Component({
  components: {
    CustomerAccountContracts,
    CustomerAccountSummaryQuotes,
    CustomerAccountSummaryReservations,
  },
})
export default class CustomerAccountSummary extends Vue {
  selectedTab: number = 0
  isTiersEnabled: boolean = false
  quotesReservationsComponents = [
    {
      text: 'Quotes',
      value: CustomerAccountSummaryQuotes,
    },
    {
      text: 'Reservations',
      value: CustomerAccountSummaryReservations,
    },
    {
      text: 'Contracts',
      value: CustomerAccountContracts,
    },
  ]

  get customerAccount(): any {
    return this.$store.getters['customerAccounts/getCustomerAccountDetail']
  }

  get billingFrequencyTypes(): BillingFrequencyType[] {
    return this.$store.getters['types/billingPaymentTypes']
  }

  get customerAccountId(): string {
    return this.$route.params.id
  }

  get customerAccountIndustryDisplay(): string {
    return this.customerAccount?.industryDisplay?.replace('||', ' > ') || '--'
  }

  get customerAccountName(): string {
    return this.customerAccount?.name || '--'
  }

  get customerAccountAccountExecutive(): string {
    return this.customerAccount?.accountExecutive?.firstName ||
      this.customerAccount?.accountExecutive?.lastName
      ? `${this.customerAccount?.accountExecutive?.firstName || ''} ${
          this.customerAccount?.accountExecutive?.lastName || ''
        }`
      : '--'
  }

  get customerAccountAddress(): string {
    return this.customerAccount?.address?.addressName || '--'
  }

  get customerAccountEmail(): string {
    return this.customerAccount?.email || '--'
  }

  get customerAccountPhone(): string {
    return this.customerAccount?.phone
      ? this.formattedPhone(this.customerAccount.phone)
      : '--'
  }

  get customerAccountSupportEmail(): string {
    return this.customerAccount?.supportEmail || '--'
  }

  get customerAccountSupportPhone(): string {
    return this.customerAccount?.supportPhone
      ? this.formattedPhone(this.customerAccount.supportPhone)
      : '--'
  }

  get canSelfServeCancel(): string {
    const value = this.customerAccount?.canSelfServeCancel
    if (value === true) {
      return 'Yes'
    } else if (value === false) {
      return 'No'
    } else if (value === null) {
      return 'Inherit from Parent'
    }
    return '--'
  }

  get customerAccountNotes(): string {
    return this.customerAccount?.notes || '--'
  }

  get customerAccountNameApprovedForUserInterface(): boolean {
    return this.customerAccount?.nameApprovedForUserInterface || false
  }

  get customerAccountBillingFrequency(): string {
    const billingFrequencyTypeId = this.customerAccount?.billingFrequencyTypeId

    return (
      this.billingFrequencyTypes.find(
        (value) => value.id == billingFrequencyTypeId
      )?.label ?? '--'
    )
  }

  get customerAccountAvatar(): string {
    return this.customerAccount?.lightLogoUrl
  }

  get customerAccountEnterpriseAccountId(): string {
    return this.customerAccount?.enterpriseAccountId
  }

  get customerAccountEnterpriseAccountName(): string {
    return this.customerAccount?.enterpriseAccountName || '--'
  }

  get customerAccountTierLabel(): string {
    return this.customerAccount?.tier?.label || '--'
  }

  get customerAccountIsDemo(): boolean {
    return this.customerAccount?.purpose === CustomerAccountPurpose.Demo
  }

  get annualContractValue(): string {
    const acv = this.customerAccount?.salesforceAccount?.annualContractValue
    return acv ? currencyFilter(acv) : '--'
  }

  get totalContractValue(): string {
    const tcv = this.customerAccount?.salesforceAccount?.totalContractValue
    return tcv ? currencyFilter(tcv) : '--'
  }

  async mounted(): Promise<void> {
    this.isTiersEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled',
      SplitFeatureFlag.ServiceTier
    )
  }

  formattedPhone(phone: string): string {
    if (!phone) {
      return ''
    }
    return phoneFormatFilter(phone)
  }

  openParentCustomerAccount(): void {
    const route = this.$router.resolve({
      name: 'customer-accounts.view',
      params: { id: this.customerAccountEnterpriseAccountId },
    })
    window.open(route.href, '_blank')
  }

  openDetailSidebar(): void {
    const component = () => import('./CustomerAccountSidebar.vue')
    this.$store.dispatch('app/openSidebarDialog', {
      data: {
        title: 'Edit Summary',
        mode: 'edit',
        id: parseInt(this.$route.params.id),
      },
      component,
      persistent: true,
    })
  }
}
