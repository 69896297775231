<template>
  <div>
    <span>{{ recipient }}: </span>
    <span class="font-weight-bold">{{ status }}</span>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    },
    recipient: {
      type: String,
      required: true
    }
  }
}
</script>
