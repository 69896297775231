
import { AmenityType, TripAmenityConfirmations } from '@/models/dto/Amenity'
import { updateTripAmenities } from '@/services/trips'
import { upsertReferralAmenityNote } from '@/services/tripMonitoring'
import { quoteFormAmenities, sideBarAmenities } from '@/utils/amenity'
import { deepClone } from '@/utils/deepClone'
import {
  guaranteedAmenityTypeIds,
  bestEffortAmenityTypeIds,
  AmenityTypeId,
  paidAmenityTypeIds,
} from '@/utils/enum'
import { EventBus } from '@/utils/event-bus'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ReferralAmenityNote } from '@/models/dto'

const NOTES_ENABLED_AMENITY_TYPES = [
  AmenityTypeId.ADA,
  AmenityTypeId.NineteenACertification,
  AmenityTypeId.BottledWater,
  AmenityTypeId.AlcoholConsumption,
  AmenityTypeId.MakeModelYear,
  AmenityTypeId.BusColor,
  AmenityTypeId.BusLogos,
  AmenityTypeId.NonStandard,
  AmenityTypeId.TripCoordinators,
  AmenityTypeId.BusWraps,
]

@Component({})
export default class ReservationAmenitiesSidebar extends Vue {
  @Prop({ type: Number, required: true }) readonly tripId: number
  @Prop({ type: Object, required: true }) readonly tripAmenities: AmenityType[]
  @Prop({ type: String, default: '' }) readonly bookedByName: string
  @Prop({ type: Boolean, default: false })
  readonly hasAcceptedReferrals: boolean
  @Prop({ type: Array, default: () => [] })
  referralAmenityNotes: ReferralAmenityNote[]
  @Prop({ type: Array, default: () => [] })
  tripAmenityConfirmations: TripAmenityConfirmations[]
  @Prop({ type: String, required: true }) readonly managedId!: string

  paidAmenitiesList: AmenityType[] = []
  guaranteedAmenitiesList = []
  bestEffortAmenitiesList = []
  selectedAmenities: AmenityType[] = []
  amenityNotes: ReferralAmenityNote[] = []
  amenityConfirmations: TripAmenityConfirmations[] = []
  referralAmenityNoteIndicesToUpdate = new Set<number>()
  previousAmenities = []

  get filteredAmenities(): AmenityType[] {
    return this.tripAmenities.filter(
      (amenity) =>
        ![
          AmenityTypeId.Bathroom,
          AmenityTypeId.Luggage,
          AmenityTypeId.Outlets,
        ].includes(amenity.id)
    )
  }

  get showAmenityConfirmations(): boolean {
    return (
      this.selectedAmenities.some((amenity) =>
        Object.values(bestEffortAmenityTypeIds).includes(amenity.id)
      ) && this.hasAcceptedReferrals
    )
  }

  mounted(): void {
    if (this.bookedByName === 'Sales Bot') {
      this.paidAmenitiesList = quoteFormAmenities.filter((amenity) =>
        Object.values(paidAmenityTypeIds).includes(amenity.id)
      )
    }

    this.guaranteedAmenitiesList = sideBarAmenities
      .filter((amenity) =>
        Object.values(guaranteedAmenityTypeIds).includes(amenity.id)
      )
      .filter(
        (amenity) => !this.paidAmenitiesList.some((a) => a.id === amenity.id)
      )

    this.bestEffortAmenitiesList = quoteFormAmenities.filter((amenity) =>
      Object.values(bestEffortAmenityTypeIds).includes(amenity.id)
    )

    this.selectedAmenities = deepClone(this.tripAmenities)
    this.previousAmenities = deepClone(this.tripAmenities)
    this.amenityNotes = deepClone(this.referralAmenityNotes)
    this.amenityConfirmations = deepClone(this.tripAmenityConfirmations)
  }

  isSelected(amenity): boolean {
    return this.selectedAmenities.some((a) => a.id === amenity.id)
  }

  hasNotes(amenity): boolean {
    return NOTES_ENABLED_AMENITY_TYPES.includes(amenity.id)
  }

  showAmenityConfirmationSwitch(amenity): boolean {
    return this.isSelected(amenity) && this.showAmenityConfirmations
  }

  selectAmenity(amenity, selected): void {
    if (selected) {
      this.selectedAmenities.push(amenity)
    } else {
      const index = this.selectedAmenities.findIndex((a) => a.id === amenity.id)
      if (index >= 0) {
        this.selectedAmenities.splice(index, 1)
      }
    }
  }

  isAmenityConfirmed(amenity): boolean {
    return this.amenityConfirmations?.find(
      (c) => c.amenityTypeId === amenity.id
    )?.isConfirmed
  }

  toggleAmenityConfirmed(amenity, confirmed): void {
    const index = this.amenityConfirmations.findIndex(
      (c) => c.amenityTypeId === amenity.id
    )
    if (index >= 0) {
      this.amenityConfirmations[index].isConfirmed = confirmed
    } else {
      this.amenityConfirmations.push({
        amenityTypeId: amenity.id,
        isConfirmed: confirmed,
      })
    }
  }

  selectedAmenityNote(amenity): string {
    return this.selectedAmenities.find((a) => a.id === amenity.id)?.note?.note
  }

  updateAmenityNote(amenity, val): void {
    const selectedAmenity = this.selectedAmenities.find(
      (a) => a.id === amenity.id
    )
    if (selectedAmenity) {
      if (!selectedAmenity.note) {
        selectedAmenity.note = {
          note: val,
        }
      } else {
        selectedAmenity.note.note = val
      }
    }
  }

  updatedReferralAmenityNote(index) {
    this.referralAmenityNoteIndicesToUpdate.add(index)
  }

  async saveAmenities(): Promise<void> {
    const tripAmenities = this.selectedAmenities.map((a) => {
      const isConfirmed =
        this.amenityConfirmations?.find((c) => c.amenityTypeId === a.id)
          ?.isConfirmed || false
      return { ...a, isConfirmed }
    })

    const payload = {
      tripAmenities,
    }
    const referralAmenityNotesToUpdate = Array.from(
      this.referralAmenityNoteIndicesToUpdate
    ).map((index) => this.amenityNotes[index])

    const requests = []
    requests.push(
      ...referralAmenityNotesToUpdate.map((note) =>
        upsertReferralAmenityNote(note.referralId, {
          referralAmenityConfirmations: [],
          note: note.note,
        })
      )
    )
    requests.push(updateTripAmenities(this.tripId, payload))
    try {
      const response = await Promise.all(requests)
      if (response && response.every((res) => res.status === 200)) {
        setTimeout(() => {
          this.$store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Trip amenities successfully updated.',
          })
        }, 2000)
        EventBus.$emit('refresh-detail')

        if (!this.hasAcceptedReferrals) {
          this.$store.dispatch('app/closeDialog')
          return
        }

        await this.$store.dispatch('app/openSidebarDialog', {
          data: {
            managedId: this.managedId,
            previousAmenities: this.previousAmenities,
            title: 'Send Booking Update',
          },
          component: () => import('./ReservationSendAmenitiesUpdate.vue'),
        })
      }
    } catch (e) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'There was an error updating the trip amenities.',
      })
    }
  }
}
