<template>
  <v-container v-if="providerDetail" fluid>
    <v-layout class="sheet" column>
      <v-icon
        style="margin-left: 5px"
        @click="
          $router.push({
            name: 'quotes.view',
            params: {
              id: quoteDetail.quoteId,
              subRoute: 'bids',
            },
          })
        "
      >
        arrow_back
      </v-icon>
      <StickyColumnLayout>
        <template #left-column>
          <ProviderInfo :provider-detail="providerDetail" />
        </template>
        <template #right-column>
          <BillingSummary
            button
            v-bind="providerDetail"
            :quote="quoteDetail"
            style="margin-top: 24px"
            @click="
              $router.push({
                name: 'charterup-checkout',
                params: {
                  quoteId: $route.params.quoteId,
                  providerId: $route.params.providerId,
                },
              })
            "
          />
          <CheckoutAmenitiesCard
            :is-self-serve="isSelfServe"
            :amenities="amenities"
            :vehicles="vehicles"
          />
          <TripCard
            :style="$cr.breakpoint.lgAndUp ? 'margin-top: -9px;' : ''"
            itinerary
            :map="$cr.breakpoint.mdAndUp"
            customer-notes
            :trips="providerDetail.quote.trips"
            :created-on="providerDetail.quote.createdOn"
          />
        </template>
        <template #bottom-portion>
          <BidCardCarousel :quote-detail="quoteDetail" />
        </template>
      </StickyColumnLayout>
      <MobileFooter
        :provider-detail="providerDetail"
        @click="
          $router.push({
            name: 'charterup-checkout',
            params: {
              quoteId: $route.params.quoteId,
              providerId: $route.params.providerId,
            },
          })
        "
      />
    </v-layout>
  </v-container>
  <v-container v-else-if="notFound" class="fill-height">
    <NotFound />
  </v-container>
</template>

<script>
import TripCard from '@/components/charterup/TripCard.vue'
import BillingSummary from '@/components/charterup/BillingSummary.vue'
import { providerDetails } from '@/services/charterup/provider'
import { quoteDetailV2 } from '@/services/charterup/quotes'
import NotFound from '@/components/charterup/NotFound.vue'
import CheckoutAmenitiesCard from '@/components/charterup/CheckoutAmenitiesCard.vue'
import StickyColumnLayout from '@/layouts/StickyColumnLayout.vue'

import ProviderInfo from './Provider/ProviderInfo.vue'
import MobileFooter from './Provider/MobileFooter.vue'
import BidCardCarousel from './Provider/BidCardCarousel.vue'

export default {
  components: {
    TripCard,
    BillingSummary,
    NotFound,
    StickyColumnLayout,
    ProviderInfo,
    MobileFooter,
    BidCardCarousel,
    CheckoutAmenitiesCard,
  },
  data() {
    return {
      providerDetail: null,
      quoteDetail: null,
      notFound: false,
    }
  },
  computed: {
    isSelfServe() {
      return this.quoteDetail?.isSelfServe
    },
    amenities() {
      return this.quoteDetail?.trips?.[0]?.tripAmenities
    },
    vehicles() {
      return this.quoteDetail?.trips?.[0]?.vehicles
    },
  },
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.loadData()
      },
    },
  },
  methods: {
    async loadData() {
      const { quoteId } = this.$route.params
      const { providerId } = this.$route.params
      await Promise.all([
        this.getQuoteDetails(quoteId),
        this.getProviderDetails(quoteId, providerId),
      ])
    },
    async getProviderDetails(quoteId, providerId) {
      try {
        const { data } = await providerDetails(quoteId, providerId)
        this.providerDetail = data.providerDetail
      } catch (e) {
        this.notFound = true
      }
    },
    async getQuoteDetails(quoteId) {
      try {
        const response = await quoteDetailV2(quoteId)
        const quote = response.data.data
        this.quoteDetail = quote
        const { bids } = quote
        const providerId = parseInt(this.$route.params.providerId)
        const filteredBids = bids?.filter(
          (bid) => parseInt(bid.companyId) !== providerId
        )
        this.quoteDetail.bids = filteredBids
      } catch (e) {
        this.notFound = true
      }
    },
  },
}
</script>
