import { render, staticRenderFns } from "./CustomerAccountSelector.vue?vue&type=template&id=afd3bd88&scoped=true"
import script from "./CustomerAccountSelector.vue?vue&type=script&lang=js"
export * from "./CustomerAccountSelector.vue?vue&type=script&lang=js"
import style0 from "./CustomerAccountSelector.vue?vue&type=style&index=0&id=afd3bd88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afd3bd88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAutocomplete,VIcon})
