import { TripVehicle } from '@/classes/TripVehicle'
import { TripVehicleGroup } from '@/classes/TripVehicleGroup'
import { TripDate } from '@/classes/TripDate'
import { deepClone, deepCloneClass } from '@/utils/deepClone'
import { convertTripDTOsToRouteTrips } from '@/utils/quoteUtils'
import { Quote } from '@/classes/Quote'
import { TripAddress } from '@/classes/TripAddress'
import { Address } from '@/classes/Address'
import { Note } from '@/classes/Note'

export const state = {
  quote: null,
  currentTripIndex: 0,
  currentVehicleGroupIndex: 0,
  mode: null,
}

export const getters = {
  getQuote(state) {
    return state?.quote
  },
  getMode(state) {
    return state?.mode
  },
  getTrips(state) {
    return state?.quote?.trips
  },
  getCurrentTrip(state) {
    return state?.quote?.trips?.[state?.currentTripIndex]
  },
  getCurrentTripIndex(state) {
    return state?.currentTripIndex
  },
  getCurrentTripVehicleGroup(state, getters) {
    return getters.getCurrentTrip?.tripVehicleGroups?.[
      state.currentVehicleGroupIndex
    ]
  },
  getCurrentFinalDropoffDate(state) {
    return state?.quote?.trips?.[state?.currentTripIndex]?.tripVehicleGroups?.[
      state?.currentVehicleGroupIndex
    ]?.finalDropoffDate
  },
  getCustomer(state) {
    return state?.quote?.customer
  },
  getCurrentTripDatesForAddress: (state) => (tripAddressHash) => {
    return state?.quote?.trips?.[state?.currentTripIndex]?.tripVehicleGroups?.[
      state?.currentVehicleGroupIndex
    ]?.tripDates?.filter((date) => date.tripAddressHash === tripAddressHash)
  },
  getCurrentColor(state) {
    return state?.quote?.trips[state?.currentTripIndex]?.tripVehicleGroups?.[
      state?.currentVehicleGroupIndex
    ]?.color
  },
  getSummaryByVehicleGroup(state) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    const addressLength = trip?.tripAddresses.length
    const vehicleGroups = trip?.tripVehicleGroups
    let summaryByVehicleGroup = {}
    for (const vehicleGroup of vehicleGroups) {
      let dateToAddrTimes = {}
      let tripDates = deepClone(vehicleGroup.tripDates)
      const finalDropoffDate = vehicleGroup?.finalDropoffDate
      if (finalDropoffDate?.tripAddressHash) {
        const foundDate = tripDates.find(
          (td) =>
            td.date === finalDropoffDate.date &&
            td.tripAddressHash === finalDropoffDate.tripAddressHash
        )
        if (foundDate) {
          foundDate.times.push(finalDropoffDate.time)
        } else {
          const fdd = deepClone(finalDropoffDate)
          fdd.times = [fdd.time]
          tripDates.push(fdd)
        }
      }
      for (const tripDate of tripDates) {
        if (!dateToAddrTimes[tripDate.date]) {
          dateToAddrTimes[tripDate.date] = []
        }
        const idx = trip.tripAddresses.findIndex(
          (tripAddress) =>
            tripAddress.tripAddressHash === tripDate.tripAddressHash
        )
        dateToAddrTimes[tripDate.date][idx] = tripDate.times
      }
      summaryByVehicleGroup[vehicleGroup.tripVehicleGroupHash] = {}
      for (const [date, addrToTimes] of Object.entries(dateToAddrTimes)) {
        let minOrder = []
        for (let i = 0; i < addrToTimes.length; i++) {
          if (!addrToTimes[i]) {
            addrToTimes[i] = []
          }
          for (let j = 0; j < addrToTimes[i].length; j++) {
            minOrder.push([i, addrToTimes[i][j]])
          }
        }
        minOrder.sort((a, b) => {
          return a[1].localeCompare(b[1])
        })
        let times = []
        let i = 0
        let j = 0
        while (i < minOrder.length) {
          if (minOrder[i][0] == j) {
            times.push(minOrder[i][1])
            i++
            j++
          } else {
            times.push('--')
            j++
          }
          if (j == addressLength) {
            j = 0
          }
        }
        while (j > 0 && j < addressLength) {
          times.push('--')
          j++
        }
        summaryByVehicleGroup[vehicleGroup.tripVehicleGroupHash][date] = times
      }
    }
    return summaryByVehicleGroup
  },
  getSummaryByTime(state) {
    let summaryByTime = {}
    const stops = state.quote.trips?.[state.currentTripIndex]?.stops
    for (const stop of stops) {
      const datetime = stop.pickupDatetime || stop.dropoffDatetime
      if (!(datetime in summaryByTime)) {
        summaryByTime[datetime] = {}
      }
      summaryByTime[datetime][stop.tripVehicleGroupHash] = stop.tripAddressHash
    }
    return summaryByTime
  },
}

export const mutations = {
  NEW_QUOTE(state, { vehicleTypes }) {
    state.quote = new Quote({ vehicleTypes })
    state.mode = 'add'
  },
  CLEAR_QUOTE(state) {
    state.quote = null
    state.mode = null
  },
  SET_QUOTE(state, { quoteData, vehicleTypes }) {
    const quote = new Quote({ vehicleTypes })
    quote.quoteId = quoteData.quoteId
    quote.checkoutPageId = quoteData.checkoutPageId
    quote.leadSourceTypeId = quoteData.leadSourceTypeId
    quote.contractId = quoteData.contractId
    quote.charterUpQuote = quoteData.charterUpQuote
    quote.decisionDate = quoteData.decisionDate
    quote.expirationDate = quoteData.expirationDate
    quote.expirationTimezone = quoteData.expirationTimezone
    quote.preferred = quoteData.preferred
    quote.dueDate = quoteData.dueDate
    quote.allowEcheckout = quoteData.allowEcheckout
    quote.attachPdfToEmail = quoteData.attachPdfToEmail
    quote.leadTemperatureTypeId = quoteData.leadTemperatureTypeId
    quote.leadFollowUpDate = quoteData.leadFollowUpDate
    quote.hasRecurrence = quoteData.hasRecurrence
    quote.isEnterprise = quoteData.isEnterprise
    quote.leadSourceType = quoteData.leadSourceType
    quote.checkoutPage = quoteData.checkoutPage
    quote.pricingMethod = quoteData.pricingMethod
    quote.sentBy = quoteData.sentBy
    quote.quoteNotes = quoteData.quoteNotes
    quote.customer = quoteData.customer
    quote.customerId = quoteData.customerId
    quote.isActive = quoteData.isActive
    quote.leadSource = quoteData.leadSource
    quote.isPrebooking = quoteData.isPrebooking
    quote.currencyType = quoteData.currencyType
    quote.createdBy = {
      id: quoteData.createdById,
      name: quoteData.createdByName,
    }
    quote.soldOut = quoteData.soldOut
    quote.isPendingConfirmation = quoteData.isPendingConfirmation
    quote.isEnterprise = quoteData.isEnterprise
    quote.accountExecutiveId = quoteData.accountExecutiveId
    quote.opportunityId = quoteData.opportunityId
    quote.isConverted = quoteData.isConverted
    quote.createdOn = quoteData.createdOn
    quote.updatedOn = quoteData.updatedOn
    quote.hash = quoteData.hash
    quote.participatingInEmailCampaigns =
      quoteData.participatingInEmailCampaigns
    quote.productClassificationId = quoteData.productClassificationId
    quote.sourcingTeamClassificationId = quoteData.sourcingTeamClassificationId
    quote.supportTeamClassificationId = quoteData.supportTeamClassificationId
    quote.tierId = quoteData.tierId
    quote.tier = quoteData.tier

    let trips = convertTripDTOsToRouteTrips(quoteData.trips)
    quote.trips = trips
    state.quote = quote
  },
  SET_MODE(state, { mode }) {
    state.mode = mode
  },
  SET_CUSTOMER(state, { customer }) {
    state.quote.customer = customer
  },
  SET_CUSTOMER_ID(state, { customerId }) {
    state.quote.customerId = customerId
  },
  SET_CUSTOMER_ACCOUNT(state, { customerAccount }) {
    state.quote.customer.customerAccount = customerAccount
  },
  SET_LEAD_SOURCE(state, { leadSource }) {
    state.quote.leadSource = leadSource
  },
  SET_CHARTER_UP_QUOTE(state, { charterUpQuote }) {
    state.quote.charterUpQuote = charterUpQuote
  },
  SET_CHECKOUT_PAGE(state, { checkoutPage }) {
    state.quote.checkoutPage = checkoutPage
  },
  SET_CHECKOUT_PAGE_ID(state, { checkoutPageId }) {
    state.quote.checkoutPageId = checkoutPageId
  },
  SET_QUOTE_PRICING_METHOD(state, { pricingMethod }) {
    state.quote.pricingMethod = pricingMethod
  },
  SET_CURRENCY_TYPE(state, { currencyType }) {
    state.quote.currencyType = currencyType
  },
  SET_TIER_ID(state, { tierId }) {
    state.quote.tierId = tierId
  },
  SET_TIER(state, { tier }) {
    state.quote.tier = tier
  },
  SET_LEAD_TEMPERATURE_TYPE_ID(state, { leadTemperatureTypeId }) {
    state.quote.leadTemperatureTypeId = leadTemperatureTypeId
  },
  SET_LEAD_FOLLOW_UP_DATE(state, { leadFollowUpDate }) {
    state.quote.leadFollowUpDate = leadFollowUpDate
  },
  SET_SOLD_OUT(state, { soldOut }) {
    state.quote.soldOut = soldOut
  },
  SET_IS_PENDING_CONFIRMATION(state, { isPendingConfirmation }) {
    state.quote.isPendingConfirmation = isPendingConfirmation
  },
  SET_IS_ENTERPRISE(state, { isEnterprise }) {
    state.quote.isEnterprise = isEnterprise
  },
  SET_ACCOUNT_EXECUTIVE(state, { accountExecutiveId, accountExecutiveName }) {
    state.quote.accountExecutiveId = accountExecutiveId
    state.quote.accountExecutiveName = accountExecutiveName
  },
  SET_OPPORTUNITY_ID(state, { opportunityId }) {
    state.quote.opportunityId = opportunityId
  },
  SET_CONTRACT_ID(state, { contractId }) {
    state.quote.contractId = contractId
  },
  SET_TOTAL(state, { total }) {
    state.quote.total = total
  },
  SET_RECURRING_TOTAL(state, { recurringTotal }) {
    state.quote.recurringTotal = recurringTotal
  },
  SET_REQUIRED_DEPOSIT(state, { requiredDeposit }) {
    state.quote.requiredDeposit = requiredDeposit
  },
  SET_ALLOW_ECHECKOUT(state, { allowEcheckout }) {
    state.quote.allowEcheckout = allowEcheckout
  },
  SET_ATTACH_PDF_TO_EMAIL(state, { attachPdfToEmail }) {
    state.quote.attachPdfToEmail = attachPdfToEmail
  },
  SET_DECISION_DATE(state, { decisionDate }) {
    state.quote.decisionDate = decisionDate
  },
  SET_EXPIRATION_DATE(state, { expirationDate }) {
    state.quote.expirationDate = expirationDate
  },
  SET_EXPIRATION_TIMEZONE(state, { expirationTimezone }) {
    state.quote.expirationTimezone = expirationTimezone
  },
  SET_CREATED_BY(state, { createdBy }) {
    state.quote.createdBy = createdBy
  },
  SET_PRODUCT_CLASSIFICATION_ID(state, { productClassificationId }) {
    state.quote.productClassificationId = productClassificationId
  },
  SET_SOURCING_TEAM_CLASSIFICATION_ID(state, { sourcingTeamClassificationId }) {
    state.quote.sourcingTeamClassificationId = sourcingTeamClassificationId
  },
  SET_SUPPORT_TEAM_CLASSIFICATION_ID(state, { supportTeamClassificationId }) {
    state.quote.supportTeamClassificationId = supportTeamClassificationId
  },
  SET_MIN_QUALITY(state, { minQuality }) {
    state.quote.minQuality = minQuality
  },
  CLEAR_TRIPS(state) {
    state.quote.trips = []
  },
  ADD_TRIP(state, { trip }) {
    state.quote.trips.push(trip)
  },
  REMOVE_TRIP(state, { tripIndex }) {
    state.quote.trips.splice(tripIndex, 1)
  },
  SET_TRIPS(state, { trips }) {
    state.quote.trips = trips
  },
  SET_TRIPS_REFRESH_PRICING(state) {
    state.quote.trips.forEach((trip) => {
      trip.refreshPricing = true
    })
  },
  ADD_QUOTE_NOTE(state, { note }) {
    state.quote.quoteNotes.push(note)
  },
  UPDATE_QUOTE_NOTE(state, { newNoteText }) {
    const quoteNote = state.quote?.quoteNotes?.[0]
    if (quoteNote) {
      quoteNote.note = newNoteText
    }
  },
  UPDATE_QUOTE_NOTE_HTML(state, { html }) {
    const quoteNote = state.quote?.quoteNotes?.[0]
    if (quoteNote) {
      quoteNote.html = html
    }
  },
  SET_ESTIMATION(state, { tripIndex, estimation }) {
    const trip = state.quote?.trips?.[tripIndex]
    if (trip) {
      trip.deadDistance = estimation.deadDistance
      trip.drivingTime = estimation.duration
      trip.distance = estimation.distance
      trip.endDate = estimation.estimatedEndDate
      trip.estimatedTimeLabel = estimation.estimatedTimeLabel
      trip.deadMileLabel = estimation.deadMileLabel
      trip.liveMileLabel = estimation.liveMileLabel
      trip.deadDistance = estimation.deadDistance
      trip.hourlyDiff = estimation.itineraryHours
      trip.nearestMarketId = estimation.nearestMarketId
      trip.pricingMarket = estimation.nearestMarketId
      trip.autoDetectedMarketId = estimation.autoDetectedMarketId
      trip.isMountainTrip = estimation.isMountainTrip
    }
  },
  SET_CURRENT_TRIP_INDEX(state, { currentTripIndex }) {
    state.currentTripIndex = currentTripIndex
  },
  SET_ROUTE_NAME(state, { tripIndex, routeName }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.routeName = routeName
    }
  },
  SET_RECURRENCES(state, { tripIndex, recurrences }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.recurrences = recurrences
    }
  },
  SET_RECURRENCE_EXCLUSIONS(state, { tripIndex, recurrenceExclusions }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.recurrences[0].recurrenceExclusions = recurrenceExclusions
    }
  },
  SET_TRIP_TYPE_ID(state, { tripIndex, tripTypeId }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.tripTypeId = tripTypeId
    }
  },
  SET_PASSENGER_COUNT(state, { tripIndex, passengerCount }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.passengerCount = passengerCount
    }
  },
  SET_REQUIRED_DRIVERS(state, { tripIndex, requiredDrivers }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.requiredDrivers = requiredDrivers
    }
  },
  SET_TRIP_DESCRIPTION(state, { tripIndex, description }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.description = description
    }
  },
  SET_TRIP_NOTES(state, { tripIndex, notes }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.notes = notes
    }
  },
  ADD_TRIP_NOTE(state, { tripIndex, note }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.tripNotes.push(note)
    }
  },
  UPDATE_TRIP_NOTE(state, { tripIndex, note, newNoteText }) {
    const tripNote = state.quote.trips?.[tripIndex]?.tripNotes?.find(
      (tripNote) => tripNote.noteType === note.noteType
    )
    if (tripNote) {
      tripNote.note = newNoteText
    }
  },
  UPDATE_TRIP_NOTE_HTML(state, { tripIndex, note, html }) {
    const tripNote = state.quote.trips?.[tripIndex]?.tripNotes?.find(
      (tripNote) => tripNote.noteType === note.noteType
    )
    if (tripNote) {
      tripNote.html = html
    }
  },
  SET_PROCESSING_FEE_PERCENTAGE(state, { tripIndex, processingFeePercentage }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.processingFeePercentage = processingFeePercentage
    }
  },
  SET_PROCESSING_FEE_CHARGE(state, { tripIndex, processingFeeCharge }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.processingFeeCharge = processingFeeCharge
    }
  },
  SET_TRIP_CONTACT_IS_CUSTOMER(state, { tripIndex, tripContactIsCustomer }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.tripContactIsCustomer = tripContactIsCustomer
    }
  },
  SET_IS_TRIP_CONTACT_SELECTED(state, { tripIndex, isTripContactSelected }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.isTripContactSelected = isTripContactSelected
    }
  },
  SET_TRIP_CONTACT(state, { tripIndex, tripContact }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.tripContact = tripContact
    }
  },
  SET_TRIP_PAYMENT_METHODS(state, { tripIndex, paymentMethods }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      trip.paymentMethods = paymentMethods
    }
  },
  SET_FARE(state, { fare }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      const baseFare = trip.charges.find((charge) => charge.chargeType.id === 1)

      if (!baseFare) {
        trip.charges.push({
          chargeType: { id: 1 },
          amount: fare,
        })
      } else {
        baseFare.amount = fare
      }
    }
  },
  SET_PRICING_METHOD(state, { pricingMethod }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.pricingMethod = pricingMethod
    }
  },
  SET_PAYMENT_TYPE_ID(state, { paymentTypeId, tripIndex }) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.paymentType = { id: paymentTypeId }
      trip.paymentTypeId = paymentTypeId
    }
  },
  SET_PAYMENT_TERMS_DAYS(state, { paymentTermsDays, tripIndex }) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.paymentTermsDays = paymentTermsDays
    }
  },
  SET_DUE_DATE(state, { dueDate, tripIndex }) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.dueDate = dueDate
    }
  },
  SET_CHARGE_TYPE_ID(state, { chargeIndex, chargeTypeId }) {
    const charge =
      state.quote.trips?.[state.currentTripIndex]?.charges?.[chargeIndex]
    if (charge) {
      charge.chargeType.id = chargeTypeId
    }
  },
  SET_CHARGE_AMOUNT(state, { chargeIndex, amount }) {
    const charge =
      state.quote.trips?.[state.currentTripIndex]?.charges?.[chargeIndex]
    if (charge) {
      charge.amount = amount
    }
  },
  ADD_CHARGE(state) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.charges.push({ chargeType: {}, amount: undefined })
    }
  },
  REMOVE_CHARGE(state, { chargeIndex }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.charges.splice(chargeIndex, 1)
    }
  },
  SET_RATE_TYPE_ID(state, { rateIndex, rateTypeId }) {
    const rate = state.quote.trips?.[state.currentTripIndex]?.rates?.[rateIndex]
    if (rate) {
      rate.rateType.id = rateTypeId
    }
  },
  SET_RATE_AMOUNT(state, { rateIndex, amount }) {
    const rate = state.quote.trips?.[state.currentTripIndex]?.rates?.[rateIndex]
    if (rate) {
      rate.amount = amount
    }
  },
  ADD_RATE(state) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.rates.push({ rateType: {}, amount: undefined })
    }
  },
  REMOVE_RATE(state, { rateIndex }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.rates.splice(rateIndex, 1)
    }
  },
  SET_TRIP_TOTAL(state, { total }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.total = total
    }
  },
  SET_PAYMENT_METHOD_ALLOWED(state, { paymentMethodIndex, value, tripIndex }) {
    const paymentMethod =
      state.quote.trips?.[tripIndex || state.currentTripIndex]
        ?.paymentMethods?.[paymentMethodIndex]
    if (paymentMethod) {
      paymentMethod.isAllowed = value
    }
  },
  SET_DEPOSIT_PERCENTAGE(state, { depositPercentage, tripIndex }) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.depositPercentage = depositPercentage
    }
  },
  SET_MARKETPLACE_DISCOUNT_PERCENT(
    state,
    { marketplaceDiscountPercent, tripIndex }
  ) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.marketplaceDiscountPercent = marketplaceDiscountPercent
    }
  },
  SET_MARKETPLACE_MARKUP_PERCENT(
    state,
    { marketplaceMarkupPercent, tripIndex }
  ) {
    const trip = state.quote.trips?.[tripIndex || state.currentTripIndex]
    if (trip) {
      trip.marketplaceMarkupPercent = marketplaceMarkupPercent
    }
  },
  SET_PRICING_MARKET(state, { pricingMarket }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.pricingMarket = pricingMarket
    }
  },
  SET_AUTO_DETECTED_NEAREST_MARKET(state, { market }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.autoDetectedMarketId = market
    }
  },
  TRIP_MANUALLY_SELECTED_PRICING_MARKET(state, { isManuallySelected }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    if (trip) {
      trip.isManuallySelectedPricingMarket = isManuallySelected
    }
  },
  ADD_TRIP_VEHICLE_GROUP(state, { tripIndex, tripVehicleGroup }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip?.tripVehicleGroups) {
      trip.tripVehicleGroups.push(tripVehicleGroup)
    }
  },
  UPDATE_TRIP_VEHICLE_GROUP(
    state,
    { tripIndex, tripVehicleGroupIndex, tripVehicleGroup }
  ) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip?.tripVehicleGroups) {
      trip.tripVehicleGroups[tripVehicleGroupIndex].color =
        tripVehicleGroup.color
      trip.tripVehicleGroups[tripVehicleGroupIndex].tripVehicles =
        tripVehicleGroup.tripVehicles
      trip.tripVehicleGroups[tripVehicleGroupIndex].manuallySetName =
        tripVehicleGroup.manuallySetName
    }
  },
  REMOVE_TRIP_VEHICLE_GROUP(state, { tripIndex, tripVehicleGroupIndex }) {
    state.quote.trips[tripIndex].tripVehicleGroups.splice(
      tripVehicleGroupIndex,
      1
    )

    // if deleting the last group, make a new one
    if (!state.quote.trips?.[tripIndex]?.tripVehicleGroups?.length) {
      state.quote.trips[tripIndex].tripVehicleGroups.push(
        new TripVehicleGroup({})
      )
    }

    // auto select the first group
    state.quote.trips[tripIndex].tripVehicleGroups[0].selected = true
  },
  SET_TRIP_VEHICLE_GROUPS(state, { tripVehicleGroups }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    trip.tripVehicleGroups = tripVehicleGroups
  },
  SET_TRIP_VEHICLE_GROUP_NAME(
    state,
    { tripIndex, tripVehicleGroupIndex, name }
  ) {
    const group =
      state.quote.trips?.[tripIndex]?.tripVehicleGroups?.[tripVehicleGroupIndex]
    if (group) {
      group.name = name
    }
  },
  SET_TRIP_VEHICLE_GROUP_FINAL_DROPOFF_DATE(state, { finalDropoffDate }) {
    const group =
      state.quote.trips?.[state.currentTripIndex]?.tripVehicleGroups?.[
        state.currentVehicleGroupIndex
      ]
    if (group) {
      group.finalDropoffDate = finalDropoffDate
    }
  },
  UNSELECT_ALL_TRIP_VEHICLE_GROUPS(state, { tripIndex }) {
    const trip = state.quote.trips?.[tripIndex]
    if (trip) {
      for (const group of trip.tripVehicleGroups) {
        group.selected = false
      }
    }
  },
  SET_SELECTED_TRIP_VEHICLE_GROUP(
    state,
    { tripIndex, tripVehicleGroupIndex, selected }
  ) {
    const group =
      state.quote.trips?.[tripIndex]?.tripVehicleGroups?.[tripVehicleGroupIndex]
    if (group) {
      group.selected = selected
    }
    state.currentVehicleGroupIndex = tripVehicleGroupIndex
  },
  COPY_DATES_FROM_GROUP_TO_GROUP(state, { tripIndex, fromHash, toHash }) {
    const trip = state?.quote?.trips?.[tripIndex]
    if (trip) {
      const fromGroup = trip.tripVehicleGroups.find(
        (group) => group.tripVehicleGroupHash === fromHash
      )
      const toGroup = trip.tripVehicleGroups.find(
        (group) => group.tripVehicleGroupHash === toHash
      )
      if (fromGroup && toGroup) {
        toGroup.tripDates = deepCloneClass(fromGroup.tripDates)
        toGroup.finalDropoffDate = deepCloneClass(fromGroup.finalDropoffDate)
      }
    }
  },
  COMBINE_GROUPS(state, { tripIndex, toHash, fromHashes }) {
    const trip = state?.quote?.trips?.[tripIndex]
    if (trip) {
      const toGroup = trip.tripVehicleGroups.find(
        (group) => group.tripVehicleGroupHash === toHash
      )
      if (toGroup) {
        for (const hash of fromHashes) {
          const index = trip.tripVehicleGroups.findIndex(
            (group) => group.tripVehicleGroupHash === hash
          )
          if (index < 0) {
            continue
          }
          trip.tripVehicleGroups[index].tripVehicles.forEach(
            (fromTripVehicle) => {
              let toTripVehicle = toGroup.tripVehicles.find(
                (toTripVehicle) =>
                  toTripVehicle.vehicleTypeId === fromTripVehicle.vehicleTypeId
              )

              if (toTripVehicle) {
                toTripVehicle.quantity += fromTripVehicle.quantity
              } else {
                toGroup.tripVehicles.push(
                  new TripVehicle({
                    vehicleTypeId: fromTripVehicle.vehicleTypeId,
                    quantity: fromTripVehicle.quantity,
                  })
                )
              }
            }
          )
          trip.tripVehicleGroups.splice(index, 1)
        }
      }
    }
  },
  ADD_ADDRESS(state) {
    state.quote.trips[state.currentTripIndex].tripAddresses.push(
      new TripAddress({
        orderIndex:
          state.quote.trips[state.currentTripIndex].tripAddresses.length,
      })
    )
  },
  EDIT_ADDRESS(state, { addressIndex, newAddress, addressTitle }) {
    const tripAddress =
      state.quote.trips[state.currentTripIndex].tripAddresses[addressIndex]
    tripAddress.address = new Address(newAddress)
    if (!newAddress.title && addressTitle) {
      tripAddress.address.title = addressTitle
    }
  },
  CLEAR_ADDRESS(state, { addressIndex }) {
    const tripAddress =
      state.quote.trips[state.currentTripIndex].tripAddresses[addressIndex]
    tripAddress.address = new Address()
  },
  REMOVE_ADDRESS(state, addressIndex) {
    const tripAddressHash =
      state.quote.trips[state.currentTripIndex].tripAddresses[addressIndex]
        .tripAddressHash
    state.quote.trips[state.currentTripIndex].tripAddresses.splice(
      addressIndex,
      1
    )
    state.quote.trips[state.currentTripIndex].tripVehicleGroups.forEach(
      (vehicleGroup) => {
        vehicleGroup.tripDates = vehicleGroup.tripDates.filter(
          (tripDate) => tripDate.tripAddressHash != tripAddressHash
        )
      }
    )
  },
  SET_TRIP_ADDRESSES(state, { tripAddresses }) {
    const trip = state.quote.trips?.[state.currentTripIndex]
    trip.tripAddresses = tripAddresses
  },
  EDIT_NOTE(state, { addressIndex, note }) {
    const tripAddress =
      state.quote.trips[state.currentTripIndex].tripAddresses[addressIndex]
    tripAddress.tripAddressNotes[0] = new Note(note)
  },
  ADD_DATE(state, date) {
    const tripVehicleGroup =
      state.quote.trips[state.currentTripIndex].tripVehicleGroups[
        state.currentVehicleGroupIndex
      ]
    tripVehicleGroup.tripDates.push(date)
  },
  EDIT_DATE(state, { tripAddressHash, tripDateIndex, newDate }) {
    const tripVehicleGroup =
      state.quote.trips[state.currentTripIndex].tripVehicleGroups[
        state.currentVehicleGroupIndex
      ]
    const tripDate = tripVehicleGroup.tripDates.filter(
      (tripDate) => tripDate.tripAddressHash === tripAddressHash
    )[tripDateIndex]
    tripDate.date = newDate
  },
  REMOVE_DATE(state, { tripAddressHash, tripDateIndex }) {
    const tripVehicleGroup =
      state.quote.trips[state.currentTripIndex].tripVehicleGroups[
        state.currentVehicleGroupIndex
      ]
    const tripDate = tripVehicleGroup.tripDates.filter(
      (tripDate) => tripDate.tripAddressHash === tripAddressHash
    )[tripDateIndex]
    const index = tripVehicleGroup.tripDates.indexOf(tripDate)
    if (index >= 0) {
      tripVehicleGroup.tripDates.splice(index, 1)
    }
  },
  ADD_TIME(state, { tripAddressHash, tripDateIndex, time }) {
    const tripVehicleGroup =
      state.quote.trips[state.currentTripIndex].tripVehicleGroups[
        state.currentVehicleGroupIndex
      ]
    const tripDate = tripVehicleGroup.tripDates.filter(
      (tripDate) => tripDate.tripAddressHash === tripAddressHash
    )[tripDateIndex]
    tripDate.times.push(time)
    tripDate.times.sort()
  },
  REMOVE_TIME(state, { tripAddressHash, tripDateIndex, timeIndex }) {
    const tripVehicleGroup =
      state.quote.trips[state.currentTripIndex].tripVehicleGroups[
        state.currentVehicleGroupIndex
      ]
    const tripDate = tripVehicleGroup.tripDates.filter(
      (tripDate) => tripDate.tripAddressHash === tripAddressHash
    )[tripDateIndex]
    tripDate.times.splice(timeIndex, 1)
  },
}

export const actions = {
  initialize({ commit, rootGetters }) {
    const vehicleTypes = rootGetters['types/vehicleTypes']
    commit('NEW_QUOTE', { vehicleTypes })
    commit('SET_CURRENT_TRIP_INDEX', { currentTripIndex: 0 })
  },
  clearQuote({ commit }) {
    commit('CLEAR_QUOTE')
  },
  setQuote({ commit, rootGetters }, { quoteData }) {
    const vehicleTypes = rootGetters['types/vehicleTypes']
    commit('SET_QUOTE', { quoteData, vehicleTypes })
    commit('SET_CURRENT_TRIP_INDEX', { currentTripIndex: 0 })
  },
  setMode({ commit }, { mode }) {
    commit('SET_MODE', { mode })
  },
  setDecisionDate({ commit }, { decisionDate }) {
    commit('SET_DECISION_DATE', { decisionDate })
  },
  setExpirationDate({ commit }, { expirationDate }) {
    commit('SET_EXPIRATION_DATE', { expirationDate })
  },
  setExpirationTimezone({ commit }, { expirationTimezone }) {
    commit('SET_EXPIRATION_TIMEZONE', { expirationTimezone })
  },
  addAddress({ commit }) {
    commit('ADD_ADDRESS')
  },
  editAddress({ commit }, { addressIndex, newAddress, addressTitle }) {
    commit('EDIT_ADDRESS', { addressIndex, newAddress, addressTitle })
  },
  clearAddress({ commit }, { addressIndex }) {
    commit('CLEAR_ADDRESS', { addressIndex })
  },
  removeAddress({ commit }, addressIndex) {
    commit('REMOVE_ADDRESS', addressIndex)
  },
  setTripAddresses({ commit }, { tripAddresses }) {
    commit('SET_TRIP_ADDRESSES', { tripAddresses })
  },
  editAddressNote({ commit }, { addressIndex, note }) {
    commit('EDIT_NOTE', { addressIndex, note })
  },
  addTrip({ commit }, { trip }) {
    commit('ADD_TRIP', { trip })
  },
  removeTrip({ commit }, { tripIndex }) {
    commit('REMOVE_TRIP', { tripIndex })
  },
  setTrips({ commit }, { trips }) {
    commit('SET_TRIPS', { trips })
  },
  setTripsRefreshPricing({ commit }) {
    commit('SET_TRIPS_REFRESH_PRICING')
  },
  addQuoteNote({ commit }, { note }) {
    commit('ADD_QUOTE_NOTE', { note })
  },
  updateQuoteNote({ commit }, { newNoteText }) {
    commit('UPDATE_QUOTE_NOTE', { newNoteText })
  },
  updateQuoteNoteHtml({ commit }, { html }) {
    commit('UPDATE_QUOTE_NOTE_HTML', { html })
  },
  setEstimation({ commit }, { tripIndex, estimation }) {
    commit('SET_ESTIMATION', { tripIndex, estimation })
  },
  setCurrentTripIndex({ commit }, { currentTripIndex }) {
    commit('SET_CURRENT_TRIP_INDEX', { currentTripIndex })
  },
  setCustomer({ commit }, { customer }) {
    commit('SET_CUSTOMER', { customer })
    commit('SET_CUSTOMER_ID', { customerId: customer.customerId })
  },
  setCustomerId({ commit }, { customerId }) {
    commit('SET_CUSTOMER_ID', { customerId })
  },
  setCustomerAccount({ commit }, { customerAccount }) {
    commit('SET_CUSTOMER_ACCOUNT', { customerAccount })
  },
  setLeadSource({ commit }, { leadSource }) {
    commit('SET_LEAD_SOURCE', { leadSource })
  },
  setCharterUpQuote({ commit }, { charterUpQuote }) {
    commit('SET_CHARTER_UP_QUOTE', { charterUpQuote })
  },
  setCheckoutPage({ commit }, { checkoutPage }) {
    commit('SET_CHECKOUT_PAGE', { checkoutPage })
  },
  setCheckoutPageId({ commit }, { checkoutPageId }) {
    commit('SET_CHECKOUT_PAGE_ID', { checkoutPageId })
  },
  setQuotePricingMethod({ commit }, { pricingMethod }) {
    commit('SET_QUOTE_PRICING_METHOD', { pricingMethod })
  },
  setCurrencyType({ commit }, { currencyType }) {
    commit('SET_CURRENCY_TYPE', { currencyType })
  },
  setTierId({ commit }, { tierId }) {
    commit('SET_TIER_ID', { tierId })
  },
  setTier({ commit }, { tier }) {
    commit('SET_TIER', { tier })
  },
  setLeadTemperatureTypeId({ commit }, { leadTemperatureTypeId }) {
    commit('SET_LEAD_TEMPERATURE_TYPE_ID', { leadTemperatureTypeId })
  },
  setLeadFollowUpDate({ commit }, { leadFollowUpDate }) {
    commit('SET_LEAD_FOLLOW_UP_DATE', { leadFollowUpDate })
  },
  setSoldOut({ commit }, { soldOut }) {
    commit('SET_SOLD_OUT', { soldOut })
  },
  setIsPendingConfirmation({ commit }, { isPendingConfirmation }) {
    commit('SET_IS_PENDING_CONFIRMATION', { isPendingConfirmation })
  },
  setIsEnterprise({ commit }, { isEnterprise }) {
    commit('SET_IS_ENTERPRISE', { isEnterprise })
  },
  setAccountExecutive(
    { commit },
    { accountExecutiveId, accountExecutiveName }
  ) {
    commit('SET_ACCOUNT_EXECUTIVE', {
      accountExecutiveId,
      accountExecutiveName,
    })
  },
  setOpportunityId({ commit }, { opportunityId }) {
    commit('SET_OPPORTUNITY_ID', { opportunityId })
  },
  setContractId({ commit }, { contractId }) {
    commit('SET_CONTRACT_ID', { contractId })
  },
  setTotal({ commit }, { total }) {
    commit('SET_TOTAL', { total })
  },
  setRecurringTotal({ commit }, { recurringTotal }) {
    commit('SET_RECURRING_TOTAL', { recurringTotal })
  },
  setRequiredDeposit({ commit }, { requiredDeposit }) {
    commit('SET_REQUIRED_DEPOSIT', { requiredDeposit })
  },
  setAllowEcheckout({ commit }, { allowEcheckout }) {
    commit('SET_ALLOW_ECHECKOUT', { allowEcheckout })
  },
  setAttachPdfToEmail({ commit }, { attachPdfToEmail }) {
    commit('SET_ATTACH_PDF_TO_EMAIL', { attachPdfToEmail })
  },
  setCreatedBy({ commit }, { createdBy }) {
    commit('SET_CREATED_BY', { createdBy })
  },
  setProductClassificationId({ commit }, { productClassificationId }) {
    commit('SET_PRODUCT_CLASSIFICATION_ID', { productClassificationId })
  },
  setSourcingTeamClassificationId(
    { commit },
    { sourcingTeamClassificationId }
  ) {
    commit('SET_SOURCING_TEAM_CLASSIFICATION_ID', {
      sourcingTeamClassificationId,
    })
  },
  setSupportTeamClassificationId({ commit }, { supportTeamClassificationId }) {
    commit('SET_SUPPORT_TEAM_CLASSIFICATION_ID', {
      supportTeamClassificationId,
    })
  },
  setMinQuality({ commit }, { minQuality }) {
    commit('SET_MIN_QUALITY', {
      minQuality,
    })
  },
  setRouteName({ commit }, { tripIndex, routeName }) {
    commit('SET_ROUTE_NAME', { tripIndex, routeName })
  },
  setRecurrences({ commit }, { tripIndex, recurrences }) {
    commit('SET_RECURRENCES', { tripIndex, recurrences })
  },
  setRecurrenceExclusions({ commit }, { tripIndex, recurrenceExclusions }) {
    commit('SET_RECURRENCE_EXCLUSIONS', { tripIndex, recurrenceExclusions })
  },
  setTripTypeId({ commit }, { tripIndex, tripTypeId }) {
    commit('SET_TRIP_TYPE_ID', { tripIndex, tripTypeId })
  },
  setPassengerCount({ commit }, { tripIndex, passengerCount }) {
    commit('SET_PASSENGER_COUNT', { tripIndex, passengerCount })
  },
  setRequiredDrivers({ commit }, { tripIndex, requiredDrivers }) {
    commit('SET_REQUIRED_DRIVERS', { tripIndex, requiredDrivers })
  },
  addRequiredVehicle({ commit }, { tripIndex }) {
    commit('ADD_REQUIRED_VEHICLE', { tripIndex })
  },
  removeRequiredVehicle({ commit }, { tripIndex, vehicleIndex }) {
    commit('REMOVE_REQUIRED_VEHICLE', { tripIndex, vehicleIndex })
  },
  setRequiredVehicleType(
    { commit },
    { tripIndex, vehicleIndex, vehicleTypeId }
  ) {
    commit('SET_REQUIRED_VEHICLE_TYPE', {
      tripIndex,
      vehicleIndex,
      vehicleTypeId,
    })
  },
  setRequiredVehicleQuantity(
    { commit },
    { tripIndex, vehicleIndex, quantity }
  ) {
    commit('SET_REQUIRED_VEHICLE_QUANTITY', {
      tripIndex,
      vehicleIndex,
      quantity,
    })
  },
  setTripDescription({ commit }, { tripIndex, description }) {
    commit('SET_TRIP_DESCRIPTION', {
      tripIndex,
      description,
    })
  },
  setTripNotes({ commit }, { tripIndex, notes }) {
    commit('SET_TRIP_NOTES', {
      tripIndex,
      notes,
    })
  },
  addTripNote({ commit }, { tripIndex, note }) {
    commit('ADD_TRIP_NOTE', {
      tripIndex,
      note,
    })
  },
  updateTripNote({ commit }, { tripIndex, note, newNoteText }) {
    commit('UPDATE_TRIP_NOTE', {
      tripIndex,
      note,
      newNoteText,
    })
  },
  updateTripNoteHtml({ commit }, { tripIndex, note, html }) {
    commit('UPDATE_TRIP_NOTE_HTML', {
      tripIndex,
      note,
      html,
    })
  },
  setProcessingFeePercentage(
    { commit },
    { tripIndex, processingFeePercentage }
  ) {
    commit('SET_PROCESSING_FEE_PERCENTAGE', {
      tripIndex,
      processingFeePercentage,
    })
  },
  setProcessingFeeCharge({ commit }, { tripIndex, processingFeeCharge }) {
    commit('SET_PROCESSING_FEE_CHARGE', {
      tripIndex,
      processingFeeCharge,
    })
  },
  setTripContactIsCustomer({ commit }, { tripIndex, tripContactIsCustomer }) {
    commit('SET_TRIP_CONTACT_IS_CUSTOMER', {
      tripIndex,
      tripContactIsCustomer,
    })
  },
  setIsTripContactSelected({ commit }, { tripIndex, isTripContactSelected }) {
    commit('SET_IS_TRIP_CONTACT_SELECTED', {
      tripIndex,
      isTripContactSelected,
    })
  },
  setTripContact({ commit }, { tripIndex, tripContact }) {
    commit('SET_TRIP_CONTACT', {
      tripIndex,
      tripContact,
    })
  },
  setTripPaymentMethods({ commit }, { tripIndex, paymentMethods }) {
    commit('SET_TRIP_PAYMENT_METHODS', {
      tripIndex,
      paymentMethods,
    })
  },
  setFare({ commit }, { fare }) {
    commit('SET_FARE', {
      fare,
    })
  },
  setPricingMethod({ commit }, { pricingMethod }) {
    commit('SET_PRICING_METHOD', {
      pricingMethod,
    })
  },
  setPaymentTypeId({ commit }, { paymentTypeId, tripIndex }) {
    commit('SET_PAYMENT_TYPE_ID', {
      paymentTypeId,
      tripIndex,
    })
  },
  setPaymentTermsDays({ commit }, { paymentTermsDays, tripIndex }) {
    commit('SET_PAYMENT_TERMS_DAYS', {
      paymentTermsDays,
      tripIndex,
    })
  },
  setDueDate({ commit }, { dueDate, tripIndex }) {
    commit('SET_DUE_DATE', {
      dueDate,
      tripIndex,
    })
  },
  setChargeTypeId({ commit }, { chargeIndex, chargeTypeId }) {
    commit('SET_CHARGE_TYPE_ID', {
      chargeIndex,
      chargeTypeId,
    })
  },
  setChargeAmount({ commit }, { chargeIndex, amount }) {
    commit('SET_CHARGE_AMOUNT', {
      chargeIndex,
      amount,
    })
  },
  addCharge({ commit }) {
    commit('ADD_CHARGE')
  },
  removeCharge({ commit }, { chargeIndex }) {
    commit('REMOVE_CHARGE', {
      chargeIndex,
    })
  },
  setPaymentMethodAllowed(
    { commit },
    { paymentMethodIndex, value, tripIndex }
  ) {
    commit('SET_PAYMENT_METHOD_ALLOWED', {
      paymentMethodIndex,
      value,
      tripIndex,
    })
  },
  setDepositPercentage({ commit }, { depositPercentage, tripIndex }) {
    commit('SET_DEPOSIT_PERCENTAGE', {
      depositPercentage,
      tripIndex,
    })
  },
  setMarketplaceDiscountPercent(
    { commit },
    { marketplaceDiscountPercent, tripIndex }
  ) {
    commit('SET_MARKETPLACE_DISCOUNT_PERCENT', {
      marketplaceDiscountPercent,
      tripIndex,
    })
  },
  setMarketplaceMarkupPercent(
    { commit },
    { marketplaceMarkupPercent, tripIndex }
  ) {
    commit('SET_MARKETPLACE_MARKUP_PERCENT', {
      marketplaceMarkupPercent,
      tripIndex,
    })
  },
  setPricingMarket({ commit }, { pricingMarket }) {
    commit('SET_PRICING_MARKET', {
      pricingMarket,
    })
  },
  setAutoDetectedNearestMarket({ commit }, { market }) {
    commit('SET_AUTO_DETECTED_NEAREST_MARKET', {
      market,
    })
  },
  setTripManuallySelectedPricingMarket({ commit }, { isManuallySelected }) {
    commit('TRIP_MANUALLY_SELECTED_PRICING_MARKET', {
      isManuallySelected,
    })
  },
  setRateTypeId({ commit }, { rateIndex, rateTypeId }) {
    commit('SET_RATE_TYPE_ID', {
      rateIndex,
      rateTypeId,
    })
  },
  setRateAmount({ commit }, { rateIndex, amount }) {
    commit('SET_RATE_AMOUNT', {
      rateIndex,
      amount,
    })
  },
  addRate({ commit }) {
    commit('ADD_RATE')
  },
  removeRate({ commit }, { rateIndex }) {
    commit('REMOVE_RATE', {
      rateIndex,
    })
  },
  setTripTotal({ commit }, { total }) {
    commit('SET_TRIP_TOTAL', {
      total,
    })
  },
  setTripVehicleGroup(
    { commit },
    { tripIndex, tripVehicleGroup, tripVehicleGroupIndex }
  ) {
    commit('UPDATE_TRIP_VEHICLE_GROUP', {
      tripIndex,
      tripVehicleGroupIndex,
      tripVehicleGroup,
    })
  },
  addTripVehicleGroup({ commit }, { tripIndex, tripVehicleGroup }) {
    commit('ADD_TRIP_VEHICLE_GROUP', {
      tripIndex,
      tripVehicleGroup,
    })
  },
  removeTripVehicleGroup({ commit }, { tripIndex, tripVehicleGroupIndex }) {
    commit('REMOVE_TRIP_VEHICLE_GROUP', {
      tripIndex,
      tripVehicleGroupIndex,
    })
  },
  setTripVehicleGroups({ commit }, { tripVehicleGroups }) {
    commit('SET_TRIP_VEHICLE_GROUPS', {
      tripVehicleGroups,
    })
  },
  setTripVehicleGroupName(
    { commit },
    { tripIndex, tripVehicleGroupIndex, name }
  ) {
    commit('SET_TRIP_VEHICLE_GROUP_NAME', {
      tripIndex,
      tripVehicleGroupIndex,
      name,
    })
  },
  setTripVehicleGroupFinalDropoffDate({ commit }, { finalDropoffDate }) {
    commit('SET_TRIP_VEHICLE_GROUP_FINAL_DROPOFF_DATE', {
      finalDropoffDate,
    })
  },
  unselectAllTripVehicleGroups({ commit }, { tripIndex }) {
    commit('UNSELECT_ALL_TRIP_VEHICLE_GROUPS', {
      tripIndex,
    })
  },
  setSelectedTripVehicleGroup(
    { commit },
    { tripIndex, tripVehicleGroupIndex, selected }
  ) {
    commit('SET_SELECTED_TRIP_VEHICLE_GROUP', {
      tripIndex,
      tripVehicleGroupIndex,
      selected,
    })
  },
  copyDatesFromGroupToGroup({ commit }, { tripIndex, fromHash, toHash }) {
    commit('COPY_DATES_FROM_GROUP_TO_GROUP', {
      tripIndex,
      fromHash,
      toHash,
    })
  },
  combineGroups({ commit }, { tripIndex, toHash, fromHashes }) {
    commit('COMBINE_GROUPS', {
      tripIndex,
      toHash,
      fromHashes,
    })
  },
  addDate({ commit }, tripAddressHash) {
    const date = new TripDate(tripAddressHash)
    commit('ADD_DATE', date)
  },
  editDate({ commit }, { tripAddressHash, tripDateIndex, newDate }) {
    commit('EDIT_DATE', { tripAddressHash, tripDateIndex, newDate })
  },
  removeDate({ commit }, { tripAddressHash, tripDateIndex }) {
    commit('REMOVE_DATE', { tripAddressHash, tripDateIndex })
  },
  addTime({ commit }, { tripAddressHash, tripDateIndex, time }) {
    commit('ADD_TIME', { tripAddressHash, tripDateIndex, time })
  },
  removeTime({ commit }, { tripAddressHash, tripDateIndex, timeIndex }) {
    commit('REMOVE_TIME', { tripAddressHash, tripDateIndex, timeIndex })
  },
}
