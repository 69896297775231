import { render, staticRenderFns } from "./ReservationAmenitiesSidebar.vue?vue&type=template&id=7b8b7749&scoped=true"
import script from "./ReservationAmenitiesSidebar.vue?vue&type=script&lang=ts"
export * from "./ReservationAmenitiesSidebar.vue?vue&type=script&lang=ts"
import style0 from "./ReservationAmenitiesSidebar.vue?vue&type=style&index=0&id=7b8b7749&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8b7749",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBtn,VCheckbox,VFlex,VLayout,VSwitch})
