<template>
  <div class="reservation-quick-refer">
    <v-card-text>
      <v-layout class="affiliate-view" row wrap>
        <v-flex>
          <v-layout column class="referral-header">
            <v-layout row class="reservation-chip-row px-3 py-2">
              <v-chip
                v-for="reservation in selectedReservations"
                :key="`reservation-chip-${reservation.item.managedId}`"
                class="reservation-chip"
                style="background-color: white !important"
                outline
                disabled
              >
                {{ reservation.item.managedId }}
                <v-icon
                  v-if="selectedReservations.length > 1"
                  right
                  class="ml-1 mr-1 close-icon"
                  @click="removeReservation(reservation)"
                >
                  cancel
                </v-icon>
              </v-chip>
            </v-layout>
            <v-layout row class="mt-5">
              <v-flex class="referral-header xs4">
                <v-flex xs11>
                  <v-layout column>
                    <div>Search</div>
                    <v-text-field
                      v-model="searchText"
                      append-icon="$vuetify.icons.search"
                      flat
                      solo
                      @input="searchInput"
                    />
                  </v-layout>
                </v-flex>
                <v-flex xs1>
                  <v-layout
                    column
                    style="justify-content: center; height: 100%"
                  >
                    <div
                      style="cursor: pointer; width: 24px; height: 32px"
                      @click="setSort"
                    >
                      <img v-if="sortUp" src="@/assets/images/sort_up.svg" />
                      <img v-else src="@/assets/images/sort_down.svg" />
                    </div>
                  </v-layout>
                </v-flex>
              </v-flex>
              <v-flex>
                <div>Filter By Market</div>
                <MultiMarket :set-filter="setMarketFilter" />
              </v-flex>
              <v-flex>
                <v-layout column class="sort-options">
                  <div>Filter by Partner Type</div>
                  <PartnerTypeFilter
                    :filters="filters"
                    :add-filter="filterAffiliates"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout column>
              <h3 class="mb-1">Routes</h3>
              <v-checkbox
                v-for="(route, routeIndex) in routes"
                :key="`reservation-reservation-${route.reservationId}-route-${route.name}`"
                v-model="selectedRoutes[routeIndex]"
                class="mb-0 mt-0 pt-0"
                hide-details
              >
                <template #label>
                  <v-layout row class="align-center route-name">
                    {{ route.name }}
                    <span class="d-flex ml-3 align-center">
                      <CRIcon color="primary" width="20" height="20">
                        drivers_circle
                      </CRIcon>
                      <div class="ml-2">
                        {{ getNumberOfDrivers(route.tripVehicles) }}
                      </div>
                    </span>
                    <span class="d-flex ml-3 align-center">
                      <CRIcon color="primary" width="20" height="20">
                        vehicles
                      </CRIcon>
                      <div class="ml-2">
                        {{ formatVehicles(route.tripVehicles) }}
                      </div>
                    </span>
                  </v-layout>
                </template>
              </v-checkbox>
            </v-layout>
          </v-layout>
        </v-flex>

        <v-flex v-if="!loading && !affiliatePage.length" text-xs-center xs12>
          No Data Available
        </v-flex>
        <v-flex xs12>
          <br />
        </v-flex>

        <v-flex v-if="loading" xs12>
          <v-layout row wrap>
            <v-flex text-xs-center xs12>
              Retrieving Affiliates
              <v-progress-linear height="2" indeterminate />
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex
          v-for="(affiliate, affiliateIndex) in affiliatePage"
          :key="`${affiliate.companyId}-${affiliateIndex}`"
          xs12
        >
          <v-layout>
            <v-flex xs5>
              <v-layout style="height: 100%" column>
                <v-flex class="affiliate-row left-border-radius" align-center>
                  <div style="padding-left: 16px">
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        width: 80%;
                        word-wrap: break;
                      "
                    >
                      <strong class="affiliate-name">
                        {{ op(affiliate, 'name') }}
                      </strong>
                    </div>

                    <div style="display: flex; align-items: center">
                      <v-chip
                        :color="$cr.theme.grayLight"
                        label
                        disabled
                        style="right: 5px"
                      >
                        {{ affiliate.partnerTypeLabel }}
                      </v-chip>
                      <v-chip
                        :color="$cr.theme.grayLight"
                        label
                        disabled
                        style="right: 5px"
                      >
                        {{ affiliate.onboardingStatusTypeLabel }}
                      </v-chip>
                      <v-chip
                        v-if="affiliate.internationalOperation"
                        :color="$cr.theme.grayLight"
                        label
                        disabled
                        style="right: 5px"
                      >
                        Int'l
                      </v-chip>
                      <v-chip
                        v-if="affiliate.tierLabel && affiliate.tierLabel !== 'No tier'"
                        :color="$cr.theme.grayLight"
                        label
                        disabled
                        style="right: 5px"
                      >
                        {{ affiliate.tierLabel }}
                      </v-chip>
                    </div>
                    <div style="display: flex; align-items: center">
                      <v-chip
                        class="pa-0"
                        color="primary"
                        disabled
                        style="right: 5px"
                      >
                        {{ affiliate.referralCount }}
                      </v-chip>
                      <template v-if="affiliate.preferred">
                        <img src="@/assets/images/CharterUP.svg" />
                      </template>
                      <template v-else>
                        <img
                          v-if="affiliate.partnerTypeId === 1"
                          class="ml-2"
                          src="@/assets/images/shofur.svg"
                        />
                        <img
                          v-if="affiliate.partnerTypeId === 2"
                          class="ml-2"
                          src="@/assets/images/gogo.svg"
                        />
                      </template>
                      <template v-if="affiliate.spab">
                        <CRIcon
                          height="42"
                          width="42"
                          style="margin: 0 0 -10px 8px"
                          color="primary"
                        >
                          spab
                        </CRIcon>
                      </template>
                    </div>
                    <div class="affiliate-name">
                      {{ phoneFormatFilter(op(affiliate, 'phone')) }}
                    </div>
                    <div
                      style="word-wrap: break; width: 90%"
                      class="affiliate-name"
                    >
                      {{ affiliate.email }}
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs2>
              <v-layout style="height: 100%" column>
                <v-flex class="affiliate-row" layout>
                  <div class="location-container">
                    <div class="garage-locations">
                      <div class="garage-container">
                        <div class="garage">
                          {{ affiliate.address.completeAddress }}
                        </div>
                        <div
                          v-for="(garage, gidx) in affiliate.garages"
                          :key="gidx"
                          class="garage"
                        >
                          {{ garage.address.completeAddress }}
                        </div>
                      </div>
                      <v-tooltip v-if="affiliate.garages.length > 1" right>
                        <template #activator="{ on }">
                          <div
                            :style="`color: ${$cr.theme.primary}; cursor: default;`"
                            v-on="on"
                          >
                            Show More
                          </div>
                        </template>
                        <div
                          v-for="(garage, gidx) in affiliate.garages"
                          :key="gidx"
                          class="garage"
                        >
                          {{ garage.address.completeAddress }}
                        </div>
                      </v-tooltip>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs5>
              <v-layout column style="height: 100%">
                <v-layout
                  row
                  wrap
                  class="affiliate-row right-border-radius"
                  style="align-items: center; justify-content: center"
                >
                  <v-flex xs8>
                    <ReservationQuickReferTakeHomeAmount
                      :id="`reservation-quick-refer-affiliate-${affiliateIndex}-text-offer-amount`"
                      :ref="affiliate.companyId"
                      :value="''"
                      :disabled="
                        offeredAffiliate !== null &&
                        offeredAffiliate !== affiliate.affiliateId
                      "
                      :company-id="affiliate.companyId"
                      :offer-amount="affiliate.offerAmount"
                      :needs-manual-referral="true"
                      type="number"
                      @input="(takeHome) => changeTakeHome(affiliate, takeHome)"
                    />
                  </v-flex>
                </v-layout>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex v-if="!loading && affiliatePage.length" xs12>
          <div>
            <v-pagination
              v-model="currentPage"
              :length="parseInt(totalPages, 10)"
              :total-visible="5"
              class="elevation-0 float-left"
              @input="changePage"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div class="reservation-quick-refer--secondary-spacer">
      <v-checkbox
        id="send-referral-email-checkbox"
        v-model="sendReferralEmail"
        hide-details
        class="reservation-quick-refer--secondary-action"
        label="Send Referral Email"
      />
    </div>
    <v-layout justify-start>
      <div class="reservation-quick-refer--btn-spacer">
        <v-btn
          id="save-button"
          class="reservation-quick-refer--action-btn"
          style="margin: 0; border-radius: 0"
          color="primary"
          :loading="isOffering"
          @click="offerReferral"
        >
          Offer
        </v-btn>
      </div>
    </v-layout>
  </div>
</template>
<script>
import op from 'simple-object-path'
import { deepClone } from '@/utils/deepClone'
import { capitalize, pluralize } from '@/utils/string'
import { currencyFilter } from '@/utils/currency'
import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { authComputed } from '@/state/helpers'
import { textLike } from '@/utils/predefined'
import MultiMarket from '@/components/ModifiedMultimarkets.vue'
import admin from '@/services/admin'
import affiliates from '@/services/affiliates'
import { getRoutesForReservations } from '@/services/reservations'
import { EventBus } from '@/utils/event-bus'
import ReservationQuickReferTakeHomeAmount from './ReservationQuickReferTakeHomeAmount.vue'
import PartnerTypeFilter from './PartnerTypeFilter.vue'
import { mapGetters } from 'vuex'

const PARTNER_TAKE_RATE = 0.1
const NON_PARTNER_TAKE_RATE = 0.2
const PARTNER_TYPES = ['Platinum', 'Gold', 'Silver', 'Bronze']
const NON_PARTNER_TYPE_LABEL = 'Non-Partner'
export default {
  components: {
    MultiMarket,
    ReservationQuickReferTakeHomeAmount,
    PartnerTypeFilter,
  },
  props: {
    mode: {
      type: String,
      default: 'enMasse',
    },
    reservations: {
      type: Array,
      default: () => undefined,
    },
    routes: {
      type: Array,
      default: () => undefined,
    },
  },
  data() {
    return {
      filters: filter(),
      selectedRoutes: Array(this.routes.length).fill(true),
      selectedReservations: [],
      sortUp: true,
      filteredAffiliates: [],
      affiliates: [],
      affiliatePage: [],
      chosenAffiliates: {},
      currentPage: 1,
      perPage: 5,
      loading: false,
      searchText: undefined,
      offeredMap: {},
      currencyFilter,
      phoneFormatFilter,
      op,
      markets: [],
      debounce: null,
      immediate: true,
      filterByCategory: 'name',
      totalPages: 100,
      isOffering: false,
      offeredAffiliate: null,
      sendReferralEmail: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      isReferralRejectionReasonV2Enabled:
        'featureToggles/isReferralRejectionReasonV2Enabled',
    }),
    canOverrideReferralPrice() {
      const roles = this.currentUserProfile?.roles || []
      return roles.find((r) => r.roleName === 'can_override_referral_price')
    },
    isOpsAgent() {
      const roles = this.currentUserProfile?.roles || []
      return roles.find((r) => r.roleName === 'is_ops_agent')
    },
  },
  watch: {
    'affilType.sort'() {
      this.immediate = true
      this.filterAffiliates()
    },
  },
  mounted() {
    this.loading = true
    this.selectedReservations = this.reservations
    this.filterAffiliates()
  },
  methods: {
    capitalize,
    getRoutesForReservations,
    removeReservation(reservation) {
      const index = this.selectedReservations.indexOf(reservation)
      if (index >= 0) {
        this.selectedReservations.splice(index, 1)
      }
    },
    getNumberOfDrivers(tripVehicles) {
      let drivers = 0
      tripVehicles.forEach((vehicle) => {
        drivers += vehicle.quantity
      })
      return drivers
    },
    formatVehicles(tripVehicles) {
      let vehicles = []
      tripVehicles.forEach((vehicle) => {
        vehicles.push(
          `${vehicle.quantity} ${pluralize(
            vehicle.quantity,
            vehicle.vehicleType.label
          )}`
        )
      })
      return vehicles.join(', ')
    },
    setMarketFilter(e) {
      this.markets = e
      this.immediate = true
      this.filterAffiliates()
    },
    setSort() {
      this.sortUp = !this.sortUp
      this.immediate = true
      this.filterAffiliates()
    },
    searchInput(arg) {
      this.immediate = false
      this.filterAffiliates(arg)
    },
    async filterAffiliates(arg) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      if (!this.immediate) {
        this.debounce = setTimeout(() => {
          this.immediate = true
          this.filterAffiliates(arg)
        }, 500)
        return
      }

      this.immediate = false
      const page = arg && arg.page ? arg.page : 1

      if (this.markets.length > 0) {
        const marketFilter = {
          column: {
            _t_id: 'marketins',
            prop: 'nearestMarketId',
            filterType: 'eq',
          },
          value: this.markets.join(' '),
        }

        this.filters.remove(marketFilter)
        this.filters.add(this.filters.createParent('and'), marketFilter)
      }

      let selectedPredefined = deepClone(textLike[0])
      selectedPredefined.controls[0].value = this.searchText
      const textFilter = {
        column: {
          _t_id: 'text_search_refer',
          prop: 'name',
          filterType: 'contains',
          method: 'and',
        },
        selectedPredefined,
        value: this.searchText,
      }
      this.filters.remove(textFilter)

      if (this.searchText) {
        this.filters.add(this.filters.createParent('and'), textFilter)
      }

      let sorts = sort()
      if (this.sortUp) {
        sorts.add({
          prop: 'referralCount',
          direction: 'desc',
        })
      } else {
        sorts.add({
          prop: 'referralCount',
          direction: 'asc',
        })
      }

      const affilResponse = await affiliates.getAffiliates({
        sorts: sorts.asQueryParams(),
        filters: this.filters.asQueryParams(),
        pageSize: 5,
        page,
      })

      this.affiliatePage = affilResponse?.data?.resultList
      this.affiliatePage.map((affil) => {
        if (!affil.offerAmount) {
          affil.offerAmount = null
          affil.takeHomeAmount = null
        }
      })
      this.loading = false

      this.immediate = true
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.filterAffiliates({ page: currentPage })
    },
    affiliateLocation(affiliate) {
      return `${affiliate?.address?.city || ''}, ${
        affiliate?.address?.state || ''
      }`
    },
    async unassignReferral(affiliate) {
      const { referredTo: referrals = [] } = this.reservation
      const referral = referrals.find(
        (r) => r?.companyId === affiliate.companyId
      )
      if (referral) {
        const unassignReferralPayload = {
          reservationId: referral.reservationId,
        }
        if (this.isReferralRejectionReasonV2Enabled) {
          await this.$store.dispatch(
            'reservations/unassignReferralV2',
            unassignReferralPayload
          )
        } else {
          await this.$store.dispatch(
            'reservations/unassignReferral',
            unassignReferralPayload
          )
        }
      }
      this.$emit('refresh-query-request')
    },
    remapNewCompanyFormatToOld(company) {
      return [
        {
          company: { id: company.companyId },
          is_active: true,
          id: company.affiliateId,
        },
        {
          id: company.companyId,
        },
      ]
    },
    close() {
      EventBus.$emit('global-table-view-refresh')
      this.$store.dispatch('app/closeDialog')
      this.$emit('close-modal')
    },
    async offerReferral() {
      for (const res of Object.entries(this.reservations)) {
        for (const offer of Object.entries(this.affiliatePage)) {
          if (
            res[1].item.maxReferralAmount != null &&
            offer[1].offerAmount > res[1].item.maxReferralAmount &&
            !this.canOverrideReferralPrice &&
            this.isOpsAgent
          ) {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message:
                'This referral is above the max referral amount ' +
                res[1].item.maxReferralAmount +
                '. Please reduce to send to the operator',
            })
            return
          }
        }
      }
      this.isOffering = true
      let allNotOffered = true
      const reservationsIds = this.reservations.map((reservation) => {
        if (reservation.item) {
          reservation = reservation.item
        }
        if (reservation.referralStatus !== 'not_offered') {
          allNotOffered = false
        }
        return reservation.reservationId
      })

      if (!allNotOffered) {
        this.close()
        setImmediate(() => {
          this.$store.dispatch('app/showAlert', {
            type: 'error',
            message:
              'There are selected reservations that are already offered. Please select only Not Offered reservations and try again.',
          })
        })
        return
      }
      for (const [key, affiliate] of Object.entries(this.chosenAffiliates)) {
        let tripVehicleGroupIds = []
        this.routes.forEach((route, routeIndex) => {
          if (this.selectedRoutes[routeIndex]) {
            tripVehicleGroupIds.push(route.tripVehicleGroupId)
          }
        })
        const offerParams = {
          amount: affiliate.offerAmount,
          companyId: affiliate.companyId,
          reservationIds: reservationsIds,
          sendReferralEmail: this.sendReferralEmail,
          tripVehicleGroupIds: tripVehicleGroupIds,
        }

        try {
          const response = await admin.createReferralsEnMasse(offerParams)
          if (response?.data?.successful) {
            await this.$store.dispatch('reservations/setManualReferralNeeded', {
              reservationIds: reservationsIds,
              needsManualReferral: true,
            })
          }
        } catch (e) {
          this.isOffering = false
          this.close()
          this.$nextTick(() => {
            this.$store.dispatch('app/showAlert', {
              type: 'error',
              message: e.response?.data?.message,
            })
          })
          return
        }
      }
      this.$emit('refresh-query-request')
      EventBus.$emit('global-table-view-refresh')
      this.isOffering = false
      this.close()
      this.$nextTick(() => {
        this.$store.dispatch('app/showAlert', {
          message: 'Referrals offered to reservations successfully.',
        })
      })
    },
    changeTakeHome(affiliate, takeHome) {
      affiliate.takeHomeAmount = parseFloat(takeHome)
      const isPartner = PARTNER_TYPES.includes(affiliate.partnerTypeLabel)
      const isNonPartner = affiliate.partnerTypeLabel === NON_PARTNER_TYPE_LABEL
      let offerAmount
      if (isPartner) {
        offerAmount = affiliate.takeHomeAmount / (1 - PARTNER_TAKE_RATE)
        if (offerAmount) {
          offerAmount = offerAmount.toFixed(2)
        }
      } else if (isNonPartner) {
        offerAmount = affiliate.takeHomeAmount / (1 - NON_PARTNER_TAKE_RATE)
        if (offerAmount) {
          offerAmount = offerAmount.toFixed(2)
        }
      } else {
        offerAmount = affiliate.takeHomeAmount
      }
      affiliate.offerAmount = offerAmount || null
      this.$forceUpdate()
      if (affiliate.offerAmount > 0) {
        this.chosenAffiliates[affiliate.affiliateId] = affiliate
        this.offeredAffiliate = affiliate.affiliateId
      } else {
        this.offeredAffiliate = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-quick-refer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 700px;

  &--btn-spacer {
    min-height: 71px;
    width: 700px;
  }

  &--secondary-spacer {
    min-height: 54px;
    width: 700px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    z-index: 3;
  }

  &--secondary-action {
    position: fixed;
    font-size: 18px;
    background-color: white;
    bottom: 71px;
    width: 700px !important;
    width: inherit;
    margin: 0;
    padding: 10px 0 10px 16px;
    z-index: 1;
  }
}

.reservation-chip-row {
  background-color: $blue-pale;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.reservation-chip {
  font-size: 15px;
  border-color: $gray-light !important;
  border-radius: 20px;
  color: $primary !important;
}

.close-icon {
  color: $gray-mid-light;
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

.route-name {
  color: $gray-dark;
}
.v-dialog {
  min-width: 750px !important;
  max-width: 1500px !important;
  .v-card {
    max-width: 1200px;
  }
}

.referral-header {
  display: flex;
  flex-grow: 10;
  justify-content: space-between;

  .flex {
    flex-grow: 2;
    padding-right: 10px;
  }

  .referral-filter {
    position: relative;
    display: flex;
    min-height: inherit;
    margin-bottom: 8px;
    margin-left: 20px;
    color: rgba($black-base, 0.54);
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    div {
      display: flex;
      align-items: center;
      height: 2.5em;
      font-size: 16px;
      line-height: 1;
    }

    i {
      margin-left: 5px;
      font-size: 16px;
    }
  }
}

.display-number {
  margin-right: 10px;
  margin-left: 10px;
}

.vehicle-display {
  display: flex;
  flex-direction: column;
  width: 13%;

  > * {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 4%;
  }

  img {
    height: 30px;
  }
}

.location-container {
  display: flex;
  align-items: center;
  height: 120px;
  padding-bottom: 5px;

  .garage-locations {
    height: 40px;

    div {
      width: 100%;
    }

    .garage-container {
      height: inherit;
      overflow: scroll;

      .garage {
        color: $black;
      }
    }
  }
}

.vehicle-layout {
  .vehicle-label {
    height: 40px;
  }
}

.offer-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.vehicle-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.driver-layout ::v-deep input {
  color: $white !important;
  text-align: right;
}

.input-false ::v-deep input {
  color: $black !important;
}

.sort-options {
  display: flex;
  flex-grow: 1;
  padding-left: 2%;
}

.padded-left-4 {
  padding-right: 4px;
  padding-left: 4px;
}

.padded-left-8 {
  padding-right: 4px;
  padding-left: 8px;
}

.padded-left-6 {
  padding-right: 6px;
  padding-left: 6px;
}

.customer-total {
  height: 40px;
  color: $white;
  background-color: $black;
  border-radius: 4px;

  .padded {
    padding: 10px;
  }
}

.padded-margin-top {
  margin-top: 14px;
}

::v-deep .v-text-field {
  .v-input__control {
    .v-input__slot {
      background-color: $gray-light !important;
    }
  }
}

.affiliate-row {
  padding: 12px 6px 0 6px;
  margin-bottom: 4px;
  background-color: $blue-pale;

  .v-text-field {
    .v-input__control {
      min-height: 34px;
      max-height: 50px;

      .v-input__slot {
        margin-bottom: 0;
      }
    }

    input {
      padding: 0;
    }
  }
}

.affiliate-view > .flex.xs12 {
  margin-bottom: 20px;
}

.affiliate-name {
  color: $blue;
  word-wrap: break-word;
}

.light {
  color: $blue-dull;
}

.column-header {
  padding-left: 6px;
  min-height: 30px;
  border-right: 1px solid rgba(128, 128, 128, 0.2);
}

.right-border-radius {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.left-border-radius {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
</style>

<style lang="scss" scoped>
.no-spin-button::v-deep input[type='number']::-webkit-inner-spin-button,
.no-spin-button::v-deep input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
