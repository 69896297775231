<template>
  <div
    class="company-form-container"
    :style="!isModeAdd ? 'padding-bottom: 48px;' : ''"
  >
    <v-snackbar
      :id="`companies-form-snackbar-error-display`"
      v-model="displayerr"
      :top="true"
      color="error"
      :multi-line="isModeMultiLine"
      :timeout="0"
      :vertical="isModeVertical"
      type="error"
    >
      {{ errmsg }}

      <v-btn
        :id="`companies-form-button-close-error`"
        dark
        flat
        @click="displayerr = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <BaseForm
      :id="`companies-form`"
      ref="form"
      :disabled="disabled"
      :form-type="formType"
      :layout="layout"
      :loading="loading"
      :row="row"
      :select-models="selectModels"
    >
      <template #top>
        <div class="button-container">
          <v-btn
            v-if="isBrokerAdmin || isSuperAdmin"
            :id="`companies-form-button-add-user`"
            class="btn-secondaryaction"
            @click="
              () => {
                $router.push({ name: 'users.add', query: { companyId: id } })
              }
            "
          >
            Add User
          </v-btn>

          <v-btn
            v-if="!isModeMyCompany"
            :id="`companies-form-button-cancel-action`"
            class="btn-secondaryaction"
            @click="cancelAction"
          >
            {{ isModeView ? 'All Companies' : 'Cancel' }}
          </v-btn>

          <v-btn
            v-if="isModeEdit"
            :id="`companies-form-button-save-changes`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Save Changes
          </v-btn>

          <v-btn
            v-if="isModeAdd"
            :id="`companies-form-add-company`"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            + Add Company
          </v-btn>

          <v-btn
            v-if="isModeView || isModeMyCompany"
            :id="`companies-form-button-edit-company`"
            class="btn-primaryaction"
            @click="enableEdit"
          >
            Edit Company
          </v-btn>
        </div>
      </template>

      <template #address>
        <div class="pb-5">
          <div>Address</div>
          <CRAddressSelector
            v-if="!address.addressName"
            :id="`companies-form`"
            :disabled="disabled"
            :show-options="showOptions"
            placeholder="Type to search"
            :use-formatted-detail="true"
            :rules="[
              isRequired(true, () => validateAddress(address), {
                req: 'Address Is Required',
                error: 'Address Is Required',
              }),
            ]"
            @place-selected="(selectedData) => placeSelected(selectedData)"
          />
          <CRInput
            v-if="address && address.addressName"
            :id="`companies-form-address-name`"
            class="cr-input-stop-address"
            type="text"
            clear-icon="replay"
            :clearable="true"
            :readonly="true"
            :disabled="disabled"
            :value="address.addressName || ''"
            :rules="[
              isRequired(true, () => validateAddress(address), {
                req: 'Address Is Required',
                error: 'Address Is Required',
              }),
            ]"
            @click:clear="() => (address = {})"
          />
        </div>
      </template>
    </BaseForm>

    <PaymentProfile
      v-if="!isModeAdd"
      :id="`companies-form`"
      ref="paymentprofile"
      :company="row"
      :mode="mode"
      :select-models="selectModels"
      @update="handleSettingUpdate"
    />
  </div>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import CompaniesSchema from '@/components/CompaniesSchema'
import PaymentProfile from '@/components/PaymentProfile.vue'
import companies from '@/services/companies'
import { isNotEmpty, isRequired, validateAddress } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { store } from '@/state/store'
import { Duration } from 'luxon'
import { convertDurationToMinutesInteger } from '@/utils/garageTimes.js'

const MILLIS_TO_MINUTES = 1000 * 60

export default {
  name: 'CompaniesForm',
  components: {
    BaseForm,
    PaymentProfile,
  },
  props: {
    id: {
      type: String,
      default: () => {
        const { getters = {} } = store
        const currentUser = getters['auth/currentUser'] || {}
        return currentUser.companyId ? '' + currentUser.companyId : null
      },
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      address: null,
      name: '',
      email: '',
      loading: false,
      disabled: true,
      displayerr: false,
      errmsg: '',
      selectModels: {},
      isRequired,
      validateAddress,
      row: {},
      layout: [],
      formType: '',
      originalMode: null,
      forcedMode: null,
      qualifiedAddress: '',
      showOptions: false,
      addrRule: [
        function (evt) {
          if (evt && evt.length > 0) {
            return true
          }
          return 'Address Required'
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
    isModeAdd() {
      return this.mode === 'add'
    },
    isModeMyCompany() {
      return this.mode === 'my-company'
    },
    isModeVertical() {
      return this.mode === 'vertical'
    },
    isModeMultiLine() {
      return this.mode === 'multi-line'
    },
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        return
      }
      Object.assign(this.$data, this.$options.data.call(this))

      this.resetCompanyForm()
      this.$validator.reset()

      if (this.$refs.form && this.$refs.form.$refs.form) {
        this.$refs.form.$refs.form.reset()
      }
    },
  },
  async mounted() {
    this.resetCompanyForm()
  },
  methods: {
    async resetCompanyForm() {
      this.disabled = !!(this.isModeView || this.isModeMyCompany)
      let company

      if (!this.isModeAdd) {
        const companyResponse = await companies.getCompany(this.id)
        company = companyResponse?.data.company
      } else {
        company = CompaniesSchema.company
      }
      this.row = company
      this.row.id = this.id
      this.formType = `${company.name}`
      this.address = company.address

      if (this.isModeView) {
        this.formType = `${company.name}`
      }

      if (this.isModeEdit) {
        this.formType = ` Edit: ${company.name}`
      }

      if (this.isModeAdd) {
        this.formType = 'Add Company'
      }

      this.qualifiedAddress = Object.keys(this.row.address)
        .map((addressKey) => {
          const filters = ['street1', 'street2', 'city', 'state', 'postalCode']

          if (
            filters.indexOf(addressKey) > -1 &&
            this.row.address[addressKey] &&
            this.row.address[addressKey].length > 0
          ) {
            return this.row.address[addressKey]
          }

          return null
        })
        .filter(Boolean)
        .join(', ')

      this.row.checkoutTypeId = this.row.checkoutTypeId || 2
      this.selectModels.checkoutTypeId = {
        checkoutTypeId: this.row.checkoutTypeId,
      }
      if (this.row?.companyType?.companyTypeId) {
        this.selectModels.companyType = this.row.companyType
      } else {
        this.selectModels.companyType = undefined
      }
      this.selectModels.insuranceOnFile = {
        insuranceOnFileTypeId: this.row.insuranceOnFile ? 1 : 0,
      }
      this.selectModels.insuranceActive = {
        insuranceActiveTypeId: this.row.insuranceActiveTypeId,
      }
      this.selectModels.linkedLeadSources = this.row.linkedLeadSources
      this.selectModels.linkableLeadSources = this.row.linkableLeadSources
      this.selectModels.defaultTimeAtYard = this.setDefaultTimeAtYard(
        this.row.defaultPreTripArrivalTime
      )

      this.selectModels.ticketCancellationRequired = {
        label: 'Track Ticket Cancellation',
        id: 1,
        required: this.row.ticketCancellationRequired,
      }

      this.selectModels.compliance = [
        {
          label: 'ADA',
          id: 1,
          compliant: this.row.ada || false,
        },
        { label: 'SPAB', id: 2, compliant: this.row.spab || false },
      ]

      const layout = [
        {
          id: 1,
          fields: [
            {
              key: 'name',
              label: 'Company Name',
              type: 'text',
              rules: [
                isRequired(true, isNotEmpty, {
                  req: 'Company Name Required',
                  error: 'Company Name Required',
                }),
              ],
            },
            {
              label: 'address',
              type: 'slot',
            },
            {
              key: 'email',
              label: 'Primary Email Address',
              type: 'text',
              color: 'primary',
              tooltip: `All transactional emails will be sent from this email address and it will be used as the
            main contact email address.`,
              rules: [
                isRequired(true, isNotEmpty, {
                  req: 'Email Required',
                  error: 'Email Required',
                }),
              ],
            },
            {
              type: 'row-block',
              label: 'form-row',
              children: [
                {
                  key: 'phone',
                  label: 'Phone Number',
                  type: 'phone',
                  rules: [
                    isRequired(true, isNotEmpty, {
                      req: 'Phone Number Required',
                      error: 'Phone Number Required',
                    }),
                  ],
                },
                {
                  key: 'opsPhone',
                  label: 'Operations Phone',
                  type: 'phone',
                  color: 'primary',
                  tooltip: `The Operations phone number is the number used for company-to-company communications. It
            will never be shown to customers.`,
                  rules: [
                    isRequired(true, isNotEmpty, {
                      req: 'Operations Phone Required',
                      error: 'Operations Phone Required',
                    }),
                  ],
                },
              ],
            },
            {
              checkLabel: 'label',
              label: 'ticket cancellation',
              checkKey: 'id',
              checkModel: 'required',
              items: [this.selectModels.ticketCancellationRequired],
            },
          ],
        },
        {
          id: 2,
          fields: [
            {
              type: 'row-block',
              label: 'form-row-2',
              children: [
                {
                  key: 'dotNumber',
                  label: 'DOT Number',
                  type: 'text',
                  rules: [
                    isRequired(true, isNotEmpty, {
                      req: 'DOT Number Required',
                      error: 'DOT Number Required',
                    }),
                  ],
                },
              ],
            },
            {
              key: 'emailSenderName',
              type: 'text',
              label: 'Email Sender Name',
              color: 'primary',
              rules: [
                isRequired(true, isNotEmpty, {
                  req: 'Email Sender Name Required',
                  error: 'Email Sender Name Required',
                }),
              ],
              tooltip: 'All transactional emails will be sent using this name.',
            },
            {
              key: 'fax',
              type: 'text',
              label: 'Fax',
            },
            {
              key: 'websiteUrl',
              type: 'text',
              label: 'Website Url',
            },
          ],
        },
      ]

      this.layout = layout
      if (this.isAdminCompany()) {
        this.layout[1].fields[0].children.splice(0, 0, {
          type: 'select',
          label: 'Company Type',
          key: 'companyType',
          model: this.selectModels.companyType,
          modelName: 'companyType',
          text: 'companyTypeName',
          id: 'companyTypeId',
          items: CompaniesSchema.companyTypes,
          rules: [
            isRequired(true, isNotEmpty, {
              req: 'Company Type Required',
              error: 'Company Type Required',
            }),
          ],
        })

        this.layout[1].fields.splice(1, 0, {
          type: 'select',
          label: 'Checkout Type',
          key: 'checkoutType',
          model: this.selectModels.checkoutTypeId,
          modelName: 'checkoutTypeId',
          text: 'checkoutTypeName',
          id: 'checkoutTypeId',
          forceDisabled: true,
          items: CompaniesSchema.checkoutTypes,
        })

        const checkoutTypeIdField = this.layout[1].fields.find(
          (f) => f.id === 'checkoutTypeId'
        )
        if (checkoutTypeIdField) {
          checkoutTypeIdField.forceDisabled = false
        }
      }

      if (this.isAdminCompany()) {
        this.layout[0].fields.push(
          {
            type: 'select',
            label: 'Insurance On File',
            model: this.selectModels.insuranceOnFile,
            modelName: 'insuranceOnFile',
            text: 'insuranceOnFileName',
            id: 'insuranceOnFileTypeId',
            items: CompaniesSchema.insuranceOnFileTypes,
          },
          {
            type: 'select',
            label: 'Insurance Active',
            model: this.selectModels.insuranceActive,
            modelName: 'insuranceActive',
            text: 'insuranceActiveName',
            id: 'insuranceActiveTypeId',
            items: CompaniesSchema.insuranceActiveTypes,
          },
        )
        this.layout[1].fields.push(
          {
            key: 'insuranceExpirationDate',
            label: 'Insurance Expiration Date',
            type: 'date',
          },
        )
      }
      const checkboxRowChildren = []
      this.layout[0].fields.push({
        type: 'row-block',
        label: 'form-row-3',
        children: checkboxRowChildren,
      })
    },
    handleSettingUpdate(val) {
      if (!this.isModeEdit) return
      const { name } = val
      if (name === 'ticket-cancellation-required') {
        const value = !this.selectModels.ticketCancellationRequired.required
        this.selectModels.ticketCancellationRequired.required = value
      }
    },
    focus() {
      this.showOptions = true
    },
    setDefaultTimeAtYard(time) {
      if (time) {
        return convertDurationToMinutesInteger(time)
      }
      return null
    },
    isBrokerAdmin() {
      return this.currentUserProfile?.isBrokerAdmin
    },
    isSuperAdmin() {
      return this.currentUserProfile?.isSuperAdmin
    },
    isAdminUser() {
      return this.currentUser?.group?.key === 'admin'
    },
    isAdminCompany() {
      return this.currentUser?.company?.companyType?.companyTypeId === 4
    },
    placeSelected(placeObject) {
      const { place } = placeObject
      this.address = place
    },
    disableAndRevert() {
      this.disabled = true
      if (this.originalMode === 'my-company') {
        this.mode = 'my-company'
        return
      }

      this.$router.push(`/companies/view/${this.id}`)
    },
    displayErr(e, status) {
      this.displayerr = status
      this.errmsg = e
    },
    async submit() {
      this.loading = true
      let reply = {}

      const validationTest = this.$refs.form.validateAll()
      let isFormValid = validationTest.length === 0 && this.address.addressName
      const partnershipSettingsExists =
        this.$refs?.paymentprofile?.$refs?.partnershipsettings !== undefined

      if (partnershipSettingsExists) {
        const isPartnershipSettingsValid = this.$refs.paymentprofile.$refs.partnershipsettings.isFormValid()
        isFormValid = isFormValid && isPartnershipSettingsValid
      }

      if (!isFormValid) {
        this.loading = false
        this.displayErr(
          'Some fields are missing that may be required or are invalid.',
          true
        )
        return
      }

      const submission = JSON.parse(JSON.stringify(this.row))
      submission.companyType = this.selectModels.companyType
      submission.ticketCancellationRequired = this.selectModels.ticketCancellationRequired.required
      submission.address = this.address || submission.address
      submission.defaultPreTripArrivalTime = Duration.fromMillis(
        this.selectModels.defaultTimeAtYard * MILLIS_TO_MINUTES
      ).toFormat('hh:mm:ss')
      submission.phone = submission.phone
        ? submission.phone.replace(/[^0-9]/g, '')
        : ''
      submission.opsPhone = submission.opsPhone
        ? submission.opsPhone.replace(/[^0-9]/g, '')
        : ''
      submission.email = submission.email.trim()

      submission.checkoutTypeId =
        this.selectModels.checkoutTypeId &&
        this.selectModels.checkoutTypeId.checkoutTypeId
          ? this.selectModels.checkoutTypeId.checkoutTypeId
          : 2
      submission.insuranceOnFile =
        this.selectModels.insuranceOnFile.insuranceOnFileTypeId === 1
      submission.insuranceActiveTypeId = this.selectModels.insuranceActive.insuranceActiveTypeId
      if (partnershipSettingsExists) {
        const partnerPayload = await this.$refs.paymentprofile.$refs.partnershipsettings.processPayload()

        Object.entries(partnerPayload).map((item) => {
          const key = item[0]
          const value = item[1]
          submission[key] = value
        })
      }

      try {
        const params = {
          id: this.id,
          payload: submission,
        }
        if (this.isModeAdd) {
          reply = await companies.addCompany(params)
          this.mode = 'view'
        } else {
          reply = await companies.modifyCompany(params)
        }
        if (partnershipSettingsExists) {
          await this.$refs.paymentprofile.$refs.partnershipsettings.processPdf()
        }
      } catch (e) {
        this.loading = false
        this.disabled = !this.disabled

        this.displayErr(e, true)
        return
      }

      this.loading = false
      this.disabled = !this.disabled

      const companyResponse = await companies.getCompany(
        reply.data.company.companyId
      )
      this.row = companyResponse?.data.company

      this.$router.push(
        `/companies${
          reply.data.company.companyId
            ? `/view/${reply.data.company.companyId}`
            : `/view/${this.id}`
        }`
      )
      if (partnershipSettingsExists) {
        await this.$refs.paymentprofile.$refs.partnershipsettings.updatePage()
      }

      this.$store.dispatch('app/showAlert', {
        message: 'Company saved successfully.',
      })
    },
    enableEdit() {
      if (this.isModeView) {
        this.$router.push(`/companies/edit/${this.id}`)
      }

      if (this.isModeMyCompany) {
        this.mode = 'edit'
        this.originalMode = 'my-company'
      }

      this.disabled = !this.disabled
    },
    backToTable() {
      this.$router.push('/companies')
    },
    cancelAction() {
      if (this.isModeEdit || this.isModeMyCompany) {
        this.disableAndRevert()
        return
      }

      this.backToTable()
    },
  },
}
</script>

<style lang="scss">
.v-content__wrap {
  padding-bottom: 24px;
}
</style>

<style lang="scss" scoped>
.company-form-container {
  background: white;
  margin-right: 24px;
  margin-left: 24px;
  border-radius: 15px;
  .sheet-form {
    margin: 0;
  }
}

.cr-base-form-display-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20%;
  margin-bottom: 50%;
}

.button-container {
  position: relative;
  bottom: 10px;
  left: 24px;
  display: flex;
  flex-direction: row;
}

::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}

::v-deep input:disabled {
  background-color: $blue-dull;
}

::v-deep .card {
  position: relative;
  flex-direction: column;
  padding: 24px;

  /* margin: 0px 0px 48px 0px; */
  background: $white;
  border-radius: 15px;
}

::v-deep .empty {
  height: 80px;
}

::v-deep .fax {
  width: 50%;
}

::v-deep .sheet {
  padding: 0 24px;
  margin-bottom: 0;
  border-radius: 15px;
  display: block;
  height: auto;
  overflow: hidden;
}

::v-deep .container {
  padding: 0 24px 0 24px;
}

::v-deep label.v-label.theme--light {
  width: 140px;
}

::v-deep .row-block {
  max-width: 45%;
}
</style>
