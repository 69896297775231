export class Operator {
  constructor(operator) {
    this.addressName = operator?.addressName || null
    this.companyId = operator?.companyId || null
    this.email = operator.email || null
    this.garageNames = operator?.garageNames || []
    this.internationalOperation = operator?.internationalOperation || false
    this.name = operator.name || null
    this.onboardingStatusTypeLabel = operator?.onboardingStatusTypeLabel || null
    this.partnerTypeId = operator?.partnerTypeId || false
    this.partnerTypeLabel = operator?.partnerTypeLabel || false
    this.phone = operator?.phone || null
    this.preferred = operator?.preferred || false
    this.referralCount = operator?.referralCount || null
    this.spab = operator?.spab || false
  }

  toObject() {
    const operator = Object.assign({}, this)
    return operator
  }
}
