<template>
  <div>
    <label>{{ label }}</label>
    <v-autocomplete
      id="customer-account-selector-autocomplete-customer-select"
      :value="customerAccountId"
      :disabled="disabled"
      :loading="loading"
      :clearable="clearable"
      item-value="customerAccountId"
      item-text="name"
      solo
      flat
      no-filter
      :search-input.sync="search"
      :items="customerAccounts"
      append-icon="keyboard_arrow_down"
      :placeholder="placeholder"
      :rules="rules"
      @input="selectCustomerAccountId"
      @click:clear="$emit('customer-account-cleared')"
    >
      <template
        v-if="annualContractValue !== '--' || totalContractValue !== '--'"
        #append
      >
        ACV: {{ annualContractValue }} | Lifetime Opp: {{ totalContractValue }}
      </template>
      <template #append-outer>
        <router-link
          v-if="linkDetail && customerAccountId"
          :to="`/customer-accounts/view/${customerAccountId}`"
        >
          <v-icon color="primary">remove_red_eye</v-icon>
        </router-link>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import customerAccounts from '@/services/customerAccounts'
import { filter } from '@/utils/filter'
import { currencyFilter } from '@/utils/string'

export default {
  name: 'CustomerAccountSelector',
  model: {
    prop: 'customerAccountId',
    event: 'input',
  },
  props: {
    customerAccountId: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Customer Account',
    },
    placeholder: {
      type: String,
      default: 'Customer Account',
    },
    hiddenCustomerAccountIds: {
      type: Array,
      default: () => [],
    },
    linkDetail: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      loading: false,
      customerAccounts: [],
      debounce: null,
    }
  },
  computed: {
    customerAccount() {
      return this.customerAccounts.find(
        (customerAccount) =>
          customerAccount.customerAccountId === this.customerAccountId
      )
    },
    annualContractValue() {
      const acv = this.customerAccount?.salesforceAccount?.annualContractValue
      return acv ? currencyFilter(acv) : '--'
    },
    totalContractValue() {
      const tcv = this.customerAccount?.salesforceAccount?.totalContractValue
      return tcv ? currencyFilter(tcv) : '--'
    },
  },
  watch: {
    search() {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (!this.loading) {
        this.debounce = setTimeout(() => {
          this.searchCustomerAccounts()
        }, 500)
        return
      }
      this.searchCustomerAccounts()
    },
    customerAccountId() {
      this.getCustomerAccount()
      if (this.customerAccountId) {
        this.$emit('customer-account', this.customerAccount)
      }
    },
  },
  mounted() {
    this.getCustomerAccount()
  },
  methods: {
    selectCustomerAccountId(customerAccountId) {
      this.customerAccountId = customerAccountId
      this.$emit('input', customerAccountId)
      this.$emit('customer-account-selected', customerAccountId)
    },
    async getCustomerAccount() {
      if (!this.customerAccountId) {
        return
      }

      this.loading = true

      try {
        const res = await customerAccounts.getCustomerAccount(
          this.customerAccountId
        )
        const customerAccount = res.data

        if (!customerAccount) {
          this.loading = false
          return
        }

        const accountIdx = this.customerAccounts.findIndex(
          (customerAccount) =>
            customerAccount.customerAccountId === this.customerAccountId
        )
        if (accountIdx === -1) {
          this.customerAccounts.push(customerAccount)
        } else {
          this.customerAccounts.splice(accountIdx, 1, customerAccount)
        }
      } catch (e) {
        console.error(e)
      }

      this.loading = false
    },
    async searchCustomerAccounts() {
      if (
        !this.search ||
        this.search?.length < 3 ||
        this.loading ||
        this.search === this.customerAccount?.name
      ) {
        return
      }

      this.loading = true

      const searchFilter = {
        column: {
          _t_id: 'customer-accounts-search-by-keyword',
          prop: 'name',
          filterType: 'contains',
          filterAsIs: true,
        },
        value: this.search,
      }
      const hiddenIdsFilter = {
        column: {
          _t_id: 'customer-accounts-hidden-ids',
          prop: 'customerAccountId',
          filterType: 'neq',
        },
        value: this.hiddenCustomerAccountIds,
      }
      const filterUtil = filter()
      const parentFilter = filterUtil.createParent('and')
      filterUtil.add(parentFilter, searchFilter)
      if (this.hiddenCustomerAccountIds?.length) {
        filterUtil.add(parentFilter, hiddenIdsFilter)
      }

      const params = {
        filters: filterUtil.asQueryParams(),
        pageSize: -1,
      }

      this.customerAccounts = await customerAccounts
        .getCustomerAccounts(params)
        .then(({ data }) => {
          return data.resultList
        })
        .catch(() => {})

      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
.create-account {
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
  line-height: 2;
  color: $blue;
  cursor: pointer;

  &.clear-account {
    color: $red;
  }
}

::v-deep.customer-account-divider {
  margin: 0 !important;
}
</style>
