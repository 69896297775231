
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import { mockReferralJourneys } from '@/utils/liveTripTracking'
import {
  triggerJourneyEventAlert,
  dismissJourneyEventAlert,
  getLiveTripDetail,
} from '@/services/tripMonitoring'
import BaseMapComponent from '@/components/BaseMapComponent.vue'
import LiveTripEvents from '@/components/LiveTripEvents.vue'
import LiveTripDetailTabs from '@/components/LiveTripDetailTabs.vue'
import { Cluster, Route } from '@/models/GoogleMaps'
import { DateTime } from 'luxon'

@Component({
  components: { BaseMapComponent, LiveTripEvents, LiveTripDetailTabs },
})
export default class LiveTripDetail extends Vue {
  map = null
  markers = []
  zoom = 12
  bounds = null
  debounce = undefined
  center = { lat: 33.771475085040976, lng: -84.39024936101845 }
  clusters: Cluster[] = []
  busMarkers = []
  journeys = []
  reservation = {
    reservationId: 604656,
  }
  selectedVehicleId = null
  currentISO = DateTime.now().toISO()
  timer = null

  @Watch('journeyRoutes', { immediate: true })
  onJourneyRoutesChange(journeyRoutes) {
    if (!journeyRoutes.length) {
      return
    }
    this.center = journeyRoutes[0].stops[0].position
  }

  @Watch('vehicles', { immediate: true })
  onVehicleChange(newVehicles) {
    if (!newVehicles?.length) {
      return
    }
    this.selectedVehicleId = this.vehicles[0].value
  }

  get journeyId(): string {
    if (!this.journeys.length) {
      return ''
    }
    return this.journeys[0].externalJourneyId
  }

  get journeyRoutes() {
    if (!this.journeys.length) {
      return []
    }
    const journey = this.journeys[0]
    const stops = journey.stops.map((s) => ({
      position: {
        lat: s.address.lat,
        lng: s.address.lng,
      },
    }))
    return [
      {
        id: journey.externalJourneyId,
        stops,
      },
    ]
  }

  get vehicles() {
    return this.journeys.map((j) => ({
      text: j.vehicleName,
      value: j.vehicleId,
    }))
  }

  get events() {
    const matchingJourney = this.journeys.find(
      (j) => j.vehicleId === this.selectedVehicleId
    )
    if (!matchingJourney) {
      return []
    }
    return matchingJourney.journeyEvents
  }

  get activeEvents() {
    return this.events.filter((e) => {
      if (!e.alerts.length) {
        return false
      }
      const alert = e.alerts[0]
      if (alert.status === 'ACTIVE') {
        return true
      } else if (
        alert.status === 'SNOOZED' &&
        DateTime.fromISO(alert.snoozedUntil) < DateTime.fromISO(this.currentISO)
      ) {
        return true
      }
      return false
    })
  }

  get eventProps() {
    return this.events.map((e) => ({
      ...e,
      showAlert: this.activeEvents.some((ae) => ae.id === e.id),
    }))
  }

  get shouldHighlightRoute() {
    return !!this.activeEvents.length
  }

  get highlightedRoutes() {
    if (!this.shouldHighlightRoute) {
      return []
    }
    return this.journeyRoutes[0].id
  }

  async handleTriggerAlert(eventId: string) {
    const journey = this.journeys.find((r) =>
      r.journeyEvents.some((e) => e.id === eventId)
    )
    const event = journey.journeyEvents.find((e) => e.id === eventId)
    event.alerts[0].status = 'ACTIVE'
    try {
      const res = await triggerJourneyEventAlert(this.journeyId, eventId)
      if (res.status !== 200) {
        throw new Error('Failed to trigger alert')
      }
    } catch (e) {
      console.error(e)
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Failed to trigger alert',
      })
    }
  }

  async handleDismissAlert(eventId: string) {
    const journey = this.journeys.find((r) =>
      r.journeyEvents.some((e) => e.id === eventId)
    )
    const event = journey.journeyEvents.find((e) => e.id === eventId)
    event.alerts[0].status = 'DISMISSED'
    try {
      const res = await dismissJourneyEventAlert(this.journeyId, eventId)
      if (res.status !== 200) {
        throw new Error('Failed to dismiss alert')
      }
    } catch (e) {
      console.error(e)
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Failed to dismiss alert',
      })
    }
  }

  async handleDismissAllAlerts() {
    const eventIds = this.activeEvents.map((e) => e.id)
    const promises = []
    for (const eventId of eventIds) {
      promises.push(this.handleDismissAlert(eventId))
    }
    await Promise.all(promises)
  }

  async fetchMarkers() {
    // TODO: Call API Here to Fetch Detail Journeys
    await new Promise((resolve) => setTimeout(resolve, 1000))
    this.journeys = mockReferralJourneys
  }

  async mounted() {
    await this.fetchMarkers()
  }

  startTimer() {
    // Update current time every second
    this.timer = setInterval(() => {
      this.currentISO = DateTime.now().toISO()
    }, 1000)
  }

  created() {
    this.startTimer()
  }
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
