var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-charge"},[_c('div',{staticClass:"add-charge--content"},[_c('v-form',{ref:"charge-form"},[_c('div',{staticClass:"add-charge--amount"},[_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"color":"primary","label":"Send Email"},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}}),_c('CRInput',{staticClass:"add-charge--input",attrs:{"id":`add-charge-form-input-amount`,"type":"number","prefix":"$","min":"0","label":Object.keys(_vm.row).length && !_vm.rows.length
              ? 'Amount'
              : 'Amount Per Reservation',"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Amount Is Required',
              error: 'Amount Is Required',
            }),
            (val) => (val <= 0 ? 'Charge Must Be Greater Than 0' : true),
          ]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('CRSelect',{attrs:{"id":`-add-charge-select-charge-type`,"items":_vm.chargeTypes,"label":"Type","item-text":"label","item-value":"id","rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Charge Type Required',
              error: 'Charge Type Required',
            }),
          ]},model:{value:(_vm.chargeType),callback:function ($$v) {_vm.chargeType=$$v},expression:"chargeType"}}),_c('CRRichText',{attrs:{"id":`-add-charge-input-payment-customer-notes`,"multi-line":"","label":"Payment Notes","placeholder":"Payment notes for you and the customer.","color":'primary'},on:{"htmlchange":_vm.updateCustomerNoteHtml,"textchange":_vm.updateCustomerNoteText}}),_c('br'),_c('CRRichText',{staticClass:"mb-3",attrs:{"id":`-add-charge-input-payment-notes`,"multi-line":"","label":"Office Notes","color":'primary',"placeholder":"Payment notes for internal use only (customers will not see this)."},on:{"htmlchange":_vm.updateOfficeNoteHtml,"textchange":_vm.updateOfficeNoteText}}),_c('ClassificationSelector',{ref:"classification-selector",attrs:{"id":"-add-charge-classification-selector","classification-type-id":3},model:{value:(_vm.classificationId),callback:function ($$v) {_vm.classificationId=$$v},expression:"classificationId"}})],1)])],1),_c('div',{staticClass:"add-charge--button-spacer"}),_c('div',{staticClass:"add-charge--submit-btn",attrs:{"id":"add-charge--submit-btn"},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v("Add Charge")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }