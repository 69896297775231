
import { Amenity } from '@/models/Amenity';
import { AmenityTypeId, VehicleTypeId, VehicleTypeKey } from './enum'
import { AmenityType } from '@/models/dto/Amenity';

const AVAILABLE_AMENITIES: Partial<Record<AmenityTypeId, Amenity>> = {
  [AmenityTypeId.Bathroom]: {
    id: AmenityTypeId.Bathroom,
    icon: 'bathroom',
    title: 'Bathroom',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Luggage]: {
    id: AmenityTypeId.Luggage,
    icon: 'luggage',
    title: 'Luggage',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Outlets]: {
    id: AmenityTypeId.Outlets,
    icon: 'power',
    title: 'Outlets',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.TVScreens]: {
    id: AmenityTypeId.TVScreens,
    icon: 'tv',
    title: 'TV Screen',
    included: true,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.Wifi]: {
    id: AmenityTypeId.Wifi,
    icon: 'wifi',
    title: 'Wifi',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.LeatherSeats]: {
    id: AmenityTypeId.LeatherSeats,
    icon: 'airline_seat_recline_extra',
    title: 'Leather Seats',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus, VehicleTypeId.MiniBus],
  },
  [AmenityTypeId.AlcoholConsumption]: {
    id: AmenityTypeId.AlcoholConsumption,
    icon: 'liquor',
    title: 'Alcohol Allowed',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.NineteenACertification]: {
    id: AmenityTypeId.NineteenACertification,
    icon: 'badge',
    title: '19A Certification',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.SeatBelts]: {
      id: AmenityTypeId.SeatBelts,
      icon: 'bus',
      title: 'Seat Belts',
      included: false,
      vehicleTypeIds: [
        VehicleTypeId.CharterBus,
        VehicleTypeId.MiniBus,
        VehicleTypeId.Sprinter,
      ],
  },
  [AmenityTypeId.ADA]: {
    id: AmenityTypeId.ADA,
    icon: 'bus',
    title: 'ADA',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.SPAB]: {
    id: AmenityTypeId.SPAB,
    icon: 'badge',
    title: 'SPAB',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.VehicleStaysOnSite]: {
    id: AmenityTypeId.VehicleStaysOnSite,
    icon: 'bus_alert',
    title: 'Vehicle Stays On Site',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ]
  },
  [AmenityTypeId.AuxBluetooth]: {
    id: AmenityTypeId.AuxBluetooth,
    icon: 'bluetooth',
    title: 'Aux/Bluetooth',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.PASystem]: {
    id: AmenityTypeId.PASystem,
    icon: 'microphone',
    title: 'PA System',
    included: false,
    vehicleTypeIds: [VehicleTypeId.CharterBus],
  },
  [AmenityTypeId.BottledWater]: {
    id: AmenityTypeId.BottledWater,
    icon: 'water_drop',
    title: 'Bottled Water',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.MakeModelYear]: {
    id: AmenityTypeId.MakeModelYear,
    icon: 'description',
    title: 'Make/Model/Year',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.BusColor]: {
    id: AmenityTypeId.BusColor,
    icon: 'palette',
    title: 'Bus Color',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.BusLogos]: {
    id: AmenityTypeId.BusLogos,
    icon: 'grid_view',
    title: 'Bus Logos',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.NonStandard]: {
    id: AmenityTypeId.NonStandard,
    icon: 'bus',
    title: 'Non-Standard Bus',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.TripCoordinators]: {
    id: AmenityTypeId.TripCoordinators,
    icon: 'groups',
    title: 'Trip Coordinators',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
  [AmenityTypeId.BusWraps]: {
    id: AmenityTypeId.BusWraps,
    icon: 'gradient',
    title: 'Bus Wraps',
    included: false,
    vehicleTypeIds: [
      VehicleTypeId.CharterBus,
      VehicleTypeId.MiniBus,
      VehicleTypeId.Sprinter,
    ],
  },
}

/**
 * Retrieves available amenities based on the given vehicle type ID.
 *
 * @param {AmenityTypeId[]} amenities - An array of amenity type IDs to filter from.
 * @param {VehicleTypeId} vehicleTypeId - The vehicle type ID to filter amenities by.
 * @returns {AmenityDetails[]} An array of amenities that are available for the given vehicle type.
 */
export const getAvailableAmenitiesByVehicleTypeId = (
  amenities: AmenityType[],
  vehicleTypeId: VehicleTypeId
): Amenity[] => {
  if (!amenities || !vehicleTypeId) {
    return [];
  }
  const availableAmenities: Amenity[] = [];
  for (const amenity of amenities) {
    const amenityDetail = AVAILABLE_AMENITIES[amenity.id];
    if (amenityDetail && amenityDetail.vehicleTypeIds.includes(vehicleTypeId)) {
      availableAmenities.push({
        ...amenityDetail,
        note: amenity.note
      });
    }
  }
  return availableAmenities;
}


/**
 * Gets the icon name for a given amenity type ID.
 *
 * @param {AmenityTypeId} amenityTypeId - The amenity type ID to get the icon for.
 * @returns {string} The icon name if found; otherwise, an empty string.
 */
export const getAmenityIcon = (amenityTypeId: AmenityTypeId): string => {
  return AVAILABLE_AMENITIES.hasOwnProperty(amenityTypeId)
    ? AVAILABLE_AMENITIES[amenityTypeId].icon
    : '';
}

export const quoteFormAmenities = [
  {
    id: 1,
    key: 'wifi',
    label: 'Wifi'
  },
  {
    id: 4,
    key: 'seat_belts',
    label: 'Seat belts',
  },
  {
    id: 5,
    key: 'ada',
    label: 'ADA',
  },
  {
    id: 6,
    key: 'tv_screens',
    label: 'TV Screens/DVD',
  },
  {
    id: 7,
    key: 'outlets',
    label: 'Power Outlets',
  },
  {
    id: 8,
    key: 'leather_seats',
    label: 'Leather Seats'
  },
  {
    id: 11,
    key: 'spab',
    label: 'SPAB',
  },
  {
    id: 12,
    key: 'alcohol_allowed',
    label: 'Alcohol Allowed'
  },
  {
    id: 14,
    key: '19a_certification',
    label: '19A Certification',
  },
  {
    id: 15,
    key: 'aux_bluetooth',
    label: 'Aux/Bluetooth',
  },
  {
    id: 16,
    key: 'pa_system',
    label: 'PA System',
  },
  {
    id: 17,
    key: 'bottled_water',
    label: 'Bottled Water',
  },
  {
    id: 18,
    key: 'make_model_year',
    label: 'Make/Model/Year of the Bus',
  },
  {
    id: 19,
    key: 'vehicle_color',
    label: 'Color of the Interior/Exterior of the Bus',
  },
  {
    id: 20,
    key: 'vehicle_logos',
    label: 'Logos or Lack Thereof on the Exterior of the Bus',
  },
  {
    id: 21,
    key: 'non_standard_vehicle_size',
    label: 'Non-Standard Bus Size/Passenger Count',
  },
  {
    id: 22,
    key: 'trip_coordinators',
    label: 'Trip Coordinators',
  },
  {
    id: 23,
    key: 'wraps',
    label: 'Bus Wraps',
  }
]

export const sideBarAmenities = [
  ...quoteFormAmenities,
  {
    id: 24,
    key: 'vehicle_stays_on_site',
    label: 'Vehicle stays on site'
  }
]

export const AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS = [
  VehicleTypeKey.CharterBus,
  VehicleTypeKey.MiniBus,
  VehicleTypeKey.Sprinter,
]

export const CHARTER_BUS_INCLUDED_AMENITY_IDS = [
  AmenityTypeId.Bathroom,
  AmenityTypeId.Luggage,
  AmenityTypeId.Outlets,
  AmenityTypeId.TVScreens,
]
