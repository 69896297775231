import axios from 'axios'
import { baseUrl } from '@/utils/env'
export const actions = {
  getTrips(store, params) {
    baseUrl()
    const {
      pageSize = 10,
      page = 1,
      sorts = null,
      filters = null,
      flag = null,
      searchId = null,
      quoteId = null,
      additionalQueries = null,
      markets = [],
    } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    if (searchId) {
      query += `&reservationId=${searchId}`
    }

    if (quoteId) {
      query += `&quoteId=${quoteId}`
    }

    if (additionalQueries) {
      query += `&${additionalQueries}`
    }

    if (flag) {
      const flags = Array.isArray(flag)
        ? flag.map((f) => f + '=true').join('&')
        : flag + '=true'
      query += `&${flags}`
    }

    if (markets.length > 0) {
      query += `&filterMarkets=${markets.join('-')}`
    }
    query = encodeURI(query)

    return axios.get(`/tables/trips?${query}`)
  },
  getTrip(store, tripId) {
    baseUrl()
    return axios.get(`/trips/${tripId}`)
  },
  getTripV3(store, tripId) {
    baseUrl()
    return axios.get(`/v3/trips/${tripId}`)
  },
  modifyTripByStops(store, params) {
    baseUrl()
    return axios.patch(
      `/trips/v2/stops/${params.tripId}?hardConflictOverride=${params.hardConflictOverride}`,
      params.payload
    )
  },
  modifyBidTrip(store, params) {
    baseUrl()
    return axios.patch(
      `/trips/${params.tripId}/updateBidTrip`,
      params.payload
    )
  },
  editTripName(store, params) {
    baseUrl()
    return axios.patch(`/trips/routeName/${params.tripId}`, params.payload)
  },
  getRecurringTripCountFromDate(store, { tripId, date }) {
    baseUrl()
    return axios.get(`/trips/${tripId}/recurringTripCountFromDate?date=${date}`)
  },
  editTripContact(store, params) {
    baseUrl()
    return axios.patch(`/trips/${params.tripId}/tripContact`, params.payload)
  },
  async sendItineraryUpdateAndPostComment(
    store,
    { tripId, referralReservations, payload }
  ) {
    baseUrl()

    let requests = []
    for (const referralReservationId of referralReservations) {
      requests.push(
        axios.post(`/reservations/${referralReservationId}/reservationComments`, {
            reservation: {
              id: referralReservationId,
            },
            note: payload.comments,
        })
      )
    }

    requests.push(
      axios.post(
        `/trips/${tripId}/sendProviderItineraryUpdatedMessage`,
        payload
      )
    )

    return Promise.all(requests)
  },
  async sendAmenitiesUpdateEmail(store, payload) {
    baseUrl()
    return axios.post(`/referralAmenityConfirmation/sendEmail`, payload)
  },
}
