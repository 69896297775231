<template>
  <CRSelect
    :id="`${id}-dot-status-type-filter-select-dot-status-type`"
    v-model="charterUPOperatingStatusTypeSelections"
    :items="charterUPOperatingStatusTypes"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import companies from '@/services/companies'
import { mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
    setReferralFilter: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      charterUPOperatingStatusTypes: [],
      charterUPOperatingStatusTypeSelections: [],
      filter: {
        column: {
          _t_id: 'd9d73f88-3948-11ef-9454-0242ac120002',
          prop: 'charterUPOperatingStatusType/id',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const result = await companies.getCharterUPOperatingStatuses()
    this.charterUPOperatingStatusTypes =
      result?.data?.charterUpOperatingStatuses

    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.charterUPOperatingStatusTypeSelections = rebuiltSelections
            .split(' ')
            .map((selection) => parseInt(selection))
          this.addFilter()
        }
      })
    }

    this.charterUPOperatingStatusTypes
      .filter((t) => t.key !== 'do_not_contact')
      .forEach((t) => {
        if (!this.charterUPOperatingStatusTypeSelections.includes(t.id)) {
          this.charterUPOperatingStatusTypeSelections.push(t.id)
        }
      })
    this.setFilter()
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.charterUPOperatingStatusTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addCharterUPOperatingStatusTypeFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
      this.setReferralFilter(this.charterUPOperatingStatusTypeSelections)
    },
    addCharterUPOperatingStatusTypeFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.charterUPOperatingStatusTypeSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>
