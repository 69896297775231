import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { OperatorAssignmentCallRequest, OperatorAssignmentCallResponse, OperatorAssignmentCallDetail } from '@/models/dto/CallRequest'

const httpService: HttpService = new HttpService()

export const createOperatorAssignmentCall = async (
  payload: OperatorAssignmentCallRequest
): Promise<AxiosResponse<OperatorAssignmentCallResponse>> => {
  const url = `https://${baseUrl()}/tripMonitoring/automatedCalls/requests/operatorPreTrip`
  return httpService.post(url, payload)
}

export const getOperatorAssignmentCallDetail = async (
  callId: string
): Promise<AxiosResponse<OperatorAssignmentCallDetail>> => {
  const url = `https://${baseUrl()}/tripMonitoring/automatedCalls/calls/operatorPreTrip/details/${callId}`
  return httpService.get(url)
}
