
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import TierBadge from '@/components/TierBadge.vue'
import { Tier } from '@/models/Tier'
import customerAccounts from '@/services/customerAccounts'
import { SplitFeatureFlag } from '@/utils/enum'
import { currencyFilter } from '@/utils/string'
import {
  CustomerAccountDefaultValueSet,
  CustomerAccountHierarchy,
} from '@/models/dto'

const PRICING_METHODS_MAP = [
  {
    key: 'single_price',
    label: 'Single Price',
  },
  {
    key: 'bids',
    label: 'Bids',
  },
  {
    key: 'category',
    label: 'Category',
  },
]

@Component({
  components: {
    TierBadge,
  },
})
export default class CustomerAccountCard extends Vue {
  @Prop({ type: Number, default: -1 }) quoteId!: number
  @Prop({ type: Number, required: true }) customerId: number
  @Prop({ type: Object, required: true }) tier: Tier

  customerAccount: any = {}
  customerAccountDefaults: CustomerAccountDefaultValueSet = null
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null
  customerAccountHierarchy: CustomerAccountHierarchy[] = []
  isTiersEnabled = false

  @Watch('customerId')
  onCustomerIdChange() {
    this.loadData()
  }

  get pricingMethod(): string {
    if (this.customerAccountDefaults?.pricingMethodKey) {
      const foundPricingMethod = PRICING_METHODS_MAP.find(
        (pm) => pm.key === this.customerAccountDefaults.pricingMethodKey
      )
      return foundPricingMethod?.label
    }
    if (this.effectiveDefaultValueSet?.pricingMethodKey) {
      const foundPricingMethod = PRICING_METHODS_MAP.find(
        (pm) => pm.key === this.effectiveDefaultValueSet.pricingMethodKey
      )
      return `${foundPricingMethod?.label} (Inherited)`
    }
    return '--'
  }

  get paymentMethodType(): string {
    if (this.customerAccountDefaults?.paymentMethodType) {
      return this.customerAccountDefaults?.paymentMethodType?.label
    }
    if (this.effectiveDefaultValueSet?.paymentMethodType) {
      return `${this.effectiveDefaultValueSet?.paymentMethodType?.label} (Inherited)`
    }
    return '--'
  }

  get paymentTermsDays() {
    if (this.customerAccountDefaults?.paymentTermsDays) {
      return `Net ${this.customerAccountDefaults.paymentTermsDays}`
    }
    if (this.effectiveDefaultValueSet?.paymentTermsDays) {
      return `Net ${this.effectiveDefaultValueSet.paymentTermsDays} (Inherited)`
    }
    return '--'
  }

  get discountPercent() {
    if (this.customerAccountDefaults?.discountPercent) {
      return `${this.customerAccountDefaults.discountPercent}%`
    }
    if (this.effectiveDefaultValueSet?.discountPercent) {
      return `${this.effectiveDefaultValueSet.discountPercent}% (Inherited)`
    }
    return '--'
  }

  get markupPercent(): string {
    if (this.customerAccountDefaults?.markupPercent) {
      return `${this.customerAccountDefaults.markupPercent}%`
    }
    if (this.effectiveDefaultValueSet?.markupPercent) {
      return `${this.effectiveDefaultValueSet.markupPercent}% (Inherited)`
    }
    return '--'
  }

  get margeEnabled(): string {
    if (this.customerAccountDefaults?.needsManualReferral === false) {
      return 'Yes'
    } else if (this.customerAccountDefaults?.needsManualReferral === true) {
      return 'No'
    }
    if (this.effectiveDefaultValueSet?.needsManualReferral === false) {
      return 'Yes (Inherited)'
    } else if (this.effectiveDefaultValueSet?.needsManualReferral === true) {
      return 'No (Inherited)'
    }
    return '--'
  }

  get operatorQualityMin(): string {
    if (this.customerAccountDefaults?.minQuality) {
      return this.customerAccountDefaults.minQuality.toString()
    }
    if (this.effectiveDefaultValueSet?.minQuality) {
      return `${this.effectiveDefaultValueSet.minQuality} (Inherited)`
    }
    return '--'
  }

  get operatorQualityMinIsSet(): boolean {
    return (
      this.customerAccountDefaults?.minQuality !== null ||
      this.effectiveDefaultValueSet?.minQuality !== null
    )
  }

  get hierarchyDisplay(): string {
    return (this.customerAccountHierarchy || []).map((h) => h.name).join(' > ')
  }

  get showTierMarker(): boolean {
    if (this.tier) {
      return this.tier?.level > 1
    }
    return false
  }

  get annualContractValue(): string {
    const acv = this.customerAccount?.salesforceAccount?.annualContractValue
    return acv ? currencyFilter(acv) : '--'
  }

  get totalContractValue(): string {
    const tcv = this.customerAccount?.salesforceAccount?.totalContractValue
    return tcv ? currencyFilter(tcv) : '--'
  }

  async mounted(): Promise<void> {
    await this.loadData()
    this.isTiersEnabled = await this.$store.dispatch(
      'split/isFeatureEnabled',
      SplitFeatureFlag.ServiceTier
    )
  }

  async loadData(): Promise<void> {
    if (!this.customerId) {
      return
    }
    const response = await customerAccounts.getCustomerAccountCard(
      this.quoteId || -1,
      this.customerId
    )
    this.customerAccount = response?.data?.customerAccount
    this.customerAccountDefaults =
      response?.data?.customerAccountDefaultValueSet
    this.effectiveDefaultValueSet = response?.data?.effectiveDefaultValueSet
    this.customerAccountHierarchy = response?.data?.customerAccountHierarchy
  }
}
