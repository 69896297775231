import { render, staticRenderFns } from "./ReservationSendAmenitiesUpdate.vue?vue&type=template&id=1036c3d5&scoped=true"
import script from "./ReservationSendAmenitiesUpdate.vue?vue&type=script&lang=js"
export * from "./ReservationSendAmenitiesUpdate.vue?vue&type=script&lang=js"
import style0 from "./ReservationSendAmenitiesUpdate.vue?vue&type=style&index=0&id=1036c3d5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1036c3d5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})
