<template>
  <div class="reservation-cancel">
    <div class="reservation-cancel--content">
      <ClassificationSelector
        id="reservation-cancellation-classification-selector"
        ref="classification-selector"
        v-model="classificationId"
        :initial-classification="classificationId"
        :classification-type-id="2"
      />
      <CRTextArea
        id="reservation-cancellation-notes-text-area"
        :value="ticket.comments"
        placeholder="No comments"
        disabled
        auto-grow
        hide-details
      />
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div
      id="reservation-cancellation-cancel-button"
      class="cr-sidebar-dialog--action-btn"
      @click="update"
    >
      <span v-if="!loading">Update</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import ClassificationSelector from '@/components/ClassificationSelector.vue'
import { EventBus } from '@/utils/event-bus'
import ticket from '@/services/ticket'

export default {
  components: {
    ClassificationSelector,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
    cancellationClassificationId: {
      type: Number,
      default: null,
    },
    reservationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      classificationId: null,
    }
  },
  mounted() {
    this.classificationId = this.cancellationClassificationId
  },
  methods: {
    close() {
      this.$store.dispatch('app/closeDialog') // TODO: make this vuex's concern. Put in 'reservations'.
    },
    async update() {
      const payload = {
        reservationId: this.reservationId,
        classificationId: this.classificationId,
        setCancellationClassification: true,
        reservationId: this.reservationId
      }
      await ticket
        .partialUpdate({
          id: this.ticket.ticketId,
          payload,
        })
        .catch((error) => {
          this.loading = false
          this.displayWarning = true
          this.warningMessage = error
          return false
        })
      this.$store.dispatch(
        'app/showAlert',
        { message: 'Ticket updated.' },
        { root: true }
      )
      this.loading = false
      this.$store.dispatch('app/showAlert', {
        color: 'success',
        message: 'Cancellation has been updated.',
      })
      EventBus.$emit('refresh-detail')
      this.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-cancel {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 20px 48px;
  }
}
</style>
