
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import BidCardAmenities from '@/components/charterup/BidCardAmenities.vue'
import bids from '@/services/bids'
import { hostBaseUrl } from '@/utils/env'
import { deepClone } from '@/utils/deepClone'
import fallBackImage from '@/assets/images/charterup/placeholder_bus.jpg'
import { certifiedPartnerModalDetail } from '@/utils/infoModal'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import InfoModal from './InfoModal.vue'
import CRIcon from '@/cr/components/CRIcon.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

const BRONZE_PROVIDER = 'Bronze'

interface ClassStyles {
  'border-radius': string;
  margin?: string;
  'z-index'?: string;
}

@Component({
  components: {
    BidCardAmenities,
    InfoModal,
    CRIcon,
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
  },
})
export default class BidCard extends Vue {
  @Prop({ type: Boolean }) stacked!: boolean;
  @Prop({ type: Number, default: 320 }) imgwidth!: number;
  @Prop({ type: Object, required: true }) bid: Record<string, any>;
  @Prop({ type: Boolean }) featured!: boolean;
  @Prop({ type: Boolean }) button!: boolean;
  @Prop({ type: Object, default: null }) goTo!: Record<string, any>;
  @Prop({ type: Boolean, default: false }) isQuoteExpired!: boolean;
  @Prop({ type: Boolean, default: false }) charterUpChoiceStyling!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) isBrandedQuoteFlowEnabled: boolean;

  loading: boolean = false

  fallBackImage: any = fallBackImage
  aspectRatio: number = 1.87
  certifiedPartnerModalDetail: InfoModalDetail = certifiedPartnerModalDetail

  @Watch('bid')
  onBidChanged() {
    if (!!this.firstBidId) {
      this.loading = false
    }
  }

  get firstBidId(): number {
    return this.bid.bidIds?.[0]
  }

  get imageClass(): ClassStyles {
    const _class: ClassStyles = this.stacked
      ? {
          'border-radius': '4px 4px 0px 0px',
        }
      : {
          'border-radius': '4px 0px 0px 4px',
        };
    _class.margin = '0px';
    _class['z-index'] = '0';
    return _class;
  }

  get isBidAvailable(): boolean {
    return this.goTo && !this.bid.soldOut && !this.isBidRejected
  }

  get vehiclePhotos(): any[] {
    return deepClone(this.bid.vehiclePhotos).sort(
      (a, b) => a.vehicleTypeId - b.vehicleTypeId
    )
  }

  get companyPhotoPath(): string {
    const path = this.vehiclePhotos?.[0]?.imagePath
    if (!path) {
      return fallBackImage
    }
    if (path?.[0] !== '/') {
      return path
    }
    return `${hostBaseUrl()}${path}`
  }

  get rating(): string {
    // @ts-ignore
    return this.$cr.breakpoint.smAndUp && this.bid.rating
      ? this.bid.rating
      : ''
  }

  get hasDiscount(): boolean {
    return !!this.bid.discountPercent
  }

  get savingsAmount(): number {
    if (!this.hasDiscount) return 0
    return Math.ceil(this.bid.subtotalAmount) - Math.ceil(this.bid.totalAmount)
  }

  get isBronze(): boolean {
    return this.bid.partnerType === BRONZE_PROVIDER
  }

  get isBidConverted(): boolean {
    return this.bid.isConverted || false
  }

  get isBidRejected(): boolean {
    return this.bid.bidStatusId === 3
  }

  get operatorQualityDisplay(): string {
    return this.bid.companyQuality !== null
      ? `Op Quality: ${this.bid.companyQuality}`
      : ''
  }

  get quoteQualityDisplay(): string {
    return this.bid.quoteMinQuality !== null
      ? `Cust Min Quality: ${this.bid.quoteMinQuality}`
      : ''
  }

  get displayAmenities(): boolean {
    return !(this.bid.featured && this.bid.reseller) || !this.isBrandedQuoteFlowEnabled
  }

  get shouldDisplayCompanyLogo(): boolean {
    return this.bid.featured && this.bid.reseller && !this.stacked && this.isBrandedQuoteFlowEnabled && !!this.companyLogo
  }

  get flipRatingAndAmenities(): boolean {
    return this.bid.featured && this.bid.reseller && !this.stacked && this.isBrandedQuoteFlowEnabled
  }

  get companyLogo(): string {
    const path = this.bid.lightLogoUrl
    if (!path) {
      return ''
    }
    return `${hostBaseUrl()}${path}`
  }

  get dottedBorderStyle(): string {
    if (this.stacked || !this.isBrandedQuoteFlowEnabled) {
      return 'none'
    } else if (this.featured) {
      return '3px dotted'
    }
    return '1px dotted'
  }

  selectBid(): void {
    if (this.loading) {
      console.warn('Cannot select bid because data is loading, refresh the page if this takes too long.')
    }

    if (this.isBidAvailable) {
      this.$router.push(this.goTo)
    }
  }

  async rejectBid(): Promise<void> {
    try {
      await bids.rejectBids(this.bid.bidIds)
      this.loading = true
      this.$emit('refresh-bid-list')
    } catch (e) {
      console.error('Unable to reject a bid due to an error.')
    }
  }

  async restoreBid(): Promise<void> {
    try {
      await bids.restoreBids(this.bid.bidIds)
      this.loading = true
      this.$emit('refresh-bid-list')
    } catch (e) {
      console.error('Unable to restore a bid due to an error.')
    }
  }

}
