import { render, staticRenderFns } from "./CRInput.vue?vue&type=template&id=5f967a42&scoped=true"
import script from "./CRInput.vue?vue&type=script&lang=js"
export * from "./CRInput.vue?vue&type=script&lang=js"
import style0 from "./CRInput.vue?vue&type=style&index=0&id=5f967a42&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f967a42",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAutocomplete,VDatePicker,VMenu,VTextField,VTimePicker,VTooltip})
