export const generateBusIcon = (hasAlert: boolean, heading: number = 0) => {
  const svg = `
    <svg
      width="45px"
      height="45px"
      viewBox="-250 -250 1500 1500"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="49.9644712%"
          y1="98.6616025%"
          x2="50.0657049%"
          y2="-47.900147%"
        >
          <stop stop-color="#5C5C5C" offset="0%"></stop>
          <stop stop-color="#979797" offset="10.42%"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-2"
          x1="49.9996089%"
          y1="105.263885%"
          x2="49.9996089%"
          y2="25.884696%"
        >
          <stop stop-color="#000000" offset="31.11%"></stop>
          <stop stop-color="#37475E" offset="100%"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-3"
          x1="-0.00411231654%"
          y1="49.9985445%"
          x2="99.9948259%"
          y2="49.9985445%"
        >
          <stop stop-color="#000000" offset="31.11%"></stop>
          <stop stop-color="#37475E" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          cx="500"
          cy="500"
          r="500"
        />
        <g id="0" transform="rotate(${heading} 500 500)">
          <polygon
            id="Path"
            fill="url(#linearGradient-1)"
            points="914.09 292.72 957.59 225.79 61.3 235.05 19.76 303.17 852.86 307.41"
          ></polygon>
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="66.81"
            y="233.98"
            width="9.83"
            height="5.62"
          ></rect>
          <path
            id="Path"
            d="M57.03,1.26 L0.3,85.76 C0.3,85.76 4.63,288.61 19.41,303.41 L71.39,234.34 L57.03,1.26 Z"
            fill="#FFFFFF"
          ></path>
          <path
            id="Path"
            d="M957.59,225.79 L79.93,234.91 C71.51,235 63.67,239.42 59.28,246.61 L37.21,279.97 C43.43,280.58 91.88,285.02 105.92,264.95 L926.59,273.48 L957.59,225.79 Z"
            fill="url(#linearGradient-2)"
          ></path>
          <path
            id="Path"
            d="M921.71,4.42 C934,4.78 944.61,13.13 947.85,24.99 C956.2,55.57 962.46,180.11 960.59,215.26 C959.82,229.73 947.8,241.03 933.31,240.91 L60.7,235.4 C60.34,235.62 57.73,0.81 56.98,0.81 L921.71,4.42 Z"
            fill="#FFFFFF"
          ></path>
          <path
            id="Path"
            d="M57.05,5.96 L20.5,59.41 C16.14,67.31 14.11,76.28 14.64,85.28 L26.05,271.14 C26.35,276.32 33.23,277.91 35.77,273.39 C44.58,257.74 60.64,231.49 66.76,231.49 C67.47,204.43 57.05,5.96 57.05,5.96 Z"
            fill="url(#linearGradient-3)"
          ></path>
          <ellipse
            id="Oval"
            fill="#110C0A"
            cx="156.71"
            cy="304.12"
            rx="38.1"
            ry="16.09"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#110C0A"
            cx="721.36"
            cy="304.12"
            rx="38.1"
            ry="16.09"
          ></ellipse>
          <ellipse
            id="Oval"
            fill="#110C0A"
            cx="801.83"
            cy="304.12"
            rx="38.1"
            ry="16.09"
          ></ellipse>
        </g>
      </g>
    </svg>
  `

  const alertSvg = `
  <svg width="51" height="60" viewBox="-10 -10 54 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19" cy="15" r="14.5" stroke="#FF6161"/>
    <circle cx="19" cy="15" r="12" fill="#EFC4C6"/>
    <g clip-path="url(#clip0_0_1)" transform="rotate(${heading} 15 15)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4227 18.7816L28.7277 16.7737L1.83897 17.0515L0.592773 19.0951L25.5858 19.2223L27.4227 18.7816Z" fill="url(#paint0_linear_0_1)"/>
      <path d="M2.29917 17.0194H2.00427V17.188H2.29917V17.0194Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.71087 10.0378L0.00897217 12.5728C0.00897217 12.5728 0.138872 18.6583 0.582272 19.1023L2.14167 17.0302L1.71087 10.0378Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7277 16.7737L2.39787 17.0473C2.14527 17.05 1.91007 17.1826 1.77837 17.3983L1.11627 18.3991C1.30287 18.4174 2.75637 18.5506 3.17757 17.9485L27.7977 18.2044L28.7277 16.7737Z" fill="url(#paint1_linear_0_1)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6513 10.1326C28.02 10.1434 28.3382 10.3939 28.4354 10.7497C28.6859 11.6671 28.8738 15.4033 28.8177 16.4578C28.7946 16.8919 28.434 17.2309 27.9993 17.2273L1.82095 17.062C1.81015 17.0686 1.73185 10.0243 1.70935 10.0243L27.6513 10.1326Z" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.71148 10.1788L0.614983 11.7823C0.484183 12.0193 0.423283 12.2884 0.439183 12.5584L0.781483 18.1342C0.790483 18.2896 0.996883 18.3373 1.07308 18.2017C1.33738 17.7322 1.81918 16.9447 2.00278 16.9447C2.02408 16.1329 1.71148 10.1788 1.71148 10.1788Z" fill="url(#paint2_linear_0_1)"/>
      <path d="M4.70129 19.6063C5.33255 19.6063 5.84429 19.3902 5.84429 19.1236C5.84429 18.857 5.33255 18.6409 4.70129 18.6409C4.07003 18.6409 3.55829 18.857 3.55829 19.1236C3.55829 19.3902 4.07003 19.6063 4.70129 19.6063Z" fill="#110C0A"/>
      <path d="M21.6408 19.6063C22.2721 19.6063 22.7838 19.3902 22.7838 19.1236C22.7838 18.857 22.2721 18.6409 21.6408 18.6409C21.0095 18.6409 20.4978 18.857 20.4978 19.1236C20.4978 19.3902 21.0095 19.6063 21.6408 19.6063Z" fill="#110C0A"/>
      <path d="M24.0549 19.6063C24.6861 19.6063 25.1979 19.3902 25.1979 19.1236C25.1979 18.857 24.6861 18.6409 24.0549 18.6409C23.4236 18.6409 22.9119 18.857 22.9119 19.1236C22.9119 19.3902 23.4236 19.6063 24.0549 19.6063Z" fill="#110C0A"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_0_1" x1="1406.34" y1="258.357" x2="1406.36" y2="-100.515" gradientUnits="userSpaceOnUse">
        <stop stop-color="#5C5C5C"/>
        <stop offset="0.1042" stop-color="#979797"/>
      </linearGradient>
      <linearGradient id="paint1_linear_0_1" x1="1381.68" y1="190.513" x2="1381.68" y2="59.4967" gradientUnits="userSpaceOnUse">
        <stop offset="0.3111"/>
        <stop offset="1" stop-color="#37475E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_0_1" x1="0.430262" y1="415.291" x2="157.14" y2="415.291" gradientUnits="userSpaceOnUse">
        <stop offset="0.3111"/>
        <stop offset="1" stop-color="#37475E"/>
      </linearGradient>
      <clipPath id="clip0_0_1">
        <rect width="30" height="30" fill="white" transform="translate(0 10)"/>
      </clipPath>
    </defs>
  </svg>`

  const icon = {
    url: 'data:image/svg+xml,' + encodeURIComponent(hasAlert ? alertSvg : svg),
  }

  return icon
}
