<template>
  <div>
    <label v-if="label" :for="id">{{ label }}</label>
    <v-autocomplete
      :id="`${id}-auto-complete-user-selected-user`"
      :value="selectedUser"
      item-value="userId"
      ref="autocomplete"
      class="user-autocomplete"
      :label="placeholder"
      :items="userList"
      :loading="loading && !disabled"
      :filter="filterUsers"
      :disabled="disabled"
      :hide-details="hideDetails"
      :clearable="clearable"
      append-icon="keyboard_arrow_down"
      return-object
      solo
      flat
      @input="selectUser"
      @click:clear="$emit('user-selected', null)"
    >
      <template v-if="useSvgIcons" #append>
        <CRIcon
          height="24"
          width="24"
          color="primary"
          material
          class="transition-all transition-duration-300"
          :class="autocompleteClasses"
        >
          keyboard_arrow_down
        </CRIcon>
      </template>
      <template slot="selection" slot-scope="data">
        <v-avatar v-if="false" size="24px">
          <img src="https://place-hold.it/100" alt="Avatar" />
        </v-avatar>
        <CRIcon
          v-if="true"
          color="primary"
          class="avatar"
          style="margin-right: 10px"
        >
          my_account
        </CRIcon>
        {{ `${data.item.firstName} ${data.item.lastName}` }}
      </template>
      <template slot="item" slot-scope="data">
        <v-list-tile-avatar>
          <img v-if="false" src="https://place-hold.it/100" alt="Avatar" />
          <CRIcon v-if="true" color="primary" class="avatar">my_account</CRIcon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>
            {{ `${data.item.firstName} ${data.item.lastName}` }}
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
/*
    This component will need some refactoring if this issue is ever resolved:
    https://github.com/vuetifyjs/vuetify/issues/4169
   */
import { filter } from '@/utils/filter'

const CHARTERUP_COMPANY_ID = 2

export default {
  props: {
    autoFocus: Boolean,
    initialUser: {
      type: Number,
      default: () => undefined,
    },
    label: {
      type: String,
      default: () => '',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    id: {
      type: String,
      default: null,
    },
    areTicketUsers: {
      type: Boolean,
      default: false,
    },
    areAccountExecutives: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    useSvgIcons: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      debounce: undefined,
      userList: [],
      selectedUser: {},
      isAutocompleteMenuOpen: false,
    }
  },
  watch: {
    initialUser(newValue, oldValue) {
      this.setInitialUser(newValue)
    },
  },
  computed: {
    autocompleteClasses() {
      return {
        'transform-rotate-180': this.isAutocompleteMenuOpen,
      }
    },
  },
  async mounted() {
    this.loading = true

    const adminFilterObject = {
      column: {
        _t_id: 'admin_data_search_id',
        prop: 'group.groupId',
        filterType: 'in',
      },
      value: '1,2', // Admin & Users (not Driver, Anonymous, or Customer)
    }
    const assignTicketsFilter = {
      column: {
        _t_id: 'role_can_be_assigned_ticket',
        prop: 'roles.roleName',
        filterType: 'eq',
      },
      value: 'can_be_assigned_tickets',
    }
    const charterupCompanyFilter = {
      column: {
        _t_id: 'company_id',
        prop: 'companyId',
        filterType: 'eq',
      },
      value: CHARTERUP_COMPANY_ID,
    }
    const accountExecutiveFilter = {
      column: {
        _t_id: 'role_account_executive',
        prop: 'roles.roleName',
        filterType: 'eq',
      },
      value: 'is_account_executive',
    }

    // This creates a "root" filter to which all the following filters are attached
    const staffFilter = filter()
    const parentFilter = staffFilter.createParent('and')

    // This component only supports charterup company users
    staffFilter.add(parentFilter, charterupCompanyFilter)

    // For ticket users, we only want admins AND users who can assign tickets
    if (this.areTicketUsers) {
      staffFilter.add(parentFilter, assignTicketsFilter)
      staffFilter.add(parentFilter, adminFilterObject)
    }

    // For account executives
    if (this.areAccountExecutives) {
      staffFilter.add(parentFilter, accountExecutiveFilter)
    }

    // Fire of the request/filter to table view service via the users module
    const staffData = await this.$store.dispatch('users/getUsers', {
      page: 1,
      pageSize: -1,
      filters: staffFilter.asQueryParams(),
    })

    this.userList = staffData.data.resultList || []
    if (this.initialUser) {
      this.setInitialUser(this.initialUser)
    }

    if (!this.useSvgIcons && this.type !== 'autocomplete') {
      return
    }
    this.$watch(
      () => {
        return this.$refs.autocomplete?.$refs?.menu?.$props?.value
      },
      (val) => {
        this.isAutocompleteMenuOpen = val
      }
    )

    this.loading = false
  },
  methods: {
    filterUsers(item, queryText) {
      if (!queryText) {
        this.$emit('user-cleared')
        return null
      }

      const first = queryText.split(' ')[0]
      const last = queryText.split(' ')[1]

      const queryMatchesFirst =
        item.firstName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      const queryMatchesLast =
        item.lastName
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1
      const splitMatchesFirst =
        first?.length &&
        item.firstName.toLocaleLowerCase().indexOf(first.toLocaleLowerCase()) >
          -1
      const splitMatchesLast =
        last?.length &&
        item.lastName.toLocaleLowerCase().indexOf(last.toLocaleLowerCase()) > -1

      const basicMatching = queryMatchesFirst || queryMatchesLast
      if (first?.length && last?.length) {
        return basicMatching || (splitMatchesFirst && splitMatchesLast)
      } else if (first?.length) {
        return basicMatching || splitMatchesFirst
      } else if (last?.length) {
        return basicMatching || splitMatchesLast
      }
      return basicMatching
    },
    selectUser(user) {
      if (!user) {
        return
      }
      this.$emit('user-selected', user)
    },
    setInitialUser(userId) {
      this.selectedUser = this.userList.find((user) => {
        return user.userId === userId
      })
    },
  },
}
</script>

<style lang="scss">
.avatar {
  margin-right: 10px;
}

.menu-parent {
  position: relative;
}

.menu-child {
  position: absolute;
  top: 50px;
  z-index: 500;
  width: 100%;
}
</style>
