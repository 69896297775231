import { QuoteFormTripStop, QuoteFormTripStopAddress } from "@/models/QuoteForm";
import { Address } from "@/models/dto";

/**
 * Validates an email address.
 * @param v - The email address string to validate.
 * @returns `true` if the email is valid or the string is empty, otherwise `false`.
 */
export const validateEmail = (v: string): boolean => {
  // not interested in the format of empty strings
  if (!v?.trim()) {
    return true;
  }

  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i.test(v);
};

/**
 * Validates a list of email addresses separated by commas.
 * @param v - The string containing the list of email addresses.
 * @returns `true` if all emails in the list are valid or the input is null, otherwise `false`.
 */
export const validateEmailList = (v: string | null): boolean => {
  if (!v) {
    return true;
  }
  const emailList = v.split(',');
  const validation: boolean[] = [];
  for (const email of emailList) {
    validation.push(validateEmail(email.trim()));
  }
  return !validation.some((validity) => validity === false);
};

/**
 * Validates a phone number.
 * @param v - The phone number string to validate.
 * @returns `true` if the phone number is valid, otherwise `false`.
 */
export const validatePhone = (v: string): boolean => {
  return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*((\d{4})(?: *x(\d+))?)\s*$/.test(v);
};

/**
 * Checks if the provided value is a number.
 * @param val - The value to check.
 * @returns `true` if the value is a number, otherwise `false`.
 */
export const isNumber = (val: any): boolean =>
  typeof Number(val) === 'number' && !isNaN(Number(val));

/**
 * Validates that the provided address object has valid latitude and longitude values.
 * Checks if the address object exists and if both latitude (`lat`) and longitude (`lng`) are truthy (not zero or undefined).
 *
 * @param val - The address object to validate.
 * @returns `true` if the address has valid latitude and longitude, `false` otherwise.
 */
export const validateAddress = (val: Address): boolean => {
  const isAddressValid = (value: Address): boolean => {
    const addressValidationResult = !!(value && value.lat && value.lng);
    return addressValidationResult;
  };

  return isAddressValid(val);
};

/**
 * Creates a validator function to check if a property of an object matches a given value.
 * @param firstVal - The object containing the property to match.
 * @param prop - The property name within the object to compare against.
 * @returns A function that takes a second value and returns `true` if it matches the property of the first object, otherwise returns an error message.
 */
export const shouldMatch = <T, K extends keyof T>(firstVal: T, prop: K) => {
  return function (secondVal: T[K]): boolean | string {
    return secondVal === firstVal[prop] || 'Passwords must match';
  };
};

/**
 * Validates that a given value is a number greater than zero.
 * @param val - The value to validate.
 * @returns `true` if the value is a number greater than zero, otherwise `false`.
 */
export const validateGreaterThanZero = (val: any): boolean => {
  const isGreaterThanZero = (value: any): boolean => {
    const number = Number(value);
    return !isNaN(number) && number > 0;
  };
  return isGreaterThanZero(val);
};

/**
 * Validates that a given value is a number less than zero.
 * @param val - The value to validate.
 * @returns `true` if the value is a number less than zero, otherwise `false`.
 */
export const validateLessThanZero = (val: any): boolean => {
  const isLessThanZero = (value: any): boolean => {
    const number = Number(value);
    return !isNaN(number) && number < 0;
  };
  return isLessThanZero(val);
};

/**
 * Checks if a value is not empty. Supports strings, arrays, numbers, booleans, dates, and objects.
 * @param val - The value to check.
 * @returns `true` if the value is not considered empty, `false` otherwise.
 */
export const isNotEmpty = (val: any): boolean => {
  const isItNotEmpty = (value: any): boolean => {
    if (value === null || typeof value === 'undefined') {
      return false;
    }
    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
      return true;
    }
    if (value instanceof Date) {
      return true;
    }
    if (typeof value === 'object' && Object.keys(value).length > 0) {
      return true; // Checks if object has at least one property
    }
    return false; // Return false for empty objects
  };
  return isItNotEmpty(val);
};


/**
 * Checks if the provided value is an array and is not empty.
 * @param val - The value to check, expected to be an array.
 * @returns `true` if the value is a non-empty array, otherwise `false`.
 */
export const isNotEmptyArray = (val: any): boolean => {
  if (!Array.isArray(val)) {
    return false; // Immediately return false if the input is not an array
  }
  return val.length > 0; // Return true if the array is not empty
};

// TODO: come back to this one
/**
 * Generates a function to validate a field based on its status, using a custom validation function and error messages.
 * @param status - Boolean indicating whether the field is required.
 * @param fn - A validation function that takes the value of the field and returns `true` if valid or a string error message if not.
 * @param messages - An object containing error messages for different validation states.
 * @returns A function that validates the given value and returns appropriate error messages or an empty string if valid.
 */
export const isRequired = <T>(
  status: boolean,
  fn: (val: T) => boolean | string,
  messages: { error?: string; req?: string }
) => {
  const isEmpty = (value: T): boolean => {

    if (typeof value === 'undefined') {
      return true;
    }

    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0;
    }
    if (['number', 'boolean'].includes(typeof value)) {
      return false;
    }
    if (value instanceof Date) {
      return false;
    }

    return typeof value === 'object' && value !== null ? false : true;
  };

  return function (val: T): string {
    const isItEmpty = isEmpty(val);

    if (!status) {
      return (fn(val) as string) || 'Field is not valid';
    }

    if (status && !isItEmpty) {
      return (fn(val) as string) || messages.error || 'Field is not valid';
    }

    if (status && isItEmpty) {
      return messages.req || 'Field is required';
    }

    return '';
  };
};

/**
 * Validates the stop dates in a trip. Ensures that pickup and dropoff dates are logically ordered
 * and that each stop follows the previous one chronologically.
 * @param stops An array of `QuoteFormTripStop` representing the stops in a trip.
 * @param index The index of the current stop to validate.
 * @returns `true` if the current stop's dates are valid, `false` otherwise.
 */
export const validateStopDate = (stops: QuoteFormTripStop[], index: number): boolean => {
  if (index === 0) {
    return true;
  }

  const stop = stops[index];
  const prevStop = stops[index - 1];

  const stopDropoff = stop.dropoffDate !== '' ? stop.dropoffDate : null;
  const stopPickup = stop.pickupDate !== '' ? stop.pickupDate : null;
  const prevStopDropoff = prevStop.dropoffDate !== '' ? prevStop.dropoffDate : null;
  const prevStopPickup = prevStop.pickupDate !== '' ? prevStop.pickupDate : null;

  if (stopPickup && stopDropoff) {
    // Check if pickup is after or equal to dropoff
    if (stopPickup < stopDropoff) {
      return false;
    }
  }

  if (prevStop && stopPickup) {
    // Check if pickup is after or equal to the previous stop's latest time
    const previousStopLatestTime = prevStopPickup ?? prevStopDropoff;
    if (stopPickup < previousStopLatestTime) {
      return false;
    }
  } else if (prevStop && stopDropoff) {
    // Check if dropoff is after or equal to the previous stop's latest time
    const previousStopLatestTime = prevStopPickup ?? prevStopDropoff;
    if (stopDropoff < previousStopLatestTime) {
      return false;
    }
  }

  return true;
};

/**
 * Validates the stop times in a trip to ensure that each stop follows the previous one chronologically,
 * taking both date and time into consideration.
 * @param stops An array of `QuoteFormTripStop` representing the stops in a trip.
 * @param index The index of the current stop to validate.
 * @returns `true` if the current stop's times are valid, `false` otherwise.
 */
export const validateStopTime = (stops: QuoteFormTripStop[], index: number): boolean => {
  if (index === 0) {
    return true
  }
  const stop = stops[index]
  const prevStop = stops[index - 1]

  const stopDropoff =
    stop.dropoffDate !== undefined && stop.dropoffDate !== null && stop.dropoffDate !== ''
      ? stop.dropoffTime !== undefined && stop.dropoffTime !== null && stop.dropoffTime !== ''
        ? stop.dropoffDate + 'T' + stop.dropoffTime
        : stop.dropoffDate
      : null

  const stopPickup =
    stop.pickupDate !== undefined && stop.pickupDate !== null && stop.pickupDate !== ''
      ? stop.pickupTime !== undefined && stop.pickupTime !== null && stop.pickupTime !== ''
        ? stop.pickupDate + 'T' + stop.pickupTime
        : stop.pickupDate
      : null

  const prevStopDropoff =
    prevStop.dropoffDate !== undefined && prevStop.dropoffDate !== null && prevStop.dropoffDate !== ''
      ? prevStop.dropoffTime !== undefined && prevStop.dropoffTime !== null && prevStop.dropoffTime !== ''
        ? prevStop.dropoffDate + 'T' + prevStop.dropoffTime
        : prevStop.dropoffDate
      : null

  const prevStopPickup =
    prevStop.pickupDate !== undefined && prevStop.pickupDate !== null && prevStop.pickupDate !== ''
      ? prevStop.pickupTime !== undefined && prevStop.pickupTime !== null && prevStop.pickupTime !== ''
        ? prevStop.pickupDate + 'T' + prevStop.pickupTime
        : prevStop.pickupDate
      : null

  if (stopPickup !== null && stopDropoff !== null) {
    // Check if pickup is after or equal to dropoff
    if (stopPickup < stopDropoff) {
      return false
    }
  }

  if (prevStop !== null && stopPickup !== null) {
    // Check if pickup is after or equal to the previous stop's latest time
    const previousStopLatestTime = prevStopPickup ?? prevStopDropoff
    if (stopPickup < previousStopLatestTime) {
      return false
    }
  } else if (prevStop !== null && stopDropoff !== null) {
    // Check if dropoff is after or equal to the previous stop's latest time
    const previousStopLatestTime = prevStopPickup ?? prevStopDropoff
    if (stopDropoff < previousStopLatestTime) {
      return false
    }
  }
  return true
}

/**
 * Validates the the input is of type number or only contains numeric characters.
 * @param val An string or number.
 * @returns `true` if the value is a number or represents a number as a string.
 */
export const isValidNumber = (val: string | number): boolean => {
  return !!val && (typeof val === 'number' || (typeof val === 'string' && !!val.trim().match(/^-?[0-9]+(\.[0-9]+)?$/)));
}

// TODO: build out tests and docs
export const validateAddressCountry = (address: QuoteFormTripStopAddress): boolean => {
  return !!address && !!address.country
}

// TODO: build out tests and docs
export const validateAddressState = (address: QuoteFormTripStopAddress): boolean => {
  return !!address && !!address.state
}

// TODO: build out tests and docs
export const validateAddressPostalCode = (address: QuoteFormTripStopAddress): boolean => {
  return !!address && !!address.postalCode
}

// TODO: this is a unique case where it's really just trying to call other validation functions on a series of forms
export function validateTripFormGroups(formNames = [], moduleType) {
  const fiveMinutes = 300000
  const firstTick = new Date()
  if (this.debounceValidator) {
    clearTimeout(this.debounceValidator)
  }
  this.formSubmitted = true
  this.$nextTick(() => {
    const validateAllForms = () => {
      const nextTick = new Date()
      const validationResults = []
      for (const formName of formNames) {
        const formList = this.$refs[formName]
        if (formList) {
          if (Array.isArray(formList)) {
            for (const formListItem of formList) {
              validationResults.push(formListItem.validate())
            }
          } else {
            validationResults.push(formList.validate())
          }
        }
      }

      const hasFailures = validationResults.some((v) => v === false)
      this.$emit('validation-results', {
        tripIndex: this.tripIndex,
        tripModule: moduleType,
        hasFailures,
      })
      this.$nextTick(() => {
        const tickDiff = (nextTick as any) - (firstTick as any)
        if (tickDiff < fiveMinutes) {
          this.debounceValidator = setTimeout(() => {
            validateAllForms()
          }, 5000)
        }
      })
    }
    validateAllForms()
  })
}

/**
 * Validates that the length of the value does not exceed the specified maximum length.
 * @param val - The value to check, expected to support a length property (e.g., string or array).
 * @param maxLength - The maximum allowed length.
 * @returns `true` if the value's length is less than or equal to the maximum length, otherwise `false`.
 */
export const validateLength = (val: { length: number }, maxLength: number): boolean => {
  const isWithinMaxLength = (value: { length: number }): boolean => {
    return value?.length <= maxLength;
  };
  return isWithinMaxLength(val);
};
