<template>
  <v-container fluid :class="hasCallCenter ? 'call-center-margin' : ''">
    <v-layout column sheet>
      <v-layout row justify-space-between style="margin-right: 100px">
        <h1 class="page-header">Operators</h1>
        <CRButton color="primary" @click="$emit('toggle-mode')">
          View Map
        </CRButton>
      </v-layout>
      <DataTable v-bind.sync="tableProps" />
      <v-dialog v-model="actionsDialog" width="600px">
        <AffiliatesViewDetail
          v-if="dialogType === 'viewDetail'"
          id="client-payment-list"
          :rows="selectedRows"
          @close-modal="closeDialog"
        />
        <AffiliatesViewDetail
          v-if="dialogType === 'viewRateDetail'"
          id="client-payment-list"
          :rows="selectedRows"
          @close-modal="closeDialog"
        />
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import AffiliatesViewDetail from '@/components/affiliatesViewDetail.vue'
import AffiliatesRateDetail from '@/components/AffiliatesRatesDetail.vue'
import AffiliatesListDetail from '@/components/AffiliatesListDetail.vue'
import { textLike } from '@/utils/predefined'
import affiliatesActionsWrapper from '@/components/AffiliatesActionsWrapper'
import AffiliateTableActions from '@/components/AffiliateTableActions.vue'
import AffiliatePartnerTypeColumn from '@/components/AffiliatePartnerTypeColumn.vue'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import PartnerTypeFilterVue from '@/components/PartnerTypeFilter.vue'
import OnboardingStatusTypeFilterVue from '@/components/OnboardingStatusTypeFilter.vue'
import InsuranceActiveTypeFilter from '@/components/InsuranceActiveTypeFilter.vue'
import CharterUPOperatingStatusTypeFilter from '@/components/CharterUPOperatingStatusTypeFilter.vue'
import DOTStatusTypeFilter from '@/components/DOTStatusTypeFilter.vue'
import DOTAuthorityStatusFilter from '@/components/DOTAuthorityStatusFilter.vue'
import DOTPassengerAuthorizedFilter from '@/components/DOTPassengerAuthorizedFilter.vue'
import DOTHireAuthorizedFilter from '@/components/DOTHireAuthorizedFilter.vue'
import DOTCarrierOperationFilter from '@/components/DOTCarrierOperationFilter.vue'
import TierFilter from '@/components/TierFilter.vue'
import { authComputed } from '@/state/helpers'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { calculatedValues, datePredefined } from '@/utils/predefined'
import affiliates from '@/services/affiliates'
import { mapActions } from 'vuex'
import { getTierLabelByQuality } from '@/utils/company'

export default {
  components: {
    DataTable,
    AffiliatesViewDetail,
    AffiliatesRateDetail,
    MultiMarketFilterVue,
  },
  props: {
    parentSorts: {
      type: String,
      default: null,
    },
    parentFilters: {
      type: String,
      default: null,
    },
  },
  metaInfo() {
    return {
      title: 'Affiliates',
    }
  },
  data() {
    return {
      dialogType: '',
      filters: () => [],
      sorts: () => [],
      user: {},
      debounce: undefined,
      actionsDialog: false,
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        detail: AffiliatesListDetail,
        addNewEnabled: false,
        addNewHandler: () => this.$router.push({ name: 'affiliates.add' }),
        enableColumnConfig: true,
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        detailKeyId: 'companyId',
        tableId: 'affiliates_tv_view',
        sort: this.sort,
        setSort: this.setSort,
        currentPage: 1,
        changePage: this.changePage,
        perPage: 10,
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        columns: [],
        collection: 'Affiliates',
        loading: true,
        total: 0,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        calculatedValues,
        eldTypes: [],
        eldCredentials: [],
      },
      insuranceActiveTypes: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 2,
          name: 'Inactive',
        },
        {
          id: 3,
          name: 'N/A',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    actionsDialog(value) {
      if (!value) {
        this.actionsDialog = false
        this.dialogType = undefined
      }
    },
  },
  async mounted() {
    this.getEldTypes()
    if (this.parentFilters) {
      this.filters = this.parentFilters
      this.tableProps.initialFilters = this.parentFilters
        .get()
        .filter((f) => typeof f.parent !== 'undefined')
    }
    const { getters = {} } = this.$store
    this.getColumns()
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable(true)
      } else {
        this.debounce = setTimeout(() => this.getTable(true), 500)
      }
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    async getEldTypes() {
      const eldTypes = await this.$store.dispatch('types/getEldTypes')
      this.eldTypes = eldTypes.data.resultList
    },
    getEldTypeLabels(companyId) {
      let eldTypeLabels = ''
      for (const eldCredentials of this.eldCredentials) {
        if (eldCredentials.companyId === companyId) {
          eldTypeLabels +=
            eldCredentials.eldType.charAt(0) +
            eldCredentials.eldType.slice(1).toLowerCase() +
            ', '
        }
      }
      return eldTypeLabels.slice(0, -2)
    },
    getEldConnectionStatus(companyId) {
      let eldConnectionStatus
      for (const eldCredentials of this.eldCredentials) {
        if (eldCredentials.companyId === companyId) {
          eldConnectionStatus =
            eldCredentials.credentialsStatus === 'ACCESS_FAILURE'
              ? 'Failing'
              : 'Connected'
          if (eldConnectionStatus === 'Failing') {
            return eldConnectionStatus
          }
        }
      }
      return eldConnectionStatus
    },
    async getColumns() {
      const affiliateWrappedActions = await affiliatesActionsWrapper(
        AffiliateTableActions,
        this.user
      )

      this.tableProps.columns = [
        {
          _t_id: '089d3f8a',
          prop: '/',
          component: affiliateWrappedActions,
          sort: false,
          filter: false,
          detail: false,
          type: 'actions',
        },
        {
          _t_id: '1ceb2b16',
          prop: 'companyId',
          component: GenericActionableColumn,
          displayType: 'clickable-operator',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'eq',
          sortProp: 'companyId',
          defaultSort: true,
          specificWidth: 12,
        },
        {
          _t_id: '1ceb2d96',
          prop: 'name',
          text: 'Name',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'name',
          specificWidth: 22,
          predefined: textLike,
        },
        {
          _t_id: '1cez2d53',
          prop: 'partnerTypeLabel',
          text: 'Partner Type',
          sort: true,
          filter: true,
          component: AffiliatePartnerTypeColumn,
          predefined: [
            {
              text: 'Partner Types',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Partner Types',
                  filterType: 'contains',
                  component: PartnerTypeFilterVue,
                },
              ],
            },
          ],
          type: 'text',
          filterType: 'contains',
          sortProp: 'partnerTypeLabel',
        },
        {
          _t_id: '1cez2d54',
          prop: 'partnerTypeId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: 'd9d73f88-3948-11ef-9454-0242ac120002',
          prop: 'charterUPOperatingStatusType',
          text: 'CharterUP Operating Status',
          sort: true,
          filter: true,
          computedText: (item) => item ? item.label : '',
          predefined: [
            {
              text: 'CharterUP Operating Statuses',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '0287009e-3949-11ef-9454-0242ac120002',
                  text: 'CharterUP Operating Statuses',
                  filterType: 'contains',
                  component: CharterUPOperatingStatusTypeFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'ceb0621b',
          prop: 'onboardingStatusTypeLabel',
          text: 'Onboarding Status',
          sort: true,
          filter: true,
          predefined: [
            {
              text: 'Onboarding Statuses',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '6e1cf967',
                  text: 'Onboarding Statuses',
                  filterType: 'contains',
                  component: OnboardingStatusTypeFilterVue,
                },
              ],
            },
          ],
          type: 'text',
          filterType: 'contains',
          sortProp: 'onboardingStatusTypeLabel',
          specificWidth: 12,
        },
        {
          _t_id: '5422ad86',
          prop: 'onboardingStatusTypeId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: 'fe43a778-300e-11ef-9454-0242ac120002',
          prop: 'dotStatusType',
          text: 'DOT Status',
          sort: true,
          filter: true,
          computedText: (item) => item ? item.label : '',
          predefined: [
            {
              text: 'DOT Status Types',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '704da85a-300f-11ef-9454-0242ac120002',
                  text: 'DOT Status Types',
                  filterType: 'contains',
                  component: DOTStatusTypeFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: '4c7e39b4-3012-11ef-9454-0242ac120002',
          prop: 'dotInterstateAllowed',
          text: 'Carrier Operation',
          sort: true,
          filter: true,
          computedText: (item) => item !== null ? (item ? 'Interstate' : 'Intrastate Only') : '',
          predefined: [
            {
              text: 'Carrier Operation',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '6ec8a054-3012-11ef-9454-0242ac120002',
                  text: 'Carrier Operation',
                  filterType: 'contains',
                  component: DOTCarrierOperationFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: 'a713f4ae-300d-11ef-9454-0242ac120002',
          prop: 'dotNumber',
          text: 'DOT Number',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '323e823a-3011-11ef-9454-0242ac120002',
          prop: 'dotOutOfServiceDate',
          text: 'OOS Date',
          computedText: (item) =>
            item
              ? `${DateTime.fromISO(item).toFormat('MM/dd/yyyy')}`
              : '',
          sort: true,
          defaultHidden: true,
        },
        {
          _t_id: 'a2b1c9e6-3011-11ef-9454-0242ac120002',
          prop: 'dotAuthorized',
          text: 'Operating Authority Status',
          sort: true,
          filter: true,
          computedText: (item) => item !== null ? (item ? 'Authorized' : 'Not Authorized') : '',
          predefined: [
            {
              text: 'Operating Authority Status',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '3a408108-3012-11ef-9454-0242ac120002',
                  text: 'Operating Authority Status',
                  filterType: 'contains',
                  component: DOTAuthorityStatusFilter,
                },
              ],
            },
          ],
          defaultHidden: true,
        },
        {
          _t_id: 'f95ef17e-3011-11ef-9454-0242ac120002',
          prop: 'dotAuthorizedForPassenger',
          text: 'Authorized for Passenger',
          sort: true,
          filter: true,
          computedText: (item) => item !== null ? (item ? 'Yes' : 'No') : '',
          predefined: [
            {
              text: 'Authorized for Passenger',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '3225b682-3012-11ef-9454-0242ac120002',
                  text: 'Authorized for Passenger',
                  filterType: 'contains',
                  component: DOTPassengerAuthorizedFilter,
                },
              ],
            },
          ],
          defaultHidden: true,
        },
        {
          _t_id: '25c30f84-3012-11ef-9454-0242ac120002',
          prop: 'dotAuthorizedForHire',
          text: 'Authorized for Hire',
          sort: true,
          filter: true,
          computedText: (item) => item !== null ? (item ? 'Yes' : 'No') : '',
          predefined: [
            {
              text: 'Authorized for Hire',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '3578e188-3012-11ef-9454-0242ac120002',
                  text: 'Authorized for Hire',
                  filterType: 'contains',
                  component: DOTHireAuthorizedFilter,
                },
              ],
            },
          ],
          defaultHidden: true,
        },
        {
          _t_id: '34d126d6-3013-11ef-9454-0242ac120002',
          prop: 'dotCarrierState',
          text: 'DOT State',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: '66145650-3013-11ef-9454-0242ac120002',
          prop: 'dotInsuranceAmount',
          text: 'Insurance Amount',
          sort: true,
          type: 'number',
          computedText: (item) => item ? currencyFilter(item) : '',
          defaultHidden: true,
        },
        {
          _t_id: '8545de04-3013-11ef-9454-0242ac120002',
          prop: 'dotRequiredAmount',
          text: 'Required Insurance Amount',
          sort: true,
          type: 'number',
          computedText: (item) => item ? currencyFilter(item) : '',
          defaultHidden: true,
        },
        {
          _t_id: '1ceb2ef4',
          prop: 'nearestMarket/marketName',
          text: 'Nearest Market',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'nearestMarket',
          predefined: [
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10zb1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
          ],
        },
        {
          _t_id: '1ceb302a',
          prop: 'phone',
          text: 'Phone',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'phone',
        },
        {
          _t_id: '1ceb3156',
          prop: 'email',
          text: 'Email',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'contains',
          sortProp: 'email',
        },
        {
          _t_id: 'c7636ea7-989f-42ff-a75e-add35239e442',
          prop: 'insuranceOnFile',
          text: 'Insurance On File',
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: 'd5a29639-eac4-4667-b1b3-3e7e7b89b17e',
          prop: 'internationalOperation',
          text: "Int'l Operation",
          computedText: (item) => (item ? 'Yes' : 'No'),
          sort: false,
          filter: true,
          detail: false,
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Yes',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 1,
            },
            {
              text: 'No',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              value: 0,
            },
          ],
        },
        {
          _t_id: '73618af0-e2e1-4ff1-a334-98c15e9065e3',
          prop: 'address/country',
          filterProp: 'address.country',
          text: 'Country',
          sort: false,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'efb6b637-61de-4744-924b-636929ba27a2',
          prop: 'insuranceExpirationDate',
          text: 'Insurance Expiration Date',
          computedText: (item) =>
            item
              ? `${DateTime.fromISO(item.split('T')[0]).toFormat('MM/dd/yyyy')}`
              : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: 'c554565e-af4f-429b-b404-285a59e19051',
          prop: 'insuranceActiveTypeId',
          text: 'Insurance Active',
          sort: true,
          filter: true,
          defaultHidden: true,
          predefined: [
            {
              text: 'Insurance Active',
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '3f6f046d-8d53-4266-89db-0e0a8a4f82f8',
                  text: 'Insurance Active',
                  filterType: 'contains',
                  component: InsuranceActiveTypeFilter,
                },
              ],
            },
          ],
          computedText: (item) =>
            this.insuranceActiveTypes.find((iat) => iat.id === item)?.name ||
            '',
          type: 'text',
          filterType: 'contains',
          sortProp: 'insuranceActiveTypeId',
          specificWidth: 12,
        },
        {
          _t_id: '1dce0074-873b-11ef-b864-0242ac120002',
          prop: 'quality',
          text: 'Tier',
          sort: true,
          filter: true,
          type: 'text',
          sortProp: 'quality',
          computedText: (item) => getTierLabelByQuality(item),
          predefined: [
            {
              text: "Tier",
              controlType: 'default-repeat',
              defaultOnSelection: true,
              refreshOnSelect: true,
              controls: [
                {
                  id: '7c8db9f6-873b-11ef-b864-0242ac120002',
                  filterType: 'contains',
                  component: TierFilter,
                },
              ],
            },
          ],
        },
        {
          _t_id: '1ceb3282',
          prop: 'referralCount',
          text: 'Referral Count',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'referralCount',
        },
        {
          _t_id: '863d6031-5f5c-435a-a648-56ac1200c049',
          prop: 'driverCount',
          text: 'Driver Count',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'driverCount',
          defaultHidden: true,
        },
        {
          _t_id: '1706172b-9f5a-44ff-92b2-ddaf6eb3cd1c',
          prop: 'vehicleCount',
          text: 'Vehicle Count',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'vehicleCount',
          defaultHidden: true,
        },
        {
          _t_id: '592514d0-92eb-491a-ae4b-d439569991e6',
          prop: 'paymentMethod',
          text: 'Payment Method',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'paymentMethod',
          defaultHidden: true,
        },
        {
          _t_id: '8a52ee31-4221-4904-bf3d-ad530ec391aa',
          prop: 'eldTypeLabels',
          text: 'ELD Types',
          sort: false,
          filter: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'eldTypeLabels',
          defaultHidden: true,
        },
        {
          _t_id: 'e56a6f59-8acd-4ed8-b726-63341f3506de',
          prop: 'eldCredentialsFailure',
          text: 'ELD Connection',
          sort: false,
          filter: false,
          detail: false,
          defaultHidden: true,
        },
        {
          _t_id: '1cez2d96',
          prop: 'companyType',
          text: 'Company Type',
          computedText: (item) => item[0].toUpperCase() + item.slice(1),
          sort: true,
          filter: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'companyType/key',
          specificWidth: 22,
          defaultHidden: true
        },
      ]
    },
    async getTable(refresh) {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.user?.company?.id
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.loading = true
      const affiliateData = await affiliates.getAffiliatesMap(params)
      this.$emit('sorts', this.sorts)
      this.$emit('filters', this.filters)

      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = affiliateData.data.resultList
      this.tableProps.total = affiliateData.data.count
      this.tableProps.loading = false

      const companyIds = this.tableProps.list.map(
        (affiliate) => affiliate.companyId
      )
      const eldCredentials = await affiliates.getAllAffiliateEldCredentialDetails(
        {
          payload: companyIds,
        }
      )
      this.eldCredentials = eldCredentials.data.data
      for (let i = 0; i < this.tableProps.list.length; i++) {
        this.tableProps.list[i].eldTypeLabels = this.getEldTypeLabels(
          this.tableProps.list[i].companyId
        )
        this.tableProps.list[
          i
        ].eldCredentialsFailure = this.getEldConnectionStatus(
          this.tableProps.list[i].companyId
        )
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.getTable(false)
    },
    closeDialog() {
      this.actionsDialog = false
      this.dialogType = undefined
      this.refresh()
    },
    showDialog(type) {
      this.actionsDialog = true
      this.dialogType = type
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.map-view {
  display: flex;
}
.cr-modal.v-card {
  border-radius: 10px !important;
}

::v-deep .pull-to-top {
  margin-top: -49px;
}
</style>
