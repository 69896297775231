
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { addressPretty } from '@/utils/string'
import { Stop } from '@/models/dto'

@Component
export default class CUItineraryItem extends Vue {
  @Prop({ type: Object, required: true }) readonly stop!: Stop

  addressPretty = addressPretty

  get stopStatus(): string {
    if (this.stop.reached) {
      return 'completed'
    }
    if (!!this.stop.estimatedArrival) {
      return 'en_route'
    }
    return 'upcoming'
  }

  get isCompleted(): boolean {
    return this.stop.reached
  }

  get isEnRoute(): boolean {
    return !this.stop.reached && !!this.stop.estimatedArrival
  }

  get isUpcoming(): boolean {
    return !this.stop.reached && !this.isEnRoute
  }

  get stopTimeForDisplay(): string {
    return this.datetimeToSimpleTime(this.stopTime)
  }

  get stopTime(): string {
    return this.stop.reachedTimeStamp || this.stop.estimatedArrival
  }

  get icon(): string {
    return this.isCompleted ? 'checkbox_circular' : 'circle'
  }

  get minutesUntilStop(): string {
    if (!!this.stopTime) {
      return `${Math.round(DateTime.fromISO(this.stopTime, { zone: this.stop.address?.timeZone }).diffNow("minutes").minutes)} min`
    }
    return ''
  }

  datetimeToSimpleTime(datetime: string): string {
    if (!!datetime) {
      return DateTime.fromISO(datetime, {zone: this.stop.address?.timeZone }).toLocaleString(DateTime.TIME_SIMPLE)
    }
    return ''
  }

}
