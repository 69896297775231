<template>
  <div class="add-refund">
    <div class="add-refund--content">
      <v-form ref="refund-form">
        <div class="add-refund--amount">
          <v-checkbox
            v-model="sendEmail"
            color="primary"
            class="send-email-checkbox"
            label="Send Email"
          />
          <CRInput
            :id="`add-refund-form-input-amount`"
            v-model="amount"
            class="add-refund--input"
            type="number"
            prefix="$"
            min="0"
            :label="
              Object.keys(row).length && !rows.length
                ? 'Amount'
                : 'Amount Per Reservation'
            "
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Amount Is Required',
                error: 'Amount Is Required',
              }),
              (val) => val >= 0 || 'Amount Must Be Positive',
            ]"
          />
          <CRSelect
            :id="`-add-refund-select-refund-type`"
            v-model="refundType"
            :items="refundTypes"
            label="Type"
            item-text="label"
            item-value="refundTypeId"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Refund Type Required',
                error: 'Refund Type Required',
              }),
            ]"
          />
          <CRRichText
            :id="`-add-refund-input-payment-notes`"
            multi-line
            label="Office Notes"
            placeholder="Payment notes for internal use only (customers will not see this)."
            class="mb-3"
            @htmlchange="updateNotesHtml"
            @textchange="updateNotesText"
          />
          <CRSelect
            :id="`-add-refund-select-refund-payment-method`"
            v-model="refundPaymentMethod"
            :items="refundPaymentMethods"
            label="Payment Method"
            item-text="label"
            item-value="id"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Refund Payment Method Required',
                error: 'Refund Payment Method Required',
              }),
            ]"
          />
        </div>
      </v-form>
    </div>
    <div class="add-refund--button-spacer"></div>
    <div
      id="add-refund--submit-btn"
      class="add-refund--submit-btn"
      @click="submit"
    >
      <span v-if="!loading">Refund</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import * as logger from '@/utils/logger'
import { currencyFilter } from '@/utils/currency'
import CRProgressCircular from '@/cr/components/CRProgressCircular.vue'
import CRSelect from '@/components/CRSelect.vue'
import CRRichText from '@/components/CRRichText.vue'
import CRInput from '@/components/CRInput.vue'
// import ClassificationSelector from '@/components/ClassificationSelector.vue'

export default {
  components: { CRInput, CRRichText, CRSelect, CRProgressCircular },
  // components: {
  //   ClassificationSelector,
  // },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      refundTypes: [],
      refundPaymentMethods: [],
      refundType: null,
      refundPaymentMethod: null,
      amount: null,
      notes: '',
      notesHtml: '',
      // classificationId: null,
      sendEmail: true,
      showAllRows: false,
      isRequired,
      isNotEmpty,
      currencyFilter,
    }
  },
  computed: {
    ...authComputed,
    previewRows() {
      return this.rows.slice(0, 3)
    },
  },
  async mounted() {
    // get refund types
    const { data: refundTypes } = await this.$store.dispatch(
      'types/getRefundTypes'
    )
    this.refundTypes = refundTypes.filter(
      (rt) => rt.label.indexOf('Migration') === -1
    )
    const { data: refundPaymentMethods } = await this.$store.dispatch(
      'types/getRefundPaymentMethodTypes'
    )
    this.refundPaymentMethods = refundPaymentMethods
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    cancel() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    updateNotesText(value) {
      this.notes = value
    },
    updateNotesHtml(value) {
      this.notesHtml = value
    },
    async submit() {
      if (!this.loading) {
        this.loading = true
        await this.$forceUpdate()
        const isValid = await this.$refs['refund-form'].validate()
        if (!isValid) {
          this.loading = false
          return
        }

        if (Object.keys(this.row).length) {
          // detail version
          const payload = [
            {
              refundType: this.refundType,
              userId: this.currentUser.userId,
              reservationId: this.row.reservationId,
              reservationVersion: this.row.version,
              companyId: this.currentUser.companyId,
              amount: this.amount,
              // TODO: the next two values will change in the future when we have the ability to track account credits
              payoutAmount: this.amount,
              accountCreditAmount: 0,
              sendEmail: this.sendEmail,
              notes: {
                note: this.notes,
                html: this.notesHtml,
              },
              // classificationId: this.classificationId,
              refundPaymentMethodId: this.refundPaymentMethod,
            },
          ]
          let res
          try {
            res = await this.$store.dispatch(
              `refunds/add${
                this.row.reservationType ? 'Referral' : 'Reservation'
              }Refunds`,
              payload
            )
          } catch (err) {
            this.showAlert({
              type: 'error',
              message: 'Error Submitting refund, Please try again',
            })
            this.loading = false
            logger.error(err)
            return
          }

          if (!res || !res.data.successful) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message: res.data?.message
                ? res.data.message
                : 'Error Submitting refund, Please try again',
            })
            logger.error(res)
          } else {
            this.showAlert({
              message: 'Refund successfully added!',
            })
            this.loading = false
            this.cancel()
          }
        } else {
          // multi-select version
          const payload = this.rows.map((res) => {
            return {
              refundType: this.refundType,
              userId: this.currentUser.userId,
              reservationId: res.item.reservationId,
              reservationVersion: res.item.version,
              companyId: this.currentUser.companyId,
              amount: this.amount,
              // TODO: the next two values will change in the future when we have the ability to track account credits
              payoutAmount: this.amount,
              accountCreditAmount: 0,
              sendEmail: this.sendEmail,
              notes: {
                note: this.notes,
                html: this.notesHtml,
              },
              // classificationId: this.classificationId,
              refundPaymentMethodId: this.refundPaymentMethod,
            }
          })
          let res
          try {
            res = await this.$store.dispatch(
              `refunds/add${
                this.rows[0].item.reservationType ? 'Referral' : 'Reservation'
              }Refunds`,
              payload
            )
          } catch (err) {
            this.showAlert({
              type: 'error',
              message: 'Error Submitting refund, Please try again',
            })
            this.loading = false
            logger.error(err)
            return
          }

          if (!res || !res.data.successful) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message: res.data?.message
                ? res.data.message
                : 'Error Submitting refund, Please try again',
            })
            logger.error(res)
          } else {
            this.showAlert({
              message: 'Refund successfully added!',
            })
            this.loading = false
            this.cancel()
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-refund {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 500px;

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  .add-refund--content {
    flex: 1;
  }

  .add-refund--amount {
    padding: 24px 48px;
  }

  .add-refund--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    height: 71px;
    width: inherit;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  .add-refund--button-spacer {
    width: 100%;
    min-height: 71px;
  }
}

.action-box {
  width: 50%;
  margin: auto;
  border: 1px solid $gray-light;
  border-radius: 4px;
}

h3 {
  margin-bottom: 15px;
}

.reservation-chip {
  margin: 4px 15px 20px 0;
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.send-email-checkbox {
  margin-top: 0;
}
</style>
