import { ParsedBounds, Location } from '@/models/GoogleMaps'
/**
 *
 * TODO: This file is currently a mock file for the live trip tracking API.
 * It should be replaced with a real API call.
 */

export const fetchLocations = async (
  bounds: ParsedBounds,
  zoom: number
): Promise<any> => {
  const CLUSTER_ZOOM_THRESHOLD = 10

  // Predefined locations for major Georgia cities
  const cityLocations = {
    atlanta: {
      center: { lat: 33.749, lng: -84.388 },
      locations: [
        { lat: 33.749, lng: -84.388, hasAlert: true }, // Downtown
        { lat: 33.7816, lng: -84.3857, hasAlert: false }, // Midtown
        { lat: 33.7629, lng: -84.3469, hasAlert: false }, // Old Fourth Ward
        { lat: 33.7844, lng: -84.4323, hasAlert: false }, // Georgia Tech
        { lat: 33.7395, lng: -84.3892, hasAlert: false }, // Five Points
        { lat: 33.7876, lng: -84.367, hasAlert: false }, // Piedmont Park
      ],
    },
    savannah: {
      center: { lat: 32.0809, lng: -81.0912 },
      locations: [
        { lat: 32.0809, lng: -81.0912 }, // Downtown
        { lat: 32.0735, lng: -81.0952, hasAlert: false }, // Forsyth Park
        { lat: 32.0841, lng: -81.0995, hasAlert: false }, // City Market
        { lat: 32.0765, lng: -81.1008, hasAlert: false }, // Riverfront
      ],
    },
    macon: {
      center: { lat: 32.8407, lng: -83.6324 },
      locations: [
        { lat: 32.8407, lng: -83.6324 }, // Downtown
        { lat: 32.8372, lng: -83.6257, hasAlert: false }, // Mercer University
        { lat: 32.8484, lng: -83.6332, hasAlert: false }, // Riverside
      ],
    },
  }

  // Combine all locations into one array
  const allLocations = [
    ...cityLocations.atlanta.locations,
    ...cityLocations.savannah.locations,
    ...cityLocations.macon.locations,
  ]

  // Create individual locations with real coordinates
  const individualLocations: Location[] = allLocations.map((pos, i) => ({
    id: `bus-${i}`,
    position: {
      lat: pos.lat,
      lng: pos.lng,
    },
    hasAlert: pos.hasAlert,
    routeNumber: `Route ${Math.floor(Math.random() * 50) + 1}`,
  }))

  const clusters = [
    {
      position: cityLocations.atlanta.center,
      count: cityLocations.atlanta.locations.length,
      hasAlert: true,
    },
    {
      position: cityLocations.macon.center,
      count: cityLocations.macon.locations.length,
      hasAlert: false,
    },
    {
      position: cityLocations.savannah.center,
      count: cityLocations.savannah.locations.length,
      hasAlert: false,
    },
  ]

  // set timeout 1sec
  await new Promise((resolve) => setTimeout(resolve, 1000))

  return zoom > CLUSTER_ZOOM_THRESHOLD
    ? { type: 'individual', locations: individualLocations }
    : { type: 'cluster', locations: clusters }
}

interface StatusObject {
  label: string
  statusLevel: 'SUCCESS' | 'WARNING' | 'FAILURE' | 'INACTIVE'
}

interface ReservationCore {
  reservationId: number
  externalId: string
  managedId: string
  pickupTime: string
  companyId: number
  companyName: string
  companyTimeZone: string
  contractName: string
  contractId: number
  marketId: number
  market: string
}

interface LiveTripMonitoringReferral extends ReservationCore {
  driverStatus: StatusObject
  vehicleStatus: StatusObject
  trackingStatus: StatusObject
  scheduleStatus: StatusObject
  alertCount: number
  lat: number
  lng: number
  heading: number | null
  reminderTime: string | null
  lastContact: string | null
  automatedCallId: string | null
  automatedCallStatus:
    | 'REQUESTED'
    | 'STARTED'
    | 'COMPLETED'
    | 'CANCELLED'
    | null
  logs: string | null
  assignedTo: string | null
  assignedToUserId: number | null
  supportTeamClassificationLabel: string | null
  tierLabel: string | null
  assignedDriverCount: number
}

interface LiveTripTableResultDTO {
  referrals: LiveTripMonitoringReferral[]
}

interface LiveTripAlert {
  id: string
  type: string
  status: string
}
interface LiveTripEvent {
  id: string
  type: string
  occurredOn: Date
  waypointId: string
  eta: Date
  timeZone: string
  alerts: LiveTripAlert[]
}

interface LiveTripStop {
  stopId: number
  waypointId: string
  tripId: number
  orderIndex: number
  pickupDatetime: Date
  dropoffDatetime: Date
  address: LiveTripAddress
}

interface LiveTripAddress {
  name: string
  street1: string
  street2: string
  city: string
  state: string
  postalCode: string
  country: string
  lat: number
  lng: number
  timeZone: string
}

interface LiveTripMonitoringReferralDetail extends ReservationCore {
  reservationId: number
  externalJourneyId: string
  managedId: string
  companyId: number
  companyName: string
  companyTimeZone: string
  contractName: string
  contractId: number
  vehicleId: number
  vehicleName: string
  stops: LiveTripStop[]
  lat: number
  lng: number
  heading: number | null
  events: LiveTripEvent[]
}

export interface LiveTripDetailResultDTO {
  referrals: LiveTripMonitoringReferralDetail[]
}

// Helper functions
const randomFromArray = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)]
}

const generateManagedId = (): string => {
  const number = Math.floor(Math.random() * 1000000)
  const decimal = Math.floor(Math.random() * 10)
  return `${number.toString().padStart(6, '0')}.${decimal}`
}

const statusMappings = {
  driver: {
    Assigned: 'SUCCESS',
    Unassigned: 'WARNING',
  },
  vehicle: {
    Compliant: 'SUCCESS',
    Assigned: 'SUCCESS',
    Unassigned: 'FAILURE',
  },
  tracking: {
    Tracking: 'SUCCESS',
    'Not Tracking': 'FAILURE',
  },
  schedule: {
    'On Schedule': 'SUCCESS',
    'Off Schedule': 'FAILURE',
  },
} as const

type StatusType = keyof typeof statusMappings
type StatusLabels<T extends StatusType> = keyof typeof statusMappings[T]
type StatusLevel = 'SUCCESS' | 'WARNING' | 'FAILURE' | 'INACTIVE'

const generateStatus = <T extends StatusType>(
  type: T,
  preferredLevel?: StatusLevel
): StatusObject => {
  const mappings = statusMappings[type]
  let availableLabels: StatusLabels<T>[] = Object.keys(
    mappings
  ) as StatusLabels<T>[]

  if (preferredLevel) {
    availableLabels = availableLabels.filter(
      (label) => mappings[label] === preferredLevel
    )
  }

  const label = randomFromArray(availableLabels)
  return {
    label: label as string,
    statusLevel: mappings[label] as StatusLevel,
  }
}

const generateCoordinate = (min: number, max: number): number => {
  return Number((Math.random() * (max - min) + min).toFixed(4))
}

const companies = [
  { name: 'Express Transit Co', id: 789 },
  { name: 'City Shuttle Services', id: 456 },
  { name: 'Metro Transportation', id: 123 },
  { name: 'Regional Transit LLC', id: 234 },
]

const markets = [
  { name: 'Atlanta', id: 101 },
  { name: 'Chicago', id: 102 },
  { name: 'Dallas', id: 103 },
  { name: 'Miami', id: 104 },
  { name: 'Los Angeles', id: 105 },
]

const contracts = [
  { name: 'Standard Service', id: 5678 },
  { name: 'Premium Service', id: 5679 },
  { name: 'Express Service', id: 5680 },
  { name: 'Corporate Contract', id: 5681 },
]

const timeZones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
]

// Generate core reservation data that will be shared among grouped referrals
const generateReservationCore = (reservationId: number): ReservationCore => {
  const company = randomFromArray(companies)
  const market = randomFromArray(markets)
  const contract = randomFromArray(contracts)
  const now = new Date()
  const pickupTime = new Date(
    now.getTime() + Math.random() * 24 * 60 * 60 * 1000
  )

  return {
    reservationId,
    externalId: `EXT-${reservationId}`,
    managedId: generateManagedId(),
    pickupTime: pickupTime.toISOString(),
    companyId: company.id,
    companyName: company.name,
    companyTimeZone: randomFromArray(timeZones),
    contractName: contract.name,
    contractId: contract.id,
    marketId: market.id,
    market: market.name,
  }
}

// Generate the variable part of a referral
const generateReferralVariables = (): Omit<
  LiveTripMonitoringReferral,
  keyof ReservationCore
> => {
  const now = new Date()
  const assignedUserId =
    Math.random() > 0.6 ? Math.floor(Math.random() * 100) : null
  const assignedUserName = assignedUserId ? `Agent ${assignedUserId}` : null

  return {
    driverStatus: generateStatus('driver'),
    vehicleStatus: generateStatus('vehicle'),
    trackingStatus: generateStatus('tracking'),
    scheduleStatus: generateStatus('schedule'),
    alertCount: Math.floor(Math.random() * 5),
    lat: generateCoordinate(25.0, 48.0),
    lng: generateCoordinate(-123.0, -75.0),
    heading: Math.random() > 0.1 ? Math.floor(Math.random() * 360) : null,
    reminderTime:
      Math.random() > 0.5
        ? new Date(
            now.getTime() + Math.random() * 12 * 60 * 60 * 1000
          ).toISOString()
        : null,
    lastContact:
      Math.random() > 0.5
        ? new Date(
            now.getTime() - Math.random() * 6 * 60 * 60 * 1000
          ).toISOString()
        : null,
    automatedCallId:
      Math.random() > 0.7
        ? `CALL-${1000 + Math.floor(Math.random() * 9000)}`
        : null,
    automatedCallStatus:
      Math.random() > 0.7
        ? randomFromArray(['REQUESTED', 'STARTED', 'COMPLETED', 'CANCELLED'])
        : null,
    logs: Math.random() > 0.8 ? 'Sample log entry' : null,
    assignedTo: assignedUserName,
    assignedToUserId: assignedUserId,
    supportTeamClassificationLabel:
      Math.random() > 0.7
        ? `Priority ${Math.floor(Math.random() * 3) + 1}`
        : null,
    tierLabel:
      Math.random() > 0.7 ? `Tier ${Math.floor(Math.random() * 3) + 1}` : null,
    assignedDriverCount: Math.floor(Math.random() * 3),
  }
}

// Create a complete referral by combining core and variable data
const createReferral = (core: ReservationCore): LiveTripMonitoringReferral => {
  return {
    ...core,
    ...generateReferralVariables(),
  }
}

export const generateLiveTripData = async (
  count: number
): Promise<LiveTripTableResultDTO> => {
  // wait 1 sec
  await new Promise((resolve) => setTimeout(resolve, 1000))
  const referrals: LiveTripMonitoringReferral[] = []
  let currentReservationId = 10000

  while (referrals.length < count) {
    // Decide if this will be a grouped reservation (30% chance)
    const isGrouped = Math.random() < 0.3

    if (isGrouped) {
      // Generate between 2-4 referrals for this reservation
      const groupSize = Math.floor(Math.random() * 3) + 2
      const core = generateReservationCore(currentReservationId)

      // Generate multiple referrals with the same core data
      for (let i = 0; i < groupSize && referrals.length < count; i++) {
        referrals.push(createReferral(core))
      }
    } else {
      // Generate a single referral
      const core = generateReservationCore(currentReservationId)
      referrals.push(createReferral(core))
    }

    currentReservationId++
  }

  return { referrals }
}

export const mockReferralJourneys = [
  {
    reservationId: 12345,
    externalJourneyId: 'abc123',
    managedId: '123456.1',
    pickupTime: '2025-01-16T14:30:00.000Z',
    companyId: 789,
    companyName: 'Metro Transport Services',
    companyTimeZone: 'America/New_York',
    contractName: 'Corporate Shuttle Service',
    contractId: 456,
    hasCirculation: true,
    circulationEndDatetime: '2025-01-22T23:00:00Z',
    stops: [
      {
        stopId: 904311,
        waypointId: 'waypoint1',
        tripId: 368380,
        orderIndex: 0,
        address: {
          addressId: 950086,
          addressName: '1060 W Addison St, Chicago, IL 60613, USA',
          street1: '1060 W Addison St',
          street2: null,
          city: 'Chicago',
          state: 'IL',
          postalCode: '60613',
          country: null,
          lat: 41.9484,
          lng: -87.6553,
          timeZone: 'America/Chicago',
          title: 'Wrigley Field',
          nearestMarketId: 4,
          addressTypes: [
            {
              addressTypeId: 35,
              label: 'Establishment',
              key: 'establishment',
              description: 'Indicates an establishment',
              specificityLevel: 2,
            },
            {
              addressTypeId: 83,
              label: 'Point of Interest',
              key: 'point_of_interest',
              description: 'Indicates a point of interest',
              specificityLevel: 2,
            },
            {
              addressTypeId: 103,
              label: 'Stadium',
              key: 'stadium',
              description: 'Indicates a stadium',
              specificityLevel: 2,
            },
            {
              addressTypeId: 111,
              label: 'Tourist Attraction',
              key: 'tourist_attraction',
              description: 'Indicates a tourist attraction',
              specificityLevel: 2,
            },
          ],
          risks: [],
          completeAddress: 'Chicago, IL',
          name: '1060 W Addison St, Chicago, IL 60613, USA',
        },
        pickupDatetime: '2025-01-15T15:00:00.000+00:00',
        notes: null,
        stopNotes: [],
        active: null,
        tripVehicleGroupId: null,
        tripAddressId: null,
        flightInformation: null,
        reached: true,
        reachedTimeStamp: '2025-01-20T15:00:00.000+00:00',
        risks: [],
        dropoffDatetime: null,
        estimatedArrival: null,
      },
      {
        stopId: 904312,
        waypointId: 'waypoint2',
        tripId: 368380,
        orderIndex: 1,
        address: {
          addressId: 950087,
          addressName: 'University Park, IL, USA',
          street1: ' ',
          street2: null,
          city: 'University Park',
          state: 'IL',
          postalCode: '',
          country: null,
          lat: 41.44,
          lng: -87.6834,
          timeZone: 'America/Chicago',
          title: 'University Park',
          nearestMarketId: 4,
          addressTypes: [
            {
              addressTypeId: 61,
              label: 'Locality',
              key: 'locality',
              description: 'Indicates a locality',
              specificityLevel: 1,
            },
            {
              addressTypeId: 85,
              label: 'Political',
              key: 'political',
              description: 'Indicates a political entity',
              specificityLevel: 1,
            },
          ],
          risks: [
            {
              riskId: 265148,
              riskTypeId: 1,
              riskTypeLabel: 'Incomplete Address',
              createdOn: '2025-01-15T23:11:13.000+00:00',
              updatedOn: '2025-01-15T23:11:13.000+00:00',
              resolved: false,
              active: true,
            },
          ],
          completeAddress: 'University Park, IL',
          name: 'University Park, IL, USA',
        },
        pickupDatetime: null,
        notes: null,
        stopNotes: [],
        active: null,
        tripVehicleGroupId: null,
        tripAddressId: null,
        flightInformation: null,
        reached: false,
        reachedTimeStamp: null,
        risks: [],
        dropoffDatetime: '2025-01-15T16:00:00.000+00:00',
        estimatedArrival: '2025-01-20T20:00:00.000+00:00',
      },
    ],
    journeyEvents: [
      {
        id: 'event-1',
        type: 'ON_TIME_ETA',
        occurredOn: '2025-01-16T14:30:00.000Z',
        waypointId: 'waypoint1',
        eta: '2025-01-16T16:00:00.000Z',
        timeZone: 'America/New_York',
        alerts: [
          {
            id: 'alert-1',
            type: 'ON_TIME',
            status: 'DISMISSED',
          },
        ],
      },
      {
        id: 'event-12',
        type: 'ON_TIME_ETA',
        occurredOn: '2025-01-16T14:30:00.000Z',
        waypointId: 'waypoint1',
        eta: '2025-01-16T16:00:00.000Z',
        timeZone: 'America/New_York',
        alerts: [
          {
            id: 'alert-15',
            type: 'ON_TIME',
            status: 'SNOOZED',
            snoozedUntil: '2025-01-17T17:08:20.309-05:00',
          },
        ],
      },

      {
        id: 'event-1234',
        type: 'ON_TIME_ETA',
        occurredOn: '2025-01-16T14:30:00.000Z',
        waypointId: 'waypoint1',
        eta: '2025-01-16T16:00:00.000Z',
        timeZone: 'America/New_York',
        alerts: [
          {
            id: 'alert-14',
            type: 'ON_TIME',
            status: 'SNOOZED',
            snoozedUntil: '2025-01-17T17:09:40.309-05:00',
          },
        ],
      },
    ],
    vehicleId: 234,
    vehicleName: 'Shuttle Bus 42',
    lat: 40.7128,
    lng: -74.006,
    heading: 180,
  },
  {
    reservationId: 12345,
    externalJourneyId: 'abc123',
    managedId: '123456.1',
    pickupTime: '2025-01-16T14:30:00.000Z',
    companyId: 789,
    companyName: 'Metro Transport Services',
    companyTimeZone: 'America/New_York',
    contractName: 'Corporate Shuttle Service',
    contractId: 456,
    hasCirculation: true,
    circulationEndDatetime: '2025-01-22T12:00:00Z',
    stops: [
      {
        stopId: 904311,
        waypointId: 'waypoint1',
        tripId: 368380,
        orderIndex: 0,
        address: {
          addressId: 950086,
          addressName: '1060 W Addison St, Chicago, IL 60613, USA',
          street1: '1060 W Addison St',
          street2: null,
          city: 'Chicago',
          state: 'IL',
          postalCode: '60613',
          country: null,
          lat: 41.9484,
          lng: -87.6553,
          timeZone: 'America/Chicago',
          title: 'Wrigley Field',
          nearestMarketId: 4,
          addressTypes: [
            {
              addressTypeId: 35,
              label: 'Establishment',
              key: 'establishment',
              description: 'Indicates an establishment',
              specificityLevel: 2,
            },
            {
              addressTypeId: 83,
              label: 'Point of Interest',
              key: 'point_of_interest',
              description: 'Indicates a point of interest',
              specificityLevel: 2,
            },
            {
              addressTypeId: 103,
              label: 'Stadium',
              key: 'stadium',
              description: 'Indicates a stadium',
              specificityLevel: 2,
            },
            {
              addressTypeId: 111,
              label: 'Tourist Attraction',
              key: 'tourist_attraction',
              description: 'Indicates a tourist attraction',
              specificityLevel: 2,
            },
          ],
          risks: [],
          completeAddress: 'Chicago, IL',
          name: '1060 W Addison St, Chicago, IL 60613, USA',
        },
        pickupDatetime: '2025-01-15T15:00:00.000+00:00',
        notes: null,
        stopNotes: [],
        active: null,
        tripVehicleGroupId: null,
        tripAddressId: null,
        flightInformation: null,
        reached: false,
        reachedTimeStamp: null,
        risks: [],
        dropoffDatetime: null,
        estimatedArrival: null,
      },
      {
        stopId: 904312,
        waypointId: 'waypoint2',
        tripId: 368380,
        orderIndex: 1,
        address: {
          addressId: 950087,
          addressName: 'University Park, IL, USA',
          street1: ' ',
          street2: null,
          city: 'University Park',
          state: 'IL',
          postalCode: '',
          country: null,
          lat: 41.44,
          lng: -87.6834,
          timeZone: 'America/Chicago',
          title: 'University Park',
          nearestMarketId: 4,
          addressTypes: [
            {
              addressTypeId: 61,
              label: 'Locality',
              key: 'locality',
              description: 'Indicates a locality',
              specificityLevel: 1,
            },
            {
              addressTypeId: 85,
              label: 'Political',
              key: 'political',
              description: 'Indicates a political entity',
              specificityLevel: 1,
            },
          ],
          risks: [
            {
              riskId: 265148,
              riskTypeId: 1,
              riskTypeLabel: 'Incomplete Address',
              createdOn: '2025-01-15T23:11:13.000+00:00',
              updatedOn: '2025-01-15T23:11:13.000+00:00',
              resolved: false,
              active: true,
            },
          ],
          completeAddress: 'University Park, IL',
          name: 'University Park, IL, USA',
        },
        pickupDatetime: null,
        notes: null,
        stopNotes: [],
        active: null,
        tripVehicleGroupId: null,
        tripAddressId: null,
        flightInformation: null,
        reached: false,
        reachedTimeStamp: null,
        risks: [],
        dropoffDatetime: '2025-01-15T16:00:00.000+00:00',
        estimatedArrival: null,
      },
    ],
    journeyEvents: [
      {
        id: 'event-2',
        type: 'ON_TIME_ETA',
        occurredOn: '2025-01-16T14:16:00.000Z',
        waypointId: 'waypoint1',
        eta: '2025-01-16T17:07:00.000Z',
        timeZone: 'America/New_York',
        alerts: [
          {
            id: 'alert-1',
            type: 'ON_TIME',
            status: 'SNOOZED',
            snoozedUntil: '2025-01-16T22:56:50.309-05:00',
          },
        ],
      },
    ],
    vehicleId: 100,
    vehicleName: 'Shuttle Bus 1',
    lat: 40.7128,
    lng: -74.006,
    heading: 180,
  },
]
