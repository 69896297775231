export const defaultHeaders = [
  { text: 'Referral ID', value: 'managedId' },
  { text: 'Pickup Time', value: 'pickupTime' },
  { text: 'Referred To', value: 'companyName' },
  { text: 'Company Time Zone', value: 'companyTimeZone' },
  { text: 'Driver Status', value: 'driverStatus.label' },
  { text: 'Vehicle Status', value: 'vehicleStatus.label' },
  { text: 'Tracking Status', value: 'trackingStatus.label' },
  { text: 'Contact', value: 'reminderTime' },
  { text: 'Last Contact', value: 'lastContact' },
  { text: 'Notes', value: 'logs' },
  { text: 'Support Team', value: 'supportTeamClassificationLabel' },
  { text: 'Tier', value: 'tierLabel' },
  { text: 'Assigned To', value: 'assignedTo' },
]
