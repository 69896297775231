<template>
  <section>
    <span>
      <v-layout row full-width>
        <ReservationAmenities
          v-if="isNewTripAmenitiesEnabled"
          :trip-amenities="tripAmenities"
          :has-accepted-referrals="hasAcceptedReferrals"
          :trip-amenity-confirmations="tripAmenityConfirmations"
          @edit-reservation-amenities="editReservationAmenities"
        />
        <ReservationNote
          title="Trip Notes"
          :notes="previousTripNotes.customerNotes"
          @edit-trip-notes="editTripNotes"
        />
        <ReservationNote
          v-if="reservationType === 0"
          title="Office Notes"
          :notes="previousTripNotes.officeNotes"
          @edit-trip-notes="editTripNotes"
        />
        <v-flex v-if="isSuperAdmin">
          <p class="reservation-notes__text">
            Driver Notes
            <span class="edit-button" @click="editTripNotes">Edit</span>
          </p>
          <div v-html="previousTripNotes.driverNotes" />
        </v-flex>
      </v-layout>
    </span>
  </section>
</template>

<script>
const INTERNAL_NOTE_TYPE = 1
const CUSTOMER_NOTE_TYPE = 2
const DRIVER_NOTE_TYPE = 3
const PARENT_RESERVATION_TYPE = 0

import { authComputed } from '@/state/helpers'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'
import ReservationNote from './ReservationNote.vue'
import ReservationAmenities from './ReservationAmenities.vue'

export default {
  components: {
    ReservationNote,
    ReservationAmenities,
  },
  props: {
    tripId: { type: Number, default: null },
    managedId: { type: String, default: null },
    tripDescription: { type: String, default: null },
    officeNotes: { type: String, default: null },
    tripNotes: { type: Array, default: () => [] },
    companyId: { type: Number, default: null },
    reservationId: { type: Number, default: null },
    reservationType: { type: Number, default: null },
    tripAmenities: { type: Array, default: () => [] },
    bookedBy: { type: Object, default: () => {} },
    hasAcceptedReferrals: { type: Boolean, default: false },
    referralAmenityNotes: { type: Array, default: () => [] },
    tripAmenityConfirmations: { type: Array, default: () => [] }
  },
  data() {
    return {
      isNewTripAmenitiesEnabled: false,
    }
  },
  computed: {
    ...authComputed,
    previousTripNotes() {
      let notes = this.tripNotes
      if (notes) {
        let customerNotes = notes.find((note) => {
          return note.noteType === CUSTOMER_NOTE_TYPE
        })
        let officeNotes = notes.find((note) => {
          return note.noteType === INTERNAL_NOTE_TYPE
        })
        let driverNotes = notes.find((note) => {
          return note.noteType === DRIVER_NOTE_TYPE
        })

        let previousNotes = {}
        previousNotes.customerNotes =
          customerNotes?.html || customerNotes?.note || this.tripDescription
        previousNotes.officeNotes =
          officeNotes?.html || officeNotes?.note || this.officeNotes
        previousNotes.driverNotes = driverNotes?.html || driverNotes?.note

        return previousNotes
      }

      return {}
    },
    isParentReservation() {
      return this.reservationType === PARENT_RESERVATION_TYPE
    },
  },
  async mounted() {
    this.isNewTripAmenitiesEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    editTripNotes() {
      const component = () => import('./ReservationAddTripNotes.vue')
      this.activeTrip = {
        tripId: this.tripId,
        description: this.tripDescription,
        notes: this.officeNotes,
        tripNotes: this.tripNotes,
      }
      this.$store.dispatch('app/openSidebarDialog', {
        reservationId: this.reservationId,
        data: {
          reservationId: this.reservationId,
          trip: this.activeTrip,
          reservationType: this.reservationType,
          title: 'Trip Notes',
        },
        component,
      })
    },
    editReservationAmenities() {
      const component = () => import('./ReservationAmenitiesSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          tripId: this.tripId,
          managedId: this.managedId,
          tripAmenities: this.tripAmenities,
          bookedByName: this.bookedBy?.completeName,
          title: 'Amenities',
          hasAcceptedReferrals: this.hasAcceptedReferrals,
          referralAmenityNotes: this.referralAmenityNotes,
          tripAmenityConfirmations: this.tripAmenityConfirmations
        },
        component,
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  cursor: pointer;
  color: $primary;
  padding-left: 8px !important;
}
.flex {
  padding: 10px;
  border-bottom: 1.5px solid $background-hover;
  &:first-child {
    border-right: 1.5px solid $background-hover;
  }
}
</style>
