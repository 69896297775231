<template>
  <v-container>
    <div class="c- col cont">
      <div class="c- row bottom-space">
        <h2 style="display: flex; align-items: center">
          {{ row.name }}
          <img v-if="row.preferred" height="22" class="padding-l-2" src="@/assets/images/CharterUP.svg" />
        </h2>
      </div>
      <div style="display: flex">
        <div style="min-width: 760px">
          <AffiliatesSummaryBox :company="row" hide-edit />
        </div>
        <div class="c- col little-grow">
          <div class="title-ico">
            <div class="title">Notes</div>
            <v-btn
              v-if="!edit"
              id="affiliates-view-detail-edit-button"
              class="btn-primaryaction"
              @click="edit = true"
            >
              Edit
            </v-btn>
          </div>
          <div style="height: 40%">
            <textarea
              id="affiliates-view-detail-text-area-edit"
              v-model="note.note"
              class="affil-notes"
              rows="5"
              :autofocus="false"
              :disabled="!edit"
            />
            <div v-if="edit" class="button-cont">
              <div style="margin-right: -1.5%">
                <v-btn
                  id="affiliates-view-detail-button-cancel"
                  class="btn-secondaryaction"
                  @click="edit = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  id="affiliates-view-detail-button-save"
                  class="btn-primaryaction"
                  @click="saveNotes"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'
import companies from '@/services/companies'
import AffiliatesSummaryBox from '@/components/AffiliatesSummaryBox.vue'

export default {
  components: {
    AffiliatesSummaryBox,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      edit: false,
      loading: false,
      expanded: this.props.expanded,
      note: {},
      paymentMethodTypes: [],
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const paymentMethodTypeResponse = await this.$store.dispatch(
      'types/getPaymentMethodTypes'
    )
    this.paymentMethodTypes = paymentMethodTypeResponse.data.filter(
      (type) => type.id <= 2
    )

    try {
      const notesRes = await companies.getCompanyNotes(this.row.companyId)
      this.note = notesRes.data.note
    } catch (e) {
      this.note = {
        noteId: null,
        note: null,
        companyId: this.row.companyId,
      }
      console.error(e)
    }

    this.$forceUpdate()
  },
  methods: {
    isAdmin() {
      return this.currentUser?.group?.groupId === 1
    },
    async updatePaymentMethod(paymentMethodTypeName) {
      const companyResponse = await companies.getCompany(this.row.companyId)
      const company = companyResponse.data.company
      for (let i = 0; i < this.paymentMethodTypes.length; i++) {
        if (this.paymentMethodTypes[i].label === paymentMethodTypeName) {
          if (company.paymentMethod === null) {
            company.paymentMethod = {
              paymentMethodTypeId: this.paymentMethodTypes[i].id,
              paymentMethodTypeName: this.paymentMethodTypes[i].label,
            }
          } else {
            company.paymentMethod.paymentMethodTypeId = this.paymentMethodTypes[
              i
            ].id
          }
        }
      }
      const params = {
        id: this.row.companyId,
        payload: company,
      }
      await companies.modifyCompany(params)
    },
    closeCard() {
      this.props.expanded = false
    },
    async saveNotes() {
      let res
      try {
        if (this.note.noteId) {
          res = await companies.updateNote({
            payload: {
              ...this.note,
            },
          })
        } else {
          res = await companies.createNote({
            payload: {
              ...this.note,
            },
          })
          this.note = res.data.note
        }
      } catch (e) {
        logger.info(e)
        return
      }

      this.edit = false
    },
  },
}
</script>

<style lang="scss" scoped>
.cont {
  display: inline;
}

.c- {
  display: flex;
  justify-content: space-between;

  &.col {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.little-grow {
    justify-content: unset;
    width: 30%;
  }

  &.top-space {
    margin-top: 2px;
  }

  &.bottom-space {
    margin-bottom: 5px;
  }
}

.val {
  margin-right: 10px;
  font-size: 14px;
}

.title- {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;

  .ico {
    width: 22px;
    height: 22px;
  }
}

.h200 {
  height: 200px;
}

.f-grow {
  flex-grow: 1;
}

.title-ico {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.button-cont {
  display: flex;
  justify-content: flex-end;
}

.affil-notes {
  width: 100%;
  height: 100%;
  padding: 10px;
  resize: none;
  background-color: $blue-light;
}

::v-deep.v-input__slot {
  background-color: $white !important;
}

.affil-edit ::v-deep textarea {
  resize: none;
  background-color: $white;
}

::v-deep.v-input__control.v-input__slot {
  background-color: $white !important;
}

.ico {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
}
</style>
