import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { Type } from '@/models/dto'
import { baseUrl } from '@/utils/env'

const httpService: HttpService = new HttpService();

export const accountNotificationsService = {
  getAccountNotificationRecipientTypes(): Promise<AxiosResponse<Array<Type>>> {
    const url = `https://${baseUrl()}/types/accountNotificationRecipient`
    return httpService.get(url)
  },
  getAccountNotificationSettingTypes(): Promise<AxiosResponse<Array<Type>>> {
    const url = `https://${baseUrl()}/types/accountNotificationSetting`
    return httpService.get(url)
  },
  getAccountNotificationStatusTypes(): Promise<AxiosResponse<Array<Type>>> {
    const url = `https://${baseUrl()}/types/accountNotificationStatus`
    return httpService.get(url)
  },
  getAccountNotificationSettings(accountId: number): Promise<AxiosResponse<AccountNotificationSettingsResponse>> {
    const url = `https://${baseUrl()}/accountNotifications/settings/${accountId}`
    return httpService.get(url)
  },
  updateAccountNotificationSettings(updatedNotificationSettings: Array<AccountNotificationSetting>): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/accountNotifications/settings`
    return httpService.patch<any, UpdateAccountNotificationSettingsRequest>(url, { updatedNotificationSettings })
  }
}

export const BOOKING_CONTACT = 'booking-contact'
export const TRIP_CONTACT = 'trip-contact'

export interface AccountNotificationSettingsResponse {
  notificationSettings: Array<AccountNotificationSetting>
}

export interface UpdateAccountNotificationSettingsRequest {
  updatedNotificationSettings: Array<AccountNotificationSetting>
}

export interface AccountNotificationSetting {
  accountId: number
  notificationType: string
  recipientType: string
  status: string
}

export interface NotificationSettingsSection<Setting> {
  header: string
  subtext: string
  settingsForNotificationType: Array<Setting>
}

export interface SettingForNotificationType {
  header: string
  subtext: string
  type: string
  status: string
  excludeForTripContact?: boolean
}

// Need better name
export const NOTIFICATION_SETTINGS_BY_SECTION_SKELETON: Array<NotificationSettingsSection<Pick<SettingForNotificationType, 'type' | 'excludeForTripContact'>>> = [
  {
    header: 'Trip Notifications',
    subtext: 'Send trip notification emails and text messages automatically generated from the system',
    settingsForNotificationType: [
      {
        type: 'trip-prep',
        excludeForTripContact: true,
      }, {
        type: 'driver-info'
      }, {
        type: 'vehicle-on-way'
      }, {
        type: 'vehicle-arrival'
      }, {
        type: 'trip-feedback'
      }
    ]
  },
  {
    header: 'Quote Confirmations',
    subtext: 'Send quote confirmation email on quote conversions triggered by a CoachRail user.',
    settingsForNotificationType: [
      {
        type: 'bid-selection',
        excludeForTripContact: true
      }, {
        type: 'bas-approval',
        excludeForTripContact: true
      }, {
        type: 'contract-convert',
        excludeForTripContact: true
      }
    ]
  },
  {
    header: 'Finance Notifications',
    subtext: 'Send finance emails on reservations triggered by a CoachRail user.',
    settingsForNotificationType: [
      {
        type: 'payment-receipt',
        excludeForTripContact: true
      }, {
        type: 'charge-modification',
        excludeForTripContact: true
      }, {
        type: 'refund-receipt',
        excludeForTripContact: true
      }
    ]
  },
  {
    header: 'Cancellation Notifications',
    subtext: 'Send cancellation emails on reservations triggered by a CoachRail user.',
    settingsForNotificationType: [
      {
        type: 'cancellation-email',
        excludeForTripContact: true
      }
    ]
  }
]



