import { accountNotificationsService } from '@/services/accountNotifications'
import { getNotificationSettingsKey } from '@/utils/accountNotification.ts'

export const state = {
  types: {
    accountNotificationRecipientTypes: [],
    accountNotificationSettingTypes: [],
    accountNotificationStatusTypes: []
  },
  accountNotificationSettings: []
}

export const getters = {
  accountNotificationRecipientTypes(state) {
    const typesMap = {}
    state.types.accountNotificationRecipientTypes.forEach(type => typesMap[type.key] = type.label)
    return typesMap
  },
  accountNotificationSettingTypes(state) {
    const typesMap = {}
    state.types.accountNotificationSettingTypes.forEach(type => typesMap[type.key] = type)
    return typesMap
  },
  accountNotificationStatusTypes(state) {
    const typesMap = {}
    state.types.accountNotificationStatusTypes.forEach(type => typesMap[type.key] = type.label)
    return typesMap
  },
  accountNotificationSettings(state) {
    const settingsMap = {}
    state.accountNotificationSettings.forEach(setting => {
      const key = getNotificationSettingsKey(setting.notificationType, setting.recipientType)
      settingsMap[key] = setting.status
    })
    return settingsMap
  }
}

export const mutations = {
  SET_ACCOUNT_NOTIFICATION_RECIPIENT_TYPES(state, newValue) {
    state.types.accountNotificationRecipientTypes = newValue
  },
  SET_ACCOUNT_NOTIFICATION_SETTING_TYPES(state, newValue) {
    state.types.accountNotificationSettingTypes = newValue
  },
  SET_ACCOUNT_NOTIFICATION_STATUS_TYPES(state, newValue) {
    state.types.accountNotificationStatusTypes = newValue
  },
  SET_ACCOUNT_NOTIFICATION_SETTINGS(state, newValue) {
    state.accountNotificationSettings = newValue
  }
}

export const actions = {
  async getAccountNotificationSettings({ commit }, accountId) {
    const resp = await accountNotificationsService.getAccountNotificationSettings(accountId)
    await commit('SET_ACCOUNT_NOTIFICATION_SETTINGS', resp.data.notificationSettings)
  },
  getAccountNotificationTypes({ commit }) {
    return Promise.all([
      accountNotificationsService.getAccountNotificationRecipientTypes()
        .then(types => commit('SET_ACCOUNT_NOTIFICATION_RECIPIENT_TYPES', types.data)),
      accountNotificationsService.getAccountNotificationSettingTypes()
        .then(types => commit('SET_ACCOUNT_NOTIFICATION_SETTING_TYPES', types.data)),
      accountNotificationsService.getAccountNotificationStatusTypes()
        .then(types => commit('SET_ACCOUNT_NOTIFICATION_STATUS_TYPES', types.data)),
    ])
  },
  updateAccountNotificationSettings({ commit }, payload) {
    return accountNotificationsService.updateAccountNotificationSettings(payload)
      .then(() => commit('SET_ACCOUNT_NOTIFICATION_SETTINGS', payload))
  }
}
