<template>
  <v-card class="cr-modal">
    <v-card-title>
      <h2>Transfer Call</h2>
    </v-card-title>
    <v-card-text>
      <v-form ref="transfer-call-form">
        <p>
          If you are creating a Lead, please make sure the lead is saved prior
          to transfering the call.
        </p>
        <CRSelect
          id="call-center-transfer-call-transfer-to-queue-select"
          v-model="transferToQueue"
          :items="transferQueueOptions"
          item-text="label"
          item-value="name"
          label="Transfer to Queue"
          placeholder="Please select the queue to transfer to"
          @change="resetAgentSelect"
        />
        <label>Transfer to Agent</label>
        <v-select
          id="call-center-transfer-call-transfer-to-agent-select"
          v-model="transferToAgent"
          flat
          solo
          :items="transferAgentOptions"
          item-value="worker_sid"
          item-text="full_name"
          no-data-text="No agents available"
          placeholder="Please select the person to transfer to"
          append-icon="keyboard_arrow_down"
          @change="resetQueueSelect"
        >
          <template slot="item" slot-scope="{ item }">
            <div
              class="status-dot"
              :class="{
                'status-dot--available': item.available,
                'status-dot--other':
                  !item.available &&
                  ['Standby', 'Direct Only'].includes(item.activity),
                'status-dot--unavailable':
                  !item.available &&
                  !['Standby', 'Direct Only'].includes(item.activity),
              }"
            />
            {{ item.full_name }} -
            {{
              item.activity
                ? item.activity
                : item.available
                ? 'Available'
                : 'Unavailable'
            }}
          </template>
        </v-select>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-layout justify-end>
        <div class="button-cont">
          <div style="margin-right: -1.5%">
            <v-btn
              id="call-center-transfer-call-cancel-button"
              color="primary"
              outline
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              id="call-center-transfer-call-transfer-button"
              color="primary"
              :disabled="isTransferButtonDisabled"
              @click="transfer"
            >
              Transfer
            </v-btn>
          </div>
        </div>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import { authComputed, callCenterComputed } from '@/state/helpers'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { deepClone } from '@/utils/deepClone'
import { SplitFeatureFlag } from '@/utils/enum'

const SDR = 'hasSDRFlow'
const SDRManager = 'hasSDRManagerFlow'
const RA = 'hasRAFlow'
const RAManager = 'hasRAManagerFlow'

export default {
  props: {
    refreshQueues: {
      type: Boolean,
    },
  },
  data() {
    return {
      transferToQueue: undefined,
      transferToAgent: undefined,
      transferQueueOptions: [],
      queues: [
        {
          label: 'SDR Call Queue',
          name: 'SDR',
          permissions: [SDRManager, RAManager],
        },
        {
          label: 'Accounts Payable Call Queue',
          name: 'Accounts Payable',
          permissions: [SDRManager, RAManager],
        },
        {
          label: 'Accounts Receivable Call Queue',
          name: 'Accounts Receivable',
          permissions: [RA, SDRManager, RAManager],
        },
        {
          label: 'Accounts Receivable Spanish Call Queue',
          name: 'Accounts Receivable Spanish',
          permissions: [RA, SDRManager, RAManager],
          featureFlag: SplitFeatureFlag.SpanishCallQueues
        },
        {
          label: 'Customer Success Call Queue',
          name: 'Customer Success',
          permissions: [RA, SDRManager, RAManager],
        },
        {
          label: 'Customer Success Spanish Call Queue',
          name: 'Customer Success Spanish',
          permissions: [RA, SDRManager, RAManager],
          featureFlag: SplitFeatureFlag.SpanishCallQueues
        },
        {
          label: 'Customer Success French Call Queue',
          name: 'Customer Success French',
          permissions: [RA, SDRManager, RAManager],
          featureFlag: SplitFeatureFlag.FrenchCallQueues
        },
        {
          label: 'Enterprise Charters Customer Support',
          name: 'enterprise_charters_customer_support',
          permissions: [RA, SDRManager, RAManager],
        },
        {
          label: 'L0 Transfer Call Queue',
          name: 'L0 Transfer',
          permissions: [SDR, RA, SDRManager, RAManager],
        },
        {
          label: 'L1 Transfer Call Queue',
          name: 'L1 Transfer',
          permissions: [SDR, RA, SDRManager, RAManager],
        },
        {
          label: 'L2 Transfer Call Queue',
          name: 'L2 Transfer',
          permissions: [SDR, RA, SDRManager, RAManager],
        },
        {
          label: 'L3 Transfer Call Queue',
          name: 'L3 Transfer',
          permissions: [SDR, RA, SDRManager, RAManager],
        },
        {
          label: 'L4 Transfer Call Queue',
          name: 'L4 Transfer',
          permissions: [SDR, RA, SDRManager, RAManager],
        },
        {
          label: 'ISR Spanish Call Queue',
          name: 'ISR Spanish',
          permissions: [SDR, RA, SDRManager, RAManager],
          featureFlag: SplitFeatureFlag.SpanishCallQueues
        },
        {
          label: 'ISR French Call Queue',
          name: 'Sales Queue French',
          permissions: [SDR, RA, SDRManager, RAManager],
          featureFlag: SplitFeatureFlag.FrenchCallQueues
        },
        {
          label: 'Live Trip Support Queue',
          name: 'Live Trip Support',
          permissions: [RA, RAManager, SDRManager],
        },
        {
          label: 'Live Trip Spanish Call Queue',
          name: 'Live Trip Support Spanish',
          permissions: [RA, RAManager, SDRManager],
          featureFlag: SplitFeatureFlag.SpanishCallQueues
        },
        {
          label: 'Mobile Call Queue',
          name: 'MobilePhoneCallQueue',
          permissions: [SDRManager, RAManager],
        },
        {
          label: 'Operations Call Queue',
          name: 'Operations',
          permissions: [RA, SDRManager, RAManager],
        },
        {
          label: 'Partnerships Call Queue',
          name: 'Partnerships',
          permissions: [RA, SDRManager, RAManager],
        },
        {
          label: 'SDR Manager Call Queue',
          name: 'SDR Manager',
          permissions: [SDR, SDRManager, RAManager],
        },
        {
          label: 'Customer Success Call Queue',
          name: 'Customer Success',
          permissions: [SDR],
        },
        {
          label: 'Customer Success Spanish Call Queue',
          name: 'Customer Success Spanish',
          permissions: [SDR],
          featureFlag: SplitFeatureFlag.SpanishCallQueues
        },
        {
          label: 'Customer Success French Call Queue',
          name: 'Customer Success French',
          permissions: [SDR],
          featureFlag: SplitFeatureFlag.FrenchCallQueues
        },
        {
          label: 'Enterprise Charters Customer Support',
          name: 'enterprise_charters_customer_support',
          permissions: [SDR],
        },
      ],
      transferAgentOptions: [],
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    ...callCenterComputed,
    isTransferButtonDisabled() {
      return !(this.transferToAgent || this.transferToQueue)
    },
    transferDetails() {
      let type
      let target
      if (this.transferToAgent) {
        type = 'agent'
        target = this.transferToAgent
      }
      if (this.transferToQueue) {
        type = 'queue'
        target = this.transferToQueue
      }
      return { type, target }
    },
  },
  watch: {
    refreshQueues() {
      if (this.refreshQueues) {
        this.computeTransferOptions()
        this.$emit('refresh')
      }
    },
  },
  async mounted() {
    await this.computeTransferOptions()
  },
  methods: {
    resetQueueSelect() {
      this.transferToQueue = null
    },
    resetAgentSelect() {
      this.transferToAgent = null
    },
    transfer() {
      const valid = this.$refs['transfer-call-form'].validate()
      if (valid) {
        this.close()
        this.$emit('call-transferred', this.transferDetails)
      }
    },
    async getQueues() {

      const featureFlags = this.queues.map((queue) => {
        return queue.featureFlag
      })
      .filter((featureFlag) => !!featureFlag)

      const featureFlagSet = new Set(featureFlags)

      const featureFlagCache = {}
      for (const featureFlag of featureFlagSet) {
        featureFlagCache[featureFlag] = await this.$store.dispatch(
          'split/isFeatureEnabled',
          featureFlag
        )
      }

      return this.queues.filter((queue) =>
        // Filter based on feature flags
        !!queue.featureFlag ? featureFlagCache[queue.featureFlag] : true
      ).filter((queue) =>
        // Filter based on permission
        queue.permissions.find((permission) => this.hasPermission(permission))
      ).map((queue) => {
        if (queue.name === this.call.currentQueue) {
          // Mark the Current Queue with an additional label
          const newQueue = deepClone(queue)
          newQueue.label = `Current Queue - ${queue.label}`
          return newQueue
        } else {
          return queue
        }
      })
    },
    async computeTransferOptions() {
      this.transferQueueOptions = await this.getQueues()
      await this.computeAgentTransferOptions()
      this.$forceUpdate()
    },
    computeAgentTransferOptions() {
      this.transferAgentOptions = this.agentAvailability
        .filter((agent) => {
          return agent.contact_uri !== this.callCenter.user.contactUri
        })
        .sort((a, b) =>
          a.full_name.toLowerCase() > b.full_name.toLowerCase()
            ? 1
            : a.full_name.toLowerCase() < b.full_name.toLowerCase()
            ? -1
            : 0
        )
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep label {
  font-weight: bold;
}
.status-dot {
  margin-top: 2px;
  margin-right: 8px;
  height: 6px;
  width: 6px;
  border-radius: 50%;

  &--available {
    background: $green;
  }
  &--other {
    background: $yellow;
  }
  &--unavailable {
    background: $red;
  }
}
</style>
