
import { DateTime } from 'luxon'
import TripMonitoringRepresentativeAssignmentSidebar from './TripMonitoringRepresentativeAssignmentSidebar.vue'
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'
import TripMonitoringAccordion from '@/components/TripMonitoringAccordion.vue'
import {
  TripMonitoringStatusLevel,
  VehicleStatusLabel,
} from '@/models/dto/TripMonitoring'
import { ContactType, SplitFeatureFlag } from '@/utils/enum'
import { defaultHeaders } from '@/utils/tripMonitoringTable'

export default {
  components: {
    TripMonitoringRepresentativeAssignmentSidebar,
    TripMonitoringAccordion,
  },
  props: {
    filteredReferrals: {
      type: Array,
      default: () => [],
    },
    selectedReferralIdsSet: {
      type: Set,
      required: true,
    },
    currentItems: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    hideTableControls: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array<{ text: string, value: string }>,
      default: () => defaultHeaders,
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expand: false,
      areAICallsEnabled: false,
      rowsPerPageItems: [...Array(10).keys()].map((x) => (x + 1) * 10),
      referrals: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'pickupTime',
      },
      managedIdCompanyNameFilter: '',
      supportTeamFilter: null,
      tierFilter: null,
      supportTeams: [
        {
          team: null,
          label: 'All',
        },
        {
          team: 'L&CS Support',
          label: 'L&CS Support',
        },
        {
          team: 'Enterprise Support',
          label: 'Enterprise Support',
        },
      ],
      tiers: [
        {
          tier: null,
          label: 'All',
        },
        {
          tier: 'Standard',
          label: 'Standard',
        },
        {
          tier: 'Plus',
          label: 'Plus',
        },
        {
          tier: 'VIP',
          label: 'VIP',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
    shouldDisableAICallButton() {
      return (
        this.selectedReferralIdsSet.size < 1 ||
        this.selectedReferralIdsSet.size > 3 ||
        this.selectedReferralCompanyIds.length > 1
      )
    },
    selectedReferralCompanyIds() {
      const selectedReferralIds = [...this.selectedReferralIdsSet]
      return this.tableFilteredReferrals
        .filter((referral) =>
          selectedReferralIds.includes(referral.reservationId)
        )
        .reduce((companyIds, referral) => {
          if (!companyIds.includes(referral.companyId)) {
            companyIds.push(referral.companyId)
          }
          return companyIds
        }, [])
    },
    tableFilteredReferrals() {
      return this.filteredReferrals.filter((referral) => {
        const matchesManagedId =
          !this.managedIdCompanyNameFilter ||
          referral.managedId
            .toString()
            .startsWith(this.managedIdCompanyNameFilter.toString())
        const matchesCompanyName =
          !this.managedIdCompanyNameFilter ||
          referral.companyName
            .toString()
            .toLowerCase()
            .startsWith(
              this.managedIdCompanyNameFilter.toString().toLowerCase()
            )
        const matchesSupportTeam =
          !this.supportTeamFilter ||
          referral.supportTeamClassificationLabel === this.supportTeamFilter
        const matchesTier =
          !this.tierFilter || referral.tierLabel === this.tierFilter

        return (
          (matchesManagedId || matchesCompanyName) &&
          matchesSupportTeam &&
          matchesTier
        )
      })
    },
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    sortByColumn(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
      if (column === 'companyTimeZone') {
        this.tableFilteredReferrals.sort((a, b) => {
          if (a.companyName.localeCompare(b.companyName) === 0) {
            if (a.pickupTime < b.pickupTime) {
              return -1
            }
            if (a.pickupTime > b.pickupTime) {
              return 1
            }
            return 0
          }
          return a.companyName.localeCompare(b.companyName)
        })
      }
      this.$forceUpdate()
    },
    getLocalTime(timestamp) {
      if (!timestamp) {
        return null
      }
      return DateTime.fromISO(timestamp)
        .setZone('America/New_York')
        .toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
          timeZoneName: 'short',
        })
    },
    getLocalDateTime(timestamp) {
      if (!timestamp) {
        return null
      }
      return DateTime.fromISO(timestamp)
        .setZone('America/New_York')
        .toFormat("hh:mm a 'on' MMM dd, yyyy ZZZZ")
    },
    toggleAllVisibleReferrals() {
      this.$emit('toggle-all-visible-referrals')
    },
    toggleReferral(referralId) {
      this.$emit('toggle-referral', referralId)
    },
    openAssignToRepSidebar() {
      this.$store.dispatch('app/openSidebarDialog', {
        component: TripMonitoringRepresentativeAssignmentSidebar,
        data: {
          title: 'Assign to Rep',
          selectedReferralIdsSet: this.selectedReferralIdsSet,
        },
      })
    },
    getStatusClass(status) {
      return {
        'successful-status':
          status.statusLevel === TripMonitoringStatusLevel.Success,
        'warning-status':
          status.statusLevel === TripMonitoringStatusLevel.Warning,
        'failure-status':
          status.statusLevel === TripMonitoringStatusLevel.Failure,
        'inactive-status':
          status.statusLevel === TripMonitoringStatusLevel.Inactive,
      }
    },
    getVehicleStatusClass(status) {
      return {
        'successful-status':
          status.label === VehicleStatusLabel.COMPLIANT,
        'non-compliant-status':
          status.label === VehicleStatusLabel.NON_COMPLIANT,
        'failure-status':
          status.label === VehicleStatusLabel.UNASSIGNED,
      }
    },
    getContactText(reminderTime) {
      if (!reminderTime) {
        return 'Contact'
      } else {
        return `Contact ${this.getLocalTime(reminderTime)}`
      }
    },
    hasCompletedCall(item) {
      return item.automatedCallId != null && item.automatedCallStatus === 'COMPLETED'
    },
    openCallTranscript(item) {
      window.open(`/ai-calls/${item.automatedCallId}`, '_blank')
    },
    isTimestampPast(timestamp) {
      const now = DateTime.now().setZone('America/New_York')
      const setTime = DateTime.fromISO(timestamp)
      return setTime < now
    },
    openContactSidebar(mode, item = null) {
      const component = () =>
        import('@/components/TripMonitoringContactSidebar.vue')
      let title = ''
      switch (mode) {
        case ContactType.Driver:
          title = 'Contact Driver'
          break
        case ContactType.Operator:
          title = 'Contact Operator'
          break
        default:
          console.error('Unexpected mode: ', mode)
          return
      }
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          reservationId: item.reservationId,
          companyId: item.companyId,
          title: title,
          mode: mode,
        },
        persistent: true,
        component,
      })
    },
    handleAICallClick() {
      this.$emit('open-ai-call-modal')
    },
  },
  async mounted() {
    // This should not be replicated anywhere if possible.
    // This fix is a workaround to replicate the 'current-items' event in
    // Vuetify 2. This should be replaced when we update.
    this.$watch(
      () => {
        return this.$refs.tripMonitoringDataTable?.filteredItems
      },
      (filteredItems) => {
        this.$emit('current-items', filteredItems)
      }
    )

    this.areAICallsEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.TripMonitoringAutomatedCalls
    )
  },
}
