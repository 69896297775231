
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { generateLiveTripData } from '@/utils/liveTripTracking'
import BaseMapComponent from '@/components/BaseMapComponent.vue'
import TripMonitoringTable from '@/views/TripMonitoringTable.vue'
import LiveTripOpsFiltering from '@/components/LiveTripOpsFiltering.vue'
import { getLiveTripMonitoringOverview } from '@/services/tripMonitoring'
@Component({
  components: {
    BaseMapComponent,
    TripMonitoringTable,
    LiveTripOpsFiltering,
  },
})
export default class LiveTripOps extends Vue {
  map = null
  markers = []

  // Initialize map at the center of the US
  zoom = 4
  center = { lat: 39.46069302477103, lng: -96.51010927460516 }
  bounds = null
  debounce = undefined
  loading = false

  tripStatuses = [
    { text: 'On Schedule', value: 'On Schedule' },
    { text: 'Off Schedule', value: 'Off Schedule' },
  ]
  alerts = [
    { text: 'Alert', value: 'alert' },
    { text: 'No Alert', value: 'no-alert' },
  ]

  // Filters
  selectedContracts = []
  selectedMarkets = []
  selectedAlerts = []
  selectedOperators = []
  selectedTripStatuses = []

  columns = [
    { text: 'Referral ID', value: 'managedId' },
    { text: 'Contract Name', value: 'contractName' },
    { text: 'Start Time', value: 'pickupTime' },
    { text: 'Tracking Status', value: 'trackingStatus.label' },
    { text: 'Trip Status', value: 'scheduleStatus.label' },
    { text: 'Alerts', value: 'alertCount' },
    { text: 'Operator', value: 'companyName' },
    { text: 'Rep Name', value: 'assignedTo' },
  ]

  referrals = []
  selectedReferralIds = new Set()

  allReferrals = []

  get contracts() {
    const contractMap = this.allReferrals.reduce((acc, referral) => {
      acc[referral.contractId] = referral.contractName
      return acc
    }, {})
    return Object.entries(contractMap).map(([contractId, contractName]) => ({
      text: contractName,
      value: parseInt(contractId),
    }))
  }

  get markets() {
    const marketMap = this.allReferrals.reduce((acc, referral) => {
      acc[referral.marketId] = referral.market
      return acc
    }, {})
    return Object.entries(marketMap).map(([marketId, market]) => ({
      text: market,
      value: parseInt(marketId),
    }))
  }

  get operators() {
    const operatorMap = this.allReferrals.reduce((acc, referral) => {
      acc[referral.companyId] = referral.companyName
      return acc
    }, {})

    return Object.entries(operatorMap).map(([companyId, companyName]) => ({
      text: companyName,
      value: parseInt(companyId),
    }))
  }

  get filters() {
    const filters = {
      contracts: [],
      markets: [],
      tripStatuses: [],
      alerts: [],
      operators: [],
    }

    if (
      this.selectedContracts.length > 0 &&
      this.selectedContracts.length < this.contracts.length
    ) {
      filters.contracts = this.selectedContracts
    }
    if (
      this.selectedMarkets.length > 0 &&
      this.selectedMarkets.length < this.markets.length
    ) {
      filters.markets = this.selectedMarkets
    }
    if (
      this.selectedTripStatuses.length > 0 &&
      this.selectedTripStatuses.length < this.tripStatuses.length
    ) {
      filters.tripStatuses = this.selectedTripStatuses
    }
    if (
      this.selectedAlerts.length > 0 &&
      this.selectedAlerts.length < this.alerts.length
    ) {
      filters.alerts = this.selectedAlerts
    }
    if (
      this.selectedOperators.length > 0 &&
      this.selectedOperators.length < this.operators.length
    ) {
      filters.operators = this.selectedOperators
    }

    return filters
  }

  get filteredReferrals() {
    return this.allReferrals.filter((referral) => {
      const {
        contractId,
        marketId,
        scheduleStatus,
        alertCount,
        companyId,
      } = referral
      const {
        contracts,
        markets,
        tripStatuses,
        alerts,
        operators,
      } = this.filters
      return (
        (contracts.length === 0 || contracts.includes(contractId)) &&
        (markets.length === 0 || markets.includes(marketId)) &&
        (tripStatuses.length === 0 ||
          tripStatuses.includes(scheduleStatus.label)) &&
        (alerts.length === 0 ||
          (alerts.includes('no-alert') && alertCount === 0) ||
          (alerts.includes('alert') && alertCount > 0)) &&
        (operators.length === 0 || operators.includes(companyId))
      )
    })
  }

  get groupedReferrals() {
    const groupedReferrals = this.filteredReferrals.reduce((acc, referral) => {
      if (!acc[referral.reservationId]) {
        acc[referral.reservationId] = referral
      } else {
        acc[referral.reservationId].alertCount += referral.alertCount
      }
      return acc
    }, {})

    const referrals = Object.values(groupedReferrals) as any[]
    return referrals
  }

  get busMarkers() {
    return this.filteredReferrals.map((referral) => ({
      position: {
        lat: referral.lat,
        lng: referral.lng,
      },
      heading: referral.heading,
      hasAlert: referral.alertCount > 0,
    }))
  }

  handleToggleAllVisibleReferrals() {
    if (this.selectedReferralIds.size === this.allReferrals.length) {
      this.selectedReferralIds.clear()
      this.selectedReferralIds = new Set(this.selectedReferralIds)
    } else {
      this.selectedReferralIds = new Set(
        this.allReferrals.map((referral) => referral.reservationId)
      )
    }
  }

  handleToggleReferral(referralId: number): void {
    if (this.selectedReferralIds.has(referralId)) {
      this.selectedReferralIds.delete(referralId)
      this.selectedReferralIds = new Set(this.selectedReferralIds)
    } else {
      this.selectedReferralIds.add(referralId)
      this.selectedReferralIds = new Set(this.selectedReferralIds)
    }
  }

  async fetchMarkers() {
    this.loading = true
    try {
      const {
        data: { referrals },
      } = await getLiveTripMonitoringOverview()
      this.allReferrals = referrals
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error loading live trip monitoring data',
      })
    }
    this.loading = false
  }

  async mounted() {
    await this.fetchMarkers()
  }
}
