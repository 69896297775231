<template>
  <div class="pa-3">
    <div v-for="notificationSection in notificationSettingsSections">
      <h2 class="font-20 font-weight-bold">{{ notificationSection.header }}</h2>
      <v-container grid-list-md class="padding-y-4 margin-l-2" column>
        <v-layout column>
          <v-flex row v-for="notificationSetting in notificationSection.settingsForNotificationType">
            <div class="font-weight-bold">{{ notificationSetting.header }}</div>
            <div class="font-12">{{ notificationSetting.subtext }}</div>
            <CRSelect
              :label="recipientTypes[BOOKING_CONTACT]"
              :items="notificationStatusOptions"
              :value="getNotificationStatusKey(notificationSetting.type, BOOKING_CONTACT)"
              item-value="value"
              item-text="label"
              @input="updateNotificationSetting(notificationSetting.type, BOOKING_CONTACT, $event)"
            />
            <CRSelect
              v-if="!notificationSetting.excludeForTripContact"
              :label="recipientTypes[TRIP_CONTACT]"
              :items="notificationStatusOptions"
              :value="getNotificationStatusKey(notificationSetting.type, TRIP_CONTACT)"
              item-value="value"
              item-text="label"
              @input="updateNotificationSetting(notificationSetting.type, TRIP_CONTACT, $event)"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <CRButton
      id="customer-accounts-notification-settings-save-btn"
      :loading="isSubmitting"
      class="customer-accounts-detail-sidebar--action-btn"
      color="primary"
      @click="submit(newNotificationSettings)"
    >
      Save
    </CRButton>
  </div>
</template>

<script>

import { deepClone } from '@/utils/deepClone'
import CRSelect from '@/components/CRSelect.vue'
import CRButton from '@/cr/components/CRButton.vue'
import { BOOKING_CONTACT, TRIP_CONTACT } from '@/services/accountNotifications'
import { getNotificationSettingsKey } from '@/utils/accountNotification'

export default {
  components: { CRButton, CRSelect },
  props: {
    accountId: {
      type: Number,
      required: true
    },
    recipientTypes: {
      type: Object,
      required: true
    },
    notificationStatuses: {
      type: Object,
      required: true
    },
    notificationSettingTypes: {
      type: Object,
      required: true
    },
    notificationSettingsSections: {
      type: Object,
      required: true
    },
    notificationSettings: {
      type: Object,
      required: true
    },
    submit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      newNotificationSettings: deepClone(this.notificationSettings),
      isSubmitting: false,
      BOOKING_CONTACT,
      TRIP_CONTACT
    }
  },
  computed: {
    notificationStatusOptions() {
      return Object.keys(this.notificationStatuses).map(key => ({
        value: key,
        label: this.notificationStatuses[key]
      }))
    }
  },
  methods: {
    updateNotificationSetting(notificationType, recipientType, newStatus) {
      this.newNotificationSettings[getNotificationSettingsKey(notificationType ,recipientType)] = newStatus
    },
    getNotificationStatusKey(notificationType, recipientType) {
      return this.notificationSettings[getNotificationSettingsKey(notificationType, recipientType)]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
