
import { AmenityType, TripAmenityConfirmations } from '@/models/dto/Amenity'
import {
  guaranteedAmenityTypeIds,
  bestEffortAmenityTypeIds,
  AmenityTypeId,
} from '@/utils/enum'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class ReservationAmenities extends Vue {
  @Prop({ type: Array, required: true }) readonly tripAmenities: AmenityType[]
  @Prop({ type: Boolean, default: false })
  readonly hasAcceptedReferrals: boolean
  @Prop({ type: Array, default: () => [] })
  readonly tripAmenityConfirmations: TripAmenityConfirmations[]

  guaranteedAmenitiesConfirmationText = ''
  hasBestEffortAmenities = false
  allBestEffortAmenitiesConfirmed = false
  bestEffortAmenitiesConfirmationText = ''

  get filteredAmenities(): AmenityType[] {
    return this.tripAmenities.filter(
      (amenity) =>
        ![
          AmenityTypeId.Bathroom,
          AmenityTypeId.Luggage,
        ].includes(amenity.id)
    )
  }

  setGuaranteedAmenitiesConfirmationText(): void {
    const guaranteedAmenitiesList = this.tripAmenities.filter((amenity) =>
      Object.values(guaranteedAmenityTypeIds).includes(amenity.id)
    )
    const guaranteedAmenitiesString =
      guaranteedAmenitiesList.length === 1
        ? ' Guaranteed amenity confirmed'
        : ' Guaranteed amenities confirmed'
    this.guaranteedAmenitiesConfirmationText =
      guaranteedAmenitiesList.length +
      ' of ' +
      guaranteedAmenitiesList.length +
      guaranteedAmenitiesString
  }

  setBestEffortAmenitiesConfirmationValues(): void {
    const bestEffortAmenitiesList = this.tripAmenities.filter((amenity) =>
      Object.values(bestEffortAmenityTypeIds).includes(amenity.id)
    )
    const confirmedAmenityTypeIds = this.tripAmenityConfirmations?.map(
      (c) => c.amenityTypeId
    )
    const confirmedBestEffortAmenities = bestEffortAmenitiesList.filter((a) =>
      confirmedAmenityTypeIds?.some((c) => a.id === c)
    )

    this.hasBestEffortAmenities = bestEffortAmenitiesList.length > 0
    this.allBestEffortAmenitiesConfirmed =
      confirmedBestEffortAmenities.length === bestEffortAmenitiesList.length
    const bestEffortAmenitiesString =
      bestEffortAmenitiesList.length === 1
        ? ' Best Effort amenity confirmed'
        : ' Best Effort amenities confirmed'
    this.bestEffortAmenitiesConfirmationText =
      confirmedBestEffortAmenities.length +
      ' of ' +
      bestEffortAmenitiesList.length +
      bestEffortAmenitiesString
  }

  @Watch('tripAmenities', { immediate: true, deep: true })
  onTripAmenitiesChanged() {
    this.setGuaranteedAmenitiesConfirmationText()
    this.setBestEffortAmenitiesConfirmationValues()
  }

  @Watch('tripAmenityConfirmations', { immediate: true, deep: true })
  onTripAmenityConfirmationsChanged() {
    this.setGuaranteedAmenitiesConfirmationText()
    this.setBestEffortAmenitiesConfirmationValues()
  }
}
