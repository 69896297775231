var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pay-provider"},[_c('div',{staticClass:"pay-provider--content"},[_c('v-form',{ref:"payment-form",attrs:{"lazy-validation":true}},[_c('v-checkbox',{staticClass:"send-email-checkbox pay-provider--input",attrs:{"id":`-pay-provider-checkbox-send-email`,"label":"Send Email"},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}}),_c('CRInput',{staticClass:"pay-provider--input",attrs:{"id":`-pay-provider-input-payment-amount`,"label":"Payment Amount","type":"number","background-color":_vm.$cr.theme.inputBackgroundGray,"prefix":"$","validate-on-blur":"","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Amount Is Required',
            error: 'Amount Is Required',
          }),
        ]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('CRSelect',{staticClass:"pay-provider--input",attrs:{"id":`-pay-provider-select-payment-type`,"items":_vm.paymentTypes,"label":"Payment Type","item-text":"label","validate-on-blur":"","item-value":"paymentTypeId","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Payment Type Required',
            error: 'Payment Type Required',
          }),
        ]},model:{value:(_vm.paymentType),callback:function ($$v) {_vm.paymentType=$$v},expression:"paymentType"}}),_c('CRInput',{staticClass:"pay-provider--input",attrs:{"id":`-pay-provider-input-reference-number`,"label":"Reference Number","background-color":_vm.$cr.theme.inputBackgroundGray,"validate-on-blur":"","rules":[
          _vm.isRequired(true, _vm.isNotEmpty, {
            req: 'Reference Number Is Required',
            error: 'Reference Number Is Required',
          }),
        ]},model:{value:(_vm.refNumber),callback:function ($$v) {_vm.refNumber=$$v},expression:"refNumber"}}),_c('CRInput',{staticClass:"pay-provider--input",attrs:{"id":`-pay-provider-input-notes`,"multi-line":"","label":"Notes"},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}})],1)],1),_c('div',{staticClass:"pay-provider--btn-spacer",staticStyle:{"height":"71px"}}),_c('div',{staticClass:"pay-provider--submit-btn",attrs:{"id":"pay-provider--submit-btn"},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v("Record Payment")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }