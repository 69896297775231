<template>
  <AccountNotificationSettingsDisplay
   :account-id="accountId"
   :recipient-types="recipientTypes"
   :notification-setting-types="notificationSettingTypes"
   :notification-settings="notificationSettings"
   :notification-settings-sections="notificationSettingsSections"
   :notification-statuses="notificationStatuses"
   :open-edit-panel="openEditPanel"
  />
</template>

<script>

import { NOTIFICATION_SETTINGS_BY_SECTION_SKELETON } from '@/services/accountNotifications'
import { deepClone } from '@/utils/deepClone'
import AccountNotificationSettingDisplay from '@/components/AccountNotificationSettingDisplay.vue'
import AccountNotificationSettingsDisplay from '@/components/AccountNotificationSettingsDisplay.vue'

export default {
  components: { AccountNotificationSettingsDisplay, AccountNotificationSettingDisplay },
  data: () => ({
    isLoading: true
  }),
  computed: {
    accountId() {
      return parseInt(this.$route.params.id)
    },
    recipientTypes() {
      return this.$store.getters['accountNotifications/accountNotificationRecipientTypes']
    },
    notificationStatuses() {
      return this.$store.getters['accountNotifications/accountNotificationStatusTypes']
    },
    notificationSettingTypes() {
      return this.$store.getters['accountNotifications/accountNotificationSettingTypes']
    },
    notificationSettingsSections() {
      if (this.isLoading) {
        return NOTIFICATION_SETTINGS_BY_SECTION_SKELETON
      }

      const fleshedOutSkeleton = deepClone(NOTIFICATION_SETTINGS_BY_SECTION_SKELETON)

      fleshedOutSkeleton.forEach(settingsSection => {
        settingsSection.settingsForNotificationType.forEach(setting => {
          const typeValue = this.notificationSettingTypes[setting.type]
          setting.header = typeValue.label
          setting.subtext = typeValue.description
        })
      })

      return fleshedOutSkeleton
    },
    notificationSettings() {
      return this.$store.getters['accountNotifications/accountNotificationSettings']
    }
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch('accountNotifications/getAccountNotificationTypes'),
      this.$store.dispatch('accountNotifications/getAccountNotificationSettings', this.accountId)
    ])
    this.isLoading = false
  },
  methods: {
    openEditPanel() {
      const component = () => import('./AccountNotificationSettingsSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Notification Settings',
          accountId: this.accountId,
          recipientTypes: this.recipientTypes,
          notificationStatuses: this.notificationStatuses,
          notificationSettingTypes: this.notificationSettingTypes,
          notificationSettingsSections: this.notificationSettingsSections,
          notificationSettings: this.notificationSettings,
          submit: this.submitNotificationSettings
        },
        component,
        persistent: true,
      })
    },
    async submitNotificationSettings(newSettings) {
      const updatedNotificationSettings = Object.keys(newSettings).map(settingKey => {
        const [notificationType, recipientType] = settingKey.split(':')
        return {
          accountId: this.accountId,
          notificationType,
          recipientType,
          status: newSettings[settingKey]
        }
      })

      try {
        await this.$store.dispatch('accountNotifications/updateAccountNotificationSettings', updatedNotificationSettings)
      } catch (e) {
        await this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Error Updating Notification Settings.',
        })
        console.error(e)
      }

      await this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Account Notification Settings Updated.',
      })
      await this.$store.dispatch('app/closeDialog')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
