<template>
  <div class="customer-accounts-default-settings-sidebar">
    <div class="customer-accounts-default-settings-sidebar--content">
      <v-form ref="customerAccountsDefaultSettingsSidebar">
        <CRSelect
          v-model="pricingMethodKey"
          label="Quote Pricing Method"
          placeholder="Select pricing method"
          item-text="label"
          item-value="key"
          :items="PRICING_METHODS"
        />
        <v-layout>
          <v-flex xs6 class="margin-r-2">
            <CRSelect
              v-model="paymentMethodType"
              label="Payment Method"
              placeholder="Select payment method"
              item-text="label"
              item-value="key"
              :items="paymentMethodTypesList"
            />
          </v-flex>
          <v-flex xs6 class="margin-r-2">
            <CRSelect
              v-model="paymentTermsDays"
              label="Payment Terms"
              placeholder="Net days"
              item-text="label"
              item-value="value"
              :items="paymentTermOptions"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6 class="margin-r-2">
            <CRSelect
              v-model="paymentType"
              label="Payment Type"
              placeholder="Select payment type"
              item-text="label"
              item-value="key"
              :items="paymentTypesList"
            />
          </v-flex>
          <v-flex v-if="showDownPaymentPercent" xs6 class="margin-r-2">
            <CRInput
              v-model="downPaymentPercent"
              type="number"
              label="Down Payment %"
              placeholder="Select payment %"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6 class="margin-r-2">
            <CRInput
              v-model="discountPercent"
              type="number"
              label="Discount %"
              placeholder="Discount %"
            />
          </v-flex>
          <v-flex xs6 class="margin-r-2">
            <CRInput
              v-model="markupPercent"
              type="number"
              label="Markup %"
              placeholder="Markup %"
            />
          </v-flex>
        </v-layout>
        <CRSelect
          v-model="productClassificationId"
          label="Product"
          placeholder="Select product"
          item-text="label"
          item-value="classificationId"
          :items="productClassifications"
        />
        <CRSelect
          v-model="sourcingTeamClassificationId"
          label="Sourcing Team"
          placeholder="Select sourcing team"
          item-text="label"
          item-value="classificationId"
          :items="sourcingTeamClassifications"
        />
        <CRSelect
          v-model="supportTeamClassificationId"
          label="Support Team"
          placeholder="Select support team"
          item-text="label"
          item-value="classificationId"
          :items="supportTeamClassifications"
        />
        <CRSelect
          v-model="needsManualReferral"
          label="Send their trips through MARGE"
          placeholder="Select MARGE usage"
          item-text="label"
          item-value="value"
          :items="NEEDS_MANUAL_REFERRAL_OPTIONS"
        />
        <CRInput
          v-model="minQuality"
          type="number"
          label="Minimum Operator Quality"
          placeholder="Minimum Operator Quality"
          :rules="rules.minQuality"
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="customer-accounts-detail-save-btn"
        :loading="loading || submitting"
        class="customer-accounts-default-settings-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  validateAddress,
} from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'
import classifications from '@/services/classifications'
import customerAccounts from '@/services/customerAccounts'
import { PAYMENT_TERM_OPTIONS_WITH_NULL } from '@/models/paymentTerms'
import { ALLOWED_PAYMENT_METHOD_KEYS } from '@/services/types'

export const PRICING_METHODS = [
  {
    key: 'single_price',
    label: 'Single Price',
  },
  {
    key: 'bids',
    label: 'Bids',
  },
  {
    key: 'category',
    label: 'Category',
  },
  {
    key: null,
    label: 'Inherit',
  },
]
export const NEEDS_MANUAL_REFERRAL_OPTIONS = [
  {
    value: false,
    label: 'Yes',
  },
  {
    value: true,
    label: 'No',
  },
  {
    value: null,
    label: 'Inherit',
  },
]
const PRODUCT_CLASSIFICATION_TYPE_KEY = 'product'
const SOURCING_TEAM_CLASSIFICATION_TYPE_KEY = 'sourcing_team'
const SUPPORT_TEAM_CLASSIFICATION_TYPE_KEY = 'support_team'

export default {
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      PRICING_METHODS,
      NEEDS_MANUAL_REFERRAL_OPTIONS,
      pricingMethodKey: null,
      paymentMethodType: null,
      paymentType: null,
      downPaymentPercent: null,
      discountPercent: null,
      markupPercent: null,
      minQuality: null,
      paymentTermsDays: null,
      needsManualReferral: null,
      productClassificationId: null,
      sourcingTeamClassificationId: null,
      supportTeamClassificationId: null,
      paymentMethodTypesList: [],
      paymentTypesList: [],
      paymentTermOptions: PAYMENT_TERM_OPTIONS_WITH_NULL,
      productClassifications: [],
      sourcingTeamClassifications: [],
      supportTeamClassifications: [],
      loading: false,
      submitting: false,
      rules: {
        minQuality: [
          (v) => v <= 1 || 'Minimum Operator Quality cannot be greater than 1',
          (v) => v >= 0 || 'Minimum Operator Quality cannot be less than 0',
          (v) => {
            if (!v) {
              return true
            }
            const decimalCount = (v.toString().split('.')[1] || '').length
            return (
              decimalCount <= 2 ||
              'Input cannot have more than 2 decimal places'
            )
          },
        ],
      },
    }
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
    customerAccountPricingMethodKey() {
      return this.customerAccount?.defaults?.pricingMethodKey
    },
    customerAccountDiscountPercent() {
      return this.customerAccount?.defaults?.discountPercent
    },
    customerAccountMarkupPercent() {
      return this.customerAccount?.defaults?.markupPercent
    },
    customerAccountMinimumOperatorQuality() {
      return this.customerAccount?.defaults?.roundedMinQuality
    },
    customerAccountPaymentMethodType() {
      return this.customerAccount?.defaults?.paymentMethodType?.key
    },
    customerAccountNeedsManualReferral() {
      return this.customerAccount?.defaults?.needsManualReferral
    },
    customerAccountPaymentType() {
      return this.customerAccount?.defaults?.paymentType?.key
    },
    customerAccountDownPaymentPercent() {
      return this.customerAccount?.defaults?.downPaymentPercent
    },
    customerAccountPaymentTermsDays() {
      return this.customerAccount?.defaults?.paymentTermsDays
    },
    customerAccountProductClassificationId() {
      return this.customerAccount?.defaults?.productClassification?.id
    },
    customerAccountSourcingTeamClassificationId() {
      return this.customerAccount?.defaults?.sourcingTeamClassification?.id
    },
    customerAccountSupportTeamClassificationId() {
      return this.customerAccount?.defaults?.supportTeamClassification?.id
    },
    showDownPaymentPercent() {
      const selectedPaymentType = this.paymentTypesList?.find(
        (type) => type.key === this.paymentType
      )
      return (
        selectedPaymentType?.key === 'down_payment' ||
        selectedPaymentType?.key === 'bill_after_services'
      )
    },
  },
  watch: {
    customerAccount: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.loadData()
        }
      },
    },
  },
  async mounted() {
    await this.loadOptions()
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      getQuoteTypes: 'types/getQuoteTypes',
    }),
    isNotEmpty,
    isRequired,
    validateEmail,
    validateAddress,
    loadData() {
      this.pricingMethodKey = this.customerAccountPricingMethodKey || null
      this.paymentMethodType = this.customerAccountPaymentMethodType || null
      this.paymentType = this.customerAccountPaymentType || null
      this.downPaymentPercent = this.customerAccountDownPaymentPercent || null
      this.discountPercent = this.customerAccountDiscountPercent || null
      this.markupPercent = this.customerAccountMarkupPercent || null
      this.minQuality = this.customerAccountMinimumOperatorQuality || null
      this.paymentTermsDays = this.customerAccountPaymentTermsDays || null
      this.needsManualReferral = this.customerAccountNeedsManualReferral === true ? true : this.customerAccountNeedsManualReferral === false ? false : null
      this.productClassificationId =
        this.customerAccountProductClassificationId || null
      this.sourcingTeamClassificationId =
        this.customerAccountSourcingTeamClassificationId || null
      this.supportTeamClassificationId =
        this.customerAccountSupportTeamClassificationId || null
    },
    async loadOptions() {
      this.loading = true
      try {
        const quoteTypesResponse = await this.getQuoteTypes().catch((e) => e)
        const quoteTypes = quoteTypesResponse.data

        const validPaymentMethodTypes = ['ach', 'credit_card', 'check']

        this.paymentMethodTypesList =
          quoteTypes?.paymentMethods?.filter((paymentMethod) =>
            validPaymentMethodTypes.includes(paymentMethod.key)
          ) || []
        this.paymentMethodTypesList.push({
          key: null,
          label: 'Inherit',
        })
        this.paymentTypesList =
          quoteTypes?.payments?.filter((paymentType) =>
            ALLOWED_PAYMENT_METHOD_KEYS.has(paymentType.key)
          ) || []
        this.paymentTypesList.push({
          key: null,
          label: 'Inherit',
        })
        const productClassificationResponse = await classifications.getClassificationsByType(
          { classificationTypeKey: PRODUCT_CLASSIFICATION_TYPE_KEY }
        )
        this.productClassifications =
          productClassificationResponse.data.subClassifications
        this.productClassifications.push({
          classificationId: null,
          label: 'Inherit',
        })
        const sourcingTeamClassificationResponse = await classifications.getClassificationsByType(
          { classificationTypeKey: SOURCING_TEAM_CLASSIFICATION_TYPE_KEY }
        )
        this.sourcingTeamClassifications =
          sourcingTeamClassificationResponse.data.subClassifications
        this.sourcingTeamClassifications.push({
          classificationId: null,
          label: 'Inherit',
        })
        const supportTeamClassificationResponse = await classifications.getClassificationsByType(
          { classificationTypeKey: SUPPORT_TEAM_CLASSIFICATION_TYPE_KEY }
        )
        this.supportTeamClassifications =
          supportTeamClassificationResponse.data.subClassifications
        this.supportTeamClassifications.push({
          classificationId: null,
          label: 'Inherit',
        })
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      if (
        !this.customerAccount?.id ||
        !this.$refs.customerAccountsDefaultSettingsSidebar.validate()
      ) {
        return
      }
      this.submitting = true
      const payload = {
        customerAccountId: this.customerAccount.id,
        productClassification: {
          id: this.productClassificationId,
        },
        sourcingTeamClassification: {
          id: this.sourcingTeamClassificationId,
        },
        supportTeamClassification: {
          id: this.supportTeamClassificationId,
        },
        downPaymentPercent: this.downPaymentPercent,
        discountPercent: this.discountPercent,
        markupPercent: this.markupPercent,
        minQuality: this.minQuality,
        paymentTermsDays: this.paymentTermsDays,
        paymentType: { key: this.paymentType },
        paymentMethodType: { key: this.paymentMethodType },
        pricingMethodKey: this.pricingMethodKey,
        needsManualReferral: this.needsManualReferral,
      }

      try {
        const updateCustomerAccountDefaultsResponse = await customerAccounts.updateCustomerAccountDefaultValueSet(
          payload
        )
        EventBus.$emit('load-customer-account')
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: 'Customer Account Configuration Updated.',
          })
        })
      } catch (e) {
        console.error(e)
      }

      this.submitting = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-accounts-default-settings-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
