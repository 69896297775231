
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class LiveTripOpsFiltering extends Vue {
  @Prop({ type: Array, required: true }) contracts!: any[]
  @Prop({ type: Array, required: true }) markets!: any[]
  @Prop({ type: Array, required: true }) tripStatuses!: any[]
  @Prop({ type: Array, required: true }) alerts!: any[]
  @Prop({ type: Array, required: true }) operators!: any[]

  @Prop({ type: Array, required: true }) selectedContracts!: any[]
  @Prop({ type: Array, required: true }) selectedMarkets!: any[]
  @Prop({ type: Array, required: true }) selectedTripStatuses!: any[]
  @Prop({ type: Array, required: true }) selectedAlerts!: any[]
  @Prop({ type: Array, required: true }) selectedOperators!: any[]

  get areAllContractsSelected() {
    return this.selectedContracts.length === this.contracts.length
  }

  get areAllMarketsSelected() {
    return this.selectedMarkets.length === this.markets.length
  }

  get areAllTripStatusesSelected() {
    return this.selectedTripStatuses.length === this.tripStatuses.length
  }

  get areAllAlertsSelected() {
    return this.selectedAlerts.length === this.alerts.length
  }

  get areAllOperatorsSelected() {
    return this.selectedOperators.length === this.operators.length
  }

  toggleAllContractsAreSelected() {
    this.updateContracts(
      this.areAllContractsSelected ? [] : this.contracts.map((c) => c.value)
    )
  }

  toggleAllMarketsAreSelected() {
    this.updateMarkets(
      this.areAllMarketsSelected ? [] : this.markets.map((m) => m.value)
    )
  }

  toggleAllTripStatusesAreSelected() {
    this.updateTripStatuses(
      this.areAllTripStatusesSelected
        ? []
        : this.tripStatuses.map((ts) => ts.value)
    )
  }

  toggleAllAlertsAreSelected() {
    this.updateAlerts(
      this.areAllAlertsSelected ? [] : this.alerts.map((a) => a.value)
    )
  }

  toggleAllOperatorsAreSelected() {
    this.updateOperators(
      this.areAllOperatorsSelected ? [] : this.operators.map((o) => o.value)
    )
  }

  updateContracts(e) {
    this.$emit('update-contracts', e)
  }

  updateMarkets(e) {
    this.$emit('update-markets', e)
  }

  updateTripStatuses(e) {
    this.$emit('update-trip-statuses', e)
  }

  updateAlerts(e) {
    this.$emit('update-alerts', e)
  }

  updateOperators(e) {
    this.$emit('update-operators', e)
  }
}
