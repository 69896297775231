
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { isRequired, isNotEmpty, validateLength } from '@/utils/validators'
import {
  getCompanyContactInfo,
  createContactLog,
  getReferralDriverContactInfo,
} from '@/services/tripMonitoring'
import { DateTime } from 'luxon'

export default {
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    companyId: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contactPreferenceText: '',
      contactEmail: '',
      contactTime: null,
      contactMethod: 'none',
      contactLogText: '',
      contactSetReminderEnabled: false,
      reminderTime: null,
      loading: true,
      submitting: false,
      contactMethodMap: [
        { text: 'None', value: 'none' },
        { text: 'Phone', value: 'phone' },
        { text: 'SMS', value: 'sms' },
        { text: 'Email', value: 'email' },
      ],
      contactPhoneNumberItems: [],
      selectedPhoneNumber: null,
      validateLength,
    }
  },
  async created() {
    switch (this.mode) {
      case 'driver':
        const contactInfoResponse = await getReferralDriverContactInfo(
          this.companyId,
          this.reservationId
        )
        const driverContactInfo = contactInfoResponse.data
        this.contactPreferenceText = driverContactInfo.contactPreferenceText
        this.contactPhoneNumberItems = driverContactInfo.driverContactInfo
          .filter((contactInfo) => !!contactInfo.phone)
          .map((contactInfo) => ({
            text: `${contactInfo.phone} (${contactInfo.firstName} ${contactInfo.lastName})`,
            value: contactInfo.phone,
          }))
        break
      case 'operator':
        const operatorContactInfoResponse = await getCompanyContactInfo(
          this.companyId
        )
        const companyContactInfo = operatorContactInfoResponse.data
        this.contactPreferenceText = companyContactInfo.contactPreferenceText
        const potentialContactPhoneNumbers = [
          {
            phone: companyContactInfo.phone,
            footer: '',
          },
          {
            phone: companyContactInfo.opsPhone,
            footer: '(Ops)',
          },
          {
            phone: companyContactInfo.partnerPhone,
            footer: '(Partner)',
          },
          {
            phone: companyContactInfo.smsPhone,
            footer: '(SMS)',
          },
        ]

        this.contactPhoneNumberItems = potentialContactPhoneNumbers
          .filter((companyPhone) => !!companyPhone.phone)
          .map((companyPhone) => ({
            text: `${companyPhone.phone} ${companyPhone.footer}`.trim(),
            value: companyPhone.phone,
          }))
        this.contactEmail = companyContactInfo.email
        break
      default:
        this.showAlert({
          type: 'error',
          message: 'UI error, could not determine who to contact',
        })
    }
    this.selectedPhoneNumber = this.contactPhoneNumberItems[0]?.value
    this.loading = false
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    isRequired,
    isNotEmpty,
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    copySelectedPhoneNumber() {
      navigator.clipboard.writeText(this.selectedPhoneNumber)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Phone number copied to your clipboard',
      })
    },
    copyEmail() {
      navigator.clipboard.writeText(this.contactEmail)
      this.$store.dispatch('app/showAlert', {
        type: 'success',
        message: 'Email copied to your clipboard',
      })
    },
    async submit() {
      if (!this.$refs['contact-form'].validate()) {
        return
      }
      this.submitting = true

      const contactDatetime = DateTime.fromISO(this.contactTime, { setZone: true }).setZone("America/New_York", { keepLocalTime: true} ).toISO()
      const reminderDatetime = DateTime.fromISO(this.reminderTime, { setZone: true }).setZone("America/New_York", { keepLocalTime: true} ).toISO()

      const payload = {
        companyId: this.companyId,
        contactPreferenceText: this.contactPreferenceText,
        reservationId: this.reservationId,
        contactLogText: this.contactLogText,
        contactMethod: this.contactMethod,
        contactType: this.mode,
        contactTime: contactDatetime,
        reminderTime: this.contactSetReminderEnabled ? reminderDatetime : null,
      }

      try {
        const createContactLogResponse = await createContactLog(payload)

        EventBus.$emit('global-table-view-refresh', {
          type: 'success',
          message: 'Contact log successfully added',
        })
      } catch (e) {
        const message = 'Error adding contact log'
        this.showAlert({
          type: 'error',
          message: message,
        })
        console.error(e)
        this.submitting = false
        return
      }

      this.submitting = false
      this.close()
    },
  },
}
