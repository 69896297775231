<template>
  <span
    v-if="buttonType === 'text'"
    class="edit-button"
    @click.stop="getVehiclesStopsEtas"
  >
    Calculate ETA
  </span>

  <v-btn
    v-else-if="buttonType === 'refresh-icon'"
    :disabled="isDisabled"
    style="margin: 0"
    flat
    icon
  >
    <v-icon size="20" @click.stop="getVehiclesStopsEtas">mdi-refresh</v-icon>
  </v-btn>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { gmapApi } from 'vue2-google-maps'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    stopId: { type: Number, required: true },
    vehicleAddress: { type: Map, required: true },
    buttonType: { type: String, required: true },
  },
  data() {
    return {
      isDisabled: true,
    }
  },
  computed: {
    ...mapGetters({
      distanceAndDuration: 'trackingDevices/distanceAndDuration',
    }),
    google: gmapApi,
    vehicleIds() {
      return Array.from(this.vehicleAddress.keys())
    },
  },
  mounted() {
    this.checkIfDisabled()
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
      getTrackingDevicesByVehicleIdsV2:
        'trackingDevices/getTrackingDevicesByVehicleIdsV2',
      calculateDistanceAndDuration:
        'trackingDevices/calculateDistanceAndDuration',
      calculateDistanceAndDurationV2:
        'trackingDevices/calculateDistanceAndDurationV2',
    }),
    checkIfDisabled() {
      let isDisabled = true
      this.vehicleAddress.forEach((address, vehicleId) => {
        const lastUpdated = this.distanceAndDuration(
          vehicleId,
          address.addressId
        )?.lastUpdated
        if (
          !lastUpdated ||
          DateTime.fromISO(lastUpdated).diffNow().shiftTo('seconds').seconds <
            -300
        ) {
          isDisabled = false
        }
      })
      this.isDisabled = isDisabled
    },
    async getTrackingData(vehicleIds) {
      const trackers = await this.getTrackingDevicesByVehicleIdsV2({
        requestBody: {
          vehicleIds,
        },
        prioritizeEld: false,
      })
      EventBus.$emit('update-sync-time', vehicleIds)
      return trackers
    },
    async getVehiclesStopsEtas() {
      const trackers = await this.getTrackingData(this.vehicleIds)
      for (const tracker of trackers.data.devices) {
        const address = this.vehicleAddress.get(tracker.vehicleId)?.address
        await this.calculateDistanceAndDurationV2({
          journeyStopId: this.vehicleAddress.get(tracker.vehicleId)
            ?.journeyStopId,
          tracker,
          address,
        })
        this.isDisabled = true
      }
      this.$emit('eta')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  width: auto;
  cursor: pointer;
  color: $primary;
  font-size: 13px;
  padding: 7px;
  ::v-deep button {
    padding: 4px;
  }
}
</style>
