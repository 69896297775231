import Vue from 'vue'
import { authComputed } from '@/state/helpers'
import { getTripAssignmentsForReservation, getRoutesForReservations } from '@/services/reservations'
import { getReservationItineraryAsString } from '@/utils/reservation'
import router from '@/router/index'
import { store } from '@/state/store'
import { EventBus } from '@/utils/event-bus'
import { ReferralStatus } from '@/utils/enum'

interface Row {
  referralStatus?: string
  contractId?: string
  reservationId: number
  item?: { reservationId: number }
  paymentStatus?: string
  purchaseOrder?: boolean
  needsManualReferral?: boolean
  isPendingConfirmation?: boolean
  isCharterUpQuote?: boolean
  poNumber?: string
  poStatus?: string
  purchaseOrderStatuses?: string[]
  externalId?: string
}

const { getters = {}, dispatch = {} } = store
const currentUser = getters['auth/currentUser']
const isShuttleCreateQuoteEnabled =
  getters['featureToggles/isShuttleCreateQuoteEnabled']
const canCreateShuttleQuotes = getters['auth/hasPermission'](
  'canCreateShuttleQuotes'
)
const canEditPurchaseOrders = getters['auth/hasPermission'](
    'canEditPurchaseOrders'
)

function referralStatusIconColor(row: Row): string {
  if ([ReferralStatus.FullyAccepted.toString(), ReferralStatus.FullyConfirmed.toString()].includes(row?.referralStatus)) {
    return 'success'
  }
  if ([ReferralStatus.FullyOffered.toString()].includes(row?.referralStatus)) {
    return 'warning'
  }
  return 'error'
}

export default async function (component: any) {
  function MenuDataMapper(
    row: Row,
    rowProps: Record<string, any>,
    handleAction: (action: string, props: Record<string, any>) => void,
    canOpenCharterUpQuote: boolean,
    isAdmin: boolean
  ) {
    if (row.contractId) {
      return [
        {
          icon: () => 'affiliate_management',
          title: () => 'Refer Trip',
          color: () => referralStatusIconColor(row),
          quickAccess: true,
          click: async () => {
            if (isShuttleCreateQuoteEnabled && canCreateShuttleQuotes) {
              try {
                const routeResults = await getRoutesForReservations([
                  row.reservationId,
                ])
                if (routeResults.data?.tripVehicleGroups?.length) {
                  const component = () =>
                    import(
                      '@/components/ReservationMultiselectRouteQuickRefer.vue'
                    )
                  store.dispatch('app/openSidebarDialog', {
                    data: {
                      reservations: [{ index: 0, item: row }],
                      routes: routeResults.data.tripVehicleGroups,
                      title: 'Offer Route Referrals',
                    },
                    component,
                  })
                } else {
                  handleAction('QUICK_REFER', rowProps)
                }
              } catch (err) {
                handleAction('QUICK_REFER', rowProps)
              }
            } else {
              handleAction('QUICK_REFER', rowProps)
            }
          },
          condition: () => false
        },
        {
          icon: () => 'map',
          viewBox: '1 2 24 26',
          quickAccess: true,
          title: () => 'Open Tracking Map',
          click: async () => {
            const reservation = await store.dispatch(
              'reservations/fetchReservationById',
              {
                reservationId: rowProps.item.reservationId,
                companyId: currentUser?.companyId,
                viewRejectedReferrals: false,
                force: true,
              }
            )

            if (!reservation) {
              return
            }

            let referredProviders = reservation?.referredTo?.filter(
              (ref) => ref.referralStatus === 'accepted'
            )
            referredProviders = referredProviders?.map((provider) => {
              let computedName = provider.companyName
              if (
                referredProviders.filter(
                  (p) => p.companyId === provider.companyId
                ).length > 1
              ) {
                computedName += ` - ${provider.managedId}`
              }
              return { computedName, ...provider }
            })

            const currentProvider = reservation?.referredTo?.[0]
            let tripVehicleGroups = []
            if (currentProvider?.tripVehicleGroups) {
              tripVehicleGroups = currentProvider.tripVehicleGroups
            } else {
              tripVehicleGroups = reservation.tripVehicleGroups
            }

            const trackingStatusResult = await store.dispatch(
              'reservations/getReservationTrackingStatuses',
              [rowProps.item.reservationId]
            )
            const trackingReservationStatus =
              trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
            const trackingAllocation =
              trackingStatusResult?.data?.trackingStatuses[0]
                ?.trackingAllocation

            const itineraryItems = reservation.stops?.map((stop, index) => {
              const {
                stopId,
                address,
                notes,
                stopNotes,
                risks,
                flightInformation,
                tripVehicleGroupId,
                type,
                pickupDatetime,
                dropoffDatetime
              } = stop
              const newStop = {
                stopNumber: index + 1,
                stopId,
                address,
                timeZone: address.timeZone,
                notes,
                stopNotes,
                risks,
                flightInformation,
                tripVehicleGroupId,
                type,
                pickupDatetime,
                dropoffDatetime
              }
              if (stop.dropoffDatetime && stop.pickupDatetime) {
                newStop.type = 'Drop-off\nPickup'
              } else if (stop.dropoffDatetime) {
                newStop.type = 'Drop-off'
              } else if (stop.pickupDatetime) {
                newStop.type = 'Pickup'
              } else {
                newStop.type = '--'
              }
              newStop.pickupDatetime = stop.pickupDatetime
              newStop.dropoffDatetime = stop.dropoffDatetime
              return newStop
            })

            const vehicleAssignmentsData = await getTripAssignmentsForReservation(
              { reservationIds: [reservation.reservationId] }
            )
            const tripAssignments =
              vehicleAssignmentsData.data.vehicleAssignments

            const component = () =>
              import('@/components/ReservationMapSidebar.vue')
            store.dispatch('app/openDialog', {
              data: {
                active: true,
                isLoading: false,
                reservationId: rowProps.item.reservationId,
                managedId: rowProps.item.managedId,
                reservationStatus: rowProps.item.reservationStatus,
                journeys: reservation.journeys,
                startDate: reservation.startDate,
                tripVehicleGroups: tripVehicleGroups,
                stops: reservation.stops,
                referredProviders,
                fullTripVehicleGroups: reservation.tripVehicleGroups,
                trackingAllocation,
                trackingReservationStatus,
                tripAssignments,
                itineraryItems,
                linkToReservationDetail: true
              },
              component,
            })
          },
          condition: () => false
        },
        {
          icon: () => 'create',
          viewBox: '0 0 24 24',
          title: () => 'Edit Trip Name',
          click: () => handleAction('EDIT_TRIP_NAME', rowProps),
        },
        {
          icon: () => 'create',
          viewBox: '0 0 24 24',
          title: () => 'Edit Trip',
          click: () =>
            router.push({
              path: `/reservations/edit/${rowProps.item.reservationId}`,
              props: {
                mode: 'edit',
              },
            }),
        },
        {
          icon: () => 'copy',
          title: () => 'Copy Itinerary',
          click: async () => {
            const itinerary = await getReservationItineraryAsString(row)
            navigator.clipboard.writeText(itinerary)
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Itinerary info copied to your clipboard',
            })
          },
          condition: () => currentUser && currentUser.companyId === 2,
        },
        {
          icon: () => 'info',
          title: () => 'Remove From MARGE',
          click: async () => {
            await store.dispatch('reservations/setManualReferralNeeded', {
              reservationIds: [row.reservationId],
              needsManualReferral: true,
            })
            EventBus.$emit('global-table-view-refresh')
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'Removed from MARGE',
            })
          },
          condition: () => !row.needsManualReferral,
        },
        {
          icon: () => 'info',
          title: () => 'Restart MARGE',
          click: async () => {
            await store.dispatch('reservations/setManualReferralNeeded', {
              reservationIds: [row.reservationId],
              needsManualReferral: false,
            })
            EventBus.$emit('global-table-view-refresh')
            store.dispatch('app/showAlert', {
              type: 'success',
              message: 'MARGE Restarted',
            })
          },
          condition: () => row.needsManualReferral,
        },
        {
          icon: () => 'secure_payment',
          title: () => 'Collect Payment',
          click: () => handleAction('COLLECT_CONTRACT_PAYMENT', rowProps),
          condition: () =>
            ['not_paid', 'partially_paid'].includes(row.paymentStatus),
        },
      ]
    }

    return [
      {
        icon: () => 'affiliate_management',
        title: () => 'Refer Trip',
        color: () => referralStatusIconColor(row),
        quickAccess: true,
        click: async () => {
          if (isShuttleCreateQuoteEnabled && canCreateShuttleQuotes) {
            try {
              const routeResults = await getRoutesForReservations([
                row.reservationId,
              ])
              if (routeResults.data?.tripVehicleGroups?.length) {
                const component = () =>
                  import(
                    '@/components/ReservationMultiselectRouteQuickRefer.vue'
                  )
                store.dispatch('app/openSidebarDialog', {
                  data: {
                    reservations: [{ index: 0, item: row }],
                    routes: routeResults.data.tripVehicleGroups,
                    title: 'Offer Route Referrals',
                  },
                  component,
                })
              } else {
                handleAction('QUICK_REFER', rowProps)
              }
            } catch (err) {
              handleAction('QUICK_REFER', rowProps)
            }
          } else {
            handleAction('QUICK_REFER', rowProps)
          }
        },
        condition: () => false
      },
      {
        icon: () => 'map',
        viewBox: '1 2 24 26',
        quickAccess: true,
        title: () => 'Open Tracking Map',
        click: async () => {
          const reservation = await store.dispatch(
            'reservations/fetchReservationById',
            {
              reservationId: rowProps.item.reservationId,
              companyId: currentUser?.companyId,
              viewRejectedReferrals: false,
              force: true,
            }
          )

          if (!reservation) {
            return
          }

          let referredProviders = reservation?.referredTo?.filter(
            (ref) => ref.referralStatus === 'accepted'
          )
          referredProviders = referredProviders?.map((provider) => {
            let computedName = provider.companyName
            if (
              referredProviders.filter(
                (p) => p.companyId === provider.companyId
              ).length > 1
            ) {
              computedName += ` - ${provider.managedId}`
            }
            return { computedName, ...provider }
          })

          const currentProvider = reservation?.referredTo?.[0]
          let tripVehicleGroups = []
          if (currentProvider?.tripVehicleGroups) {
            tripVehicleGroups = currentProvider.tripVehicleGroups
          } else {
            tripVehicleGroups = reservation.tripVehicleGroups
          }

          const trackingStatusResult = await store.dispatch(
            'reservations/getReservationTrackingStatuses',
            [rowProps.item.reservationId]
          )
          const trackingReservationStatus =
            trackingStatusResult?.data?.trackingStatuses[0]?.reservationStatus
          const trackingAllocation =
            trackingStatusResult?.data?.trackingStatuses[0]?.trackingAllocation

          const itineraryItems = reservation.stops?.map((stop, index) => {
            const {
              stopId,
              address,
              notes,
              stopNotes,
              risks,
              flightInformation,
              tripVehicleGroupId,
              type,
              pickupDatetime,
              dropoffDatetime
            } = stop
            const newStop = {
              stopNumber: index + 1,
              stopId,
              address,
              timeZone: address.timeZone,
              notes,
              stopNotes,
              risks,
              flightInformation,
              tripVehicleGroupId,
              type,
              pickupDatetime,
              dropoffDatetime
            }
            if (stop.dropoffDatetime && stop.pickupDatetime) {
              newStop.type = 'Drop-off\nPickup'
            } else if (stop.dropoffDatetime) {
              newStop.type = 'Drop-off'
            } else if (stop.pickupDatetime) {
              newStop.type = 'Pickup'
            } else {
              newStop.type = '--'
            }
            newStop.pickupDatetime = stop.pickupDatetime
            newStop.dropoffDatetime = stop.dropoffDatetime
            return newStop
          })

          const vehicleAssignmentsData = await getTripAssignmentsForReservation(
            { reservationIds: [reservation.reservationId] }
          )
          const tripAssignments = vehicleAssignmentsData.data.vehicleAssignments

          const closeHandler = () => {
            const pattern = /\map\/\d+/
            const path = router.app._route.path.replace(pattern, '')
            history.replaceState({}, null, `/ci${path}`)
          }

          const path = router.app._route.path.replace(router.app._route.path, '/reservations/')
          history.replaceState({}, null, `/ci${path}map/${rowProps.item.reservationId}`)

          const component = () =>
            import('@/components/ReservationMapSidebar.vue')
          store.dispatch('app/openDialog', {
            data: {
              active: true,
              isLoading: false,
              reservationId: rowProps.item.reservationId,
              managedId: rowProps.item.managedId,
              reservationStatus: rowProps.item.reservationStatus,
              journeys: reservation.journeys,
              startDate: reservation.startDate,
              tripVehicleGroups: tripVehicleGroups,
              stops: reservation.stops,
              referredProviders,
              fullTripVehicleGroups: reservation.tripVehicleGroups,
              trackingAllocation,
              trackingReservationStatus,
              tripAssignments,
              itineraryItems,
              closeHandler,
              linkToReservationDetail: true
            },
            component,
          })
        },
        condition: () => false
      },
      {
        icon: () => 'trash',
        title: () => 'Delete Reservation',
        condition: () => isAdmin,
        quickAccess: false,
        click: () => handleAction('DELETE', rowProps),
      },
      {
        icon: () => 'note',
        title: () => 'Comments',
        click: () => handleAction('COMMENTS', rowProps),
      },
      {
        icon: () => 'secure_payment',
        title: () => 'Collect Payment',
        click: () => handleAction('COLLECT_PAYMENT', rowProps),
        condition: () =>
          ['not_paid', 'partially_paid'].includes(row.paymentStatus),
      },
      {
        icon: () => 'purchase_order',
        title: () => 'Edit Purchase Order',
        click: () => editPurchaseOrder(row),
        condition: () => canEditPurchaseOrders && row.purchaseOrder,
      },
      {
        icon: () => 'copy',
        title: () => 'Copy Itinerary',
        click: async () => {
          const itinerary = await getReservationItineraryAsString(row)
          navigator.clipboard.writeText(itinerary)
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Itinerary info copied to your clipboard',
          })
        },
        condition: () => currentUser && currentUser.companyId === 2,
      },
      {
        icon: () => 'info',
        title: () => 'Remove from MARGE',
        click: async () => {
          await store.dispatch('reservations/setManualReferralNeeded', {
            reservationIds: [row.reservationId],
            needsManualReferral: true,
          })
          EventBus.$emit('global-table-view-refresh')
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'Removed from MARGE',
          })
        },
        condition: () => !row.needsManualReferral,
      },
      {
        icon: () => 'info',
        title: () => 'Restart Marge',
        click: async () => {
          await store.dispatch('reservations/setManualReferralNeeded', {
            reservationIds: [row.reservationId],
            needsManualReferral: false,
          })
          EventBus.$emit('global-table-view-refresh')
          store.dispatch('app/showAlert', {
            type: 'success',
            message: 'MARGE Restarted',
          })
        },
        condition: () => row.needsManualReferral,
      },
      {
        icon: () => 'error_color',
        title: () =>
          row.isPendingConfirmation
            ? 'Remove Pending Confirmation'
            : 'Mark Pending Confirmation',
        click: async () => {
          await store
            .dispatch(
              'reservations/togglePendingConfirmation',
              row.reservationId
            )
            .then(() => {
              store.dispatch('app/showAlert', {
                type: 'success',
                message: 'Pending Confirmation Status Changed',
              })
              EventBus.$emit('global-table-view-refresh')
            })
        },
      },
      {
        icon: () => 'open_in_browser',
        viewBox: '0 0 24 24',
        title: () => 'Client View',
        condition: () => canOpenCharterUpQuote && row.isCharterUpQuote,
        path: () => `/charterup/reservations/${row.reservationId}`,
      },
    ]
  }

  function editPurchaseOrder(row: Row) {
    const component = () =>
      import('@/components/PurchaseOrderSidebar.vue')
    dispatch('app/openSidebarDialog', {
      data: {
        purchaseOrderNumber: row.poNumber,
        purchaseOrderStatus: row.poStatus,
        purchaseOrderStatuses: row.purchaseOrderStatuses,
        reservationExternalId: row.externalId,
        title: "PO Status",
      },
      component,
    })
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...authComputed,
      isAdmin() {
        const roles = this.currentUserProfile?.roles || []
        return !!roles.find(
          (r: any) =>
            r.roleName === 'is_free_admin' ||
            'is_paid_admin' ||
            'is_broker_admin' ||
            'is_admin_admin'
        )
      },
      canOpenCharterUpQuote() {
        const roles = this.currentUserProfile?.roles || []
        const charterUpRole = roles.find(
          (r: any) => r.roleName === 'is_charterup_sales'
        )
        const charterUpPermissions = charterUpRole?.permissions || []
        return !!charterUpPermissions.find(
          (p: any) => p.permissionName === 'canSendCharterUPQuote'
        )
      },
    },
    render(createElement: any) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            this.canOpenCharterUpQuote,
            this.isAdmin
          ),
        },
      })
    },
  })
}
