
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DateTime, Interval } from 'luxon'

import { PaymentMethodType, PaymentType, StagePaymentMethod } from '@/models/dto'
import { isMarketplaceQuote, shouldAutoPriceQuote } from '@/utils/quoteUtils'
import { currencyFilter } from '@/utils/currency'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import { PaymentMethodTypeId, PaymentStageKey, PaymentTypeId } from '@/utils/enum'
import { isNotEmpty, isRequired, validateGreaterThanZero, validateTripFormGroups } from '@/utils/validators'
import { PAYMENT_TERM_OPTIONS } from '@/models/paymentTerms'
import {
  PAYMENT_TERM_DAYS_FOR_DOWNPAYMENT,
  PAYMENT_TERM_DAYS_FOR_DOWNPAYMENT_MARKETPLACE,
  PAYMENT_TERM_DAYS_FOR_PURCHASE_ORDER,
} from '@/utils/constants'

const DEFAULT_DEPOSIT_PERCENT = 10

@Component
export default class QuoteFormTripPaymentOptions extends Vue {
  @Prop({ type: Object, required: true }) readonly tripData: any
  @Prop({ type: Object, required: true }) readonly quote: any
  @Prop({ type: String, default: '' }) readonly mode: string
  @Prop({ type: Number, required: true }) readonly tripIndex: number
  @Prop({ type: Number, default: null }) readonly total: number
  @Prop({ type: Boolean, default: false }) readonly showMarketplaceFeatures: boolean
  @Prop({ type: Object, default: null, required: false }) readonly quoteDefaults: any
  @Prop({ type: String, default: '' }) readonly validationKey: string
  @Prop({ type: Array, default: () => [] }) readonly paymentMethodTypes: PaymentMethodType[]
  @Prop({ type: Array, default: () => [] }) readonly paymentTypes: PaymentType[]

  paymentTermOptions = PAYMENT_TERM_OPTIONS
  formSubmitted: boolean = false
  selectedBalancePaymentMethodTypeId: number = null
  isCheckoutPaymentMethodSet: boolean = false
  isBalancePaymentMethodSet: boolean = false
  PaymentTypeId = PaymentTypeId
  PaymentStageKey = PaymentStageKey
  currencyFilter = currencyFilter

  get preferredPaymentMethodText(): string {
    const base = '* Preferred payment method'
    if (!this.quoteDefaults) {
      return base
    }
    return this.quoteDefaults?.useContractDetails ? `${base} - contract defaults` : `${base} - customer account defaults`
  }

  get isModeView(): boolean {
    return this.mode === 'view'
  }

  get isModeAdd(): boolean {
    return this.mode === 'add'
  }

  get isMarketplaceQuote(): boolean {
    return isMarketplaceQuote(this.quote)
  }

  get balanceDueDate(): string {
    return this.tripData?.dueDate || this.currentTrip?.dueDate
  }

  get currentTrip(): any {
    return this.$store.getters['quoteForm/getCurrentTrip']
  }

  get paymentTypeId(): number {
    return this.tripData?.paymentType?.id || this.currentTrip?.paymentType?.id
  }

  get paymentTermsDays(): number {
    return this.tripData?.paymentTermsDays || this.currentTrip?.paymentTermsDays
  }

  get checkoutPaymentMethods(): StagePaymentMethod[] {
    return this.tripData?.stagePaymentMethods?.checkoutPaymentMethods ||
      this.currentTrip?.stagePaymentMethods?.checkoutPaymentMethods ||
      []
  }

  get balancePaymentMethods(): StagePaymentMethod[] {
    return this.tripData?.stagePaymentMethods?.balancePaymentMethods ||
      this.currentTrip?.stagePaymentMethods?.balancePaymentMethods ||
      []
  }

  get isBalancePaymentMethodEnabled(): boolean {
    return this.paymentTypeId === PaymentTypeId.DownPayment
  }

  get isBalanceDueDateEnabled(): boolean {
    return [
      PaymentTypeId.DownPayment,
      PaymentTypeId.PurchaseOrder
    ].includes(this.paymentTypeId)
  }

  get shouldShowPaymentTerms(): boolean {
    return [
      PaymentTypeId.BillAfterServices,
      PaymentTypeId.PurchaseOrder
    ].includes(this.paymentTypeId)
  }

  get isPurchaseOrder(): boolean {
    return PaymentTypeId.PurchaseOrder === this.paymentTypeId
  }

  get balanceDueDateFlexProps(): any {
    return [
      PaymentTypeId.BillAfterServices,
      PaymentTypeId.PurchaseOrder
    ].includes(this.paymentTypeId)
      ? { xs4: true }
      : { xs3: true }
  }

  get earliestPickupDateTime(): DateTime {
    const stopToPickupTime = function (stop) {
      const pickupDate = stop.pickupDate
      const pickupTime = stop.pickupTime
      const timeZone = stop.address.timeZone

      return DateTime.fromISO(`${pickupDate}T${pickupTime}`, {
        zone: timeZone,
      })
    }

    const stops = this.tripData?.stops || deepClone(this.currentTrip?.stops)
    const pickupDateTimes = stops
      ?.filter((s) => !!s.pickupDate)
      .map((s) => stopToPickupTime(s))

    let earliestPickupDateTime = null
    for (const pickupDateTime of pickupDateTimes) {
      if (
        earliestPickupDateTime === null ||
        pickupDateTime.toMillis() < earliestPickupDateTime.toMillis()
      ) {
        earliestPickupDateTime = pickupDateTime
      }
    }

    return earliestPickupDateTime
  }

  get minPickupDaysAway(): number {
    if (this.earliestPickupDateTime === null) {
      return Infinity
    }

    return Interval.fromDateTimes(
      DateTime.local(),
      this.earliestPickupDateTime
    ).toDuration('days').days
  }

  get requiredDeposit(): number {
    return this.paymentTypeId === PaymentTypeId.FullPayment
      ? this.total
      : this.total * this.depositPercentage * 0.01
  }

  get depositPercentage(): number {
    if (
      this.tripData?.depositPercentage === null ||
      this.tripData?.depositPercentage === undefined
    ) {
      return parseInt(this.currentTrip?.depositPercentage)
    }
    return parseInt(this.tripData?.depositPercentage)
  }

  get isPrePricingMode(): boolean {
    return !shouldAutoPriceQuote(this.quote)
  }

  get hasAccountDefaultPaymentType(): boolean {
    return !!this.quoteDefaults?.paymentType?.id
  }

  get hasAccountDefaultPaymentMethod(): boolean {
    return !!this.quoteDefaults?.paymentMethodTypes?.length
  }

  get hasAccountDefaultPaymentDeposit(): boolean {
    return !!this.quoteDefaults?.downPaymentPercent &&
      this.quoteDefaults.downPaymentPercent >= 0 &&
      this.quoteDefaults.downPaymentPercent <= 100
  }

  get canAutoSetPaymentType(): boolean {
    return !this.isModeView && this.tripData.autoSetPaymentType
  }

  @Watch('quoteDefaults.paymentTermsDays', { immediate: true, deep: true })
  onQuoteDefaultPaymentTermsDaysChange(): void {
    if (!this.paymentTermsDays) {
      this.resetPaymentTerms()
    }
  }

  @Watch('paymentMethodTypes')
  onPaymentMethodTypesChanged(
    newValue: PaymentMethodType[],
    oldValue: PaymentMethodType[]
  ): void {
    if (!oldValue) {
      this.autoSetPaymentOptions()
    }
  }

  @Watch('showMarketplaceFeatures')
  onShowMarketplaceFeaturesChanged(): void {
    this.autoSetPaymentOptions()
  }

  @Watch('tripData.stops', { immediate: true, deep: true })
  onStopsChanged(): void {
    this.autoSetPaymentType()
  }

  @Watch('validationKey')
  onValidationKeyChanged(): void {
    this.checkPaymentMethodSelections()
    this.validateForms()
  }

  @Watch('currentTrip.tripVehicleGroups')
  onTripVehicleGroupsChanged(): void {
    this.setCustomerAccountDefaults()
  }

  @Watch('quote.customer', { deep: true })
  onCustomerChanged(): void {
    this.autoSetPaymentOptions()
  }

  @Watch('tripData.paymentTypeId')
  onPaymentTypeIdChanged() {
    this.resetPaymentTerms()
    this.updateBalanceDueDate()
  }

  @Watch('earliestPickupDateTime')
  onEarliestPickupDateTimeChanged() {
    this.updateBalanceDueDate()
  }

  updateBalanceDueDate(): void {
    if (!this.earliestPickupDateTime) {
      return
    }

    switch (this.paymentTypeId) {
      case PaymentTypeId.PurchaseOrder:
        const dueDatePO = this.earliestPickupDateTime.plus({ days: this.paymentTermsDays })
        this.setBalanceDueDate(dueDatePO)
        break

      case PaymentTypeId.DownPayment:
        const today = DateTime.local()
        const daysToSubtract = this.isMarketplaceQuote ? PAYMENT_TERM_DAYS_FOR_DOWNPAYMENT_MARKETPLACE : PAYMENT_TERM_DAYS_FOR_DOWNPAYMENT
        const dueDateDP = this.earliestPickupDateTime.minus({ days: daysToSubtract })
        if (dueDateDP > today) {
          this.setBalanceDueDate(dueDateDP.toFormat('yyyy-MM-dd'))
        }
        break

      default:
        this.setBalanceDueDate(null)
        break
    }
  }

  resetPaymentTerms(): void {
    switch (this.paymentTypeId) {
      case PaymentTypeId.BillAfterServices:
        this.setPaymentTermsDays(this.quoteDefaults.paymentTermsDays ||
          PAYMENT_TERM_DAYS_FOR_PURCHASE_ORDER)
        break

      case PaymentTypeId.PurchaseOrder:
        this.setPaymentTermsDays(PAYMENT_TERM_DAYS_FOR_PURCHASE_ORDER)
        break

      case PaymentTypeId.FullPayment:
        this.setPaymentTermsDays(null)
        break

      case PaymentTypeId.DownPayment:
        this.setPaymentTermsDays(null)
        break

      default:
        break
    }
  }

  async mounted(): Promise<void> {
    this.autoSetPaymentOptions()
    this.resetPaymentTerms()
  }

  setCustomerAccountDefaults(): void {
    if (!this.quoteDefaults) {
      return
    }
    if (this.hasAccountDefaultPaymentType) {
      this.handlePaymentTypeChange(this.quoteDefaults.paymentType.id)
    }
    if (this.hasAccountDefaultPaymentDeposit) {
      this.setDepositPercent(this.quoteDefaults.downPaymentPercent)
    }
    if (this.hasAccountDefaultPaymentMethod) {
      this.setCustomerAccountDefaultPaymentMethods()
    }
  }

  autoSetPaymentOptions(): void {
    this.checkPaymentMethodSelections()
    if (!this.isModeAdd) {
      return
    }

    this.setCustomerAccountDefaults()
    this.autoSetPaymentType()
    this.autoSetPaymentDeposit()
    this.autoSetPaymentMethods()

    this.checkPaymentMethodSelections()
    EventBus.$emit('recalculate-payment-methods')
  }

  autoSetPaymentType(): void {
    if (!this.canAutoSetPaymentType || this.hasAccountDefaultPaymentType) {
      return
    }
    let paymentTypeId = PaymentTypeId.DownPayment
    if (!this.isPrePricingMode) {
      paymentTypeId = this.minPickupDaysAway < 7
        ? PaymentTypeId.FullPayment
        : PaymentTypeId.DownPayment
    }
    this.handlePaymentTypeChange(paymentTypeId)
  }

  autoSetPaymentDeposit(): void {
    if (this.hasAccountDefaultPaymentDeposit) {
      return
    }
    this.setDepositPercent(DEFAULT_DEPOSIT_PERCENT)
  }

  autoSetPaymentMethods(): void {
    if (this.hasAccountDefaultPaymentMethod) {
      return
    }
    this.checkAllowedPaymentMethodsSet()
    if (!this.isCheckoutPaymentMethodSet) {
      this.handlePaymentMethodSelection(
        PaymentMethodTypeId.CreditCard, PaymentStageKey.Checkout, true
      )
      this.preClickPaymentMethodCheckBoxes(PaymentStageKey.Checkout)
    }
    if (this.isBalancePaymentMethodEnabled) {
      if (!this.isBalancePaymentMethodSet) {
        this.handlePaymentMethodSelection(
          PaymentMethodTypeId.CreditCard, PaymentStageKey.RemainingBalance, true
        )
        this.selectedBalancePaymentMethodTypeId = PaymentMethodTypeId.CreditCard
      }
    } else {
      this.clearPaymentMethods(PaymentStageKey.RemainingBalance)
    }
    this.checkPaymentMethodSelections()
  }

  handlePaymentTermsDaysChange(paymentTermsDays: number): void {
    if (!paymentTermsDays) {
      return
    }
    this.setPaymentTermsDays(paymentTermsDays)
  }

  handlePaymentTypeChange(paymentTypeId: number): void {
    if (!paymentTypeId) {
      return
    }
    this.setPaymentType(paymentTypeId)
    EventBus.$emit('recalculate-payment-methods')
  }

  setSelectedBalancePaymentMethodTypeId(): void {
    const selectedBalancePaymentMethod = this.balancePaymentMethods.find(
      (method) => method.isAllowed
    )
    if (selectedBalancePaymentMethod) {
      this.selectedBalancePaymentMethodTypeId = selectedBalancePaymentMethod.paymentMethodId
    }
  }

  handlePaymentMethodSelection(
    paymentMethodTypeId: number,
    paymentStageKey: PaymentStageKey,
    isAllowed: boolean
  ): void {
    EventBus.$emit(
      'set-trip-payment-method',
      this.tripIndex,
      paymentStageKey,
      paymentMethodTypeId,
      isAllowed
    )
    this.checkPaymentMethodSelections()
  }

  checkPaymentMethodSelections(): void {
    this.checkAllowedPaymentMethodsSet()

    const hasCheckoutPaymentMethodFailures = !this.isCheckoutPaymentMethodSet
    const hasBalancePaymentMethodFailures = this.isBalancePaymentMethodEnabled &&
      !this.isBalancePaymentMethodSet
    const hasFailures = hasCheckoutPaymentMethodFailures ||
      hasBalancePaymentMethodFailures

    EventBus.$emit('recalculate-payment-methods')
    this.$emit('validation-results', {
      tripIndex: this.tripIndex,
      tripModule: 'payment-method',
      hasFailures,
    })

    if (
        this.isBalancePaymentMethodEnabled &&
        this.isBalancePaymentMethodSet &&
        !this.selectedBalancePaymentMethodTypeId
    ) {
      this.setSelectedBalancePaymentMethodTypeId()
    }
  }

  checkAllowedPaymentMethodsSet(): void {
    if (this.checkoutPaymentMethods) {
      this.isCheckoutPaymentMethodSet
        = this.checkoutPaymentMethods.some((method) => method.isAllowed)
    }
    if (this.balancePaymentMethods) {
      this.isBalancePaymentMethodSet
        = this.balancePaymentMethods.some((method) => method.isAllowed)
    }
  }

  getPaymentMethodLabel(paymentMethodTypeId: number): string {
    if (!this.paymentMethodTypes) {
      return ''
    }

    const paymentMethodType = this.paymentMethodTypes.find((method) =>
      method.id === paymentMethodTypeId
    )

    const isDefaultPaymentMethodType = this.quoteDefaults?.paymentMethodTypes.some(type => type.id === paymentMethodType.id)
    if (isDefaultPaymentMethodType && paymentMethodType?.label) {
      return paymentMethodType.label + "*"
    }

    return paymentMethodType?.label || ''
  }

  get formattedPaymentTypes() {
    const defaultPaymentTypeId = this.quoteDefaults?.paymentType?.id

    return this.paymentTypes.map((paymentType) => {
      if (paymentType.id === defaultPaymentTypeId) {
        return { ...paymentType, label: `${paymentType.label} *` }
      }
      return paymentType
    })
  }

  get formattedPaymentTerms() {
    const defaultPaymentTermsDays = this.quoteDefaults?.paymentTermsDays

    return this.paymentTermOptions?.map((paymentTerm) => {
      if (paymentTerm.value === defaultPaymentTermsDays) {
        return { ...paymentTerm, label: `${paymentTerm.label} *` }
      }
      return paymentTerm
    })
  }

  clearPaymentMethods(paymentStageKey: PaymentStageKey): void {
    if (!this.isModeAdd || !this.tripData.stagePaymentMethods) {
      return
    }
    let stagePaymentMethods = []
    if (paymentStageKey === PaymentStageKey.Checkout) {
      stagePaymentMethods = this.tripData.stagePaymentMethods.checkoutPaymentMethods
    }
    if (paymentStageKey === PaymentStageKey.RemainingBalance) {
      stagePaymentMethods = this.tripData.stagePaymentMethods.balancePaymentMethods
    }

    stagePaymentMethods.forEach(
      (method) => {
        this.handlePaymentMethodSelection(method.paymentMethodId, paymentStageKey, false)
      }
    )
    this.checkPaymentMethodSelections()
  }

  createPaymentMethodsRefMap(paymentStageKey: string): any {
    const refMap = {}

    let refs = null
    if (paymentStageKey === PaymentStageKey.Checkout) {
      refs = this.$refs['checkout-payment-method-checkboxes']
    }
    if (paymentStageKey === PaymentStageKey.RemainingBalance) {
      refs = this.$refs['balance-payment-method-checkboxes']
    }

    if (refs) {
      for (const ref of refs) {
        refMap[ref.textContent.trim()] = ref
      }
    }

    return refMap
  }

  preClickPaymentMethodCheckBoxes(paymentStageKey: PaymentStageKey): void {
    const paymentMethodsRefMap = this.createPaymentMethodsRefMap(paymentStageKey)

    let tripPaymentMethods = []
    if (paymentStageKey === PaymentStageKey.Checkout) {
      tripPaymentMethods = this.checkoutPaymentMethods
    }
    if (paymentStageKey === PaymentStageKey.RemainingBalance) {
      tripPaymentMethods = this.balancePaymentMethods
    }

    tripPaymentMethods
      .filter((method) => method.isAllowed)
      .forEach((method) => {
        const label = this.getPaymentMethodLabel(method.paymentMethodId)
        const ref = paymentMethodsRefMap[label]
        ref.firstChild.click()
      })
  }

  setCustomerAccountDefaultPaymentMethods(): void {
    const defaultPaymentMethodTypeIds = this.quoteDefaults?.paymentMethodTypes?.map((type) => type.id) || []
    this.clearPaymentMethods(PaymentStageKey.Checkout)
    for (const methodTypeId of defaultPaymentMethodTypeIds) {
      this.handlePaymentMethodSelection(methodTypeId, PaymentStageKey.Checkout, true)
    }

    if (this.isBalancePaymentMethodEnabled) {

      //We permit only one balancePaymentMethodType, but contracts can support multiple allowed payment method types.
      //So, we are defaulting to the first allowed type.
      const firstMethodTypeId = defaultPaymentMethodTypeIds[0]
      if (firstMethodTypeId) {
        this.handlePaymentMethodSelection(firstMethodTypeId, PaymentStageKey.RemainingBalance, true)
        this.selectedBalancePaymentMethodTypeId = firstMethodTypeId
      }
    } else {
      this.clearPaymentMethods(PaymentStageKey.RemainingBalance)
    }
    this.checkPaymentMethodSelections()
  }

  setPaymentType(paymentTypeId: number): void {
    const paymentType = this.paymentTypes?.find((type) => type.id === paymentTypeId)
    if (!paymentType) {
      return
    }
    const updatedTripData = {
      ...this.tripData,
      paymentType: deepClone(paymentType),
      paymentTypeId,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-payment-type-id', paymentType.id)
  }

  setPaymentTermsDays(paymentTermsDays: number): void {
    const updatedTripData = {
      ...this.tripData,
      paymentTermsDays: paymentTermsDays
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-payment-terms-days', paymentTermsDays)
  }

  setBalanceDueDate(dueDate: any): void {
    const updatedDueDate = dueDate && (dueDate instanceof DateTime) ? dueDate.toISO() : dueDate

    const updatedTripData = {
      ...this.tripData,
      dueDate: updatedDueDate
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-due-date', dueDate)
  }

  disableAutoSetPaymentType(): void {
    const updatedTripData = {
      ...this.tripData,
      autoSetPaymentType: false,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
  }

  setDepositPercent(percent: number): void {
    if (percent === null || percent === undefined) {
      return
    }
    const updatedTripData = {
      ...this.tripData,
      depositPercentage: percent,
    }
    EventBus.$emit('shallow-merge-trip-data', this.tripIndex, updatedTripData)
    EventBus.$emit('set-deposit-percentage', percent)
  }

  notEmptyValidator(message: string): Function[] {
    return this.formSubmitted
      ? [isRequired(true, isNotEmpty, { req: message, error: message })]
      : []
  }

  depositPercentValidator(): Function[] {
    const message = 'Deposit percentage is required'
    return this.formSubmitted
      ? [isRequired(
          true,
          () => validateGreaterThanZero(this.tripData.depositPercentage),
          { req: message, error: message }
        )]
      : []
  }

  validateForms(): void {
    const formNames = ['trip-payment-options-form']
    validateTripFormGroups.call(this, formNames, 'payment')
  }
}
